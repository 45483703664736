import { ChangeDetectionStrategy, Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { asyncScheduler, fromEvent, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

/**
 * The primary body content of a Dynamic Dialog Modal, excluding the header and
 * footer. If this can fit entirely on the screen without scrolling, it appears
 * seamless with the header and footer. If it is scrollable, there is a border
 * separating it from the footer and a shadow that appears at the top when the
 * user scrolls down.
 *
 * To achieve these effects, we use pseudoelements. While it would be ideal to
 * apply the shadow and border directly to the header and footer, this would
 * require cross-component communication. The developer experience of keeping it
 * all in one place is :cheffs-kiss:.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'bp-dialog-body',
	template: `<ng-content></ng-content>`,
	styles: [
		`
			:host {
				display: block;
				flex: 1;
				overflow-y: auto;
				padding: 0 28px;
				position: relative;

				/* Top shadow pseudo-element */
				&::before {
					content: '';
					position: sticky;
					top: 0;
					display: block;
					/* Height and margin-top cancel to avoid taking space */
					height: 8px;
					margin-top: -8px;
					/* Extend to the edges of the dialog (offsets the host padding) */
					margin-inline: -28px;
					z-index: 1;
					pointer-events: none;
					background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent);
					opacity: 0;
					transition: opacity 0.2s;
				}

				/* Bottom border pseudo-element */
				&::after {
					content: '';
					position: sticky;
					bottom: 0;
					display: block;
					height: 1px;
					/* Extend to the edges of the dialog (offsets the host padding) */
					margin: 0 -28px;
					background-color: #e2e6ec;
					opacity: 0;
					transition: opacity 0.2s;
				}

				/* Show shadow when scrolled down and border when scrollable */
				&.not-at-top::before,
				&.scrollable::after {
					opacity: 1;
				}
			}
		`,
	],
})
export class DialogBodyComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();
	private observer?: MutationObserver;

	constructor(private elementRef: ElementRef<HTMLElement>, private ngZone: NgZone) {}

	ngOnInit() {
		this.ngZone.runOutsideAngular(() => {
			this.updateScrollClasses();

			// Listen for scroll events
			fromEvent(this.elementRef.nativeElement, 'scroll', { passive: true })
				.pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }), takeUntil(this.destroy$))
				.subscribe(() => {
					this.updateScrollClasses();
				});

			// Listen for resize which might affect scrollability
			fromEvent(window, 'resize', { passive: true })
				.pipe(throttleTime(100), takeUntil(this.destroy$))
				.subscribe(() => {
					this.updateScrollClasses();
				});

			// Listen for DOM changes that might affect scrollability
			this.observer = new MutationObserver(() => {
				this.updateScrollClasses();
			});

			this.observer.observe(this.elementRef.nativeElement, {
				childList: true,
				subtree: true,
				characterData: true,
			});
		});
	}

	ngOnDestroy() {
		if (this.observer) {
			this.observer.disconnect();
		}
		this.destroy$.next();
		this.destroy$.complete();
	}

	private updateScrollClasses() {
		const el = this.elementRef.nativeElement;
		const hasScroll = el.scrollHeight > el.clientHeight;
		const isAtTop = el.scrollTop <= 0;
		const isAtBottom = el.scrollHeight - el.scrollTop - el.clientHeight <= 1;

		/*
		 * We are setting the classes here directly instead of using Angular host
		 * bindings. If we did use host binding, we'd need to run change detection
		 * afterwards. In this case, we are explicitly saying that these are *only*
		 * for styling purposes and won't otherwise affect the template.
		 */
		el.classList.toggle('scrollable', hasScroll);
		el.classList.toggle('at-top', isAtTop);
		el.classList.toggle('not-at-top', hasScroll && !isAtTop);
		el.classList.toggle('at-bottom', isAtBottom);
	}
}
