import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SchoolActivity, SchoolActivityInstance } from 'app/models';
import { FlexPeriod, FlexPeriodService } from 'app/services/flex-period.service';
import { CreateSchoolActivityInstanceReq, InstanceTimes, SchoolActivityService } from 'app/services/school-activity.service';
import { SelectDaysComponent } from 'app/shared/shared-components/select-days/select-days.component';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { getUpcomingCustomInstanceTimes, getUpcomingFlexInstanceTimes } from '../activity-form-util';

/**
 * AddRepeatingSessionDatesComponent
 *  Given an activity and a list of upcoming instances, this component opens the
 *  SelectDaysComponent with the proper state.
 *
 * It handles the logic for determining if the "New Session" button should be
 * disabled, and what the tooltip should be.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'sp-add-repeating-session-dates',
	template: `<bp-button
		size="lg"
		type="lightest"
		width="full"
		leftIcon="add-01"
		[disabled]="buttonDisabled(flexPeriodService.flexPeriod$(activity.flex_period_id) | async)"
		[shrinkOnPress]="true"
		[customToolTip]="disabledTooltip(flexPeriodService.flexPeriod$(activity.flex_period_id) | async)"
		(click)="openSelectDaysModal()">
		New Session</bp-button
	>`,
})
export class AddRepeatingSessionDatesComponent {
	@Input() activity!: SchoolActivity;
	@Input() upcomingInstances: SchoolActivityInstance[] = [];

	constructor(private matDialog: MatDialog, private activityService: SchoolActivityService, public flexPeriodService: FlexPeriodService) {}

	disabledTooltip(flexPeriod: FlexPeriod | null | undefined): string {
		if (!this.buttonDisabled(flexPeriod)) {
			return '';
		}

		if (flexPeriod) {
			return `This activity already occurs every ${flexPeriod.name}`;
		} else {
			return `All sessions already scheduled from now until ${moment(this.activity.repeat_end_date).format('MMM D, YYYY')}.`;
		}
	}

	buttonDisabled(flexPeriod: FlexPeriod | null | undefined): boolean {
		let allUpcoming: InstanceTimes[] = [];
		switch (this.activity.schedule_type) {
			case 'custom':
				if (!this.activity.custom_start_time || !this.activity.custom_end_time) {
					return true;
				}
				allUpcoming = getUpcomingCustomInstanceTimes(
					this.activity.custom_start_time,
					this.activity.custom_end_time,
					this.activity.custom_repeat_rule,
					this.activity.repeat_end_date
				);
				break;
			case 'flex_period':
				if (!flexPeriod) {
					return true;
				}
				allUpcoming = getUpcomingFlexInstanceTimes(flexPeriod, new Date(), this.activity.repeat_end_date);
				break;
		}

		// Find the delta betwee all upcoming instances and the ones that are already scheduled
		const remaining = allUpcoming.filter((instance) => {
			return !this.upcomingInstances.some((existingInstance) => {
				return (
					moment(existingInstance.start_time).isSame(moment(instance.start), 'minute') &&
					moment(existingInstance.end_time).isSame(moment(instance.end), 'minute')
				);
			});
		});

		return remaining.length == 0;
	}

	async openSelectDaysModal(): Promise<void> {
		const flexPeriod = await firstValueFrom(this.flexPeriodService.flexPeriod$(this.activity.flex_period_id));

		const selectDialog = this.matDialog.open(SelectDaysComponent, {
			panelClass: 'select-days-flex',
			width: '433px',
			disableClose: true,
			data: {
				flexPeriod: flexPeriod,
				instances: this.upcomingInstances,
				activity: this.activity,
				state: this.activity.state,
			},
		});

		selectDialog.componentInstance.closeDialog = (state: string, times: InstanceTimes[]) => {
			selectDialog.close();

			if (state === 'flex_recurring' && this.activity.state !== 'flex_recurring') {
				this.activityService.UpdateActivity({ id: this.activity.id, state: 'flex_recurring' });
				return;
			}

			const req: CreateSchoolActivityInstanceReq[] = times.map((time, index) => {
				return {
					start: time.start,
					end: time.end,
					activityId: this.activity.id ? this.activity.id : 0,
					state: 'scheduled',
					selected: index === 0,
				};
			});
			this.activityService.CreateActivityInstanceBulk(req);
		};
	}
}
