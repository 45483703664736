import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * The footer content at the bottom of a Dynamic Dialog Modal. This typically
 * will include buttons to cancel or save the dialog. It also has a "left" slot
 * for additional content, such as "Step 1 of 2".
 *
 * In most cases, you can use an `<ng-container>` for the footer content, and
 * the buttons will be spaced appropriately via flexbox.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'bp-dialog-footer',
	template: `
		<div class="left-content">
			<ng-content select="[left]"></ng-content>
		</div>
		<div class="right-content">
			<ng-content></ng-content>
		</div>
	`,
	styles: [
		`
			:host {
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: sticky;
				bottom: 0;
				z-index: 1;
				background-color: white;
				padding: 16px 28px 24px 28px;
			}

			.left-content {
				display: flex;
				align-items: center;
				flex-grow: 1;
			}

			.right-content {
				display: flex;
				justify-content: flex-end;
				gap: 8px;
			}
		`,
	],
})
export class DialogFooterComponent {}
