<div class="card-container" #modalContainer>
	<div class="pass-card-container {{ passStatus }} {{ passCssClasses }}" *ngIf="pass">
		<div
			class="pass-card-wrapper {{ borderClasses }} {{ endedBgColorClass }}"
			[ngClass]="{ 'pass-border': passStatus !== 'ended' }"
			[ngStyle]="{ overflow: 'hidden' }"
			#cardWrapper
			[style.background]="backgroundGradient"
			[style.border-radius]="borderRadius"
			ondragstart="return false">
			<sp-pass-modal-header
				*ngIf="originRoomIcon"
				[pass]="pass"
				[passStatus]="passStatus"
				[forStaff]="forStaff"
				[isRecurringFuture]="isRecurringFuture"
				[inFormContainer]="true"
				[forKioskModeFormContainer]="false"
				[isMissed]="false"
				[originRoomIcon]="originRoomIcon"
				[originRoomTitle]="pass.origin.title"
				(closeDialogEmit)="dialogRef.close()"
				(cancelEditEmit)="cancelEdit($event)"></sp-pass-modal-header>

			<div class="center-block">
				<div class="center-block-inner">
					<div class="duration-picker">
						<!-- ************************* TIME LIMIT SELECTION **************************-->
						<app-duration-picker
							[forStaff]="forStaff"
							[plural]="students.length > 1"
							(onChange)="selectedDuration = $event"
							(onSelectTravelType)="selectedTravelType = $event"
							[inFormContainer]="true"
							[choices]="pass.destination.travel_types"
							[maxDuration]="pass.destination.max_allowed_time ? pass.destination.max_allowed_time : 15"></app-duration-picker>
					</div>
				</div>
			</div>
			<div class="bottom-block">
				<div class="button-wrapper">
					<div class="button single-button">
						<app-card-button
							(onClick)="newPass()"
							[disabled]="performingAction"
							[backgroundGradient]="footerBackgroundGradient"
							[useOverlay]="false"
							[inFormContainer]="true"
							height="75px">
							<div class="button-content">
								<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
								<img
									*ngIf="
										!performingAction &&
										(formState?.data?.destLimitReached || (waitInLineEnabled && formState?.data?.origLimitReached)) &&
										formState?.data?.selectedStudents.length === 1 &&
										waitInLineEnabled
									"
									class="ds-mx-15"
									width="36"
									[src]="'./assets/Clock (White).svg' | resolveAsset"
									alt="Clock face" />
								<div *ngIf="!performingAction" class="button-content-title">
									{{ buttonText }}
								</div>
								<img *ngIf="!performingAction" class="button-icon-right" [src]="'./assets/Chevron Right (White).svg' | resolveAsset" />
							</div>
						</app-card-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #confirmDialogBody let-totalStudents="totalStudents" let-limitReachedStudents="limitReachedStudents">
	<div *ngIf="totalStudents > 1" class="confirm-body-text">
		<p *ngFor="let s of limitReachedStudents">
			{{ s.displayName }}: {{ s.passLimit }}/{{ s.passLimit }} passes {{ passLimit?.frequency === 'day' ? 'today' : 'this ' + passLimit?.frequency }}
		</p>
	</div>

	<div *ngIf="totalStudents === 1" class="confirm-body-text">
		<span>If it's an emergency, you can override the limit.</span>
	</div>
</ng-template>

<ng-template let-alerts="alerts" #confirmDialogBodyVisibility>
	<ng-container *ngFor="let a of alerts">
		<div class="ds-dialog-title">
			<span>{{ a.phrase }}</span>
		</div>

		<div class="ds-dialog-normal-text ds-mb-10">
			<p notranslate>{{ a.students }}</p>
		</div>
	</ng-container>
</ng-template>
