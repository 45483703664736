import { Injectable } from '@angular/core';
import { captureException } from '@sentry/angular';
import { Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { BannerService } from './banner.service';
import { KioskModeService } from './kiosk-mode.service';
import { AudibleEvent, LiveUpdateService } from './live-update.service';
import { StorageKeys, StorageService } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class SoundService {
	private currentAudio: HTMLAudioElement | null = null;
	private audioCache: { [key: string]: HTMLAudioElement } = {};

	private destroy$ = new Subject<void>();

	constructor(
		liveUpdateService: LiveUpdateService,
		private kioskModeService: KioskModeService,
		private storageService: StorageService,
		private bannerService: BannerService
	) {
		const audibleEvents$ = liveUpdateService.listen().pipe(
			takeUntil(this.destroy$),
			filter((m): m is typeof m & Required<Pick<typeof m, 'audible_event'>> => !!m.audible_event)
		);

		audibleEvents$
			.pipe(
				catchError((err, caught) => {
					console.log('error playing sound: ' + err);
					return caught;
				})
			)
			.subscribe((e) => this.playAudibleEvent(e.audible_event));
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	private playAudibleEvent(e: AudibleEvent) {
		if (this.storageService.getItem(StorageKeys.sounds) == 'false') {
			return;
		}
		const kioskMode = !!this.kioskModeService.getCurrentRoom().value;
		const kioskRoom = this.kioskModeService.getCurrentRoom().value;
		if (e.kiosk_only && !kioskMode) {
			console.log(`[sound service] not playing ${e.sound} sound because kiosk mode is not enabled`);
			return;
		}
		if (e.teacher_only && kioskMode) {
			console.log(`[sound service] not playing ${e.sound} sound because kiosk mode is enabled`);
			return;
		}
		if (!!kioskRoom && e.room_ids.length > 0 && !e.room_ids.includes(kioskRoom.id)) {
			console.log(`[sound service] not playing ${e.sound} sound because the current room is not the target of the sound`);
			return;
		}
		console.log(`[sound service] playing sound: ${e.sound}`);
		const url = 'assets/audio/' + e.sound + '.ogg';
		this.playSound(url);
	}

	// playSound plays a sound at the given |src| url. It also caches the
	// HTMLAudioElement so that the sound can be played in quick succession.
	playSound(src: string, durationMS = 10000) {
		// If a sound is playing, stop it
		if (this.currentAudio) {
			this.currentAudio.pause();
			this.currentAudio.currentTime = 0;
			this.currentAudio.oncanplaythrough = null; // remove the event listener
		}

		// If the sound is in the cache, use the cached Audio instance
		// If not, load the sound and add it to the cache
		if (!this.audioCache[src]) {
			this.audioCache[src] = new Audio(src);
		}

		this.currentAudio = this.audioCache[src];

		// When the sound is ready, play it
		this.currentAudio.oncanplaythrough = () => {
			if (!this.currentAudio) {
				return;
			}
			this.currentAudio.play().catch((error) => {
				this.showSoundBanner(error);
			});

			setTimeout(() => {
				if (!this.currentAudio) {
					return;
				}
				this.currentAudio.pause();
				this.currentAudio.currentTime = 0;
				this.currentAudio.oncanplaythrough = null; // remove the event listener
			}, durationMS);
		};
	}

	private showSoundBanner(error: unknown): void {
		if (error instanceof Error) {
			captureException(error);
		}
		this.bannerService.changeMessage('Your browser is muting notification sounds');
		this.bannerService.changeButtonText('Turn On');
		this.bannerService.changeImage('./assets/No_Audio.svg');
		this.bannerService.changeStatus(true);
	}
}
