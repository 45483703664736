import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { ClassLinkInfo } from '../../../models/ClassLinkInfo';
import { CleverInfo } from '../../../models/CleverInfo';
import { GSuiteOrgs } from '../../../models/GSuiteOrgs';
import { School } from '../../../models/School';
import { SchoolSyncInfo } from '../../../models/SchoolSyncInfo';
import { AdminService } from '../../../services/admin.service';
import { HttpService } from '../../../services/http-service';
import { OldLoginService } from '../../../services/old-login.service';
import { UserService } from '../../../services/user.service';
import * as schoolsActions from '../actions';

declare const window;

@Injectable()
export class SchoolsEffects {
	getSchools$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.getSchools),
			exhaustMap((action) => {
				return this.http.getSchools().pipe(
					map((schools) => schools.map((s) => School.fromJSON(s))),
					map((schools: School[]) => {
						window.waitForAppLoaded(true);
						return schoolsActions.getSchoolsSuccess({ schools });
					}),
					catchError((error) => {
						const errorMessage = error && error.error && error.error.detail ? error.error.detail : 'School loading error';
						return of(schoolsActions.getSchoolsFailure({ errorMessage }));
					})
				);
			})
		);
	});

	patchSchool$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.updateSchool),
			concatMap((action: any) => {
				return this.adminService.updateSchoolSettings(action.school.id, action.fields).pipe(
					map((school: any) => {
						return schoolsActions.updateSchoolSuccess({ school: School.fromJSON(school) });
					}),
					catchError((error) => {
						return of(schoolsActions.updateSchoolFailure({ errorMessage: error.message }));
					})
				);
			})
		);
	});

	showErrorToast$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.getSchoolsFailure),
			switchMap((action: any) => {
				window.appLoaded();
				this.oldLoginService.loginErrorMessage$.next(action.errorMessage);
				this.oldLoginService.clearInternal(true);
				this.http.clearInternal();
				this.http.setSchool(null);
				this.userService.clearUser();
				this.userService.userData.next(undefined);
				return [schoolsActions.errorToastSuccess(), schoolsActions.clearSchools()];
			})
		);
	});

	getSchoolSyncInfo$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.getSchoolSyncInfo),
			concatMap((action: any) => {
				return this.adminService.getSpSyncing().pipe(
					map((syncInfo: SchoolSyncInfo) => {
						return schoolsActions.getSchoolSyncInfoSuccess({ syncInfo });
					}),
					catchError((error) => of(schoolsActions.getSchoolSyncInfoFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateSchoolSyncInfo$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.updateSchoolSyncInfo),
			concatMap((action) => {
				return this.adminService.updateSpSyncing(action.data).pipe(
					map((syncInfo: any) => {
						if (action.data.selector_students) {
							return schoolsActions.updateGSuiteInfoSelectors({ selectors: action.data });
						}
						return schoolsActions.updateSchoolSyncInfoSuccess({ syncInfo });
					}),
					catchError((error) => of(schoolsActions.updateSchoolSyncInfoFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	getGSuiteInfo$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.getGSuiteSyncInfo),
			concatMap((action) => {
				return this.adminService.getGSuiteOrgs().pipe(
					map((gSuiteInfo: GSuiteOrgs) => {
						return schoolsActions.getGSuiteSyncInfoSuccess({ gSuiteInfo });
					}),
					catchError((error) => of(schoolsActions.getGSuiteSyncInfoFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateGSuiteInfoSelectors$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.updateGSuiteInfoSelectors),
			map((action) => {
				const selectors = {
					admin: {
						selector: action.selectors.selector_admins,
					},
					student: {
						selector: action.selectors.selector_students,
					},
					teacher: {
						selector: action.selectors.selector_teachers,
					},
					assistant: {
						selector: action.selectors.selector_assistants,
					},
				};
				return schoolsActions.updateGSuiteInfoSelectorsSuccess({ selectors });
			})
		);
	});

	getSchoolCleverInfo$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.getCleverInfo),
			switchMap((action: any) => {
				return this.adminService.getCleverInfo().pipe(
					map((cleverInfo: CleverInfo) => {
						return schoolsActions.getCleverInfoSuccess({ cleverInfo });
					}),
					catchError((error) => of(schoolsActions.getCleverInfoFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	getSchoolClassLinkInfo$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(schoolsActions.getClassLinkInfo),
			switchMap((action: any) => {
				return this.adminService.getClassLinkSyncInfo().pipe(
					map((classLinkInfo: ClassLinkInfo) => {
						return schoolsActions.getClassLinkSuccess({ classLinkInfo });
					}),
					catchError((error) => of(schoolsActions.getClassLinkFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	constructor(
		private actions$: Actions,
		private http: HttpService,
		private adminService: AdminService,
		private router: Router,
		private userService: UserService,
		private oldLoginService: OldLoginService
	) {}
}
