import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { StorageKeys, StorageService } from './storage.service';
import { ToastIdEnum, ToastService } from './toast.service';

export enum LoginErrors {
	Suspended = 'this user is suspended',
	Disabled = 'this user is disabled',
	NotActive = 'this profile is not active',
	TeacherNoAssistants = 'Assistant does`t have teachers',
	PopupBlocked = 'pop up blocked',
	InvalidCreds = 'username/password is incorrect',
}

export const LoginErrorMap: Record<LoginErrors, string> = {
	[LoginErrors.Suspended]: 'Account is suspended. Please contact your school admin.',
	[LoginErrors.Disabled]: 'Account is disabled. Please contact your school admin.',
	[LoginErrors.NotActive]: 'Account is not active. Please contact your school admin.',
	[LoginErrors.TeacherNoAssistants]: 'Account does not have any associated teachers. Please contact your school admin.',
	[LoginErrors.PopupBlocked]: 'Pop up blocked. Please allow pop ups.',
	[LoginErrors.InvalidCreds]: 'Incorrect password. Try again or contact your school admin to reset it.',
};

export interface DemoLogin {
	username: string;
	password?: string;
	invalid?: boolean;
	kioskMode: boolean;
	type: 'demo-login';
}

export interface ClassLinkLogin {
	classlink_code: string;
	type: 'classlink-login';
}

export interface CleverLogin {
	clever_code: string;
	type: 'clever-login';
}

export interface GoogleLogin {
	google_code: string;
	type: 'google-login';
}

export interface SessionLogin {
	provider: string;
	token?: string;
	username?: string;
}

export function isDemoLogin(d: any): d is DemoLogin {
	return (<DemoLogin>d).type === 'demo-login';
}

export function isClassLinkLogin(d: any): d is ClassLinkLogin {
	return (<ClassLinkLogin>d).type === 'classlink-login';
}

export function isCleverLogin(d: any): d is CleverLogin {
	return (<CleverLogin>d).type === 'clever-login';
}

export function isGoogleLogin(d: any): d is GoogleLogin {
	return (<GoogleLogin>d).type === 'google-login';
}

export type AuthObject = GoogleLogin | DemoLogin | ClassLinkLogin | CleverLogin;

/**
 * @deprecated This service should not be used for any future implementations.
 */
@Injectable({
	providedIn: 'root',
})
export class OldLoginService implements OnDestroy {
	static googleOAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=560691963710-220tggv4d3jo9rpc3l70opj1510keb59.apps.googleusercontent.com&response_type=code&access_type=offline&scope=profile%20email%20openid`;
	private authObject$ = new BehaviorSubject<AuthObject>(null);
	loginErrorMessage$ = new Subject<string>();
	isAuthenticated$ = new ReplaySubject<boolean>(1);
	continueAuthFlow$ = new Subject<boolean>();
	stopCookieVerificationTimer$ = new Subject<void>();
	private destroy$ = new Subject<void>();

	constructor(
		@Inject(APP_BASE_HREF)
		private baseHref: string,
		private storage: StorageService,
		private dialogRef: MatDialog,
		private toast: ToastService,
		private cookie: CookieService,
		private router: Router,
		private authenticationService: AuthenticationService
	) {
		this.authObject$.pipe(takeUntil(this.destroy$)).subscribe((auth) => {
			if (auth) {
				const storageKey = isDemoLogin(auth)
					? JSON.stringify({ username: (auth as DemoLogin).username, type: (auth as DemoLogin).type })
					: JSON.stringify(auth);
				this.storage.setItem(StorageKeys.googleAuth, storageKey);
			}
		});

		const savedServerConfig = this.storage.getItem('server');
		this.isAuthenticated$.next(!!savedServerConfig && !!this.cookie.get('smartpassToken'));
		// When another tab logs out, this tab should log out as well
		window.addEventListener(
			'storage',
			(event: StorageEvent) => {
				if (event.key === 'server') {
					console.debug('storage event', event, { key: event.key, oldValue: event.oldValue, newValue: event.newValue });
				}
				if (event.key === 'server' && this.storage.getItem('server') === null) {
					console.log('navigating to sign-out because server is missing from localstorage', event, this.storage.getItem('server'));
					// it's not necessary to call AuthenticationService.logout here since that service already listens for storage events
					this.router.navigate(['sign-out']);
				}
			},
			false
		);
	}

	checkIfAuthStored(): Observable<boolean> {
		const isCookiePresent = !!this.cookie.get('smartpassToken');

		if (!isCookiePresent) {
			console.group();
			console.log('removing storage item because cookie is not present');
			console.trace();
			console.groupEnd();
			this.storage.removeItem('server');
			this.authenticationService.logout();
			return of(false);
		}

		const svrString = this.storage.getItem('server');
		if (!svrString) {
			return of(false);
		}

		return of(true);
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	// Returns authObject
	getAuthObject(): Observable<AuthObject> {
		return this.authObject$.pipe(filter((t) => !!t));
	}

	// updating this triggers a login flow, clearing this logs the user out
	updateAuth(auth: AuthObject): void {
		this.authObject$.next(auth);
	}

	clearInternal(permanent = false): void {
		console.group();
		console.log('clearInternal, permanent:', permanent);
		console.trace();
		console.groupEnd();
		this.authObject$.next(undefined);
		if (window.location.host.includes('localhost')) {
			this.cookie.delete('smartpassToken', '/', 'localhost', false);
		} else {
			this.cookie.delete('smartpassToken', '/', '.smartpass.app', true);
		}
		this.storage.removeItem('server');
		this.authenticationService.logout();
		this.storage.removeItem('current-kiosk-room');

		if (!permanent) {
			this.isAuthenticated$.next(false);
		}

		this.storage.removeItem(StorageKeys.googleAuth);
		this.storage.removeItem('refresh_token');
		this.storage.removeItem('google_id_token');
		this.storage.removeItem(StorageKeys.kioskModeLocationId);
		this.storage.removeItem('context');
	}

	/**
	 * This method will trigger the Google authentication pop-up.
	 *
	 * Some browsers (Chrome) having strict rules about when a popup can be triggered including
	 * that the triggering of the popup happens during an actual click event. This makes it impossible
	 * to use RxJS' subscribe() behavior and is the reason for some of the weirder construction of this
	 * method.
	 */

	triggerGoogleAuthFlow(userEmail?: string, decodedState?: string): void {
		// TODO IMPLEMENT THIS
		let url = OldLoginService.googleOAuthUrl + `&redirect_uri=${this.getRedirectUrl()}google_oauth`;
		if (userEmail) {
			url += `&login_hint=${userEmail}`;

			// Append state only if decodedState is a non-empty string
			if (decodedState) {
				url += `&state=${decodedState}`;
			}
		}
		window.location.href = url;
	}

	private getRedirectUrl(): string {
		const url = [window.location.protocol, '//', window.location.host, this.baseHref].join('');
		return url;
	}

	signInDemoMode(username: string, password: string): void {
		this.authObject$.next({ username: username, password: password, type: 'demo-login', kioskMode: false });
	}

	startCookieVerificationTimer(): void {
		timer(0, 60000)
			.pipe(
				tap(() => {
					if (!this.cookie.check('smartpassToken')) {
						this.logout();
						this.stopCookieVerificationTimer$.next(undefined);
					}
				}),
				takeUntil(this.stopCookieVerificationTimer$)
			)
			.subscribe();
	}

	logout(): void {
		this.clearInternal(true);
		this.dialogRef.closeAll();
		this.router.navigate(['']);
		this.toast.openToast(
			{
				title: 'Your session has expired',
				subtitle: 'Please sign in again to continue using SmartPass.',
				type: 'error',
				closeManually: true,
			},
			ToastIdEnum.SESSION_EXPIRED
		);
	}
}
