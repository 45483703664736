import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, find } from 'lodash';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { NextStep } from '../../animations';
import { DeviceDetection } from '../../device-detection.helper';
import { PassLimitInfo } from '../../models/HallPassLimits';
import { Location } from '../../models/Location';
import { NoFlyTime } from '../../models/NoFlyTime';
import { Pinnable } from '../../models/Pinnable';
import { RecurringOption } from '../../models/RecurringFutureConfig';
import { StudentList } from '../../models/StudentList';
import { User } from '../../models/User';
import { HallPassesService } from '../../services/hall-passes.service';
import { LiveUpdateEvent } from '../../services/live-update.service';
import { LocationsService } from '../../services/locations.service';
import { PassLimitService } from '../../services/pass-limit.service';
import { ScreenService } from '../../services/screen.service';
import { UserService } from '../../services/user.service';
import { CreateFormService } from '../create-form.service';
import { CreatePassDialogData } from '../create-hallpass-forms.component';
import { LocationVisibilityService } from './location-visibility.service';
import { States } from './locations-group-container/locations-group-container.component';

export enum Role {
	Teacher = 1,
	Student = 2,
}

export enum FormFactor {
	HallPass = 1,
	Request = 2,
	Invitation = 3,
}

export interface FormMode {
	role?: number;
	formFactor?: number;
}

export interface Navigation {
	step: number;
	previousStep?: number;
	state?: number;
	previousState?: number;
	fromState?: number;
	formMode?: FormMode;
	data?: {
		destLimitReached?: boolean; // A simple way of passing along the condition of the destination room being filled
		origLimitReached?: boolean;
		request?: any;
		date?: {
			date?: Date;
			declinable?: boolean;
			schedule_option?: RecurringOption;
		};
		selectedStudents?: User[];
		selectedGroup?: StudentList;
		teacherRooms?: Pinnable[];
		direction?: {
			from?: Location;
			to?: Location;
			pinnable?: Pinnable;
		};
		icon?: string;
		gradient?: string;
		message?: string;
		requestTarget?: User;
		hasClose?: boolean;
		// filtered students after skipping some of selected ones to comply with the room visibility rules
		roomStudents?: User[] | null;
		// needed when back from pass card
		roomStudentsAfterFromStep?: User[];
		roomOverride?: boolean;
		kioskModeStudent?: User;
	};
	quickNavigator?: boolean;
	forInput?: boolean;
	forLater?: boolean;
	missedRequest?: boolean;
	resendRequest?: boolean;
	kioskMode?: boolean;
	noFlyTimes?: NoFlyTime[];
	currentNoFlyTimeIndex?: number;
	noFlyTimeActive?: boolean;
	currentNoFlyEndTimeString?: string;
	passLimitInfo?: PassLimitInfo;
	extras?: BehaviorSubject<string>;
	openedFrom?: string;
	// Only applies to the |FromToWhere| state.
	lockOriginLocation?: boolean;
	lockDestinationLocation?: boolean;
}

@Component({
	selector: 'app-main-hallpass-form',
	templateUrl: './main-hall-pass-form.component.html',
	styleUrls: ['./main-hall-pass-form.component.scss'],
	animations: [NextStep],
	providers: [LocationVisibilityService],
})
export class MainHallPassFormComponent implements OnInit, OnDestroy {
	FORM_STATE: Navigation;
	formSize = {
		containerHeight: '0px',
		containerWidth: '0px',
		height: '0px',
		width: '0px',
	};
	frameMotion$: BehaviorSubject<any>;

	user: User;
	isIOSTablet: boolean;
	isStaff: boolean;
	isDeviceMid: boolean;
	isDeviceLarge: boolean;
	createdRequest = false;
	passLimitInfo: PassLimitInfo;
	formStateInitialized = false;

	private destroy$ = new Subject();

	@ViewChild('wrapper') wrapper: ElementRef<HTMLDivElement>;

	@HostListener('window:resize')
	checkDeviceScreen() {
		this.isDeviceMid = this.screenService.isDeviceMid;
		this.isDeviceLarge = this.extraLargeDevice;
		this.setFormSize();
	}

	@HostListener('window:mousedown', ['$event'])
	checkIfClickedOutside(event: MouseEvent) {
		if (this.dialog.openDialogs.length > 1) {
			return;
		}
		if (!this.wrapper.nativeElement.contains(event.target as Node)) {
			this.dialogRef.close();
		}
	}

	constructor(
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public dialogData: Partial<CreatePassDialogData>,
		public dialogRef: MatDialogRef<MainHallPassFormComponent>,
		private formService: CreateFormService,
		private locationsService: LocationsService,
		private screenService: ScreenService,
		private passesService: HallPassesService,
		private userService: UserService,
		private studentPassLimits: PassLimitService,
		private cdr: ChangeDetectorRef
	) {}

	get isScaled() {
		return this.formService.scalableBoxController.asObservable();
	}

	ngOnInit() {
		this.isDeviceMid = this.screenService.isDeviceMid;
		this.isDeviceLarge = this.screenService.isDeviceLarge;
		this.isIOSTablet = DeviceDetection.isIOSTablet();
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.formService.cleanupDirectionOnClose(this.dialogRef.afterClosed());
		this.passesService.getPinnablesRequest();
		this.locationsService.getPassLimitRequest();
		this.formStateInitialized = !!this.dialogData.FORM_STATE;
		this.FORM_STATE = this.formStateInitialized
			? this.dialogData.FORM_STATE
			: {
					step: null,
					previousStep: 0,
					state: !this.dialogData?.forLater ? States.FromToWhere : States.from,
					fromState: null,
					formMode: {
						role: null,
						formFactor: null,
					},
					data: {
						selectedGroup: null,
						selectedStudents: [],
						direction: {
							from: this.dialogData.kioskModeRoom || null,
						},
						roomStudents: null,
					},
					forInput: this.dialogData.forInput || false,
					forLater: this.dialogData.forLater,
					kioskMode: this.dialogData.kioskMode || false,
					noFlyTimes: this.dialogData.noFlyTimes || [],
					currentNoFlyTimeIndex: this.dialogData.currentNoFlyTimeIndex >= 0 ? this.dialogData.currentNoFlyTimeIndex : -1,
					noFlyTimeActive: this.dialogData.noFlyTimeActive || false,
					currentNoFlyEndTimeString: this.dialogData?.currentNoFlyEndTimeString,
					extras: new BehaviorSubject<string>(null),
					openedFrom: this.dialogData.openedFrom,
					passLimitInfo: this.dialogData.passLimitInfo,
					lockOriginLocation: this.dialogData.lockOriginLocation,
					lockDestinationLocation: this.dialogData.lockDestinationLocation,
			  };

		this.FORM_STATE.extras.pipe(takeUntil(this.destroy$)).subscribe((extrasCommand) => {
			if (extrasCommand === 'createdRequest') {
				this.createdRequest = true;
			}
		});

		switch (this.FORM_STATE.forInput) {
			case true:
				this.FORM_STATE.formMode.role = this.dialogData.forStaff ? Role.Teacher : Role.Student;
				if (this.FORM_STATE.forLater) {
					if (this.dialogData.forStaff) {
						if (this.dialogData.fromAdmin) {
							this.FORM_STATE.step = 1;
							this.FORM_STATE.data.selectedStudents = [this.dialogData.adminSelectedStudent];
						} else {
							this.FORM_STATE.step = 2;
							this.FORM_STATE.state = this.FORM_STATE.kioskMode
								? States.restrictedTarget
								: !this.FORM_STATE?.forLater
								? States.FromToWhere
								: States.from;
						}
						this.FORM_STATE.formMode.formFactor = FormFactor.Invitation;
					} else {
						this.FORM_STATE.step = 1;
						this.FORM_STATE.formMode.formFactor = FormFactor.HallPass;
					}
				} else {
					this.FORM_STATE.formMode.formFactor = FormFactor.HallPass;
					if (this.dialogData.forStaff) {
						if (this.formStateInitialized) {
							this.FORM_STATE.formMode.formFactor = FormFactor.HallPass;
						} else if (this.FORM_STATE.kioskMode && this.dialogData.kioskModeSelectedUser) {
							this.FORM_STATE.data.selectedStudents = this.dialogData.kioskModeSelectedUser;
							this.FORM_STATE.step = 3;
							this.FORM_STATE.state = !this.FORM_STATE?.forLater ? States.FromToWhere : States.toWhere;
						} else {
							if (this.dialogData.fromAdmin) {
								this.FORM_STATE.step = 3;
								this.FORM_STATE.data.selectedStudents = [this.dialogData.adminSelectedStudent];
							} else {
								this.FORM_STATE.step = 2;
								this.FORM_STATE.state = this.dialogData.kioskMode
									? States.restrictedTarget
									: !this.FORM_STATE?.forLater
									? States.FromToWhere
									: States.from;
								if (this.dialogData.adminSelectedStudent) {
									this.FORM_STATE.data.selectedStudents = [this.dialogData.adminSelectedStudent];
								}
							}
						}
					} else {
						this.FORM_STATE.step = 3;
					}
				}
				break;
			case false:
				console.log('creating request!');
				if (this.dialogData.hasClose) {
					this.FORM_STATE.data.hasClose = true;
				}
				if (this.dialogData.missedRequest) {
					this.FORM_STATE.missedRequest = true;
				}
				if (this.dialogData.resend_request) {
					this.FORM_STATE.resendRequest = true;
				}
				this.FORM_STATE.formMode.formFactor = FormFactor.Request;
				this.FORM_STATE.formMode.role = this.dialogData.isDeny ? Role.Teacher : Role.Student;
				this.FORM_STATE.step = this.dialogData.entryState.step;
				this.FORM_STATE.state = this.dialogData.entryState.state;
				this.FORM_STATE.data.date = {
					date: this.dialogData.request_time,
				};
				this.FORM_STATE.data.request = this.dialogData.request;
				this.FORM_STATE.data.requestTarget = this.dialogData.teacher;
				this.FORM_STATE.data.gradient = this.dialogData.gradient;
				this.FORM_STATE.data.direction = {
					from: this.dialogData.originalFromLocation,
					to: this.dialogData.originalToLocation,
				};
				break;
		}

		this.studentPassLimits
			.passLimitChanges$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((info) => {
				this.FORM_STATE.passLimitInfo = info;
				this.passLimitInfo = info;
				this.cdr.detectChanges();
			});

		this.setFormSize();
		this.setContainerSize('start');
		this.checkDeviceScreen();
		this.userService.effectiveUser$
			.pipe(
				tap((effectiveUser) => {
					this.user = effectiveUser;
					this.isStaff = this.user.isTeacher() || this.user.isAssistant();
					this.locationsService.getLocationsWithTeacherRequest(this.user);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		combineLatest(this.passesService.pinnables$, this.locationsService.teacherLocations$)
			.pipe(
				filter(([pin, locs]: [Pinnable[], Location[]]) => this.isStaff && !!pin.length && !!locs.length),
				takeUntil(this.destroy$),
				map(([pinnables, locations]) => {
					const filterPinnables = cloneDeep(pinnables).filter((pin) => {
						return locations.find((loc) => {
							return (loc.category ? loc.category : loc.title) === pin.title;
						});
					});
					return filterPinnables.map((fpin) => {
						if (fpin.type === 'category') {
							const locFromCategory = find(locations, ['category', fpin.title]);
							fpin.title = locFromCategory.title;
							fpin.type = 'location';
							fpin.location = locFromCategory;
							return fpin;
						}
						return fpin;
					});
				})
			)
			.subscribe((rooms) => {
				this.FORM_STATE.data.teacherRooms = rooms;
			});
		this.locationsService
			.listenPassLimitSocket()
			.pipe(takeUntil(this.destroy$))
			.subscribe((loc) => {
				this.locationsService.updatePassLimitRequest(loc);
			});

		// usually a location is patched through a pinnable so those cases are handled in listenPinnableSocket
		// but for cases as an admin adding texhers to a room or anything that modify a room
		// this code listening to location only, separate of pinnable, is usefull
		this.locationsService
			.listenLocationSocket()
			.pipe(
				takeUntil(this.destroy$),
				filter((res) => !!res),
				tap((res) => {
					try {
						const loc: Location = Location.fromJSON(res.data);
						this.locationsService.updateLocationSuccessState(loc);
						this.formService.setUpdatedChoice(loc);
						this.formService.updatedByWS$.next(true);
					} catch (e) {
						console.log(e);
					}
				})
			)
			.subscribe();

		this.locationsService
			.listenPinnableSocket()
			.pipe(
				takeUntil(this.destroy$),
				filter<LiveUpdateEvent>(Boolean),
				distinctUntilChanged(
					(prev, res) =>
						(prev.data as Pinnable).show_as_origin_room === (res.data as Pinnable).show_as_origin_room ||
						(prev.data as Pinnable).ignore_students_pass_limit === (res.data as Pinnable).ignore_students_pass_limit
				),
				tap((res) => {
					const pinnable: Pinnable = Pinnable.fromJSON(res.data as Pinnable);
					const pinnableData = {
						// data to create the pinnable
						ignore_students_pass_limit: pinnable.ignore_students_pass_limit,
						show_as_origin_room: pinnable.show_as_origin_room,
					};
					if (this.user.isAdmin()) {
						this.passesService.updatePinnableRequest(pinnable.id, pinnableData);
					}
					this.locationsService.updatePinnableSuccessState(pinnable);
				})
			)
			.subscribe();

		this.dialogRef
			.backdropClick()
			.pipe(
				takeUntil(this.destroy$),
				filter(() => this.FORM_STATE.state !== States.from)
			)
			.subscribe(() => {
				this.formService.setFrameMotionDirection('disable');
				this.formService.compressableBoxController.next(false);
				this.formService.scalableBoxController.next(false);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	onNextStep(evt: Navigation) {
		if (evt.step === 0 || (evt as any).action === 'exit') {
			this.formService.setFrameMotionDirection('disable');
			this.formService.compressableBoxController.next(false);
			this.formService.scalableBoxController.next(false);
			this.dialogRef.close(evt);
			//} //else if (evt.step === 4 && evt.missedRequest && !this.isStaff) {
			//this.dialogRef.close(this.FORM_STATE);
		} else {
			this.FORM_STATE = evt;
			this.setFormSize();
		}
	}

	setContainerSize(startOrEnd: 'start' | 'end') {
		switch (startOrEnd) {
			case 'start':
				this.formSize.containerWidth = this.formSize.width;
				this.formSize.containerHeight = this.formSize.height;
				break;
			case 'end':
				this.formSize.containerWidth = `${window.innerWidth}px`;
				this.formSize.containerHeight = `${window.innerHeight}px`;
				break;
		}
	}

	setFormSize() {
		switch (this.FORM_STATE.step) {
			case 1: {
				this.formSize.width = `425px`;
				this.formSize.height = `550px`;
				this.formSize.containerHeight = '550px';
				break;
			}
			case 2: {
				this.formSize.width = this.screenService.isDeviceLargeExtra ? `335px` : `425px`;
				this.formSize.height = `500px`;
				this.formSize.containerHeight = '500px';
				break;
			}
			case 3: {
				this.formSize.width = `425px`;
				this.formSize.height = `500px`;
				this.formSize.containerHeight = '500px';
				break;
			}
			case 4: {
				this.formSize.width = '428px';
				let height = '472px';
				if (this.FORM_STATE.missedRequest) {
					height = '476px';
					this.formSize.containerHeight = '476px';
				}
				if (this.FORM_STATE.kioskMode) {
					this.formSize.width = this.passesService.getModalWidth(false);
					this.formSize.containerWidth = this.passesService.getModalWidth(false);
					height = this.passesService.getModalHeight(null, true, true, false, false);
				}
				this.formSize.height = height;
				this.formSize.containerHeight = height;
				break;
			}
		}
	}

	get extraLargeDevice() {
		return this.screenService.isDeviceLargeExtra;
	}
}
