import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToolTipDataType } from 'app/backpack/tooltip/tooltip.component';
import { findIndex, range } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { bumpIn, ChangeBG } from '../../animations';
import { SchoolTermType, TooltipDateInfo } from '../../services/calendar.service';

export interface CalendarDate {
	mDate: moment.Moment;
	disabled?: boolean;
	tooltipContent?: ToolTipDataType;
	selected?: boolean;
	today?: boolean;
	isDot?: boolean;
	cssClasses?: string;
	isWeekendDay?: boolean;
	isInSelectedMonth?: boolean;
}
export type SchoolTermInfo = {
	type: SchoolTermType;
	termName: string;
	startDate: string;
	endDate: string;
	id?: number;
};

@Component({
	selector: 'app-calendar-picker',
	templateUrl: './calendar-picker.component.html',
	styleUrls: ['./calendar-picker.component.scss'],
	animations: [bumpIn, ChangeBG],
})
export class CalendarPickerComponent implements OnInit, OnChanges {
	@Input() width = 270;
	@Input() selectedColor = '#00B476';
	@Input() selectedDates: moment.Moment[] = [];
	@Input() min?: moment.Moment;
	@Input() max?: moment.Moment;
	@Input() outsideRangeTooltip?: ToolTipDataType;
	@Input() showWeekend = false;
	@Input() showTime = true;
	@Input() showYear = true;
	@Input() range = false;
	@Input() rangeWeeks = false;
	@Input() dotsDates?: Map<string, number>;
	@Input() changeCurrentDate = false;
	@Input() exclusionId: string | null = null;
	@Input() tooltipDates?: TooltipDateInfo[];
	@Input() useUtcTime = false;
	@Input() hoveredDates: moment.Moment[] = [];
	@Input() classes = '';

	@Output() dateSelected = new EventEmitter<moment.Moment[]>();

	currentDate = moment();
	dayNames = ['M', 'T', 'W', 'T', 'F'];
	weeks: CalendarDate[][] = [];
	hovered = false;
	forseUpdate$: BehaviorSubject<moment.Moment>;

	get isDisabledPreviousMonthButton() {
		return this.min && moment(this.currentDate).isSameOrBefore(this.min, 'month');
	}

	// Disable the "Next Month" button if the current month is the same as or after the max date
	get isDisabledNextMonthButton() {
		return this.max && moment(this.currentDate).isSameOrAfter(this.max, 'month');
	}

	ngOnInit(): void {
		if (this.showWeekend) {
			this.dayNames.unshift('S');
			this.dayNames.push('S');
		}
		if (this.min) {
			this.currentDate = this.min;
			this.forseUpdate$ = new BehaviorSubject(moment(this.currentDate));
		}
		if (this.selectedDates.length && this.selectedDates[0] && !this.range) {
			this.currentDate = this.selectedDates[0];
			this.forseUpdate$ = new BehaviorSubject(moment(this.currentDate));
		} else if (this.selectedDates.length && this.selectedDates[0]) {
			this.currentDate = this.selectedDates[0];
			this.forseUpdate$ = new BehaviorSubject(moment(this.currentDate));
		}
		this.forseUpdate$ = new BehaviorSubject(moment(this.currentDate));
		this.generateCalendar();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedDates && changes.dotsDates && changes.selectedDates.currentValue) {
			this.generateCalendar();
		}

		if (this.changeCurrentDate && changes.selectedDates) {
			this.currentDate = changes.selectedDates.currentValue[0];
			this.generateCalendar();
		}
	}

	isToday(date: moment.Moment): boolean {
		if (this.useUtcTime) {
			return moment.utc().isSame(moment.utc(date), 'day');
		}
		return moment().isSame(moment(date), 'day');
	}

	isSelected(date: moment.Moment): boolean {
		return (
			findIndex(this.selectedDates, (selectedDate) => {
				return moment(date).isSame(selectedDate, 'day');
			}) > -1
		);
	}

	isFirstSelectedDay(date: moment.Moment): boolean {
		return date.isSame(this.selectedDates[0], 'day');
	}

	isLastSelectedDay(date: moment.Moment): boolean {
		return this.selectedDates.length > 1 && date.isSame(this.selectedDates[this.selectedDates.length - 1], 'day');
	}

	isLastHoveredDay(date: moment.Moment): boolean {
		return this.hoveredDates.length > 1 && date.isSame(this.hoveredDates[this.hoveredDates.length - 1], 'day');
	}

	showWeekendDay(date: moment.Moment): boolean {
		if (!this.showWeekend) {
			return moment(date).isoWeekday() !== 6 && moment(date).isoWeekday() !== 7;
		} else {
			return true;
		}
	}
	private getIsDayOnWeekend(date: moment.Moment): boolean {
		return date.isoWeekday() === 7 || date.isoWeekday() === 6;
	}

	isSelectedMonth(date: moment.Moment): boolean {
		return moment(date).isSame(this.currentDate, 'month');
	}

	isNextMonth(date: moment.Moment): boolean {
		return moment(date).month() === moment(this.currentDate).month() + 1;
	}
	isPreviousMonth(date: moment.Moment): boolean {
		return moment(date).month() === moment(this.currentDate).month() - 1;
	}

	isRangeHovered(date: moment.Moment): boolean {
		return (
			(this.range || this.rangeWeeks) &&
			findIndex(this.hoveredDates, (hoveredDate) => {
				return moment(date).isSame(hoveredDate, 'day');
			}) > -1
		);
	}

	hoverDates(date: moment.Moment): void {
		this.hovered = true;
		if (this.range && this.selectedDates.length === 1 && !this.hoveredDates.length) {
			const countDiff = date.diff(moment(this.selectedDates[0]), 'days');
			for (let i = 1; i <= countDiff; i++) {
				const hoveredDate = moment(this.selectedDates[0]).add(i, 'days');
				this.hoveredDates.push(hoveredDate);
				this.hoveredDates.push(date);
			}
		} else if (this.rangeWeeks) {
			const start = moment(date).startOf('week');
			for (let i = 1; i <= 5; i++) {
				const hovered = moment(start).add(i, 'days');
				this.hoveredDates.push(hovered);
			}
		}
	}

	disabledHovered(): void {
		this.hovered = false;
		if ((this.range && this.selectedDates.length === 1) || this.rangeWeeks) {
			this.hoveredDates = [];
		}
	}

	private changeDate(newDate: moment.Moment): moment.Moment {
		const baseDate = this.currentDate.isUTC() || this.useUtcTime ? moment.utc(newDate) : moment(newDate).local();
		baseDate.set({
			hour: this.currentDate.hour(),
			minute: this.currentDate.minutes(),
		});

		return baseDate;
	}

	selectDate(date: CalendarDate): void {
		const bulkSelect = this.range;
		const fullDate = this.changeDate(date.mDate);

		if (bulkSelect && this.selectedDates.length > 1) {
			this.selectedDates = [fullDate];
		}
		if (bulkSelect && this.selectedDates.length) {
			if (date.mDate.isAfter(moment(this.selectedDates[0]))) {
				const countDiff = date.mDate.diff(moment(this.selectedDates[0]), 'days');
				this.selectedDates.push(date.mDate);
				for (let i = 1; i <= countDiff; i++) {
					const rangeDates = moment(this.selectedDates[0]).add(i, 'day');
					if (this.showWeekendDay(rangeDates)) {
						this.hoveredDates.push(rangeDates);
					}
				}
			} else {
				this.selectedDates = [fullDate];
				this.hoveredDates = [];
			}
		} else if (this.rangeWeeks) {
			this.selectedDates = [...this.hoveredDates];
			this.dateSelected.emit([this.selectedDates[0], this.selectedDates[this.selectedDates.length - 1]]);
			this.generateCalendar();
			return;
		} else {
			if (fullDate.isSameOrAfter(moment().add(5, 'minutes'), 'minute') || !this.showTime) {
				this.selectedDates = [fullDate];
				this.currentDate = fullDate;
			} else {
				this.currentDate = moment().add(5, 'minutes');
				this.selectedDates = [this.currentDate];
				this.forseUpdate$.next(this.currentDate);
			}
		}
		this.generateCalendar();
		this.dateSelected.emit(this.selectedDates);
	}

	prevMonth(): void {
		this.currentDate = moment(this.currentDate).subtract(1, 'months');
		this.generateCalendar();
	}

	nextMonth(): void {
		this.currentDate = moment(this.currentDate).add(1, 'months');
		this.generateCalendar();
	}

	switchMonth(day: CalendarDate): void {
		if (this.isNextMonth(day.mDate)) {
			this.nextMonth();
			if (!day.disabled) {
				const fullDate = this.changeDate(day.mDate);
				this.selectedDates = [fullDate];
				this.dateSelected.emit(this.selectedDates);
			}
			this.generateCalendar();
		}
	}

	prevYear(): void {
		this.currentDate = moment(this.currentDate).subtract(1, 'year');
		this.generateCalendar();
	}

	nextYear(): void {
		this.currentDate = moment(this.currentDate).add(1, 'year');
		this.generateCalendar();
	}

	timePickerResult(date: moment.Moment): void {
		this.currentDate = date;
		this.generateCalendar();
		this.dateSelected.emit([this.currentDate]);
	}

	private generateCalendar(): void {
		const dates = this.fillDates(this.currentDate);
		const weeks: CalendarDate[][] = [];
		while (dates.length > 0) {
			weeks.push(dates.splice(0, 7));
		}
		this.weeks = weeks;
	}

	private fillDates(currentMoment: moment.Moment): CalendarDate[] {
		let firstOfMonth = moment(currentMoment).startOf('month').day();
		let firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
		if (this.useUtcTime) {
			firstOfMonth = moment(currentMoment).utc().startOf('month').day();
			firstDayOfGrid = moment(currentMoment).utc().startOf('month').subtract(firstOfMonth, 'days');
		}

		const start = firstDayOfGrid.date();
		return range(start, start + 42).map((date: number): CalendarDate => {
			const d = moment(firstDayOfGrid).date(date);
			const dateInfo: CalendarDate = {
				today: this.isToday(d),
				disabled: this.getIsDayDisabled(d),
				selected: this.isSelected(d),
				mDate: d,
				isDot: this.dotsDates ? this.dotsDates.has(d.toDate().toDateString()) : false,
				tooltipContent: this.getIsDayOutOfRangeTooltip(d),
				isWeekendDay: this.getIsDayOnWeekend(d),
				isInSelectedMonth: this.isSelectedMonth(d),
			};
			if (this.tooltipDates) {
				let tooltipDate = this.tooltipDates.find((tooltip) => moment.utc(tooltip.date).isSame(d.utc(), 'day'));
				if (this.exclusionId !== null) {
					tooltipDate = this.tooltipDates.find(
						(tooltip) => moment.utc(tooltip.date).isSame(d.utc(), 'day') && tooltip.exclusionId !== this.exclusionId
					);
				}
				if (tooltipDate) {
					dateInfo.disabled = tooltipDate.disabled;
					if (tooltipDate.disabled && tooltipDate.tooltipContent) {
						dateInfo.tooltipContent = tooltipDate.tooltipContent;
					}
				}
				dateInfo.cssClasses = this.getCssClassesForDay(dateInfo);
				return dateInfo;
			}
			dateInfo.cssClasses = this.getCssClassesForDay(dateInfo);
			return dateInfo;
		});
	}

	private getCssClassesForDay(day: CalendarDate): string {
		const classes = ['week-date'];
		if ((this.isNextMonth(day.mDate) || this.isPreviousMonth(day.mDate) || !day.isInSelectedMonth) && !day.today) {
			classes.push('is-next-month');
		}
		if (day.selected) {
			classes.push('selected');
		}
		if (day.disabled) {
			classes.push('disabled');
		}
		if (this.rangeWeeks && day.selected) {
			classes.push('first_last_element_radius');
		}
		if (day.today) {
			classes.push('today');
		}
		if (day.selected && this.selectedDates.length > 1 && this.isFirstSelectedDay(day.mDate)) {
			classes.push('leftBorder');
		}
		return classes.join(' ');
	}

	private getIsDayOutOfRangeTooltip(date: moment.Moment): ToolTipDataType | null {
		if (
			(this.min && this.max && date.utc().isBefore(this.min.utc(), 'day')) ||
			(this.min && this.max && date.utc().isAfter(this.max.utc(), 'day') && this.outsideRangeTooltip)
		) {
			return this.outsideRangeTooltip;
		}
		return null;
	}

	private getIsDayDisabled(date: moment.Moment): boolean {
		if (!this.showWeekendDay(date)) {
			return false;
		}
		return (this.min && date.utc().isBefore(this.min, 'day')) || (this.max && date.utc().isAfter(this.max, 'day'));
	}

	onClickDay(day: CalendarDate): void {
		if (day.disabled) {
			this.switchMonth(day);
			return;
		}
		this.selectDate(day);
	}
}
