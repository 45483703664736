/*
Example use of this component

In the parent component that opens the dialog, there should be a ViewChild template ref
that will be passed into the dialog.	If the dialog should contain a form input,
you will also need a form group and dialog service (defined as a variable, not injected
in the constructor). You will also need to inject the DialogFactoryService in the constructor.

  In the typescript of the parent component:

  ============
	@ViewChild('modalBody', {read: TemplateRef, static: false}) modalBody: TemplateRef<any>;
	private dialogService: DynamicDialogService;
	private className: string; // create variable to hold value of form input

	constructor(private dialogFactoryService: DialogFactoryService) {}

	public ngOnInit(): void {
		// create form group with input control
		this.form = new FormGroup({
			className: new FormControl('Classroom NBC', [Validators.required]),
		});
		// Example for how to subscribe to form value changes and disable button if not valid
		this.form.valueChanges.subscribe((results) => {
			this.dialogService.setDisablePrimaryButton(!this.form.get('className').valid);
			this.className = this.form.get('className').value;
		});
	}
	===========

	In the template of the parent:
	<ng-template #modalBody>Modal body goes here</ng-template>

	Example template with form input:

	<ng-template #modalBody>
		<form [formGroup]="form">
			<sp-input
				[group]="form"
				[control]="'className'"
				[label]="'Class Name'"
				[description]="'Use the name of the course. For example, simply “Algebra 2.”'"
				[placeholder]="'e.g. Algebra 2'"
				[errorMessage]="'Please enter a name for this class.'"></sp-input>
		</form>
	</ng-template>

When opening the dialog, do the following in the parent component:

	public openModal(): void {
		const data: DynamicDialogData = {
			headerText: 'Edit Class Name',
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.modalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
		};
		const options: DynamicDialogOptions = {
			panelClass: 'dynamic-dialog-modal',
			disableClose: false,
		};
		this.dialogService = this.dialogFactoryService.open(data, options);
		this.dialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				// do something based on which button was clicked (use form values to save)
				console.log('selectedOption', selectedOption);
				if (selectedOption === 'Save') {
					// save this.className
				}
			},
		});
	}

 */

import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { WrappedIcon } from './wrapped-icon.component';

export type DynamicDialogData = {
	headerText?: string;
	// this will display below the header if provided
	subHeaderTemplate?: TemplateRef<any>;
	showCloseIcon?: boolean;
	primaryButtonLabel: string;
	secondaryButtonLabel: string;
	modalBody: TemplateRef<any>;
	displayModalFooter: boolean;
	modalMaxHeight?: number;
	templateData?: Record<string, any>;
	// this will display in the left part of the footer if provided
	// but cannot be used if dialog is a wizard with steps
	leftFooterTemplate?: TemplateRef<any>;
	primaryButtonTooltip?: TemplateRef<any>;
	primaryButtonGradientBackground?: string;
	primaryButtonTextColor?: string;
	secondaryButtonGradientBackground?: string;
	secondaryButtonTextColor?: string;
	disablePrimaryButton?: boolean;
	disableSecondaryButton?: boolean;
	classes?: string;
	icon?: WrappedIcon;
};

export type DynamicDialogDataWithWizard = {
	headerText: string;
	showCloseIcon: boolean;
	secondaryButtonGradientBackground: string;
	secondaryButtonTextColor: string;
	steps: WizardSteps[];
};
export type WizardSteps = {
	stepNumber: string; // 1 but would display as 1 of Wizard.steps.length
	dialogData: DynamicDialogData; // this updates for each step
};

export type DynamicDialogOptions = {
	panelClass: string | string[];
	disableClose: boolean;
	autoFocus?: boolean;
};
export type DynamicDialogAction = 'secondary' | 'primary';

export const DYNAMIC_DIALOG_OPTIONS: DynamicDialogOptions = {
	panelClass: 'dynamic-dialog-modal',
	disableClose: false,
};

@Component({
	selector: 'sp-dynamic-dialog-modal',
	templateUrl: './dynamic-dialog-modal.component.html',
	styleUrls: ['./dynamic-dialog-modal.component.scss'],
})
export class DynamicDialogModalComponent implements OnInit, AfterViewChecked {
	@ViewChild('modalHeader', { read: ElementRef, static: false }) modalHeader!: ElementRef<HTMLElement>;
	@ViewChild('modalBodyContainer', { read: ElementRef, static: false }) modalBodyContainer!: ElementRef<HTMLElement>;
	@ViewChild('modalFooter', { read: ElementRef, static: false }) modalFooter!: ElementRef<HTMLElement>;

	stepChanged$ = new BehaviorSubject<number>(0);

	displayData!: DynamicDialogData;
	stepIndex = 0;
	isWizard = false;
	modalBodyContainerHeightStr = '';
	private modalMaxHeight = 0.85;
	hasScroll = false;
	scrolled = false;
	templateData: Record<string, any> = {};

	constructor(
		public dialogRef: MatDialogRef<DynamicDialogModalComponent>,
		public cdr: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: DynamicDialogData | DynamicDialogDataWithWizard
	) {}

	ngOnInit(): void {
		if ((this.data as DynamicDialogDataWithWizard)?.steps?.length > 0) {
			this.isWizard = true;
			this.updateDisplayData();
		} else {
			this.displayData = this.data as DynamicDialogData;
			this.templateData = this.displayData?.templateData ?? {};
		}
		if (this.displayData.modalMaxHeight) {
			this.modalMaxHeight = this.displayData.modalMaxHeight;
		}
	}

	ngAfterViewChecked(): void {
		this.updateModalScrolling();
	}

	onSecondaryButtonClick(): void {
		if (!this.isWizard) {
			this.dialogRef.close('secondary');
			return;
		}
		// moves back a step if not on first step
		if (this.stepIndex > 0) {
			this.stepIndex--;
			this.updateDisplayData(true);
			this.updateModalScrolling();
			this.stepChanged$.next(this.stepIndex);
			return;
		} else if (this.stepIndex == 0) {
			this.dialogRef.close('secondary');
			return;
		}
	}

	onPrimaryButtonClick(): void {
		if (!this.isWizard) {
			this.dialogRef.close('primary');
			return;
		}
		if (this.stepIndex + 1 == (this.data as DynamicDialogDataWithWizard).steps.length) {
			this.dialogRef.close('primary');
			return;
		} else {
			// trigger next step
			this.stepIndex++;
			this.updateDisplayData(true);
			this.updateModalScrolling();
			this.stepChanged$.next(this.stepIndex);
			return;
		}
	}

	private updateDisplayData(isStepChange = false): void {
		this.displayData = (this.data as DynamicDialogDataWithWizard).steps[this.stepIndex].dialogData;
		this.displayData.headerText ? null : (this.displayData.headerText = this.data.headerText);
		this.displayData.showCloseIcon = this.data.showCloseIcon;
		this.displayData.secondaryButtonGradientBackground = this.data.secondaryButtonGradientBackground;
		this.displayData.secondaryButtonTextColor = this.data.secondaryButtonTextColor;
		if (!isStepChange) {
			(this.data as DynamicDialogData).disablePrimaryButton = this.displayData.disablePrimaryButton;
		}
	}

	private updateModalScrolling(): void {
		const modalHeaderHeight = this.modalHeader?.nativeElement.offsetHeight ?? 0;
		const modalFooterHeight = this.modalFooter?.nativeElement.offsetHeight ?? 0;
		const modalBodyScrollHeight = this.modalBodyContainer.nativeElement.scrollHeight ?? 0;

		const windowHeight = window.innerHeight;
		const modalMaxHeight = windowHeight * this.modalMaxHeight;

		const needsScroll = modalMaxHeight < modalBodyScrollHeight + modalHeaderHeight + modalFooterHeight;
		let newHeightStr = '';
		if (needsScroll) {
			this.hasScroll = true;
			this.dialogRef.addPanelClass('has-scroll');
			newHeightStr = `${modalMaxHeight - (modalHeaderHeight + modalFooterHeight)}px`;
		} else {
			this.hasScroll = false;
			this.dialogRef.removePanelClass('has-scroll');
		}

		if (this.modalBodyContainerHeightStr != newHeightStr) {
			this.modalBodyContainerHeightStr = newHeightStr;
			this.cdr.detectChanges();
		}
	}

	onScroll(event: Event): void {
		const target = event.target as HTMLElement | undefined;
		if (target) {
			this.scrolled = target.scrollTop > 0;
		}
	}
}
