import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { DeviceDetection } from '../../../../device-detection.helper';
import { PassLimitInfo } from '../../../../models/HallPassLimits';
import { Location } from '../../../../models/Location';
import { PassLimit } from '../../../../models/PassLimit';
import { Pinnable } from '../../../../models/Pinnable';
import { User } from '../../../../models/User';
import { FeatureFlagService, FLAGS } from '../../../../services/feature-flag.service';
import { HallPassesService } from '../../../../services/hall-passes.service';
import { KioskModeService } from '../../../../services/kiosk-mode.service';
import { LocationsService } from '../../../../services/locations.service';
import { PassLimitService } from '../../../../services/pass-limit.service';
import { ScreenService } from '../../../../services/screen.service';
import { UserService } from '../../../../services/user.service';
import { CreateFormService } from '../../../create-form.service';
import { FormFactor, Navigation } from '../../main-hall-pass-form.component';
import { States } from '../locations-group-container.component';
import { PassLimitDialogComponent } from '../pass-limit-dialog/pass-limit-dialog.component';

/**
 * TODO: This component should be refactored so that it emits a location and nothing more
 */

@Component({
	selector: 'app-to-where',
	templateUrl: './to-where.component.html',
	styleUrls: ['./to-where.component.scss'],
})
export class ToWhereComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('header', { static: true }) header: ElementRef<HTMLDivElement>;

	@ViewChild('rc', { static: true }) set rc(rc: ElementRef<HTMLDivElement>) {
		if (rc) {
			fromEvent(rc.nativeElement, 'scroll').subscribe((evt: Event) => {
				let blur: number;

				if ((evt.target as HTMLDivElement).scrollTop < 100) {
					blur = 5;
				} else if ((evt.target as HTMLDivElement).scrollTop > 100 && (evt.target as HTMLDivElement).scrollTop < 400) {
					blur = (evt.target as HTMLDivElement).scrollTop / 20;
				} else {
					blur = 20;
				}

				this.header.nativeElement.style.boxShadow = `0 1px ${blur}px 0px rgba(0,0,0,.2)`;
			});
		}
	}

	@Input() formState: Navigation;
	@Input() isStaff: boolean;
	@Input() date;
	@Input() studentText;

	@ViewChildren('ngForPinnables') checkPinnables: QueryList<any>;

	ngAfterViewInit(): void {
		this.loading = this.checkPinnables.length === 0;
		this.checkPinnables.changes.subscribe((change) => {
			if (change?.length > 0) {
				this.loading = false;
			}
		});
	}

	loading = false;
	placeholder: string;

	@Output() selectedLocation: EventEmitter<Pinnable> = new EventEmitter();
	@Output() backButton: EventEmitter<any> = new EventEmitter<any>();

	states;

	teacherRooms: Pinnable[] = [];

	passLimits: { [id: number]: PassLimit };

	frameMotion$: BehaviorSubject<any>;

	hasSelectedPinnableValue = false;

	headerTransition = {
		'to-header': true,
		'to-header_animation-back': false,
	};

	updatedLocation$: Observable<Location>;
	suggestedPinnables: Observable<Pinnable[]> = of([]);
	originLocation: Location;
	originPinnable$: Observable<Pinnable>;
	destroy$: Subject<void> = new Subject<void>();
	destinationPinnable: Pinnable; // only defined when a destination is selected
	pinnableDimensions = this.screenService.getPinnableDimensions();
	numSelectedStudents: string;
	pinnables: Pinnable[];

	@HostListener('window:resize')
	private refreshPinnableDimensions() {
		this.pinnableDimensions = this.screenService.getPinnableDimensions();
	}

	constructor(
		public dialogRef: MatDialogRef<ToWhereComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private formService: CreateFormService,
		public screenService: ScreenService,
		private locationsService: LocationsService,
		private dialog: MatDialog,
		private userService: UserService,
		private kioskService: KioskModeService,
		private featureFlags: FeatureFlagService,
		private pinnableService: HallPassesService,
		private studentPassLimits: PassLimitService
	) {
		this.states = States;
	}

	ngOnInit() {
		this.pinnableService.pinnables$.pipe(takeUntil(this.destroy$)).subscribe((pinnables) => {
			this.pinnables = pinnables;
		});
		this.placeholder = this.isStaff ? 'To Where?' : 'Where to?';
		this.originLocation = this.formState.data.direction.from;
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		if (this.formState.data.teacherRooms && !this.dialogData['kioskMode']) {
			this.teacherRooms = this.formState.data.teacherRooms;
		}

		this.numSelectedStudents = this.getNumSelectedStudents();
		this.frameMotion$.subscribe((v: any) => {
			switch (v.direction) {
				case 'back':
					this.headerTransition['to-header'] = false;
					this.headerTransition['to-header_animation-back'] = true;
					break;
				case 'forward':
					this.headerTransition['to-header'] = true;
					this.headerTransition['to-header_animation-back'] = false;
					break;
				default:
					this.headerTransition['to-header'] = true;
					this.headerTransition['to-header_animation-back'] = false;
			}
		});

		if (this.originLocation) {
			this.originPinnable$ = this.pinnableService.pinnables$.pipe(
				takeUntil(this.destroy$),
				map((pins) => {
					const pin = pins.find((p) => p?.location?.id === this.originLocation.id) || pins.find((p) => p?.category === this.originLocation.category);
					return new Pinnable(
						undefined,
						this.originLocation.title,
						pin.gradient_color,
						pin.icon,
						'location',
						this.originLocation,
						pin.category,
						pin.color_profile,
						pin.ignore_students_pass_limit,
						pin.show_as_origin_room
					);
				})
			);
		}

		if (this.formState.data?.direction?.from?.id) {
			const studentPassLimit$ =
				this.isStaff && !this.formState.kioskMode ? of<PassLimitInfo>({ showPasses: false }) : this.studentPassLimits.passLimitChanges$();

			this.suggestedPinnables = combineLatest(
				this.locationsService.getSuggestedRooms(this.formState.data?.direction?.from.id),
				this.pinnableService.pinnables$.pipe(take(1)),
				studentPassLimit$
			).pipe(
				takeUntil(this.destroy$),
				map(([response, allPins, passLimitInfo]) => {
					const pins = response.suggested_locations.map((loc) => {
						return allPins.find((p) => p?.location?.id === loc.location.id) || Pinnable.fromAugmentedLocation(loc);
					});
					const { showPasses, current } = passLimitInfo;
					return pins.map((p) => {
						const newPinnable = cloneDeep(p);
						newPinnable.location.restricted = p.location.restricted || (!p.ignore_students_pass_limit && showPasses && current === 0);
						return newPinnable;
					});
				})
			);
		} else {
			this.suggestedPinnables = of([]);
		}

		this.locationsService.getPassLimitRequest();
		this.locationsService.pass_limits_entities$.subscribe((res) => {
			this.passLimits = res;
		});

		if (this.formState.data.roomStudentsAfterFromStep) {
			this.formState.data.roomStudents = [...this.formState.data.roomStudentsAfterFromStep];
		}

		this.userService.userData
			.pipe(
				filter((u) => !!u),
				take(1)
			)
			.subscribe({
				next: (u: User) => {
					this.user = u;

					const stateData = this.formState.data;
					const isDedicatedUser =
						this.formState.kioskMode && (!!this.user?.roles.includes('_profile_kiosk') || stateData?.kioskModeStudent instanceof User);
					const isStaffUser = !this.user.isStudent() && this.formState.kioskMode;
					const isChooseSelectedStudent = isStaffUser || isDedicatedUser;
					const student = [this.user];
					if (isChooseSelectedStudent) {
						student[0] = stateData.kioskModeStudent || stateData.selectedStudents[0];
					}
				},
			});

		this.updatedLocation$ = this.formService.getUpdatedChoice();
	}

	private user: User;

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	checkPinnable(forTeacherRooms: boolean, pinnable: Pinnable): boolean {
		// hide kiosk mode room
		if (!forTeacherRooms) {
			if (this.formState.kioskMode) {
				return pinnable.location && this.originLocation ? this.isValidPinnable(pinnable) : true;
			} else {
				return true;
			}
		} else {
			if (this.formState.kioskMode) {
				return pinnable.location && this.originLocation ? pinnable.location.id != this.originLocation?.id : true;
			} else {
				return true;
			}
		}
	}

	isValidPinnable(pinnable: Pinnable) {
		// === and ids are dangerous as ids are numeric strings or numbers
		// using == will pose its own dangers
		// if (pinnable.location.id == this.location.id)
		// as we know ids are numbers we cast them to pure numbers
		if (+pinnable.location.id === +this.originLocation?.id) return false;

		if (this.isStaff && !this.formState.kioskMode) return true;

		const forNowCondition =
			!this.formState.forLater &&
			pinnable.location.restricted &&
			pinnable.location.request_mode === 'all_teachers_in_room' &&
			pinnable.location.request_send_origin_teachers &&
			!this.originLocation?.teachers.length;

		const forLaterCondition =
			this.formState.forLater &&
			pinnable.location.scheduling_restricted &&
			pinnable.location.scheduling_request_mode === 'all_teachers_in_room' &&
			pinnable.location.scheduling_request_send_origin_teachers &&
			!this.originLocation?.teachers.length;

		return !(forNowCondition || forLaterCondition);
	}

	countStudents(): number {
		let sum = 0;
		const selectedStudents = this.formState.data.roomStudents ?? this.formState.data.selectedStudents;
		if (selectedStudents) sum += selectedStudents.length;
		return sum;
	}

	/**
	 * If there's nothing in the way ot selecting a location or pinnable, then we can emit
	 * and move on to the next pass screen after selecting a destination
	 */
	private forwardAndEmit() {
		this.formService.setFrameMotionDirection('disable');
		this.formService.scalableBoxController.next(true);
		setTimeout(() => {
			this.selectedLocation.emit(this.destinationPinnable);
		}, 100);
	}

	/**
	 * If a student has not reached the room limit, they are allowed to continue creating their pass
	 */
	private async handleStudentRoomLimits(selection: Location) {
		const reachedRoomPassLimit = this.locationsService.reachedRoomPassLimit('to', this.passLimits[+selection.id]);

		if (!reachedRoomPassLimit) {
			this.forwardAndEmit();
			return;
		}

		if (this.featureFlags.isFeatureEnabled(FLAGS.WaitInLine)) {
			// move forward to wait in line card
			this.forwardAndEmit();
			return;
		}

		const studentRoomLimitReachedConfig = {
			panelClass: 'overlay-dialog',
			backdropClass: 'custom-backdrop',
			width: '450px',
			height: '163px',
			disableClose: true,
			data: {
				isStudent: true,
			},
		};

		const chooseAnotherLocation = (
			await this.dialog.open(PassLimitDialogComponent, studentRoomLimitReachedConfig).afterClosed().pipe(takeUntil(this.destroy$)).toPromise()
		).override;

		if (!chooseAnotherLocation) {
			this.dialogRef.close();
		}

		return;
	}

	private overwritePinWithNewLocation(pin: Pinnable, loc: Location): Pinnable {
		const clonedPin = cloneDeep(pin);
		clonedPin.location = loc;
		clonedPin.title = loc.title;
		clonedPin.type = 'location';
		clonedPin.id = null;
		return clonedPin;
	}

	/**
	 * If a teacher selects a room for a group of students and some student do not belong to the
	 * room's visibility rule, we have the option to either skip those students or override the rule
	 * and create the pass for all selected students
	 */
	async locationSelected(location: Location) {
		const destinationPinnable = this.pinnables?.find((p) => p.location?.id === location.id || p.category === location.category);
		if (location && destinationPinnable) {
			this.destinationPinnable = this.overwritePinWithNewLocation(destinationPinnable, location);
		}
		// only students
		if (!this.isStaff || this.formState.kioskMode) {
			await this.handleStudentRoomLimits(location);
			return;
		}

		this.hasSelectedPinnableValue = true;
		const allowed = await this.locationsService.checkIfFullRoom(location, this.kioskService.isKioskMode(), this.countStudents());
		if (!allowed) {
			this.hasSelectedPinnableValue = false;
			return;
		}

		const emitter = () => {
			this.selectedLocation.emit(this.destinationPinnable);
		};
		const permission = await this.formService
			.checkRoomVisibility(this.formState, false, location, emitter)
			.pipe(takeUntil(this.destroy$))
			.toPromise();
		if (!permission) {
			this.hasSelectedPinnableValue = false;
			return;
		}
	}

	async pinnableSelected(pin: Pinnable) {
		if (pin.type === 'category') {
			this.selectedLocation.emit(pin);
			return;
		}

		await this.locationSelected(pin.location);
	}

	back() {
		this.formService.scalableBoxController.next(false);
		this.formService.compressableBoxController.next(false);
		this.formService.setFrameMotionDirection('back');
		// }
		setTimeout(() => {
			if (!!this.date && !!this.studentText && (this.formState.previousStep === 2 || this.formState.previousStep === 4)) {
				this.formState.step = 1;
				this.formState.previousStep = 3;
			} else {
				if (this.formState.formMode.formFactor === FormFactor.Invitation && this.formState.data.date.declinable) {
					this.formState.step = 1;
				} else {
					if (this.formState.kioskMode) {
						this.formState.step = 2;
						this.formState.state = States.restrictedTarget;
						if (!this.kioskService.getKioskModeSettings().findByName && !this.kioskService.getKioskModeSettings().findById) this.formState.step = 0;
					} else {
						this.formState.data.direction.from = null;
						this.formState.state -= 1;
					}
				}
			}
			this.formState.previousState = 2;
			if (this.formState?.data) {
				this.formState.data.roomStudentsAfterFromStep = null;
			}

			this.backButton.emit(this.formState);
		}, 100);
	}

	private getNumSelectedStudents(): string {
		const num = this.formState.data.selectedStudents.length;
		return `${num} student${num != 1 ? 's' : ''}`;
	}

	get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}
}
