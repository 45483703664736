import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { FlexPeriod } from 'app/services/flex-period.service';
import {
	CreateFlexPeriodAction,
	DeleteFlexPeriodAction,
	DeleteFlexPeriodFailureAction,
	GetFlexPeriodsSuccessAction,
	UpdateFlexPeriodAction,
	UpdateFlexPeriodFailureAction,
	UpdateFlexPeriodSuccessAction,
} from './flex-periods.actions';
import { FlexPeriodState } from './flex-periods.state';

export const flexPeriodFeatureKey = 'flexPeriod';

export const initialState: FlexPeriodState = {
	ids: [],
	entities: {},
	loading: true,
	loaded: false,
};

export const flexPeriodAdapter: EntityAdapter<FlexPeriod> = createEntityAdapter<FlexPeriod>();

const reducer = createReducer(
	initialState,

	on(GetFlexPeriodsSuccessAction, (state, { flexPeriods }) => {
		if (flexPeriods) {
			return flexPeriodAdapter.setAll(flexPeriods, { ...state, loading: false, loaded: true });
		}
		return state;
	}),

	// Create
	on(CreateFlexPeriodAction, (state, { flexPeriod }) => {
		return flexPeriodAdapter.addOne(flexPeriod, { ...state, loading: false, loaded: true });
	}),

	// Update
	on(UpdateFlexPeriodAction, (state, { update }) => {
		return flexPeriodAdapter.updateOne(update, { ...state, loading: false, loaded: true });
	}),
	on(UpdateFlexPeriodSuccessAction, (state, { updated }) => {
		return flexPeriodAdapter.upsertOne(updated, { ...state, loading: false, loaded: true });
	}),
	on(UpdateFlexPeriodFailureAction, (state, { original }) => {
		return flexPeriodAdapter.upsertOne(original, { ...state, loading: false, loaded: true });
	}),

	// Delete
	on(DeleteFlexPeriodAction, (state, { id }) => {
		return flexPeriodAdapter.removeOne(id, { ...state, loading: false, loaded: true });
	}),
	on(DeleteFlexPeriodFailureAction, (state, { flexPeriod }) => {
		return flexPeriodAdapter.upsertOne(flexPeriod, { ...state, loading: false, loaded: true });
	})
);

export function flexPeriodsReducer(state: FlexPeriodState, action: Action) {
	return reducer(state, action);
}
