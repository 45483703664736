import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { uniqBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetection } from '../../../../device-detection.helper';
import { MessageBoxViewRestriction } from '../../../../models/message-box-view-restrictions/MessageBoxViewRestriction';
import { MessageBoxViewRestrictionLg } from '../../../../models/message-box-view-restrictions/MessageBoxViewRestrictionLg';
import { MessageBoxViewRestrictionSm } from '../../../../models/message-box-view-restrictions/MessageBoxViewRestrictionSm';
import { User } from '../../../../models/User';
import { FeatureFlagService } from '../../../../services/feature-flag.service';
import { ScreenService } from '../../../../services/screen.service';
import { CreateFormService } from '../../../create-form.service';
import { Navigation } from '../../main-hall-pass-form.component';
import { States } from '../locations-group-container.component';

@Component({
	selector: 'app-restricted-message',
	templateUrl: './restricted-message.component.html',
	styleUrls: ['./restricted-message.component.scss'],
})
export class RestrictedMessageComponent implements OnInit {
	@Input() formState: Navigation;
	@Input() gradient: string;
	@Input() teacher: User;
	@Input() date: string | boolean;
	@Input() isStaff: boolean;

	@Output() resultMessage: EventEmitter<any> = new EventEmitter<any>();
	@Output() backButton: EventEmitter<any> = new EventEmitter<any>();

	messageBox;
	@ViewChild('messageBox', { static: true }) set content(content: ElementRef) {
		this.messageBox = content;
		// this.messageBox.nativeElement.focus();
	}

	message: UntypedFormControl;

	frameMotion$: BehaviorSubject<any>;

	headerTransition = {
		'rest-mes-header': true,
		'rest-mes-header_animation-back': false,
	};

	messageBoxViewRestriction: MessageBoxViewRestriction = new MessageBoxViewRestrictionLg();

	constructor(private formService: CreateFormService, private screenService: ScreenService, private featureFlagService: FeatureFlagService) {}

	get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}

	get headerGradient() {
		const colors = this.gradient;
		return 'radial-gradient(circle at 98% 97%,' + colors + ')';
	}

	get teachersNames() {
		const to = this.formState.data.direction.to;
		if (!this.formState.forLater && to?.request_mode === 'specific_teachers') {
			return to.request_teachers;
		} else if ((!this.formState.forLater && to.request_mode === 'all_teachers_in_room') || (!this.formState.forLater && this.teachersLength === 1)) {
			if (to.request_send_origin_teachers && to.request_send_destination_teachers) {
				return [...this.formState.data.direction.from.teachers, ...this.formState.data.direction.to.teachers];
			} else if (to.request_send_origin_teachers) {
				return this.formState.data.direction.from.teachers;
			} else if (to.request_send_destination_teachers) {
				return this.formState.data.direction.to.teachers;
			}
		}
		if (this.formState.forLater && to.scheduling_request_mode === 'specific_teachers') {
			return to.scheduling_request_teachers;
		} else if (
			(this.formState.forLater && to.scheduling_request_mode === 'all_teachers_in_room') ||
			(this.formState.forLater && this.teachersLength === 1)
		) {
			if (to.scheduling_request_send_origin_teachers && to.scheduling_request_send_destination_teachers) {
				return [...this.formState.data.direction.from.teachers, ...this.formState.data.direction.to.teachers];
			} else if (to.scheduling_request_send_origin_teachers) {
				return this.formState.data.direction.from.teachers;
			} else if (to.scheduling_request_send_destination_teachers) {
				return this.formState.data.direction.to.teachers;
			}
		}
		return [this.teacher];
	}

	get teachersLength() {
		const to = this.formState.data.direction.to;
		const from = this.formState.data.direction.from;
		if (to.request_mode === 'teacher_in_room') {
			if (to.request_send_origin_teachers && !to.request_send_destination_teachers) {
				return from.teachers.length;
			} else if (!to.request_send_origin_teachers && to.request_send_destination_teachers) {
				return to.teachers.length;
			} else if (to.request_send_origin_teachers && to.request_send_destination_teachers) {
				return to.teachers.length + from.teachers.length;
			}
		}
		if (to.scheduling_request_mode === 'teacher_in_room') {
			if (to.scheduling_request_send_origin_teachers && !to.scheduling_request_send_destination_teachers) {
				return from.teachers.length;
			} else if (!to.scheduling_request_send_origin_teachers && to.scheduling_request_send_destination_teachers) {
				return to.teachers.length;
			} else if (to.scheduling_request_send_origin_teachers && to.scheduling_request_send_destination_teachers) {
				return to.teachers.length + from.teachers.length;
			}
		}
	}

	get filteredTeachers() {
		return uniqBy(this.teachersNames as User[], 'id');
	}

	ngOnInit() {
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.message = new UntypedFormControl(this.formState.data.message);
		this.teacher = this.formState.data.requestTarget;
		this.messageBoxViewRestriction = this.getViewRestriction();
		this.frameMotion$.subscribe((v: any) => {
			switch (v.direction) {
				case 'back':
					this.headerTransition['rest-mes-header'] = false;
					this.headerTransition['rest-mes-header_animation-back'] = true;
					break;
				case 'forward':
					this.headerTransition['rest-mes-header'] = true;
					this.headerTransition['rest-mes-header_animation-back'] = false;
					break;
				default:
					this.headerTransition['rest-mes-header'] = true;
					this.headerTransition['rest-mes-header_animation-back'] = false;
			}
		});
	}

	back() {
		this.formService.setFrameMotionDirection('back');

		setTimeout(() => {
			if (!this.formState.forInput) {
				if (this.formState.missedRequest) {
					this.formState.state = !this.formState?.forLater ? States.FromToWhere : States.from;
				} else {
					this.formState.step = 0;
				}
			} else {
				if (this.formState.previousState === States.toWhere) {
					this.formState.previousState = this.formState.state;
					this.formState.state = States.toWhere;
				} else if (this.formState.previousState === States.FromToWhere) {
					this.formState.previousState = this.formState.state;
					this.formState.state = States.FromToWhere;
				} else {
					this.formState.previousState = this.formState.state;
					this.formState.state = this.formState.state - 1;
				}
			}
			this.backButton.emit(this.formState);
		}, 100);
	}

	sendRequest() {
		this.formService.setFrameMotionDirection('disable');
		setTimeout(() => {
			this.resultMessage.emit(this.message.value);
		}, 100);
	}

	onChangeAnimationDirection(evt) {
		// console.log(evt);
		this.headerTransition['rest-mes-header'] = false;
		this.headerTransition['rest-mes-header_animation-back'] = true;
	}

	@HostListener('window: resize')
	changeMessageBoxView() {
		this.messageBoxViewRestriction = this.getViewRestriction();
	}

	private getViewRestriction(): MessageBoxViewRestriction {
		if (this.screenService.isDeviceLargeExtra) {
			return new MessageBoxViewRestrictionSm();
		}

		return new MessageBoxViewRestrictionLg();
	}
}
