import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { UNANIMATED_CONTAINER } from '../../consent-menu-overlay';
import { ConsentMenuComponent } from '../../consent-menu/consent-menu.component';
import { DarkThemeSwitch } from '../../dark-theme-switch';
import { School } from '../../models/School';
import { SchoolActivity } from '../../models/SchoolActivity';
import { TotalAccounts } from '../../models/TotalAccounts';
import { AdminService } from '../../services/admin.service';
import { DaysOfWeek, FlexPeriod, FlexPeriodService } from '../../services/flex-period.service';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';
import {
	ConfirmationDialogComponent,
	RecommendedDialogConfig,
} from '../../shared/shared-components/confirmation-dialog/confirmation-dialog.component';
import { CreateFlexPeriodComponent } from './create-flex-period/create-flex-period.component';

declare const window: Window & typeof globalThis & { Intercom: (_: string) => void };

export enum FlexOptionSelect {
	DeleteFlex = 'delete_flex',
	DeleteActivity = 'delete_activity',
	DeleteInstance = 'delete_instance',
}

@Component({
	selector: 'sp-flex-period',
	templateUrl: './flex-period.component.html',
	styleUrls: ['./flex-period.component.scss'],
})
export class FlexPeriodComponent implements OnInit, OnDestroy {
	flexPeriods: FlexPeriod[] = [];
	activities: SchoolActivity[] = [];
	activitiesSorted: { [id: number]: SchoolActivity[] } = {};
	daysOfWeek = DaysOfWeek;
	loading = true;
	school: School | null = null;
	studentCount = 0;
	private destroy$: Subject<void> = new Subject<void>();

	@ViewChild('deleteDialogBody') deleteDialogBody!: TemplateRef<HTMLElement>;

	constructor(
		public darkTheme: DarkThemeSwitch,
		private dialog: MatDialog,
		private flexPeriodService: FlexPeriodService,
		private toastService: ToastService,
		private userService: UserService,
		private adminService: AdminService
	) {}

	accounts$: Observable<TotalAccounts | undefined> = this.adminService.countAccounts$;

	ngOnInit(): void {
		this.updateFlexPeriods();
		this.school = this.userService.getUserSchool();
		this.adminService.refreshAccountCounts();
		this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((count) => {
			const newCount = Number(count?.student_count);
			if (!isNaN(newCount)) {
				this.studentCount = newCount;
			}
		});
	}

	updateFlexPeriods(): void {
		this.loading = true;
		this.flexPeriodService
			.GetAllFlexPeriodsHTTP()
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => (this.loading = false))
			)
			.subscribe((res) => (this.flexPeriods = res));
	}

	deletePeriod(id: number): void {
		this.loading = true;
		this.flexPeriodService
			.DeleteFlexPeriodHTTP(id)
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => (this.loading = false))
			)
			.subscribe((wasDeleted) => {
				this.updateFlexPeriods();
				if (wasDeleted) {
					this.toastService.openToast({ title: `Flex period removed`, type: 'success' });
					return;
				}
				this.toastService.openToast({ title: `Issue encountered while deleting flex period, try again`, type: 'error' });
			});
	}

	openCreateFlex(): void {
		const dialogRef = this.dialog.open(CreateFlexPeriodComponent, { panelClass: 'main-form-dialog-container', width: '426px', maxHeight: '501px' });
		dialogRef.componentInstance.closeDialog = () => {
			this.updateFlexPeriods();
			dialogRef.close();
		};
	}

	formatDate(timestamp: string): string {
		const date = new Date(timestamp);
		return date.toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true,
		});
	}

	openDelete(event: MouseEvent, id: number) {
		const target = new ElementRef(event.currentTarget);
		const options = [{ display: 'Remove Flex Period', color: '#7083A0', action: FlexOptionSelect.DeleteFlex }];

		UNANIMATED_CONTAINER.next(true);
		const cancelDialog = this.dialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { header: '', options: options, trigger: target, adjustForScroll: true },
		});

		cancelDialog
			.afterClosed()
			.pipe(
				takeUntil(this.destroy$),
				filter<FlexOptionSelect>(Boolean),
				tap(() => UNANIMATED_CONTAINER.next(false))
			)
			.subscribe((action) => {
				if (action === FlexOptionSelect.DeleteFlex) {
					this.dialog
						.open(ConfirmationDialogComponent, {
							...RecommendedDialogConfig,
							width: '450px',
							data: {
								headerText: 'Remove the flex period?',
								body: this.deleteDialogBody,
								buttons: {
									confirmText: 'Remove flex period',
									denyText: 'Cancel',
								},
								templateData: {},
							},
						})
						.afterClosed()
						.toPromise()
						.then((deleteConfirmed: unknown) => {
							if (deleteConfirmed) {
								this.deletePeriod(id);
							}
						});
				}
			});
	}

	openIntercom(): void {
		window.Intercom('show');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
