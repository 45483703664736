import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DarkThemeSwitch } from '../../../dark-theme-switch';
import { HttpService } from '../../../services/http-service';
import { QRBarcodeGeneratorService } from '../../../services/qrbarcode-generator.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-id-card',
	templateUrl: './id-card.component.html',
	styleUrls: ['./id-card.component.scss'],
})
export class IdCardComponent implements OnInit, OnDestroy {
	@Input() userName = 'Nicholas Demarco';
	@Input() schoolName = 'Walt Whitman High School';
	@Input() userRole = 'Student';
	@Input() backgroundColor = '#00B476';
	@Input() barcodeType: string;
	@Input() profile_picture: string;
	@Input() backsideText: string;
	@Input() logoURL: string;
	@Input() IDNumberData: any = {};
	@Input() greadLevel: number;
	@Input() buttonBackColor = '#FFFFFF';
	@Input() isDummyCard = false;
	@Input() isLoggedIn = true;
	@Input() showCustomID = false;
	@Input() stickers: any = [];

	isIdCardLoaded = true;
	idCardAccess = false;

	destroy$: Subject<unknown> = new Subject<unknown>();

	constructor(
		public darkTheme: DarkThemeSwitch,
		private userService: UserService,
		private qrBarcodeGenerator: QRBarcodeGeneratorService,
		private http: HttpService
	) {}

	ngOnInit(): void {
		this.http.currentSchool$
			.pipe(
				takeUntil(this.destroy$),
				filter((school) => !!school)
			)
			.subscribe((school) => {
				this.idCardAccess = school.id_card_access == 'pro' ? true : false;
			});
		if (this.backsideText) {
			this.backsideText = this.urlify(this.backsideText);
		}
	}

	get getButtonText(): string {
		return document.getElementById('flip-box-inner').style.transform == 'rotateY(180deg)' ? 'Flip to front' : 'Flip to back';
	}

	get selectedBarcodeType(): string {
		return this.IDNumberData?.type;
	}

	toggleFlip() {
		if (document.getElementById('flip-box-inner').style.transform == 'rotateY(180deg)') {
			document.getElementById('flip-box-inner').style.transform = 'rotateY(0deg)';
		} else {
			document.getElementById('flip-box-inner').style.transform = 'rotateY(180deg)';
		}
	}

	urlify(text) {
		const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
		//var urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function (url, b, c) {
			const url2 = c == 'www.' ? 'http://' + url : url;
			return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
		});
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
