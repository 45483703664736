import { animate, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	Optional,
	Pipe,
	PipeTransform,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExclusionGroup, PreventEncounters } from 'app/models/ExclusionGroup';
import { HallPass } from 'app/models/HallPass';
import { HallPassLimit } from 'app/models/HallPassLimits';
import { Location } from 'app/models/Location';
import { User } from 'app/models/User';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';
import {
	BulkHallPassPostResponse,
	HallPassesService,
	OverrideEPData,
	OverridePassRequest,
	StartWaitingInLinePassResponse,
} from 'app/services/hall-passes.service';
import { LocationsService } from 'app/services/locations.service';
import { PassLimitService } from 'app/services/pass-limit.service';
import { ToastService } from 'app/services/toast.service';
import { UserService } from 'app/services/user.service';
import { WaitInLineService } from 'app/services/wait-in-line.service';
import { remove } from 'lodash';
import { EMPTY, forkJoin, Observable, of, Subject, timer } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, retryWhen, switchMap, take, takeUntil, tap } from 'rxjs/operators';

interface BlockingPass {
	pass: HallPass;
	group: ExclusionGroup;
}

@Pipe({
	name: 'filterEndedPasses',
	pure: false,
})
export class FilterEndedPassesPipe implements PipeTransform {
	transform(passes: HallPass[], currentTime: Date): HallPass[] {
		if (!passes || !currentTime) {
			return passes;
		}
		return passes.filter((pass) => new Date(pass.end_time) > currentTime);
	}
}

const animationTime = '300ms';

@Component({
	selector: 'sp-override-encounter-prevention',
	templateUrl: './override-encounter-prevention.component.html',
	styleUrls: ['./override-encounter-prevention.component.scss'],
	animations: [
		trigger('heightAnimation', [
			transition(':enter', []),
			transition(':leave', []),
			transition(
				'* => *',
				[
					style({
						height: '{{startHeight}}px',
						overflow: 'hidden',
					}),
					animate('{{time}} ease-in-out', style({ height: '{{endHeight}}px' })),
				],
				{ params: { startHeight: 0, endHeight: 0, time: animationTime } }
			),
		]),
	],
})
export class OverrideEncounterPreventionComponent implements OnInit, OnDestroy, AfterViewInit {
	descText = '';
	loading = true;
	blockedStudents: User[] = [];
	private studentsPassCreated: User[][] = [];
	dest!: Location;
	blockingPasses: BlockingPass[] = [];
	blockingPassesPasses: HallPass[] = [];
	showTopBorder = false;
	showBottomBorder = false;
	passesContainerExpanded = false;
	blockedGradient = '';
	blockedStudentGroups: ExclusionGroupWithOverrides[] = [];
	user!: User;
	currentTime!: Date;
	private allGroups: ExclusionGroupWithOverrides[] = [];
	private allBlockedStudents: User[] = [];
	private autoCreateTriggered = false;
	private allBlockingPasses: HallPass[] = [];
	private passLimit: HallPassLimit | undefined;
	private destroy$ = new Subject<void>();
	private studentIdSubject = new Subject<number | null>();

	heightAnimationParams = { value: '', params: { startHeight: 0, endHeight: 0, time: animationTime } };

	private blockedPasses$: Observable<void> = this.studentIdSubject.pipe(
		switchMap((id) => {
			if (id) {
				return this.encounterPreventionService.getExclusionGroupsWithOverrides({ studentId: id });
			}
			return of([]);
		}),
		take(1),
		map((groups: ExclusionGroupWithOverrides[]) => {
			this.allGroups = groups;
			this.blockedStudentGroups = groups.filter((g) => g.enabled);
			this.setBlockingPasses(this.blockedStudentGroups);
			return;
		}),
		takeUntil(this.destroy$),
		catchError(() => {
			this.toastService.openToast({
				title: 'Error fetching exclusion groups',
				type: 'error',
			});
			this.close();
			return EMPTY;
		})
	);

	private pinnable$: Observable<void> = this.locationService.getLocation(this.data.passRequest.destination).pipe(
		tap((l) => (this.dest = l)),
		switchMap(async (l) => this.hallPassService.getPinnableFromLocation(l)),
		take(1),
		map((res) => {
			this.setGradient(res.gradient_color);
			return;
		}),
		takeUntil(this.destroy$),
		catchError(() => {
			this.toastService.openToast({
				title: 'Issue encountered with displaying override modal. Please refresh the page and try again',
				type: 'error',
			});
			this.close();
			return EMPTY;
		})
	);

	@ViewChild('confirmDialogBody') confirmDialog!: TemplateRef<HTMLElement>;
	@ViewChild('overrideContainer') overrideContainer!: ElementRef;
	@ViewChild('passesContainer') passesContainer!: ElementRef;

	constructor(
		private hallPassService: HallPassesService,
		private locationService: LocationsService,
		private toastService: ToastService,
		private userService: UserService,
		private encounterPreventionService: EncounterPreventionService,
		private passLimitService: PassLimitService,
		private router: Router,
		private wilService: WaitInLineService,
		@Inject(MAT_DIALOG_DATA) public data: OverrideEPData,
		@Optional() private overrideRef: MatDialogRef<OverrideEncounterPreventionComponent>
	) {}

	ngOnInit(): void {
		this.passLimitService
			.getPassLimit()
			.pipe(takeUntil(this.destroy$))
			.subscribe((pl) => (this.passLimit = pl.pass_limit));
		this.allBlockingPasses = this.data.conflictPasses ?? [];
		this.initBlockedStudents();
		forkJoin([this.blockedPasses$, this.pinnable$])
			.pipe(
				take(1),
				finalize(() => (this.loading = false))
			)
			.subscribe();
		this.studentIdSubject.next(this.blockedStudents?.length ? this.blockedStudents[0].id : null);
		this.userService.effectiveUser$
			.pipe(
				takeUntil(this.destroy$),
				tap((u) => {
					this.user = u;
				})
			)
			.subscribe();
		timer(0, 1000)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.currentTime = new Date();
			});
		this.hallPassService
			.listenForActivePasses({})
			.pipe(
				takeUntil(this.destroy$),
				tap((passes) => {
					if (!this.autoCreateTriggered) {
						// Remove any passes that have ended
						this.allBlockingPasses = this.allBlockingPasses.filter((bp) => passes.find((p) => p.id === bp.id));
						this.setBlockingPasses(this.blockedStudentGroups);
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	ngAfterViewInit(): void {
		setTimeout(() => this.setBorderVisibility(), 500);
	}

	private initBlockedStudents(): void {
		this.allBlockedStudents = this.data.conflictStudentIds
			.map((sId) => {
				// If pass was sucessfully created, ignore
				if (this.data?.createdPasses?.find((h) => h.student.id === sId)) {
					return null;
				}

				const student = this.data.selectedStudents.find((s) => s.id === sId);
				if (student) {
					student.profile_picture = student.profile_picture || './assets/Avatar Default.svg';
				}
				return student;
			})
			.filter((s) => !!s)
			// Make alphabetical
			.sort((a, b) => {
				if (a.display_name < b.display_name) {
					return -1;
				}
				if (a.display_name > b.display_name) {
					return 1;
				}
				return 0;
			});

		const nextStudent = this.allBlockedStudents.shift();
		if (nextStudent) {
			this.blockedStudents = [nextStudent];
		}
	}

	setBorderVisibility(): void {
		const element = this.overrideContainer.nativeElement;
		const isOverflowing = element.scrollHeight > element.clientHeight;
		const atTop = element.scrollTop === 0;
		const atBottom = element.scrollHeight - element.scrollTop - 1 <= element.clientHeight;
		if (!isOverflowing) {
			this.showTopBorder = false;
			this.showBottomBorder = false;
		} else {
			this.showTopBorder = !atTop;
			this.showBottomBorder = atTop || !atBottom;
		}
	}

	private handlePassCreation(body: OverridePassRequest, onSuccess: (newPasses: HallPass[]) => void, onError: () => void): void {
		this.loading = true;
		of(body)
			.pipe(
				takeUntil(this.destroy$),
				concatMap((b) => this.hallPassService.bulkCreatePass(b)),
				retryWhen((errors: Observable<HttpErrorResponse>) =>
					this.hallPassService
						.handlePassLimitError({
							errors,
							studentIds: body.students ?? [],
							studentName: this.data.selectedStudents[0].display_name,
							frequency: this.passLimit?.frequency,
							confirmDialog: this.confirmDialog,
						})
						.pipe(
							filter(({ override }) => override !== undefined),
							concatMap(({ override, students }: { override: boolean; students: number[] }) => {
								body.override = override;
								if (override) {
									return of(null);
								}
								remove(body.students ?? [], (elem) => students.includes(elem));
								if (body.students?.length === 0) {
									return EMPTY;
								}
								return of(null);
							})
						)
				),
				catchError(() => {
					onError();
					return [];
				})
			)
			.subscribe({
				next: (res: Partial<BulkHallPassPostResponse>) => {
					let newPasses: HallPass[] = [];
					if (res.passes && res.passes.length > 0) {
						newPasses = res.passes.map((p) => HallPass.fromJSON(p));
					}
					onSuccess(newPasses);
				},
			});
	}

	overridePass(): void {
		this.loading = true;
		const body = this.preparePassRequestBody();

		if (this.data.waitInLinePassId) {
			if (body.override_encounter_ids && body.override_encounter_ids.length > 0) {
				this.startWaitInLinePass(body.override_encounter_ids[0]);
			} else {
				this.showToastError();
				this.close();
			}
		} else {
			this.createOverridePass(body);
		}
	}

	private preparePassRequestBody(): OverridePassRequest {
		const body = { ...this.data.passRequest };
		body.students = this.blockedStudents.map((s) => s.id);

		let encounter: PreventEncounters | undefined;
		encounter = this.data?.preventedEncounters?.find((pe) =>
			this.blockingPasses.length > 0
				? this.blockingPasses.some((bp) => bp.pass.id === pe.conflict_pass_id)
				: this.blockedStudentGroups.some((g) => g.id === pe.group_id)
		);
		if (!encounter) {
			encounter = this.data?.preventedEncounters?.find((pe) => this.allGroups.some((g) => g.id === pe.group_id));
		}

		if (encounter?.id) {
			body.override_encounter_ids = [encounter.id];
		}
		body.override = false;
		return body;
	}

	private startWaitInLinePass(encounterId: number): void {
		this.wilService
			.startWilPassNow(this.data.waitInLinePassId as number, encounterId)
			.pipe(
				takeUntil(this.destroy$),
				catchError(() => {
					this.showToastError();
					this.close();
					return EMPTY;
				})
			)
			.subscribe((res: StartWaitingInLinePassResponse) => {
				this.allBlockingPasses.push(res.pass);
				this.studentsPassCreated.push([res.pass.student]);
				this.handlePassCompletion([res.pass]);
			});
	}

	private createOverridePass(body: OverridePassRequest): void {
		this.handlePassCreation(
			body,
			(newPasses) => {
				this.allBlockingPasses.push(...newPasses);
				this.studentsPassCreated.push([...newPasses.map((p) => p.student)]);
				this.handlePassCompletion(newPasses);
			},
			() => {
				this.showToastError();
				this.close();
			}
		);
	}

	private showToastError(): void {
		this.toastService.openToast({
			title: `Unable to override pass${this.blockedStudents.length > 0 ? 'es' : ''}, please try again`,
			type: 'error',
		});
	}

	private createPass(): void {
		const body = this.data.passRequest;
		body.students = this.blockedStudents.map((s) => s.id);
		body.override = false;

		this.handlePassCreation(
			body,
			(newPasses) => {
				this.allBlockingPasses.push(...newPasses);

				for (const pass of newPasses) {
					this.toastService.openToast({
						title: `${pass.student.display_name}'s Pass Started`,
						subtitle: `Override no longer needed because blocking pass ended.`, // TO-DO name the blocking students
						type: 'success',
					});
				}
				this.handlePassCompletion(newPasses);
				this.autoCreateTriggered = false;
			},
			() => {
				this.toastService.openToast({
					title: `Unable to create pass${this.blockedStudents.length > 0 ? 'es' : ''}, please try again`,
					type: 'error',
				});
				this.close();
			}
		);
	}

	private handlePassCompletion(newPasses: HallPass[]): void {
		// No additional students to override
		if (this.allBlockedStudents.length === 0) {
			if (this.studentsPassCreated.length > 0) {
				for (const students of this.studentsPassCreated) {
					this.toastService.openToast({
						title: `Encounter Prevention Overridden`,
						subtitle: this.getSuccessMessage(students),
						type: 'success',
					});
				}
			}

			if (newPasses.length > 0) {
				const origin = newPasses[0].origin;
				const destination = newPasses[0].destination;
				if (
					!this.router.url.includes('student') &&
					this.user?.roles.includes('access_hall_monitor') &&
					origin &&
					destination &&
					(!this.data.teacherLocation || (origin?.id !== this.data.teacherLocation?.id && destination?.id !== this.data.teacherLocation?.id))
				) {
					this.router.navigate(['main/hallmonitor']);
				}
			}
			this.close();
		} else {
			this.nextStudent();
		}
	}

	private getSuccessMessage(students: User[]): string {
		switch (students.length) {
			case 1:
				return `${this.printNames(students)} has an active pass now.`;
			case 2:
				return `${this.printNames(students)} both have active passes now.`;
			default:
				return `${this.printNames(students)} all have active passes now.`;
		}
	}

	private setBlockingPasses(groups: ExclusionGroup[]): void {
		this.blockingPasses = [];
		const matchedGroups: ExclusionGroupWithOverrides[] = [];
		for (const pass of this.allBlockingPasses) {
			// Students shouldn't block themselves
			if (!this.blockedStudents.find((bs) => bs.id === pass.student.id)) {
				for (const group of groups) {
					if (group.users.find((u: User) => u.id === pass.student.id)) {
						this.blockingPasses.push({ pass: pass, group: group });
						matchedGroups.push(group);
						break;
					}
				}
			}
		}
		this.blockingPassesPasses = this.blockingPasses.map((bp) => bp.pass); // We're not allowed to used functions in the template so this is a workaround

		// Find all students that are in the same group as the blocked student
		if (this.blockingPasses.length === 0) {
			for (const group of groups) {
				for (const student of this.allBlockedStudents) {
					if (student.id !== this.blockedStudents[0].id && group.users.find((u: User) => u.id === student.id)) {
						this.blockedStudents.push(student);
						this.allBlockedStudents = this.allBlockedStudents.filter((s) => s.id !== student.id);
					}
				}
				if (this.blockedStudents.length > 1) {
					matchedGroups.push(group);
					break;
				}
			}
		}
		this.blockedStudentGroups = matchedGroups;
		this.setDesc();
		this.animatePassesContainer();

		// All blocking passes have ended
		if (this.blockedStudents.length === 1 && this.blockingPasses.length === 0 && this.currentTime && !this.loading && !this.autoCreateTriggered) {
			this.autoCreateTriggered = true;
			this.createPass();
		}
	}

	nextStudent(): void {
		const nextStudent = this.allBlockedStudents.shift();
		if (nextStudent) {
			this.blockedStudents = [nextStudent];
		} else {
			return this.close();
		}
		this.loading = true;
		this.blockedPasses$
			.pipe(
				takeUntil(this.destroy$),
				take(1),
				finalize(() => (this.loading = false))
			)
			.subscribe();
		this.studentIdSubject.next(this.blockedStudents[0]?.id);
	}

	private setDesc(): void {
		// One blocked student
		if (this.blockedStudents.length === 1) {
			if (this.blockingPasses.length === 0) {
				this.descText = `${this.printNames(this.blockedStudents)} no longer has any passes blocking them.`;
				return;
			}
			this.descText = `${this.printNames(
				this.blockedStudents
			)}'s pass was prevented because they can't have a pass at the same time as ${this.printNames(
				this.blockingPasses.map((bp) => bp.pass.student)
			)}.`;
		}
		// All students in pass creation were in an encounter prevention group
		else {
			this.descText = `${this.printNames(this.blockedStudents)}'s passes were prevented because they can't have a pass at the same time.`;
		}
	}

	close(): void {
		this.overrideRef.close();
		this.loading = false;
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	private animatePassesContainer(): void {
		const element = this.passesContainer.nativeElement;
		const startHeight = element.offsetHeight;
		const totalItems = this.blockingPasses.length + this.blockedStudents.length;
		this.passesContainerExpanded = totalItems > 3;

		setTimeout(() => {
			const endHeight = element.offsetHeight;

			this.heightAnimationParams = {
				value: this.passesContainerExpanded ? 'expanded' : 'collapsed',
				params: {
					startHeight: startHeight,
					endHeight: endHeight,
					time: animationTime,
				},
			};
		}, 0);
		this.setBorderVisibility();
		if (this.passesContainerExpanded) {
			this.showBottomBorder = true;
		}
	}

	private printNames(users: User[]): string {
		const names = users.map((u) => u.display_name);

		switch (names.length) {
			case 0:
				return '';
			case 1:
				return names[0];
			case 2:
				return names.join(' and ');
		}

		const last = names.pop();
		return `${names.join(', ')}, and ${last}`;
	}

	private setGradient(gradient: string): void {
		const colors = gradient.split(',');
		if (colors?.length < 2) {
			return;
		}
		this.blockedGradient = 'radial-gradient(circle at 73% 71%, ' + colors[0] + ', ' + colors[1] + ')';
	}

	cancel(): void {
		if (this.data.waitInLinePassId) {
			this.loading = true;
			this.wilService
				.deleteWilPass(this.data.waitInLinePassId)
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => this.close());
		}
		this.blockedStudents = [];
		this.nextStudent();
	}
}
