<div
	[ngClass]="{
		'to-wrapper-to-date': formState.forLater && formState.formMode.role === 1,
		'to-wrapper': !formState.forLater || formState.formMode.role !== 1,
		'no-fly-time-style': formState.noFlyTimeActive && !formState.forLater
	}">
	<div [ngClass]="headerTransition" #header>
		<div
			class="header-background"
			[attr.data-motion-opacity]="(frameMotion$ | async).direction"
			[style.background-image]="'radial-gradient(circle at 98% 97%, #FFFFFF, #FFFFFF)'"></div>

		<ng-container *ngIf="!formState.kioskMode && !isStaff">
			<span class="currently-header-text" [attr.data-motion-translate]="(frameMotion$ | async).direction">Currently at</span>
			<div
				*ngIf="originPinnable$ | async as originPin"
				class="from-origin"
				[attr.data-motion-translate]="(frameMotion$ | async).direction"
				(click)="back()">
				<div [style.background]="originPin.getCssGradient()" class="room-icon-container">
					<img class="room-icon" [src]="originPin.icon | resolveAsset" alt="ICON" />
				</div>

				<span class="room-title">{{ formState.data?.direction?.from?.title || 'Pass Origin' }}</span>
				<img *ngIf="formState.fromState !== 4" [src]="'./assets/Edit (Gray).svg'" alt="Change Origin Icon" />
			</div>
		</ng-container>

		<ng-container *ngIf="!formState.kioskMode && isStaff">
			<div style="margin-left: 16px" class="back-button back-button-grey" (click)="back()">
				<img [src]="'./assets/Back-Button.svg' | resolveAsset" alt="Back to Student Select" />
			</div>
			<div style="margin-right: 16px" class="selected-students" *ngIf="numSelectedStudents">
				<img class="group-icon" [src]="'./assets/Group (Blue-Gray).svg' | resolveAsset" />
				<div class="text">{{ numSelectedStudents }}</div>
			</div>
		</ng-container>
	</div>

	<div
		class="to-content ds-mt-20"
		[attr.data-motion-opacity]="(frameMotion$ | async).direction"
		[attr.data-motion-translate]="(frameMotion$ | async).direction">
		<div class="location-tables" style="width: 95%">
			<ng-container *ngIf="suggestedPinnables | async as sp">
				<app-location-table-v2
					class="locations"
					[forStaff]="isStaff"
					[rightHeaderText]="true"
					[inputWidth]="'240px'"
					[forLater]="formState.forLater"
					[selectedStudents]="formState.data.roomStudents ? formState.data.roomStudents : formState.data.selectedStudents"
					[hasLocks]="true"
					[showStars]="false"
					[noRightStar]="true"
					[showPicture]="true"
					[showFavorites]="true"
					[invalidLocation]="originLocation?.id"
					[originLocation]="originLocation"
					[searchByTeacherName]="true"
					[placeholder]="placeholder"
					[dummyString]="'Check your spelling and try again.'"
					[currentPage]="'to'"
					[updatedLocation$]="updatedLocation$"
					(choiceSelected)="locationSelected($event)"
					(pinnableSelected)="pinnableSelected($event)"
					[mergedAllRooms]="true"
					[forKioskMode]="formState.kioskMode"
					[passLimitInfo]="formState.passLimitInfo"
					[hasSelectedPinnable]="hasSelectedPinnableValue"
					[showAsPinnables]="true"
					[suggestedPinnables]="sp">
					<!-- Preliminary method of trying to make code more reusable -->
					<div pin-collection>
						<ng-template #loadingSuggested>
							<div style="width: 100%; display: grid; place-content: center; margin-bottom: 20px" class="loading-theme_blue-gray">
								<mat-spinner [diameter]="30" color="accent"></mat-spinner>
							</div>
						</ng-template>
						<ng-container *ngIf="suggestedPinnables | async as suggestedPins; else loadingSuggested">
							<ng-container *ngIf="suggestedPins.length > 0">
								<div class="closest-rooms-header">Closest Rooms</div>
								<div class="pin-grid" [ngClass]="pinnableDimensions.isLarge ? 'grid-lg' : 'grid-sm'">
									<ng-container #ngForPinnables *ngFor="let pinnable of suggestedPins">
										<ng-container *ngIf="checkPinnable(false, pinnable)">
											<app-pinnable
												[height]="pinnableDimensions.height"
												[width]="pinnableDimensions.width"
												[valid]="pinnable.location && originLocation ? isValidPinnable(pinnable) : true"
												[forStaff]="isStaff && !dialogData.kioskMode"
												[passLimit]="pinnable.location ? passLimits[pinnable.location.id] : null"
												[disabledRoom]="pinnable.location ? !pinnable.location.enable : false"
												[forLater]="!!date"
												[pinnable]="pinnable"
												[showActivePassesCounter]="isStaff"
												[currentPage]="'to'"
												[isSameRoom]="pinnable.location && originLocation ? +pinnable.location.id === +originLocation.id : false"
												(onSelectEvent)="locationSelected($event)"></app-pinnable>
										</ng-container>
									</ng-container>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</app-location-table-v2>
			</ng-container>
		</div>
	</div>

	<app-pass-limit-info *ngIf="!isStaff || (isStaff && formState.kioskMode)" [passLimitInfo]="formState?.passLimitInfo"></app-pass-limit-info>
</div>
