import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { combineLatest, concat, defer, Observable, of } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { pickTruthy } from 'Util';
import { HttpService } from './http-service';
import { UserService } from './user.service';

// If we need to roll out changes and "forget" previously stored routes, we can increment this number.
const KEY_VERSION = 0;

@Injectable({
	providedIn: 'root',
})
export class RouteMemoService {
	constructor(private httpService: HttpService, private userService: UserService, private router: Router, private storage: StorageMap) {}

	selectedRoute$ = concat(
		// A simple `startWith` doesn't work here; it runs before redirects are applied.
		defer(() => of(this.router.url)),
		this.router.events.pipe(
			filter((event): event is NavigationEnd => event instanceof NavigationEnd),
			map((event) => event.urlAfterRedirects)
		)
	).pipe(
		distinctUntilChanged(),
		map((url) => url.split('?')[0]),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	private keyPrefix = combineLatest([this.httpService.currentSchool$.pipe(pickTruthy()), this.userService.user$]).pipe(
		map(([school, user]) => `${KEY_VERSION}-${school.id}-${user.id}:`)
	);

	/**
	 * Given a root path, such as '/' or '/admin', keep track of the latest route
	 * @param rootPath Only store routes that are prefixed with this path
	 * @param exclude Don't store any routes that include this string
	 */
	memo(rootPath: string, exclude?: string): Observable<void> {
		return combineLatest([this.selectedRoute$, this.keyPrefix]).pipe(
			filter(
				([url]) =>
					url !== rootPath && // Root path is reserved for redirects. Don't get stuck by sending people there.
					url.startsWith(rootPath) &&
					(!exclude || !url.includes(exclude))
			),
			switchMap(([url, prefix]) => this.storage.set(`${prefix}${rootPath}`, url))
		);
	}

	// Pull from storage the last route that we navigated to within the given root path.
	recall(rootPath: string): Observable<string | undefined> {
		return this.keyPrefix.pipe(
			first(),
			switchMap((prefix) => this.storage.get(`${prefix}${rootPath}`, { type: 'string' }))
		);
	}

	clear(): Observable<void> {
		return this.keyPrefix.pipe(
			first(),
			switchMap((prefix) =>
				this.storage.keys().pipe(
					filter((key) => key.startsWith(prefix)),
					concatMap((key) => this.storage.delete(key))
				)
			)
		);
	}
}
