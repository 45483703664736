import { EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { FlexPeriod } from '../../services/flex-period.service';
import { AppState } from '../app-state/app-state';

// export type FlexPeriodState = EntityState<FlexPeriod>;
export interface FlexPeriodState extends EntityState<FlexPeriod> {
	loading: boolean;
	loaded: boolean;
}

export const getFlexPeriodState = (state: AppState) => state.flexPeriods;

export const getIsLoadingFlexPeriods = createSelector(getFlexPeriodState, (state: FlexPeriodState) => state.loading);
export const getIsLoadedFlexPeriods = createSelector(getFlexPeriodState, (state: FlexPeriodState) => state.loaded);
