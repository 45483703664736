import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { BackpackIconsModule } from 'app/backpack-icons/backpack-icons.module';
import { ResolveAssetPipe } from 'app/resolve-asset.pipe';
import { MaterialModule } from 'app/shared/material/material.module';
import { ButtonComponent } from './button/button.component';
import { DialogBodyComponent } from './dialog-modal/dialog-body.component';
import { DialogContentComponent } from './dialog-modal/dialog-content.component';
import { DialogFooterComponent } from './dialog-modal/dialog-footer.component';
import { DialogHeaderComponent } from './dialog-modal/dialog-header.component';
import { DialogModalTestComponent } from './dialog-modal/dialog-modal-test.component';
import { DialogService } from './dialog-modal/dialog.service';
import { PressableTileComponent } from './pressable-tile/pressable-tile.component';
import { BetaPillComponent } from './sidenav/beta-pill.component';
import { SidenavFloatingMenuComponent } from './sidenav/sidenav-floating-menu.component';
import { SidenavMenuComponent } from './sidenav/sidenav-menu.component';
import { SidenavTabComponent } from './sidenav/sidenav-tab.component';
import { TagComponent } from './tag/tag.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
	imports: [CommonModule, MaterialModule, RouterModule, BackpackIconsModule, MatDialogModule],
	declarations: [
		// Pipes
		ResolveAssetPipe,

		// Components
		ButtonComponent,
		BetaPillComponent,
		SidenavFloatingMenuComponent,
		SidenavMenuComponent,
		SidenavTabComponent,
		TagComponent,
		TooltipComponent,
		PressableTileComponent,
		DialogModalTestComponent,
		DialogContentComponent,
		DialogHeaderComponent,
		DialogBodyComponent,
		DialogFooterComponent,
	],
	exports: [
		// Pipes
		ResolveAssetPipe,

		// Components
		ButtonComponent,
		BetaPillComponent,
		SidenavFloatingMenuComponent,
		SidenavMenuComponent,
		SidenavTabComponent,
		TagComponent,
		TooltipComponent,
		BackpackIconsModule,
		PressableTileComponent,
		DialogModalTestComponent,
		DialogContentComponent,
		DialogHeaderComponent,
		DialogBodyComponent,
		DialogFooterComponent,
	],
	providers: [DialogService],
})
export class BackpackModule {}
