import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SchoolActivity } from './models/SchoolActivity';
import { TeacherActivityComponent } from './teacher-activity/teacher-activity.component';

export interface IOpenActivityDialog {
	activity: SchoolActivity;
	day?: Date;
	updateCallback?: () => void;
}

@Injectable({ providedIn: 'root' })
export class DialogService {
	constructor(private dialog: MatDialog) {}

	openActivityDialog({ activity, day, updateCallback }: IOpenActivityDialog): void {
		const dialogRef = this.dialog.open(TeacherActivityComponent, {
			panelClass: 'main-form-dialog-container',
			width: '955px',
			maxHeight: '577px',
			data: { activity: activity, day: day },
		});

		dialogRef.afterClosed().subscribe(() => {
			if (updateCallback) {
				updateCallback();
			}
		});

		dialogRef.componentInstance.closeDialog = () => {
			if (updateCallback) {
				updateCallback();
			}
			dialogRef.close();
		};
	}
}
