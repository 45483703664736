<div class="action-sheet-dialog">
	<div class="options">
		<div
			*ngFor="let option of data.options"
			class="tw-flex tw-justify-between option"
			[ngClass]="{ selected: isSelected(option), disabled: option.disabled }"
			(click)="!option.disabled ? selectOption(option) : null">
			<div class="tw-flex tw-gap-2">
				<div *ngIf="option.icon" class="tw-flex tw-items-center">
					<img
						src="{{ isSelected(option) ? option.iconSelected || option.icon : option.icon }}"
						alt="Option Icon"
						style="width: 16px; height: 16px" />
				</div>
				<div>{{ option.title }}</div>
			</div>
			<img
				*ngIf="isSelected(option)"
				[src]="'./assets/Check (Navy).svg' | resolveAsset"
				alt="Option Selected Icon"
				style="width: 16px; height: 16px" />
		</div>
	</div>
</div>
