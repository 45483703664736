import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { HallPassLimit, StudentPassLimit } from '../models/HallPassLimits';
import { User } from '../models/User';
import { Router } from '@angular/router';
import { PassLimitService } from '../services/pass-limit.service';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PassLimitInputComponent } from '../pass-limit-input/pass-limit-input.component';
import { concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { AdminPassLimitDialogComponent } from '../admin-pass-limits-dialog/admin-pass-limits-dialog.component';
import { CreateFormService } from '../create-hallpass-forms/create-form.service';
import { PassLimitFeedbackComponent } from '../pass-limit-feedback/pass-limit-feedback.component';
import { NextStep } from '../animations';
import { DynamicDialogData } from 'app/dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DialogFactoryService } from 'app/dialog-factory.service';
import { DynamicDialogService } from 'app/dynamic-dialog.service';
import { Term } from 'app/models/Schedule';
import { ScheduleService } from 'app/services/schedule.service';

const individualPassLimitRangeValidator =
	(): ValidatorFn =>
	(form: UntypedFormGroup): ValidationErrors => {
		if (form.value['passLimit'] === 'Unlimited') {
			return null;
		}
		const num = parseInt(form.value['passLimit'], 10);
		if (isNaN(num)) {
			return { format: true };
		}
		if (num < -2 || num > 50) {
			return { range: true };
		}
		return null;
	};

@Component({
	selector: 'app-pass-limit-student-info',
	templateUrl: './pass-limit-student-info.component.html',
	styleUrls: ['./pass-limit-student-info.component.scss'],
	animations: [NextStep],
})
export class PassLimitStudentInfoComponent implements OnInit {
	@Input() set inputData(data: { studentPassLimit: StudentPassLimit; user: User }) {
		if (!data) {
			return;
		}
		this.data = data;
		if (this.data.studentPassLimit.passLimit === -2) {
			this.passLimitForm.patchValue({
				passLimit: 'Unlimited',
			});
		}
		this.setBordersAndButtons();
	}
	// TODO: come up with a better way of sharing this component
	@Input() isDialog = true; // this is not usually a good approach, literally anything is better than this

	// button and border controls
	// gives either the school or individual limits their border based on incoming data
	isAdmin: boolean;
	individualEditButton: boolean;
	schoolEditButton: boolean;
	individualBorder: boolean;
	schoolLimitBorder: boolean;

	// request spinner controls
	requestLoading = false;
	deleteLoading = false;

	// individual form controls
	passLimitForm = new UntypedFormGroup(
		{
			passLimit: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^([1-9]\d*)$|^(0){1}$|^(Unlimited)$/)]),
			description: new UntypedFormControl(null),
		},
		individualPassLimitRangeValidator()
	);
	passLimitFormLastValue = { passLimit: null, description: '' };
	passLimitFormChanged: Observable<boolean>;
	schoolPassLimit: HallPassLimit;

	// framer motion controls
	page = 1;
	frameMotion$ = this.framerMotionService.getFrameMotionDirection();

	private dialogService: DynamicDialogService;

	@ViewChild('passLimitInput') passLimitInput: PassLimitInputComponent;
	@ViewChild('deleteDialogBody') deleteDialogBody: TemplateRef<HTMLElement>;
	@ViewChild(PassLimitFeedbackComponent) set feedbackPosition(comp: PassLimitFeedbackComponent) {
		const dialog = document.querySelector<HTMLElement>('mat-dialog-container');
		const feedback = document.querySelector<HTMLElement>('app-pass-limit-feedback');
		if (dialog && feedback) {
			const coords = dialog.getBoundingClientRect();
			feedback.style.top = `${(this.isDialog ? coords.bottom : coords.height) + 30}px`;
		}
	}

	@Output() backEmit = new EventEmitter();

	constructor(
		private router: Router,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: { studentPassLimit: StudentPassLimit; user: User },
		private dialogRef: MatDialogRef<PassLimitStudentInfoComponent>,
		private passLimitsService: PassLimitService,
		private cdr: ChangeDetectorRef,
		private framerMotionService: CreateFormService,
		private dialogFactoryService: DialogFactoryService,
		public scheduleService: ScheduleService
	) {}

	ngOnInit(): void {
		this.isAdmin = this.data.user.roles.includes('manage_school');
		this.setBordersAndButtons();
		this.passLimitsService.getPassLimit().subscribe({
			next: (pl) => {
				this.schoolPassLimit = pl.pass_limit;
			},
		});
		this.passLimitFormChanged = this.passLimitForm.statusChanges.pipe(
			switchMap(() => this.passLimitForm.valueChanges),
			map((v) => {
				if (!v.passLimit) {
					v.passLimit = null;
				}
				return JSON.stringify(v) !== JSON.stringify(this.passLimitFormLastValue);
			})
		);
	}

	private setBordersAndButtons() {
		if (this.data.studentPassLimit) {
			this.schoolEditButton = this.data.studentPassLimit.schoolPassLimitEnabled;
			this.individualEditButton = this.data.studentPassLimit.isIndividual;
			this.schoolLimitBorder = this.data.studentPassLimit.schoolPassLimitEnabled;
			this.individualBorder = this.data.studentPassLimit.isIndividual && !this.data.studentPassLimit.noLimitsSet;
		}
	}

	navigateToAdminPage() {
		this.dialogRef.close();
		this.dialog.open(AdminPassLimitDialogComponent, {
			hasBackdrop: true,
			panelClass: 'overlay-dialog',
			backdropClass: 'custom-bd',
			width: '425px',
			height: '500px',
			disableClose: true,
		});
	}

	loadForm() {
		this.framerMotionService.setFrameMotionDirection();
		setTimeout(() => {
			if (this.data.studentPassLimit.isIndividual) {
				let passLimit: number | string = this.data?.studentPassLimit?.passLimit;
				if (passLimit === -2) {
					passLimit = 'Unlimited';
				}
				const value = {
					passLimit,
					description: this.data?.studentPassLimit?.description || null,
				};

				this.passLimitFormLastValue = value;
				this.passLimitForm.patchValue(value);
			}
			this.page = 2;
		}, 100);
	}

	backToHomePage() {
		this.framerMotionService.setFrameMotionDirection('back');
		setTimeout(() => {
			this.passLimitFormLastValue = {
				passLimit: null,
				description: null,
			};
			this.passLimitForm.reset();
			this.page = 1;
		}, 100);
	}

	updatePassLimits() {
		const parsedForm = {
			passLimit: this.passLimitForm.value.passLimit === 'Unlimited' ? -2 : parseInt(this.passLimitForm.value.passLimit, 10),
			description: (this.passLimitForm?.value?.description || '').trim(),
		};

		this.requestLoading = true;
		this.passLimitsService
			.updateIndividualLimit({
				...parsedForm,
				students: [this.data.studentPassLimit.student.id],
			})
			.pipe(concatMap(() => this.passLimitsService.getStudentPassLimit(this.data.studentPassLimit.student.id)))
			.subscribe({
				next: (res) => {
					this.data.studentPassLimit = res;
					this.requestLoading = false;
					this.setBordersAndButtons();
					this.cdr.detectChanges();
					this.backToHomePage();
				},
				error: (err) => {
					this.requestLoading = false;
				},
			});
	}

	resetPassLimitsForm() {
		this.passLimitForm.patchValue(this.passLimitFormLastValue);
		if (this.passLimitInput?.passLimitDropdownRef?.getState() === MatDialogState.OPEN) {
			this.passLimitInput.passLimitDropdownRef.close();
		}
	}

	openDeleteDialog() {
		const data: DynamicDialogData = {
			headerText: 'Remove the individual limit?',
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: 'Remove limit',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.deleteDialogBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonGradientBackground: '#E32C66',
			primaryButtonTextColor: 'white',
			classes: 'tw-min-h-0',
			templateData: {},
		};

		this.dialogService = this.dialogFactoryService.open(data, { panelClass: 'dynamic-dialog-modal-min', disableClose: false });
		this.dialogService.closed$
			.pipe(
				filter((action) => action === 'primary'),
				tap(() => (this.deleteLoading = true)),
				concatMap(() => this.passLimitsService.removeIndividualLimit(this.data.studentPassLimit.student.id)),
				concatMap(() => this.passLimitsService.getStudentPassLimit(this.data.studentPassLimit.student.id))
			)
			.subscribe({
				next: (limit) => {
					this.data.studentPassLimit = limit;
					this.setBordersAndButtons();
					this.deleteLoading = false;
					this.cdr.detectChanges();
					this.backToHomePage();
				},
				error: console.error,
			});
	}

	close() {
		if (this.isDialog) {
			this.dialogRef.close();
		} else {
			this.backEmit.emit();
		}
	}
}
