import { Holiday } from 'app/models/Schedule';
import * as moment from 'moment';
import { Observable, of, pipe, UnaryFunction } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { TimeService } from './app/services/time.service';

// TODO: create a util directory, and break up the functions here by category.

enum Month {
	Jan = 0,
	Feb = 1,
	Mar = 2,
	Apr = 3,
	May = 4,
	Jun = 5,
	Jul = 6,
	Aug = 7,
	Sep = 8,
	Oct = 9,
	Nov = 10,
	Dec = 11,
}

export class Util {
	static weekday: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	static month: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

	static invalidDate(date: moment.MomentInput) {
		return moment().isSameOrAfter(date);
	}

	static isDateBetweenStr(currentTime: Date, startDate: string, endDate: string): boolean {
		return moment(currentTime).isBetween(startDate, endDate);
	}

	static isTimeBetweenStr(currentTime: string, startTime: string, endTime: string): boolean {
		const time = moment(currentTime, 'HH:mm');
		const start = moment(startTime, 'HH:mm');
		const end = moment(endTime, 'HH:mm');
		return time.isBetween(start, end);
	}

	static splitPeriodTime(time: string): { hour: number; minute: number } {
		const split = time.split(':');
		return {
			hour: parseInt(split[0], 10),
			minute: parseInt(split[1], 10),
		};
	}

	// returns a moment with the specified hour and minutes
	// specify hour and minutes as a string like HH:mm
	// this extra parsing was necessary to avoid moment.js deprecation issues
	static setTimeForCurrentDate(time: string): moment.Moment {
		const now = moment();
		const split = Util.splitPeriodTime(time);
		now.set('hour', split.hour);
		now.set('minute', split.minute);
		now.set('second', 0);
		now.set('millisecond', 0);
		return now;
	}

	static isDateBetween(startDate: Date, targetDate: Date, endDate: Date): boolean {
		return startDate <= targetDate && targetDate <= endDate;
	}

	static isTimeBetween(currentDate: Date, startTime: string, endTime: string): boolean {
		const time = moment(currentDate);
		const start = Util.setTimeForCurrentDate(startTime);
		const end = Util.setTimeForCurrentDate(endTime);
		return time.isBetween(start, end, 'second');
	}

	static isTimeBefore(currentDate: Date, startTime: string): boolean {
		const time = moment(currentDate);
		const start = Util.setTimeForCurrentDate(startTime);
		return time.isBefore(start, 'second');
	}

	static isTimeAfter(currentDate: Date, endTime: string): boolean {
		const time = moment(currentDate);
		const end = Util.setTimeForCurrentDate(endTime);
		return time.isAfter(end, 'second');
	}

	// time parameter should be "10:00" format for 10am
	static getTimeUntil(time: string): moment.Duration {
		const currentTime = moment();
		const timeAsMoment = moment(time, 'HH:mm');
		const timeDiff = timeAsMoment.diff(currentTime);
		return moment.duration(timeDiff);
	}

	static formatDateTime(s: Date, timeOnly?: boolean, utc?: boolean) {
		const formattedTime = moment(s).format('h:mm A');
		if (timeOnly) return formattedTime;

		let formattedDate = '';
		const now: Date = TimeService.getNowDate();

		if (s.getFullYear() === now.getFullYear()) {
			if (s.getMonth() === now.getMonth()) {
				if (s.getDate() === now.getDate()) {
					formattedDate = 'Today';
				} else if (s.getDate() === now.getDate() + 1) {
					formattedDate = 'Tomorrow';
				} else if (s.getDate() === now.getDate() - 1) {
					formattedDate = 'Yesterday';
				} else {
					if (s.getDate() > now.getDate() + 6 || s.getDate() < now.getDate() - 1) {
						formattedDate = this.month[s.getMonth()] + ' ' + s.getDate();
					} else {
						formattedDate = this.weekday[s.getDay()];
					}
				}
			} else {
				formattedDate = this.month[s.getMonth()] + ' ' + s.getDate();
			}
		} else {
			return this.month[s.getMonth()] + ' ' + s.getDate() + ', ' + s.getFullYear();
		}

		if (formattedDate === 'Wednesday') {
			formattedDate = 'Wed.';
		}

		return formattedDate + ', ' + formattedTime;
	}

	static formatDateTimeForDateRange(sFromDate: Date, sToDate: Date) {
		const formattedTime_sFromDate: string =
			(sFromDate.getHours() > 12 ? sFromDate.getHours() - 12 : sFromDate.getHours()) +
			':' +
			(sFromDate.getMinutes() < 10 ? '0' : '') +
			sFromDate.getMinutes() +
			(sFromDate.getHours() > 12 ? ' PM' : ' AM');
		const formattedTime_sToDate: string =
			(sToDate.getHours() > 12 ? sToDate.getHours() - 12 : sToDate.getHours()) +
			':' +
			(sToDate.getMinutes() < 10 ? '0' : '') +
			sToDate.getMinutes() +
			(sToDate.getHours() > 12 ? ' PM' : ' AM');
		return (
			sFromDate.getMonth() +
			1 +
			'/' +
			sFromDate.getDate() +
			',' +
			formattedTime_sFromDate +
			' to ' +
			(sToDate.getMonth() + 1) +
			'/' +
			sToDate.getDate() +
			',' +
			formattedTime_sToDate
		);
	}

	// the guide for these formats comes from the notion doc here:
	// https://www.notion.so/smartpass/Pass-Design-Details-afd5becadf7945b5a62efafe7c042104
	static formatRelativeTime(date: Date, separator = ',', uppercase = true): string {
		const dateAsMoment = moment(date);
		const today = moment();
		const yesterday = moment().subtract(1, 'day');
		const tomorrow = moment().add(1, 'day');
		const week = moment().add(7, 'day');
		// for missed passes yesterday
		if (dateAsMoment.isSame(yesterday, 'day')) {
			return `${uppercase ? 'Y' : 'y'}esterday${separator} ${dateAsMoment.format('h:mm a')}`;
		}
		// for missed passes today
		// for scheduled passes today
		if (dateAsMoment.isSame(today, 'day')) {
			return `${uppercase ? 'T' : 't'}oday${separator} ${dateAsMoment.format('h:mm a')}`;
		}
		// for scheduled passes tomorrow
		if (dateAsMoment.isSame(tomorrow, 'day')) {
			return `${uppercase ? 'T' : 't'}omorrow${separator} ${dateAsMoment.format('h:mm a')}`;
		}
		// pass is upcoming after tomorrow
		if (dateAsMoment.isAfter(tomorrow)) {
			return `${dateAsMoment.format('MMM D, h:mm a')}`;
		}
	}

	// Return a short formatted string for a set of individual dates
	// that may span on ore more months.
	//
	// For example:
	// November 4, 5; December 10, 12
	static formatDateSet(dates: Date[]): string {
		// Group dates by month
		const datesByMonth: Record<number, number[]> = {};
		dates.forEach((date) => {
			const month = date.getMonth();
			const day = date.getDate();
			if (!datesByMonth[month]) {
				datesByMonth[month] = [];
			}
			datesByMonth[month].push(day);
		});

		// Format each month's dates
		const formattedDates: string[] = [];
		for (const month in datesByMonth) {
			const monthName = Month[month]; // Get the month name from the enum
			const dayList = datesByMonth[month].join('; ');
			formattedDates.push(`${monthName} ${dayList}`);
		}

		// Combine formatted dates with semicolon separator
		const result = formattedDates.join('; ');

		return result;
	}

	static getWeekDaysForDate(date: moment.Moment): moment.Moment[] {
		const weekDates: moment.Moment[] = [];
		const inputDate = moment.utc(date);
		const monday = inputDate.clone().locale('en').startOf('isoWeek');
		for (let i = 0; i < 5; i++) {
			weekDates.push(monday.clone().add(i, 'days'));
		}
		return weekDates;
	}

	static getNextWeekDayForDate(date: moment.Moment): moment.Moment {
		let nextWeekDay = date.clone().add(1, 'days');
		while (nextWeekDay.day() === 0 || nextWeekDay.day() === 6) {
			nextWeekDay = nextWeekDay.add(1, 'days');
		}
		return nextWeekDay;
	}

	static numberWithCommas(x: number) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	static getOverlapDates(
		startDate: string,
		endDate: string,
		arrayToCheckOverlap: {
			start_date: string;
			end_date: string;
		}[]
	): string[] {
		const overlapDates = [];

		// Check for overlaps
		for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
			if (
				arrayToCheckOverlap?.some((object) => {
					// Create a new date object for the day after the object's end date
					const dayAfterEndDate = new Date(object.end_date);
					dayAfterEndDate.setDate(dayAfterEndDate.getDate() + 1);

					// Check if d is before this new threshold
					return d >= new Date(object.start_date) && d < dayAfterEndDate;
				})
			) {
				overlapDates.push(new Date(d).toISOString().split('T')[0] + 'T00:00:00Z');
			}
		}
		return overlapDates;
	}

	static exportHolidayDates(holidaysList: Holiday[]): { start_date: string; end_date: string }[] {
		// Convert holidays to Date objects and sort them
		const holidaysDateObjects = holidaysList
			.map((holiday) => ({
				start_date: holiday.start_date,
				end_date: holiday.end_date,
			}))
			.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

		return holidaysDateObjects;
	}

	static convertHex(hex: string, opacity: number) {
		hex = hex.replace('#', '');
		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);

		return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
	}

	static findNextAvailableWeekdayAfterHoliday(
		startDate: string,
		arrayToCheckOverlap: {
			start_date: string;
			end_date: string;
		}[]
	): string {
		let nextDay = this.addDayButSkipWeekend(moment.utc(startDate));
		let overlapFound = true;

		while (overlapFound) {
			overlapFound = false;
			for (const holiday of arrayToCheckOverlap) {
				const holidayStart = moment.utc(holiday.start_date);
				const holidayEnd = moment.utc(holiday.end_date);
				if (nextDay.isSameOrAfter(holidayStart) && nextDay.isSameOrBefore(holidayEnd)) {
					nextDay = this.addDayButSkipWeekend(holidayEnd.clone());
					overlapFound = true;
					break;
				}
			}
		}

		return nextDay.toISOString().split('T')[0] + 'T00:00:00Z';
	}

	static addDayButSkipWeekend(inputDate: moment.Moment): moment.Moment {
		inputDate.add(1, 'day');
		if (inputDate.day() === 0) {
			inputDate.add(1, 'day');
		}
		if (inputDate.day() === 6) {
			inputDate.add(2, 'day');
		}
		return inputDate;
	}

	static getPreviousNonHolidayWeekday(inputDate: moment.Moment, holidaysList: Holiday[]): moment.Moment {
		const dayBefore = inputDate.clone().subtract(1, 'days');
		while (dayBefore.day() === 0 || dayBefore.day() === 6 || this.isHoliday(dayBefore, holidaysList)) {
			dayBefore.subtract(1, 'days');
		}
		return dayBefore;
	}

	static isHoliday(inputDate: moment.Moment, holidaysList: Holiday[]): boolean {
		return holidaysList.some((holiday) => {
			const holidayStartDate = moment.utc(holiday.start_date);
			const holidayEndDate = moment.utc(holiday.end_date);
			return inputDate.utc().isBetween(holidayStartDate, holidayEndDate, 'day', '[]');
		});
	}

	static getUTCDateForLocalDate(inputDate: string): Date {
		const mDate = moment.utc(inputDate);
		const returnDate = new Date();
		returnDate.setHours(mDate.hours());
		returnDate.setMinutes(mDate.minutes());
		returnDate.setFullYear(mDate.year());
		returnDate.setMonth(mDate.month());
		returnDate.setDate(mDate.date());
		return returnDate;
	}

	static ensureDateIsNotWeekend(inputDate: moment.Moment): moment.Moment {
		let mDate = moment.utc(inputDate);
		if (mDate.weekday() === 6) {
			mDate = mDate.add(2, 'days');
		}
		if (mDate.weekday() === 0) {
			mDate = mDate.add(1, 'days');
		}
		return mDate;
	}

	static isWeekendDate(inputDate: Date): boolean {
		const date = moment(inputDate);
		return date.weekday() === 6 || date.weekday() === 0;
	}
}

export function isTruthy<T>(val: T): val is Exclude<T, null | undefined | false | typeof NaN | 0> {
	return !!val;
}

export function pickTruthy() {
	return pipe(filter(isTruthy));
}

export type EitherValueOrError<T, E = unknown> = { error: null; value: T } | { error: E; value: null };

/**
 * Upgrades the stream into a wrapper object `{ error: null; value: T } | { error: E; value: null }`,
 * and recovers all errors.
 */
export function recoverError<T, E = unknown>(): UnaryFunction<Observable<T>, Observable<EitherValueOrError<T, E>>> {
	return pipe(
		map((value) => ({ error: null, value })),
		catchError((error) => {
			return of({ error, value: null });
		})
	);
}

/**
 * Returns whether the given object can be indexed by a string.
 */
export function isStringIndexable(obj: unknown): obj is { [key: string]: unknown } {
	return typeof obj === 'object' && obj !== null;
}

/**
 * Returns whether the given `obj` has all keys in `keys`.
 */
export function hasKeys<T extends object>(obj: unknown, keys: (keyof T)[]): obj is T {
	return isStringIndexable(obj) && keys.every((key) => key in obj);
}
