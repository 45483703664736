import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Location } from '../../../../models/Location';
import { CreateFormService } from '../../../create-form.service';
import { Navigation } from '../../main-hall-pass-form.component';
import { States } from '../locations-group-container.component';

@Component({
	selector: 'app-student-footer',
	templateUrl: './student-footer.component.html',
	styleUrls: ['./student-footer.component.scss'],
})
export class StudentFooterComponent implements OnInit, OnDestroy {
	@Input() formState: Navigation;

	@Input() date;

	@Input() state;

	@Output() changeAnimationDirectionEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() changeLocation: EventEmitter<Navigation> = new EventEmitter<Navigation>();
	@Output() locsViewEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	fromLocation: Location;
	toLocation: Location;
	forInput: boolean;
	frameMotion$: BehaviorSubject<any>;

	destroy$: Subject<any> = new Subject<any>();

	constructor(private formService: CreateFormService) {}

	private _fromLocationText$: BehaviorSubject<string>;
	fromLocationText$: Observable<string>;
	fromLocationText() {
		return this.fromLocation ? this.fromLocation.title : 'Origin';
	}

	get toLocationText() {
		return this.toLocation && this.state !== 'to' && this.state !== 'category' ? this.toLocation.title : 'Destination';
	}

	get fromCursor() {
		return this.state !== 'from' && this.forInput;
	}

	get toCursor() {
		return this.state !== 'to' && this.state !== 'from' && this.forInput;
	}

	ngOnInit() {
		this.frameMotion$ = this.formService.getFrameMotionDirection();

		if (this.formState) {
			this.forInput = this.formState.forInput;
			this.fromLocation = this.formState.data.direction.from;
			this.toLocation = this.formState.data.direction.to;
		}

		this._fromLocationText$ = new BehaviorSubject(this.fromLocationText());
		this.fromLocationText$ = this._fromLocationText$.asObservable().pipe(takeUntil(this.destroy$));
		this.formService
			.getUpdatedChoice()
			.pipe(takeUntil(this.destroy$))
			.subscribe((loc) => {
				if (!this.formState.data.direction.to) {
					this.formState.data.direction.from = loc;
					this.fromLocation = loc;
					this._fromLocationText$.next(this.fromLocationText());
				}
			});
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	goToFromWhere() {
		this.changeAnimationDirection();
		setTimeout(() => {
			if (this.state === 'from' || !this.forInput) {
				return false;
			}
			this.formState.previousState = this.formState.state;
			this.formState.fromState = this.formState.state;
			this.formState.state = States.FromToWhere;
			this.changeLocation.emit(this.formState);
		}, 100);
	}

	goToToWhere() {
		this.changeAnimationDirection();
		setTimeout(() => {
			if (this.state === 'to' || this.state === 'from' || !this.forInput) {
				return false;
			}
			this.formState.previousState = this.formState.state;
			this.formState.state = States.FromToWhere;
			this.changeLocation.emit(this.formState);
		}, 100);
	}

	goToDate() {
		this.changeAnimationDirection();
		setTimeout(() => {
			this.formState.previousState = this.formState.state;
			this.formState.step = 1;
			this.formState.state = States.FromToWhere;
			this.formState.previousStep = 3;
			this.formState.quickNavigator = true;
			this.changeLocation.emit(this.formState);
		}, 100);
	}

	private changeAnimationDirection() {
		this.formService.scalableBoxController.next(false);
		this.formService.setFrameMotionDirection('back');
		this.changeAnimationDirectionEvent.emit(true);
	}
}
