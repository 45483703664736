import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { School } from '../models/School';
import { HttpService } from './http-service';

/**
 * Add any future feature flags to this enum
 */
export enum FLAGS {
	AbbreviateLastName = 'feature_flag_new_abbreviation',
	ActivityManagement = 'activity_management',
	ActivityManagementV2 = 'activity_management_ea2',
	ActivityManagementOverview = 'activity_management_overview',
	ActivityStudentSnapshot = 'activity_student_snapshot',
	ActivityStudentSignupWindow = 'activity_student_signup_window',
	ActivitiesWithoutPasses = 'activity_without_pass',
	AdminSideBarNux = 'admin_side_bar_nux',
	Attendance = 'attendance',
	ClassesFiltersBar = 'class_filters_bar',
	ClosestRoom2 = 'closest_room',
	CustomActivitySchedule = 'custom_activity_schedule',
	DevPlayground = 'dev_playground',
	DigitalId = 'feature_flag_digital_id',
	DirectIntegration = 'direct_integration',
	EmergencyMode = 'emergency_mode',
	EncounterDetection = 'feature_flag_encounter_detection',
	EncounterPreventionOverride = 'encounter_prevention_override',
	FeatureFlagPanel = 'feature_flag_panel',
	FlexPeriods = 'flex_periods',
	GetStarted = 'getting_started',
	LocationFormsRefactor = 'location_forms_refactor',
	NotificationSounds = 'feature_flag_alert_sounds',
	OriginWaitInLine = 'origin_wait_in_line',
	ParentAccounts = 'feature_flag_parent_accounts',
	PhoneAccess = 'feature_flag_phone',
	ReferralProgramme = 'feature_flag_referral_program',
	RenewalChecklist = 'feature_flag_renewal_checklist',
	Schedules = 'schedules',
	ShowStreaks = 'feature_flag_streaks',
	ShowWaitInLine = 'feature_flag_show_wait_in_line',
	URLNavigation = 'url_navigation',
	UseNewWebsocket = 'use_new_websocket',
	WaitInLine = 'feature_flag_wait_in_line',
	WaitInLineUIRefresh = 'wil_ui_refresh',
}

// TODO: Replace individual feature flag functions with this service
@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private school: School | null = null;
	private flagsV2$: Observable<string[]>;

	constructor(private http: HttpService) {
		this.flagsV2$ = this.http.currentSchool$.pipe(
			shareReplay({ bufferSize: 1, refCount: false }),
			filter((s) => !!s),
			map((s) => s.feature_flags_v2)
		);

		this.http.currentSchool$.subscribe({
			next: (s) => (this.school = s),
		});
	}

	isFeatureEnabled(featureFlag: FLAGS): boolean {
		return !!this.school?.[featureFlag];
	}

	isFeatureEnabledV2(featureFlag: FLAGS): boolean {
		if (!this.school?.feature_flags_v2) {
			return false;
		}

		return this.school.feature_flags_v2.includes(featureFlag);
	}

	isFeatureEnabledV2$(flag: FLAGS): Observable<boolean> {
		return this.flagsV2$.pipe(map((flags) => flags.includes(flag)));
	}

	attendancePlan(): 'no_access' | 'yes' {
		if (this.school?.attendance_access == 'yes') {
			return 'yes';
		}

		return 'no_access';
	}

	listFeatureFlags(): Observable<ListFeatureFlagsResp> {
		return this.http.post<ListFeatureFlagsResp>('v2/school/feature_flags/list', {}, undefined, false);
	}

	updateFeatureFlags(req: UpdateFeatureFlagsReq): Observable<ListFeatureFlagsResp> {
		return this.http.post<ListFeatureFlagsResp>('v2/school/feature_flags/update', req, undefined, false);
	}
}

export interface Flag {
	internal_name: string;
	title: string;
	description: string;
	value: FlagValue;
}

export interface FlagValue {
	enabled: boolean;
}

export interface ListFeatureFlagsResp {
	flags: Flag[];
}

export interface UpdateFlag {
	internal_name: string;
	value: FlagValue;
}

export interface UpdateFeatureFlagsReq {
	flags: UpdateFlag[];
}
