import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ImportStudentListComponent } from '../../../admin/overlay-container/visibility-room/import-student-list/import-student-list.component';
import { CreateFormService } from '../../../create-hallpass-forms/create-form.service';
import { ROLES, SchoolActivityInstance, User } from '../../../models';
import { StudentList } from '../../../models/StudentList';
import { FeatureFlagService, FLAGS } from '../../../services/feature-flag.service';
import { HallPassesService } from '../../../services/hall-passes.service';
import { HomepageService } from '../../../services/homepage.service';
import { KioskModeService } from '../../../services/kiosk-mode.service';
import { LocationsService } from '../../../services/locations.service';
import { SchedulePassActivityService } from '../../../services/schedule-pass-activity.service';
import { ScheduleService } from '../../../services/schedule.service';
import { SchoolActivityAttendee, SchoolActivityService } from '../../../services/school-activity.service';
import { ScreenService } from '../../../services/screen.service';
import { UserService } from '../../../services/user.service';
import { ConfirmationComponent } from '../../../shared/shared-components/confirmation/confirmation.component';
import { GroupsAndStudentSelectComponent } from '../../../shared/shared-components/groups-and-student-select/groups-and-student-select.component';

type StudentSearchResult = {
	user: User;
	overwriteable: boolean;
	assignerId?: number;
	activityName?: string;
	message?: string;
	alreadyIn?: boolean;
	groupId?: number;
};

@Component({
	selector: 'sp-student-instance-search',
	templateUrl: './student-instance-search.component.html',
	styleUrls: [
		'../../../shared/shared-components/groups-and-student-select/groups-and-student-select.component.scss',
		'../../../create-hallpass-forms/main-hallpass--form/student-groups/groups-container-v2/groups-container-v2.component.scss',
		'./student-instance-search.component.scss',
	],
})
export class StudentInstanceSearchComponent extends GroupsAndStudentSelectComponent implements OnInit, AfterViewInit {
	@ViewChild('searchInput', { static: false }) searchInput!: ElementRef;
	@Input() instance!: SchoolActivityInstance;
	@Input() maxAttendance!: number;
	@Output() isOverLimit: EventEmitter<boolean> = new EventEmitter<boolean>();

	private initialStudentCount = 0;
	private newStudentCount = 0;
	spotsRemaining = 0;

	searchText = '';
	allGroups: StudentList[] = [];
	searchResults: StudentSearchResult[] = [];
	selectedStudents: User[] = [];
	overrideableStudents: StudentSearchResult[] = [];
	loading = true;
	isSearchFocused = false;
	overLimit = 0;
	addDisabled = true;
	overrideAllDisabled = false;
	searchInputChanges$ = new Subject<string>();
	usersLoaded = false;
	selectedGroupsAndStudents: (StudentList | User)[] = this.visibilityService.getInitialStudent();
	private staffNameMap: { [id: number]: string } = {};
	private attendeeMap: { [userId: number]: SchoolActivityAttendee } = {};
	private user!: User;
	private attendeeIdsToDelete: number[] = [];
	hasSchedulesFF = false;

	constructor(
		public userService: UserService,
		public sanitizer: DomSanitizer,
		public formService: CreateFormService,
		public screenService: ScreenService,
		private featureFlags: FeatureFlagService,
		public dialog: MatDialog,
		private visibilityService: SchedulePassActivityService,
		private activityService: SchoolActivityService,
		private renderer: Renderer2,
		private changeDetector: ChangeDetectorRef,
		public featureFlagService: FeatureFlagService,
		public scheduleService: ScheduleService,
		public pinnableService: HallPassesService,
		public homePageService: HomepageService,
		public locationsService: LocationsService,
		public kioskService: KioskModeService
	) {
		super(
			userService,
			sanitizer,
			formService,
			screenService,
			dialog,
			featureFlagService,
			scheduleService,
			pinnableService,
			homePageService,
			locationsService,
			kioskService
		);
	}

	ngOnInit(): void {
		this.initialStudentCount = this.instance.current_num_attendees || 0;
		this.hasSchedulesFF = this.featureFlags.isFeatureEnabledV2(FLAGS.Schedules);
		const from = new Date(this.instance.start_time);
		const to = new Date(this.instance.end_time);
		from.setMinutes(from.getMinutes() - 1);
		to.setMinutes(to.getMinutes() + 1);

		this.activityService
			.GetAttendeeRecordsForTimePeriod(from, to)
			.pipe(takeUntil(this.destroy$))
			.subscribe((attendees) => attendees.map((attendee) => (this.attendeeMap[attendee.user_id] = attendee)));

		forkJoin([this.userService.getUsersList('_profile_teacher'), this.userService.getUsersList('_profile_admin')])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([tList, aList]) => {
				const staffList: User[] = [...tList, ...aList];
				staffList.map((staff) => (this.staffNameMap[staff.id] = staff.display_name));
			});

		this.userService.effectiveUserNullable$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (effectiveUser) => {
				this.user = effectiveUser;
			},
		});

		this.userService
			.getStudentGroupsHTTP()
			.pipe(
				switchMap(() => this.userService.studentGroups$),
				tap((groups: StudentList[]) => {
					this.allGroups = groups;
					this.groups = groups;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.frameMotion$ = this.formService.getFrameMotionDirection();

		of(!this.groups || (this.groups && !this.groups.length))
			.pipe(takeUntil(this.destroy$))
			.subscribe((v) => {
				this.isEmptyGroups = v;
			});

		this.searchInputChanges$
			.pipe(
				tap(() => (this.usersLoaded = false)),
				distinctUntilChanged(),
				debounceTime(300),
				takeUntil(this.destroy$),
				tap((search) => {
					this.onSearch(search);
				})
			)
			.subscribe();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.loading = false;
			this.setFocusOnSearch();
			this.changeDetector.detectChanges();
		});
	}

	onSearch(searchText: string): void {
		if (searchText !== '') {
			this.groups = this.allGroups.filter((group) => {
				const titleMatches = group.title.toLowerCase().includes(searchText.toLowerCase());
				const alreadyAdded = this.selectedGroupsAndStudents.find((selectedGroup) => group.id === selectedGroup.id);
				return titleMatches && !alreadyAdded;
			});
			this.userService
				.searchProfile(ROLES.Student, 100, searchText)
				?.pipe(
					map((responses) => responses.results.map((r) => User.fromJSON(r))),
					map((students) => {
						return students.filter(
							(student) =>
								!this.selectedGroupsAndStudents.find((groupStudent) => groupStudent.id === student?.id) &&
								!this.overrideableStudents.find((result) => result.user.id === student?.id)
						);
					}),
					takeUntil(this.destroy$),
					finalize(() => (this.usersLoaded = true))
				)
				.subscribe((filteredStudents) => {
					if (filteredStudents?.length > 0) {
						this.searchResults = filteredStudents.map((student) => {
							const attendeeRecord = this.attendeeMap[student!.id];
							const result = this.buildMessage(student!, attendeeRecord);
							return {
								user: student!,
								overwriteable: result.overwriteable,
								assignerId: attendeeRecord?.assigner_id,
								activityName: attendeeRecord?.activity_name,
								message: result.message,
								alreadyIn: this.attendeeMap[student!.id]?.activity_instance_id === this.instance.id,
							};
						});
						this.activityService.hasSearchResults$.next(this.searchResults.length > 0);
					}
				});
		} else {
			this.groups = this.allGroups.filter((group) => !this.selectedGroupsAndStudents.find((selectedGroup) => selectedGroup.id === group.id));
			this.searchResults = [];
			this.activityService.hasSearchResults$.next(false);
		}
	}

	onSelect(item: StudentList | StudentSearchResult): void {
		// Adding Group
		if (typeof item === 'object' && 'users' in item) {
			this.selectedGroupsAndStudents.push(item);
			this.newStudentCount = this.newStudentCount + item.users.length;
			this.calculateOverLimit();
			this.cleanUpSearch();
			this.activityService.hasSearchResults$.next(true);
		}

		// Adding Student
		if (typeof item === 'object' && !('users' in item)) {
			// Not in another activity
			if (!item?.message && item.overwriteable) {
				this.newStudentCount = this.newStudentCount + 1;
				this.calculateOverLimit();
				this.selectedGroupsAndStudents.push(item.user);
				this.cleanUpSearch();
			}
			// In another activity
			else if (item.overwriteable) {
				this.newStudentCount = this.newStudentCount + 1;
				this.calculateOverLimit();
				const overrideStudent = () => {
					this.selectedGroupsAndStudents.push(item.user);
					this.cleanUpSearch();
				};
				if (item.assignerId !== this.user.id) {
					const data = {
						title: `Override Scheduled Activity?`,
						message: item.message || '',
						okButtonText: 'Override',
					};
					this.openConfirmationDialog(data).then((res) => {
						if (res) {
							overrideStudent();
						}
					});
				} else {
					// Teacher assigned them elsewhere
					overrideStudent();
				}
			}
		}
	}

	private cleanUpSearch(): void {
		this.setSelectedStudents();
		this.searchText = '';
		this.groups = this.allGroups.filter((group) => !this.selectedGroupsAndStudents.find((selectedGroup) => selectedGroup.id === group.id));
		this.searchResults = [];
		this.setFocusOnSearch();
	}

	onSearchFocus(isFocused: boolean): void {
		setTimeout(() => (this.isSearchFocused = isFocused), 50);
	}

	protected setFocusOnSearch(): void {
		if (this.searchInput?.nativeElement) {
			setTimeout(() => this.renderer.selectRootElement(this.searchInput.nativeElement).focus(), 50);
		}
	}

	private openConfirmationDialog(data: { title: string; message: string; okButtonText: string }): Promise<boolean> {
		return new Promise((resolve) => {
			const dialogRef = this.dialog.open(ConfirmationComponent, {
				panelClass: 'search-pass-card-dialog-container',
				backdropClass: 'custom-bd',
				disableClose: true,
				data: data,
			});

			dialogRef
				.afterClosed()
				.toPromise()
				.then((result) => {
					return resolve(result);
				});
		});
	}

	private setSelectedStudents(): void {
		this.selectedStudents = [];
		this.attendeeIdsToDelete = [];
		// Student
		this.selectedGroupsAndStudents.forEach((groupOrStudent) => {
			if (!('users' in groupOrStudent) && !this.isInSelectStudents(groupOrStudent)) {
				this.selectedStudents.push(groupOrStudent);
				const attendee = this.attendeeMap[groupOrStudent.id];
				if (attendee) {
					this.attendeeIdsToDelete.push(attendee.id);
				}
			}
		});
		// Group
		this.selectedGroupsAndStudents.forEach((groupOrStudent) => {
			if (typeof groupOrStudent === 'object' && 'users' in groupOrStudent) {
				groupOrStudent.users.forEach((student) => {
					if (!this.isInSelectStudents(student)) {
						const attendeeRecord = this.attendeeMap[student.id];
						// Add all the students in the group that aren't in another activity
						if (!attendeeRecord) {
							this.selectedStudents.push(student);
						}
						// Add the students in another activity to the override list
						else if (
							attendeeRecord.activity_instance_id !== this.instance.id &&
							!this.overrideableStudents.find((result) => result.user.id === student.id)
						) {
							const result = this.buildMessage(student, attendeeRecord);
							this.overrideableStudents.push({
								user: student,
								overwriteable: result.overwriteable,
								assignerId: attendeeRecord?.assigner_id,
								activityName: attendeeRecord?.activity_name,
								message: result.message,
								groupId: groupOrStudent.id,
							});
						}
					}
				});
			}
		});
		// Order by overrideablity and name
		this.overrideableStudents.sort((a, b) => {
			if (a.overwriteable && !b.overwriteable) {
				return -1;
			} else if (!a.overwriteable && b.overwriteable) {
				return 1;
			}

			return a.user.display_name.localeCompare(b.user.display_name);
		});

		// Validators update
		this.calculateOverLimit();
		this.addDisabled = this.selectedStudents.length === 0 || this.overLimit > 0;
		this.overrideAllDisabled =
			(this.maxAttendance > 0 ? this.overrideableStudents.filter((student) => student.overwriteable).length + this.overLimit > 0 : false) ||
			this.overrideableStudents.filter((student) => student.overwriteable).length === 0;
		this.activityService.selectedStudents$.next(this.selectedStudents);
		this.activityService.attendeeIdsToDelete$.next(this.attendeeIdsToDelete);
	}

	private isInSelectStudents(user: User): boolean {
		return !!this.selectedStudents.find((student) => student.id === user.id);
	}

	private buildMessage(
		student: User,
		attendeeRecord?: SchoolActivityAttendee
	): {
		message: string;
		overwriteable: boolean;
	} {
		if (!attendeeRecord) {
			return { message: '', overwriteable: true };
		}

		if (this.attendeeMap[student.id]?.activity_instance_id === this.instance.id) {
			return { message: '', overwriteable: false };
		}

		if (student.id === attendeeRecord.assigner_id) {
			return {
				message: `${student.display_name} added themselves to ${attendeeRecord.activity_name}.`,
				overwriteable: true,
			};
		}

		if (attendeeRecord.assigner_id === this.user.id) {
			return {
				message: `You added this student to ${attendeeRecord.activity_name}.`,
				overwriteable: this.instance.id !== attendeeRecord.activity_instance_id,
			};
		}

		const assignerName = this.staffNameMap[attendeeRecord.assigner_id];
		if (this.user.isAdmin()) {
			return { message: `${assignerName} added this student to ${attendeeRecord.activity_name}.`, overwriteable: true };
		}

		return {
			message: `${assignerName} added this student to ${attendeeRecord.activity_name}.\nIf you want to add this student, ${assignerName} must remove them first.`,
			overwriteable: false,
		};
	}

	removeSelection(selection: User | StudentList): void {
		this.selectedGroupsAndStudents = this.selectedGroupsAndStudents.filter((s) => s.id !== selection.id);
		// Remove group members from override list
		if (typeof selection === 'object' && 'users' in selection) {
			this.groups.push(selection);
			this.overrideableStudents = this.overrideableStudents.filter((r) => r?.groupId !== selection.id);
			this.newStudentCount = this.newStudentCount - selection.users.length;
		} else {
			this.newStudentCount = this.newStudentCount - 1;
		}
		this.calculateOverLimit();
		this.setSelectedStudents();
		this.setFocusOnSearch();
		this.activityService.hasSearchResults$.next(this.selectedGroupsAndStudents.length > 0);
	}

	showImportStudentList(): void {
		const dialogRef = this.dialog.open(ImportStudentListComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				forInput: true,
			},
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((students: User[]) => {
				if (students?.length > 0) {
					students.forEach((student) => {
						if (!this.isInSelectStudents(student)) {
							const attendeeRecord = this.attendeeMap[student.id];
							// Add student if not in another activity
							if (!attendeeRecord) {
								this.selectedGroupsAndStudents.push(student);
							}
							// Add student in another activity to the override list
							else if (attendeeRecord.activity_instance_id !== this.instance.id) {
								const result = this.buildMessage(student, attendeeRecord);
								this.overrideableStudents.push({
									user: student,
									overwriteable: result.overwriteable,
									assignerId: attendeeRecord?.assigner_id,
									activityName: attendeeRecord?.activity_name,
									message: result.message,
								});
							}
						}
					});
					this.setSelectedStudents();
				}
			});
	}

	overrideStudent(userId: number, singleOverride = true): void {
		const studentToOverride = this.overrideableStudents.find((student) => student.user.id === userId);
		if (studentToOverride && studentToOverride.overwriteable) {
			const overrideStudent = () => {
				this.overrideableStudents = this.overrideableStudents.filter((student) => student.user.id !== userId);
				this.selectedGroupsAndStudents.push(studentToOverride.user);
			};

			if (singleOverride) {
				// Don't show confirmation for students the teacher already assigned
				if (studentToOverride.assignerId !== this.user.id) {
					const data = {
						title: `Override Scheduled Activity?`,
						message: studentToOverride.message || '',
						okButtonText: 'Override',
					};
					this.openConfirmationDialog(data).then((res) => {
						if (res) {
							overrideStudent();
							this.setSelectedStudents();
						}
					});
				} else {
					overrideStudent();
					this.setSelectedStudents();
				}
			} else {
				overrideStudent();
			}
		}
	}

	calculateOverLimit(): void {
		this.overLimit = this.maxAttendance > 0 ? this.initialStudentCount + this.newStudentCount - this.maxAttendance : 0;
		this.isOverLimit.emit(this.overLimit > 0);
		if (this.overLimit > 0) {
			this.spotsRemaining = this.maxAttendance - this.initialStudentCount;
		}
	}

	overrideAll(): void {
		const data = {
			title: `Override Scheduled Activities?`,
			message: `This will override ${
				this.overrideableStudents.filter((student) => student.overwriteable).length
			} students assigned to other activities`,
			okButtonText: 'Override',
		};
		this.openConfirmationDialog(data).then((res) => {
			console.log(res);
			if (res) {
				for (const student of this.overrideableStudents) {
					this.overrideStudent(student.user.id, false);
				}
				this.setSelectedStudents();
			}
		});
	}
}
