import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import { take } from 'rxjs';
import { CalendarPickerComponent } from '../calendar-components/calendar-picker/calendar-picker.component';

const CENTERED_POSITION: ConnectedPosition = {
	originX: 'center',
	originY: 'center',
	overlayX: 'center',
	overlayY: 'top',
};

@Injectable({
	providedIn: 'root',
})
export class CalendarPickerService {
	overlayRef!: OverlayRef;

	constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder) {
		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			backdropClass: 'tw-bg-transparent',
		});
	}

	showCalendar(connectedTo: ElementRef, selectedDate: moment.Moment[], onClose: () => void = () => {}): CalendarPickerComponent {
		this.overlayRef.detach();

		const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(connectedTo).withPositions([CENTERED_POSITION]);
		this.overlayRef.updatePositionStrategy(positionStrategy);

		const calendarRef = this.overlayRef.attach(new ComponentPortal(CalendarPickerComponent));
		const calendar = calendarRef.instance;
		Object.assign(calendar, {
			selectedDates: selectedDate,
			showTime: false,
			showYear: false,
			width: 240,
			classes: 'tw-bg-white tw-rounded-xl tw-shadow-base',
		});

		this.overlayRef
			.backdropClick()
			.pipe(take(1))
			.subscribe(() => {
				this.closeCalendar(this.overlayRef, calendarRef);
				onClose();
			});

		calendar.dateSelected.pipe(take(1)).subscribe(() => {
			this.closeCalendar(this.overlayRef, calendarRef);
			onClose();
		});

		return calendar;
	}

	private closeCalendar(overlayRef: OverlayRef, calendarRef: ComponentRef<unknown>): void {
		overlayRef.detach();
		calendarRef.destroy();
	}
}
