import { createAction, props } from '@ngrx/store';
import { SchoolActivity, SchoolActivityStatus } from '../../../models/SchoolActivity';
import { CreateSchoolActivityReq, InstanceTimes, UpdateSchoolActivityRequest } from '../../../services/school-activity.service';

export enum SchoolActivitiesActionTypes {
	AddSchoolActivity = '[SchoolActivities] Add SchoolActivity',
	AddSchoolActivitySuccess = '[SchoolActivities] Add SchoolActivity Success',
	AddSchoolActivityFailure = '[SchoolActivities] Add SchoolActivity Failure',

	GetSchoolActivities = '[SchoolActivities] Get SchoolActivities',
	GetSchoolActivitiesSuccess = '[SchoolActivities] Get SchoolActivities Success',
	GetSchoolActivitiesFailure = '[SchoolActivities] Get SchoolActivities Failure',

	GetSchoolActivitiesByStatus = '[SchoolActivities] Get SchoolActivitiesByStatus',
	GetSchoolActivitiesByStatusSuccess = '[SchoolActivities] Get SchoolActivitiesByStatus Success',
	GetSchoolActivitiesByStatusFailure = '[SchoolActivities] Get SchoolActivitiesByStatus Failure',

	GetSchoolActivityById = '[SchoolActivities] Get GetSchoolActivityById',
	GetSchoolActivityByIdSuccess = '[SchoolActivities] Get GetSchoolActivityById Success',
	GetSchoolActivityByIdFailure = '[SchoolActivities] Get GetSchoolActivityById Failure',

	UpdateSchoolActivity = '[SchoolActivities] Update SchoolActivity',
	UpdateSchoolActivitySuccess = '[SchoolActivities] Update SchoolActivity Success',
	UpdateSchoolActivityFailure = '[SchoolActivities] Update SchoolActivity Failure',

	DeleteSchoolActivity = '[SchoolActivities] Delete SchoolActivity',
	DeleteSchoolActivitySuccess = '[SchoolActivities] Delete Success',
	DeleteSchoolActivityFailure = '[SchoolActivities] Delete SchoolActivity Failure',
}
export const AddSchoolActivityAction = createAction(
	'[SchoolActivities] Add SchoolActivity',
	props<{ createReq: CreateSchoolActivityReq; instanceTimes: InstanceTimes[] }>()
);

export const AddSchoolActivitySuccessAction = createAction(
	'[SchoolActivities] Add SchoolActivity Success',
	props<{ schoolActivity: SchoolActivity }>()
);

export const AddSchoolActivityFailureAction = createAction('[SchoolActivities] Add SchoolActivity Failure', props<{ error: string }>());

export const GetSchoolActivitiesAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivities,
	props<{
		flex_period_id: number | undefined;
	}>()
);
export const GetSchoolActivitiesSuccessAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivitiesSuccess,
	props<{ schoolActivities: SchoolActivity[] }>()
);
export const GetSchoolActivitiesFailureAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivitiesFailure,
	props<{ errorMessage: string }>()
);

export const GetSchoolActivitiesByStatusAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivitiesByStatus,
	props<{
		status: SchoolActivityStatus;
	}>()
);
export const GetSchoolActivitiesByStatusSuccessAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivitiesByStatusSuccess,
	props<{ schoolActivities: SchoolActivity[] }>()
);
export const GetSchoolActivitiesByStatusFailureAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivitiesByStatusFailure,
	props<{ errorMessage: string }>()
);

export const GetSchoolActivityByIdAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivityById,
	props<{
		activityId: number;
	}>()
);
export const GetSchoolActivityByIdSuccessAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivityByIdSuccess,
	props<{ schoolActivity: SchoolActivity }>()
);
export const GetSchoolActivityByIdFailureAction = createAction(
	SchoolActivitiesActionTypes.GetSchoolActivityByIdFailure,
	props<{ errorMessage: string }>()
);

export const UpdateSchoolActivityAction = createAction(
	SchoolActivitiesActionTypes.UpdateSchoolActivity,
	props<{
		activity: Partial<UpdateSchoolActivityRequest>;
	}>()
);
export const UpdateSchoolActivitySuccessAction = createAction(
	SchoolActivitiesActionTypes.UpdateSchoolActivitySuccess,
	props<{ schoolActivity: SchoolActivity }>()
);
export const UpdateSchoolActivityFailureAction = createAction(
	SchoolActivitiesActionTypes.UpdateSchoolActivityFailure,
	props<{ errorMessage: string }>()
);

export const DeleteSchoolActivityAction = createAction(
	SchoolActivitiesActionTypes.DeleteSchoolActivity,
	props<{
		activityId: number;
	}>()
);
export const DeleteSchoolActivitySuccessAction = createAction(
	SchoolActivitiesActionTypes.DeleteSchoolActivitySuccess,
	props<{
		activityId: number;
	}>()
);
export const DeleteSchoolActivityFailureAction = createAction(
	SchoolActivitiesActionTypes.DeleteSchoolActivityFailure,
	props<{ errorMessage: string }>()
);
