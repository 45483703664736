import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
	transform(array: any[], field: string): any[] {
		if (!Array.isArray(array)) {
			return [];
		}

		return array.sort((a, b) => {
			const fieldA = this.resolveField(a, field)?.toString().toLowerCase() || '';
			const fieldB = this.resolveField(b, field)?.toString().toLowerCase() || '';
			return fieldA.localeCompare(fieldB);
		});
	}

	private resolveField(obj: any, field: string): any {
		return field.split('.').reduce((acc, part) => acc?.[part], obj);
	}
}
