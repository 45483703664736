import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { DialogFactoryService } from '../../dialog-factory.service';
import { DynamicDialogAction, DynamicDialogData } from '../../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from '../../dynamic-dialog.service';
import { memo } from '../../helpers/helper';
import { SchoolActivityInstance, User } from '../../models';
import { AttendanceRecord, AttendanceRecordState } from '../../models/AttendanceRecord';
import { AttendanceRecordService } from '../../services/attendance-record-service';
import { FeatureFlagService, FLAGS } from '../../services/feature-flag.service';
import {
	BulkSignUpForActivityReq,
	GetAttendeesForInstanceReq,
	SchoolActivityAttendee,
	SchoolActivityService,
} from '../../services/school-activity.service';
import { DeleteInstancePayload } from '../teacher-activity.component';

@Component({
	selector: 'sp-attendees',
	templateUrl: './attendees.component.html',
	styleUrls: ['../teacher-activity.component.scss'],
})
export class AttendeesComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('addStudentsModalBody', { read: TemplateRef, static: false }) addStudentsModalBody!: TemplateRef<unknown>;

	@Input() selectedInstance: SchoolActivityInstance | undefined;
	@Input() maxNumAttending = 0;
	@Input() showDeleteInstance = true;
	@Output() openDeleteInstance: EventEmitter<DeleteInstancePayload> = new EventEmitter<DeleteInstancePayload>();

	attendanceRecords: AttendanceRecord[] = [];
	loading = true;
	numAttendees = 0;
	numUnmarkedRecords = 0;
	addStudentDisabled = false;
	showAttendance = false;
	removeSubject$ = new Subject<number>();

	private attendanceEnum = AttendanceRecordState;
	private destroy$: Subject<void> = new Subject<void>();
	private interval: ReturnType<typeof setInterval> | null = null;

	private addStudentsDialogService: DynamicDialogService | undefined;

	constructor(
		public activityService: SchoolActivityService,
		private dialogFactoryService: DialogFactoryService,
		private featureFlagService: FeatureFlagService,
		private attendanceRecordService: AttendanceRecordService
	) {}

	ngOnInit(): void {
		const attendanceEnabled = this.featureFlagService.isFeatureEnabledV2(FLAGS.Attendance) && this.featureFlagService.attendancePlan() === 'yes';

		if (attendanceEnabled) {
			this.showAttendance = this.showAttendanceCheckIn();
			this.interval = setInterval(() => {
				this.showAttendance = this.showAttendanceCheckIn();
			}, 1000);
		}

		this.removeSubject$.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe((studentId) => this.removeStudent(studentId));

		this.refreshAttendees();

		this.activityService.selectedStudents$.pipe(takeUntil(this.destroy$)).subscribe((results) => {
			this.addStudentsDialogService?.setDialogConfig({ disablePrimaryButton: !results });
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.selectedInstance?.current_num_attendees) {
			this.numAttendees = this.selectedInstance.current_num_attendees;
		}

		if (this.selectedInstance && this.selectedInstance.id > 0) {
			const req: GetAttendeesForInstanceReq = {
				activity_instance_id: this.selectedInstance.id,
				start_time: this.selectedInstance.start_time,
				include_user_profiles: true,
			};
			this.activityService.GetAttendeesForInstance(req);
		} else {
			this.activityService.ClearActivityAttendeesStore();
		}
		this.loading = false;

		this.refreshAttendees();
	}

	handleBack(): void {
		this.attendanceRecords = [];
		this.activityService.ClearSelectedActivityInstance();
	}

	handleDeleteInstance(event: MouseEvent, instance: SchoolActivityInstance): void {
		this.openDeleteInstance.emit({ event: event, instance: instance });
	}

	toggleSearch(): void {
		const data: DynamicDialogData = {
			headerText: 'Add Students to Activity',
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: 'Add',
			secondaryButtonLabel: 'Cancel',
			disablePrimaryButton: true,
			modalBody: this.addStudentsModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			classes: 'tw-min-h-0',
		};
		this.addStudentsDialogService = this.dialogFactoryService.open(data, {
			panelClass: 'dynamic-dialog-modal-min',
			disableClose: false,
		});

		this.addStudentsDialogService.closed$.pipe(takeUntil(this.destroy$), filter(Boolean)).subscribe((result) => {
			const selectedOption: DynamicDialogAction = result as DynamicDialogAction;
			if (selectedOption === 'primary') {
				const students = this.activityService.selectedStudents$.getValue();
				const userIds = students.map((user) => user.id);
				if (userIds.length > 0 && this.selectedInstance) {
					const req: BulkSignUpForActivityReq = {
						user_ids: userIds,
						activity_id: this.selectedInstance.activity_id,
						scheduled_date: new Date(this.selectedInstance.start_time),
						activity_instance_id: this.selectedInstance.id < 0 ? 0 : this.selectedInstance.id,
					};
					const attendeeIds = this.activityService.attendeeIdsToDelete$.getValue();
					if (attendeeIds.length) {
						this.activityService.BulkRemoveAttendeeFromActivityInstanceAndSignUp(attendeeIds, req, this.selectedInstance);
					} else {
						this.activityService.TeacherBulkSignUpForActivity(req, this.selectedInstance);
					}
					this.activityService.selectedStudents$.next([]);
					this.activityService.attendeeIdsToDelete$.next([]);
				}
			}
		});
	}

	refreshAttendees(): void {
		this.loading = true;
		if (this.selectedInstance?.id !== 0) {
			let attendanceRecords$: Observable<AttendanceRecord[]>;
			if (this.showAttendance && this.selectedInstance) {
				attendanceRecords$ = this.attendanceRecordService.getRecordsForInstanceId(this.selectedInstance.id);
			} else {
				attendanceRecords$ = of([]);
			}
			combineLatest([this.activityService.attendeesCount$, attendanceRecords$])
				.pipe(takeUntil(this.destroy$))
				.subscribe((result) => {
					this.numAttendees = result[0];
					this.refreshDisableButton();
					this.setAttendanceRecords(result[1]);
					this.loading = false;
				});
		} else {
			this.loading = false;
		}
	}

	getAttendanceForStudent = memo((student: User, attendanceRecords: AttendanceRecord[]) => {
		return attendanceRecords.find((a) => a.attendance_user.id === student.id);
	});

	private refreshDisableButton(): void {
		this.addStudentDisabled = this.maxNumAttending > 0 && this.numAttendees >= this.maxNumAttending;
	}

	removeStudent(attendeeId: number): void {
		this.refreshDisableButton();
		this.activityService.RemoveAttendeeFromActivityInstanceAndSignUp(attendeeId);
	}

	markAllAsPresent(attendees: SchoolActivityAttendee[]): void {
		attendees.forEach((attendee) => {
			if (attendee.user) {
				const attendanceRecord = this.getAttendanceForStudent(attendee.user, this.attendanceRecords);
				if (attendanceRecord) {
					this.upsertAttendanceRecord('present', attendee.user, attendanceRecord);
				}
			}
		});
	}

	upsertAttendanceRecord(state: string, student: User, attendanceRecord: AttendanceRecord): void {
		if (attendanceRecord) {
			this.attendanceRecordService
				.updateRecordState({ id: attendanceRecord.id, state: state })
				.pipe(take(1))
				.subscribe((updatedRecord) => {
					const index = this.attendanceRecords.findIndex((a) => a.id === updatedRecord.id);
					const records = this.attendanceRecords.map((a, i) => (i === index ? updatedRecord : a));
					this.setAttendanceRecords(records);
				});
		} else if (this.selectedInstance) {
			this.attendanceRecordService
				.addRecords({
					activity_instance_id: this.selectedInstance.id,
					attendance_user_ids: [student.id],
					state: state,
				})
				.pipe(take(1))
				.subscribe((a) => {
					this.setAttendanceRecords(this.attendanceRecords.concat(a));
				});
		}
	}

	ngOnDestroy(): void {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	showAttendanceCheckIn(): boolean {
		if (!this.selectedInstance) {
			return false;
		}
		const minutesBeforeStart = 5;
		const currentTime = new Date();
		const startTime = new Date(this.selectedInstance.start_time);
		const endTime = new Date(this.selectedInstance.end_time);
		startTime.setMinutes(startTime.getMinutes() - minutesBeforeStart);
		return currentTime >= startTime && currentTime <= endTime;
	}

	private setAttendanceRecords(attendanceRecords: AttendanceRecord[]): void {
		this.attendanceRecords = attendanceRecords;
		this.numUnmarkedRecords = attendanceRecords.filter((record) => record.state === this.attendanceEnum.NotSelected).length;
	}
}
