export enum PassLimitEvent {
	Update = 'pass_limit.update',
}

export enum WaitingInLineEvents {
	Root = 'waiting_in_line_pass',
	Create = 'waiting_in_line_pass.create',
	Update = 'waiting_in_line_pass.update',
	Delete = 'waiting_in_line_pass.delete',
	LineStatus = 'waiting_in_line_pass.line_status',
}

export enum EmergencyEvents {
	Create = 'pass_event.create',
	Update = 'pass_event.update',
	Message = 'pass_event_message.create',
	Checkin = 'pass_event_checkin.create',
	UpdatePublic = 'pass_event.update.public',
	CreatePublic = 'pass_event.create.public',
}

export enum BellScheduleEvent {
	Update = 'bell_schedule.update',
}

export enum SchoolActivityAttendeeEvent {
	Create = 'school_activity_attendees.create',
	Update = 'school_activity_attendees.update',
	Delete = 'school_activity_attendees.delete',
}
