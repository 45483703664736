import { ElementRef, Injectable } from '@angular/core';
export interface ElementProperties {
	height: number;
	width: number;
	offsetTop: number;
	boundingClientRect: DOMRect;
}

@Injectable({
	providedIn: 'root',
})
export class DynamicStylingService {
	private elements = new Map<string, ElementProperties>();

	registerElement(key: string, ref: ElementRef<HTMLElement>): void {
		const properties: ElementProperties = {
			height: ref.nativeElement.offsetHeight,
			width: ref.nativeElement.offsetWidth,
			offsetTop: ref.nativeElement.offsetTop,
			boundingClientRect: ref.nativeElement.getBoundingClientRect(),
		};
		this.elements.set(key, properties);
	}

	getElementProperties(key: string): ElementProperties | undefined {
		return this.elements.get(key);
	}

	listElementRefs(): string[] {
		return Array.from(this.elements.keys());
	}

	getWindowInnerHeight(): number {
		return window.innerHeight;
	}

	keepViewablePosition(clickX: number, clickY: number, offsetX: number, offsetY: number): { top: string; left: string } {
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;
		const computedX = clickX + offsetX;
		const computedY = clickY + offsetY;
		const x = computedX > viewportWidth ? clickX - offsetX : clickX - 20;
		const y = computedY > viewportHeight ? clickY - offsetY : clickY + 20;
		return { top: `${y}px`, left: `${x}px` };
	}
}
