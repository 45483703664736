import { Component, Input } from '@angular/core';
import { IconName } from 'app/backpack-icons/backpack-icon.component';

export type WrappedIcon = {
	name: IconName;
	background: string;
	size?: string;
};

@Component({
	selector: 'sp-wrapped-icon',
	template: `
		<div class="icon-wrapper" [style.background]="icon.background">
			<bp-icon [icon]="icon.name" [size]="icon.size ?? '34'"></bp-icon>
		</div>
	`,
	styles: [
		`
			:host {
				display: contents;
			}
			.icon-wrapper {
				text-align: center;
				height: 30px;
				width: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
				padding: 4px 6px;
				margin-right: 16px;
				color: white;

				img {
					min-height: 23px;
				}
			}
		`,
	],
})
export class WrappedIconComponent {
	@Input() icon!: WrappedIcon;
}
