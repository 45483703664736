import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DarkThemeSwitch } from '../dark-theme-switch';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { User } from '../models/User';
import { HttpService } from '../services/http-service';
import { LocationsService } from '../services/locations.service';
import { StorageKeys, StorageService } from '../services/storage.service';
import { UserService } from '../services/user.service';

export interface RepresentedUser {
	user: User;
	roles: string[];
}

@Component({
	selector: 'sp-teacher-toggle-bar',
	templateUrl: './teacher-toggle-bar.component.html',
	styleUrls: ['./teacher-toggle-bar.component.scss'],
})
export class TeacherToggleBarComponent implements OnInit {
	isEnabledProfilePictures$: Observable<boolean>;
	private destroyer$ = new Subject<any>();
	user: User;
	private representedUsers: RepresentedUser[];
	effectiveUser: User;
	private representedUsersDialogRef: any;
	icon = '';

	constructor(
		public userService: UserService,
		private http: HttpService,
		public dialog: MatDialog,
		public darkTheme: DarkThemeSwitch,
		private storageService: StorageService,
		private locationsService: LocationsService
	) {}

	ngOnInit(): void {
		this.isEnabledProfilePictures$ = this.userService.isEnableProfilePictures$;
		this.icon = this.getIcon('Rect', 'White', 'Navy');

		this.userService.representedUsers$
			.pipe(
				tap((res) => {
					this.representedUsers = res;
				}),
				switchMap(() => {
					return this.userService.effectiveUserNullable$;
				}),
				tap((eu) => {
					this.effectiveUser = eu;
				}),
				takeUntil(this.destroyer$)
			)
			.subscribe();

		this.userService.userJSON$
			.pipe(
				filter((u) => !!u),
				tap((user) => {
					this.user = User.fromJSON(user);
				}),
				takeUntil(this.destroyer$)
			)
			.subscribe();
	}

	private getIcon(iconName: string, darkFill?: string, lightFill?: string): string {
		return this.darkTheme.getIcon({
			iconName: iconName,
			darkFill: darkFill,
			lightFill: lightFill,
		});
	}

	showTeaches(target): void {
		if (this.representedUsersDialogRef) {
			this.closeDialog();
		} else {
			this.openDialog(target);
		}
	}

	private openDialog(target): void {
		this.representedUsersDialogRef = this.dialog.open(DropdownComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				trigger: target.currentTarget,
				heading: 'You can create and manage passes for this teacher.',
				teachers: this.representedUsers.length > 1 ? this.representedUsers.map((u) => u.user) : null,
				selectedTeacher: this.effectiveUser,
				maxHeight: '200px',
				isHiddenSearchField: this.representedUsers.length > 4,
			},
		});

		this.representedUsersDialogRef.afterClosed().subscribe((userOrId: User | string) => {
			this.representedUsersDialogRef = null;

			if (userOrId instanceof User && userOrId.id == this.effectiveUser.id) {
				return;
			}

			const id = userOrId as string;
			if (!userOrId) {
				return;
			}

			const efUser = this.representedUsers.find((u) => +u.user.id === +id);
			this.http.effectiveUserId.next(+efUser.user.id);
			const cleanedUser = this.userAdapater(efUser);
			this.userService.updateEffectiveUser(cleanedUser);
			this.storageService.setItem(StorageKeys.selectedEffectiveUserId, efUser.user.id);
			this.locationsService.getLocationsWithTeacherRequest(efUser.user);
		});
	}

	private userAdapater(data: any): User {
		if (data.user) {
			const user = {
				...data.user,
				roles: data.roles,
			};
			return User.fromJSON(user);
		}
		return data;
	}

	closeDialog(): void {
		if (this.representedUsersDialogRef) {
			this.representedUsersDialogRef.close();
		}
	}
}
