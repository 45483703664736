import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { DeviceDetection } from '../device-detection.helper';
import { HallPass } from '../models/HallPass';
import { HelpCenterService } from './help-center.service';

export type PinnableDimensions = {
	height: string;
	width: string;
	isLarge: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class ScreenService {
	enabledLocationTableDnD: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	customBackdropEvent$: Subject<boolean> = new Subject<boolean>();
	customBackdropStyle$: Subject<{ background: string } | null> = new Subject();

	constructor(private helpCenter: HelpCenterService) {}

	private extraSmallDeviceBreakPoint = 320;
	private smallDevicesBreakPoint = 375;
	private midDevicesBreakPoint = 475;
	largeDevicesBreakPoint = 768;
	extraLargeDeviceBreakPoint = 940;
	navBarStaffBreakpoint = 1350;
	showPassInModal$: ReplaySubject<HallPass> = new ReplaySubject<null>();

	get smallDeviceExtra() {
		return this.extraSmallDeviceBreakPoint;
	}

	get smallDevice() {
		return this.smallDevicesBreakPoint;
	}

	get midDevice() {
		return this.midDevicesBreakPoint;
	}

	get largeDevice() {
		return this.largeDevicesBreakPoint;
	}

	get isDeviceSmallExtra() {
		return this.windowWidth <= this.smallDeviceExtra;
	}

	get isDeviceSmall() {
		return this.windowWidth <= this.smallDevice;
	}

	get isDeviceMid() {
		return this.windowWidth <= this.midDevice;
	}

	get isDeviceLarge() {
		return this.windowWidth <= this.largeDevicesBreakPoint;
	}

	get isDeviceLargeExtra() {
		/**
		 * This name is misleading. For example, this function would return true for a screen size 335px (which isn't
		 * considered extra large). This can easily confuse devs.
		 */
		this.extraLargeDeviceBreakPoint = 940;
		if (this.isIOSTablet) {
			this.extraLargeDeviceBreakPoint = 1024;
		}
		return this.windowWidth <= this.extraLargeDeviceBreakPoint;
	}

	get isIpadWidth() {
		return this.extraLargeDeviceBreakPoint >= this.windowWidth && this.midDevicesBreakPoint <= this.windowWidth;
	}

	get isDesktopWidth() {
		return this.extraLargeDeviceBreakPoint < this.windowWidth;
	}

	get windowWidth() {
		if (this.helpCenter.isHelpCenterOpen.getValue()) {
			return (
				window.innerWidth - document.getElementById('help-center-content').offsetWidth ||
				document.documentElement.clientWidth - document.getElementById('help-center-content').offsetWidth ||
				document.body.clientWidth - document.getElementById('help-center-content').offsetWidth
			);
		} else {
			return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		}
	}

	get windowHeight() {
		return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	}

	private get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}

	createCustomBreakPoint(breakPoint: number) {
		return breakPoint > this.windowWidth || breakPoint >= this.windowWidth;
	}

	getPinnableDimensions(): PinnableDimensions {
		return this.windowWidth >= this.extraLargeDeviceBreakPoint
			? {
					width: '120px',
					height: '70px',
					isLarge: true,
			  }
			: {
					width: '152px',
					height: '90px',
					isLarge: false,
			  };
	}

	clearCustomBackdrop() {
		this.customBackdropStyle$.next(undefined);
		this.customBackdropEvent$.next(false);
	}
}
