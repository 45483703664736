import { UpdateNum } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import { FlexPeriod } from 'app/services/flex-period.service';

export enum FlexPeriodActionTypes {
	GetFlexPeriods = '[FlexPeriod] Get FlexPeriods',
	GetFlexPeriodsSuccess = '[FlexPeriod] Get FlexPeriods Success',
	GetFlexPeriodsFailure = '[FlexPeriod] Get FlexPeriods Failure',

	CreateFlexPeriod = '[FlexPeriod] Created FlexPeriod',

	DeleteFlexPeriod = '[FlexPeriod] Delete FlexPeriod',
	DeleteFlexPeriodFailure = '[FlexPeriod] Delete FlexPeriod Failure',

	UpdateFlexPeriod = '[FlexPeriod] Update FlexPeriod',
	UpdateFlexPeriodSuccess = '[FlexPeriod] Update FlexPeriod Success',
	UpdateFlexPeriodFailure = '[FlexPeriod] Update FlexPeriod Failure',
}

export const GetFlexPeriodsAction = createAction(FlexPeriodActionTypes.GetFlexPeriods);
export const GetFlexPeriodsSuccessAction = createAction(FlexPeriodActionTypes.GetFlexPeriodsSuccess, props<{ flexPeriods: FlexPeriod[] }>());
export const GetFlexPeriodsFailureAction = createAction(FlexPeriodActionTypes.GetFlexPeriodsFailure, props<{ errorMessage: string }>());

export const CreateFlexPeriodAction = createAction(FlexPeriodActionTypes.CreateFlexPeriod, props<{ flexPeriod: FlexPeriod }>());

export type DeleteFlexPeriodParams = { id: number; toRemove: FlexPeriod | undefined };
export const DeleteFlexPeriodAction = createAction(FlexPeriodActionTypes.DeleteFlexPeriod, props<DeleteFlexPeriodParams>());
export const DeleteFlexPeriodFailureAction = createAction(FlexPeriodActionTypes.DeleteFlexPeriodFailure, props<{ flexPeriod: FlexPeriod }>());

export type FlexPeriodUpdateParams = { update: UpdateNum<FlexPeriod>; original: FlexPeriod | undefined };
export const UpdateFlexPeriodAction = createAction(FlexPeriodActionTypes.UpdateFlexPeriod, props<FlexPeriodUpdateParams>());
export const UpdateFlexPeriodSuccessAction = createAction(FlexPeriodActionTypes.UpdateFlexPeriodSuccess, props<{ updated: FlexPeriod }>());
export const UpdateFlexPeriodFailureAction = createAction(FlexPeriodActionTypes.UpdateFlexPeriodFailure, props<{ original: FlexPeriod }>());
