import { Component, ComponentFactoryResolver, Injectable, Injector, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WaitingInLineEvents } from 'app/live-data/event-types';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { bufferCount, catchError, filter, flatMap, map, reduce, take, takeUntil } from 'rxjs/operators';
import {
	PassLimitDialogComponent,
	PassLimitOverride,
	PassLimitOverrideResponse,
} from '../create-hallpass-forms/main-hallpass--form/locations-group-container/pass-limit-dialog/pass-limit-dialog.component';
import { DialogFactoryService } from '../dialog-factory.service';
import { DynamicDialogAction, DynamicDialogData } from '../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { constructUrl } from '../live-data/helpers';
import { BaseModel, ColorProfile, Location, Paged, PassLimit, Pinnable, User, WaitingInLinePass } from '../models';
import { AppState } from '../ngrx/app-state/app-state';
import { getFavoriteLocations, updateFavoriteLocations } from '../ngrx/favorite-locations/actions';
import {
	getFavoriteLocationsCollection,
	getLoadedFavoriteLocations,
	getLoadingFavoriteLocations,
} from '../ngrx/favorite-locations/states/favorite-locations-getters.state';
import {
	getLocations,
	getLocationsFromCategory,
	postLocation,
	removeLocation,
	searchLocations,
	updateLocation,
	updateLocationSuccess,
} from '../ngrx/locations/actions';
import {
	getCreatedLocation,
	getFoundLocations,
	getLoadedLocations,
	getLoadingLocations,
	getLocationById,
	getLocationsCollection,
	getLocationsFromCategoryGetter,
	getUpdatedLocation,
} from '../ngrx/locations/states/locations-getters.state';
import { getPassLimits, updatePassLimit } from '../ngrx/pass-limits/actions';
import { getPassLimitCollection, getPassLimitEntities } from '../ngrx/pass-limits/states';
import { updatePinnableSuccess } from '../ngrx/pinnables/actions';
import { getPinnableCollection } from '../ngrx/pinnables/states';
import { getLocsWithTeachers } from '../ngrx/teacherLocations/actions';
import { getTeacherLocationsCollection } from '../ngrx/teacherLocations/state/locations-getters.state';
import { WaitInLineCardComponent } from '../pass-cards/wait-in-line-card/wait-in-line-card.component';
import { FeatureFlagService, FLAGS } from './feature-flag.service';
import { HttpService } from './http-service';
import { LiveUpdateEvent, LiveUpdateService } from './live-update.service';

export interface CheckLocationTitle {
	title_used: boolean;
}

export type AugmentedLocation = Pick<Pinnable, 'location' | 'icon' | 'color_profile' | 'ignore_students_pass_limit' | 'show_as_origin_room'>;

export type SuggestedLocations = {
	root_location: AugmentedLocation;
	suggested_locations: AugmentedLocation[];
};

export type CategoryLocations = {
	locations: Location[];
	title: string;
};

export type CategorizedLocations = {
	categories: CategoryLocations[];
	other_locations: Location[];
};

// this is a Location object with some added fields from a pinnable object
export interface LocationWithPinnableData extends Location {
	pinnable_id: number;
	pinnable_title: string;
	gradient_color: string;
	icon: string;
	type: 'location' | 'category';
	category: string;
	color_profile: ColorProfile;
	ignore_students_pass_limit: boolean;
	show_as_origin_room: boolean;
}

@Component({
	template: ` <ng-template #mainBody>
		<div class="supporting-text">
			<span [innerHTML]="message"></span>
		</div>
	</ng-template>`,
	styles: [
		`
			.supporting-text {
				font-size: 15px;
				font-weight: normal;
				color: #7f879d;
				padding-bottom: 20px;
			}
		`,
	],
})
export class OverrideBodyComponent implements OnInit {
	@ViewChild('mainBody') mainBody!: TemplateRef<unknown>;
	@Input() location!: Location;
	@Input() overrideData!: Partial<PassLimitOverride>;
	@Input() isOriginRoom = false;
	private wilUiRefresh: boolean;
	message = '';

	constructor(private ff: FeatureFlagService) {
		this.wilUiRefresh = this.ff.isFeatureEnabledV2(FLAGS.WaitInLineUIRefresh);
	}

	ngOnInit(): void {
		const { isKiosk, isStaff, roomPassLimit, studentCount, isWaitInLine, currentCount } = this.overrideData;
		const fromTo = this.isOriginRoom ? 'from' : 'to';
		const isStudent = !isStaff || isKiosk;

		if (isWaitInLine) {
			this.message = this.wilUiRefresh
				? `${currentCount}/${roomPassLimit} students has passes ${fromTo} <b>${this.location.title}</b>. If it is an emergency, you can skip the line and start the pass.`
				: `
        ${currentCount}/${roomPassLimit} students have passes to this room. If it is an emergency, you can
        skip the line and start this pass.
      `;
			return;
		}

		if (!isStudent) {
			this.message = studentCount === 1 ? 'If this is an emergency, you can override the limit.' : 'Are you sure you want to override the limit?';

			return;
		}

		this.message = 'Please wait for a spot to open.';
	}
}

@Injectable({
	providedIn: 'root',
})
export class LocationsService {
	locations$: Observable<Location[]> = this.store.select(getLocationsCollection);

	/* This observable can be used to easily get the color profile and other pinnable data for a location by location id.
		Pinnables are loaded into the store automatically, but locations are not, so components
		using this observable should ensure that locations are loaded before using it by calling refreshLocationsIfEmpty().

		example usage:
		-- in typescript
		this.locationsService.refreshLocationsIfEmpty();
		locationsMap$ = this.locationsService.LocationMapWithPinnableDataByLocationId$;
		-- in template
		*ngIf="locationsMap$ | async as locationsMap"
		then use locationsMap[location.id] to get the pinnable data for a location */
	LocationMapWithPinnableDataByLocationId$: Observable<Record<number, LocationWithPinnableData>> = combineLatest([
		this.store.select(getPinnableCollection),
		this.locations$,
	]).pipe(
		filter(([p, l]) => p.length > 0 && l.length > 0),
		map(([pinnables, locations]) => {
			const mapByLocationId: { [key: number]: LocationWithPinnableData } = {};
			locations.forEach((loc) => {
				if (loc.category) {
					const pin = pinnables.find((pin) => pin.category === loc.category);
					if (pin) {
						mapByLocationId[loc.id] = this.getLocationWithPinnableData(loc, pin);
					}
				} else {
					const pin = pinnables.find((pin) => pin.location?.id === loc.id);
					if (pin) {
						mapByLocationId[loc.id] = this.getLocationWithPinnableData(loc, pin);
					}
				}
			});
			return mapByLocationId;
		})
	);

	location$ = (id: number) => this.store.select(getLocationById(id));
	createdLocation$: Observable<Location> = this.store.select(getCreatedLocation);
	updatedLocation$: Observable<Location> = this.store.select(getUpdatedLocation);
	loadingLocations$: Observable<boolean> = this.store.select(getLoadingLocations);
	loadedLocations$: Observable<boolean> = this.store.select(getLoadedLocations);
	pass_limits$: Observable<PassLimit[]> = this.store.select(getPassLimitCollection);
	pass_limits_entities$: Observable<{ [id: number]: PassLimit }> = this.store.select(getPassLimitEntities);

	foundLocations$: Observable<Location[]> = this.store.select(getFoundLocations);
	locsFromCategory$: Observable<Location[]> = this.store.select(getLocationsFromCategoryGetter);

	favoriteLocations$: Observable<Location[]> = this.store.select(getFavoriteLocationsCollection);
	loadingFavoriteLocations$: Observable<boolean> = this.store.select(getLoadingFavoriteLocations);
	loadedFavoriteLocations$: Observable<boolean> = this.store.select(getLoadedFavoriteLocations);

	teacherLocations$: Observable<Location[]> = this.store.select(getTeacherLocationsCollection);

	focused = new BehaviorSubject<boolean>(true);

	constructor(
		private http: HttpService,
		private store: Store<AppState>,
		private liveUpdateService: LiveUpdateService,
		private featureFlags: FeatureFlagService,
		private dialog: MatDialog,
		private dialogFactoryService: DialogFactoryService,
		private compFactRes: ComponentFactoryResolver,
		private inj: Injector
	) {}

	refreshLocationsIfEmpty(): void {
		this.locations$.pipe(take(1)).subscribe((locations) => {
			if (locations.length === 0) {
				this.refreshLocations();
			}
		});
	}

	// this is the preferred method for populating the store with locations
	// note that it does not handle excluding or including favorite/starred locations
	refreshLocations(limit = 500, visibility_ids_only = true): void {
		const url = `v1/locations?limit=${limit}&visibility_ids_only=${visibility_ids_only}`;
		this.store.dispatch(getLocations({ url: url }));
	}

	// TODO: Convert params of function into an object
	getLocationsWithCategory(category: string, show_removed = false): Observable<Location[]> {
		return this.http.get('v1/locations', {
			params: {
				category: category,
				// TODO(angular-12): this should be safe to remove as of Angular 12. Requires an update to the smartpass http `Config` interface.
				/* eslint @typescript-eslint/ban-ts-comment: 'warn' -- Current version of Angular (10.2.2) has incorrect types. `boolean` is supported as a parameter, but the type only defines strings. */
				// @ts-ignore
				show_removed,
			},
		});
	}

	getLocationsWithTeacherRequest(teacher: User): Observable<Location[]> {
		this.store.dispatch(getLocsWithTeachers({ teacher }));
		return this.teacherLocations$;
	}

	getLocationsWithTeacherHTTP(teacher: User): Observable<Location[]> {
		return this.http.get<Location[]>(`v1/locations?teacher_id=${teacher.id}&visibility_ids_only=true`);
	}

	getLocationsWithManyTeachers(teachers: User[]): Observable<Location[]> {
		const teacherIds = teachers.map((t) => t.id);
		return from(teacherIds).pipe(
			bufferCount(20),
			flatMap((ids) => {
				const url = constructUrl('v1/locations', {
					teacher_id: ids,
				});
				return this.http.get<Location[]>(url);
			}),
			reduce((acc, arr) => acc.concat(arr), [])
		);
	}

	getLocation(id): Observable<Location> {
		return this.http.get(`v1/locations/${id}`);
	}

	createLocationRequest(data): Observable<Location> {
		this.store.dispatch(postLocation({ data }));
		return this.createdLocation$;
	}

	createLocation(data): Observable<Location> {
		return this.http.post('v1/locations', data);
	}

	updateLocationRequest(id, data): Observable<Location> {
		this.store.dispatch(updateLocation({ id, data }));
		return this.updatedLocation$;
	}

	updateLocation(id, data): Observable<Location> {
		return this.http.patch(`v1/locations/${id}`, data);
	}

	deleteLocationRequest(id): void {
		this.store.dispatch(removeLocation({ id }));
	}

	deleteLocation(id): Observable<Location> {
		return this.http.delete(`v1/locations/${id}`, {
			headers: { 'X-Ignore-Errors': 'pass-through' }, // this allows the error to be handled by ngrx effects rather than the progress interceptor
		});
	}

	searchLocationsRequest(url: string): Observable<Location[]> {
		this.store.dispatch(searchLocations({ url }));
		return this.foundLocations$;
	}

	getLocationsFromCategory(category: string): Observable<Location[]> {
		this.store.dispatch(getLocationsFromCategory({ category }));
		return this.locsFromCategory$;
	}

	// @deprecated - use refreshLocations() instead if possible
	getLocationsWithConfigRequest(url: string): Observable<Location[]> {
		this.store.dispatch(getLocations({ url }));
		return this.locations$;
	}

	getLocationsWithConfig(url: string): Observable<Paged<Location>> {
		return this.http.get(url);
	}

	getLocationsWithConfigV2(config: Record<string, string | number | boolean>): Observable<Location[]> {
		let url = 'v1/locations?';

		Object.entries(config).forEach(([key, value], index) => {
			url += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
		});

		return this.http.get(url);
	}

	searchLocations(limit = 10, config = ''): Observable<Paged<Location>> {
		return this.http.get<Paged<Location>>(`v1/locations?limit=${limit}${config}`);
	}

	getLocationsWithFolder(): Observable<CategorizedLocations> {
		return this.http.get('v1/locations/categorized');
	}

	checkLocationName(value): Observable<CheckLocationTitle> {
		return this.http.get(`v1/locations/check_fields?title=${value}`);
	}

	checkLocationNumber(value) {
		return this.http.get(`v1/locations/check_fields?room=${value}`);
	}

	getPassLimit() {
		return this.http.get<{ pass_limits: PassLimit[] }>('v1/locations/pass_limits');
	}

	getPassLimitRequest() {
		this.store.dispatch(getPassLimits());
	}

	updatePassLimitRequest(item) {
		this.store.dispatch(updatePassLimit({ item }));
	}

	listenPassLimitSocket(): Observable<LiveUpdateEvent> {
		return this.liveUpdateService.listen('location.active_pass_counts');
	}

	updateLocationSuccessState(location: Location): void {
		this.store.dispatch(updateLocationSuccess({ location }));
	}

	updatePinnableSuccessState(pinnable: Pinnable) {
		this.store.dispatch(updatePinnableSuccess({ pinnable }));
	}

	getSuggestedRooms(locationId: number) {
		return this.http.post<SuggestedLocations>('v2/suggested_locations/list', { locationId }, undefined, false);
	}

	setSuggestedRooms(locationId: number, suggestedIds: number[]) {
		return this.http.post<SuggestedLocations>(
			'v2/suggested_locations/set',
			{
				locationId,
				suggestedIds,
			},
			undefined,
			false
		);
	}

	listenLocationSocket(): Observable<LiveUpdateEvent> {
		return this.liveUpdateService.listenOnCurrentSchool('location.patched');
	}

	listenPinnableSocket(): Observable<LiveUpdateEvent> {
		return this.liveUpdateService.listenOnCurrentSchool('pinnable.patched');
	}

	/////// Favorite Locations
	getFavoriteLocationsRequest(): Observable<Location[]> {
		this.store.dispatch(getFavoriteLocations());
		return this.favoriteLocations$;
	}

	getFavoriteLocations(): Observable<Location[]> {
		return this.http.get('v1/users/@me/starred');
	}

	updateFavoriteLocationsRequest(locations: Location[]): void {
		this.store.dispatch(updateFavoriteLocations({ locations }));
	}

	updateFavoriteLocations(body): Observable<number[]> {
		return this.http.put('v1/users/@me/starred', body);
	}

	getNumActiveOriginLimitedRooms() {
		return this.http
			.post<{
				active: number;
			}>('v2/locations/count_origin_limit_active', {}, undefined, false)
			.pipe(
				catchError(() => {
					return of({ active: 0 });
				})
			)
			.toPromise();
	}

	/**
	 * This function checks if a user is allowed to create passes into a destination.
	 * @param location The pass destination that is being checked for overrides
	 * @param isOriginRoom Whether the location is an origin or destination
	 * @param isKioskMode true if kiosk mode, false otherwise
	 * @param studentCount The number of passes being made into location
	 * @param skipLine true if we're overriding a Wait in Line queue. False/undefined otherwise
	 * @return {Promise<boolean>} A promise containing whether the user can create passes into the destination.
	 * Returns Promise<false> if the location limit isn't being overridden.
	 * A return value of Promise<true> means that we are allowed to create passes into the destination. This happens
	 * under the following circumstances:
	 * - The destination doesn't have a pass limit
	 * - The destination's pass limit isn't reached
	 * - Wait in Line is enabled, one student is selected and a teacher is not skipping the line
	 * - The room limit is reached and the user confirms that they wish to override the room limit
	 */
	async checkIfFullRoom(
		location: Location,
		isKioskMode: boolean,
		studentCount: number,
		skipLine?: boolean,
		wilp?: WaitingInLinePass
	): Promise<boolean> {
		if (isKioskMode) {
			return true;
		}

		const allPassLimits = (await this.getPassLimit().pipe(take(1)).toPromise())?.pass_limits;
		const passLimit = allPassLimits?.find((pl) => pl?.id == location?.id);

		if (!passLimit) {
			// passLimits has no location.id
			return true;
		}

		const passLimitReached = this.isRoomPassLimitReached(false, passLimit, studentCount);
		if (!passLimitReached) {
			return true;
		}

		const wilEnabled = this.featureFlags.isFeatureEnabled(FLAGS.WaitInLine);

		// room pass limit has been reached on the teacher's side
		if (wilEnabled) {
			const multipleStudents = studentCount > 1; // more than one student has been selected
			if (!multipleStudents && !skipLine) {
				return true;
			}
		}

		const roomPassLimit = passLimit.max_passes_to;
		const currentCount = passLimit.to_count;
		const wilUiRefresh = this.featureFlags.isFeatureEnabledV2(FLAGS.WaitInLineUIRefresh);

		const data: Partial<PassLimitOverride> = {
			isKiosk: false,
			isStaff: true,
			roomPassLimit,
			studentCount,
			currentCount,
			isWaitInLine: wilEnabled && skipLine,
			wilp,
		};

		if (wilUiRefresh) {
			const result = await this.openOverrideDialog(data, location, false).toPromise();
			return result === 'primary';
		}

		if (wilEnabled && wilUiRefresh && skipLine) {
			data.customBodyHtml = `${currentCount}/${roomPassLimit} students has passes to <b>${location.title}</b>. If it is an emergency, you can skip the line and start the pass.`;
		}

		// if not wilp, or if wilp and doubly-linked and (not ready at either line or ready at origin but not destination)
		const dialogRef = this.dialog.open(PassLimitDialogComponent, {
			panelClass: 'overlay-dialog',
			backdropClass: 'custom-backdrop',
			width: '450px',
			disableClose: true,
			data,
		});

		const result: Partial<PassLimitOverrideResponse> = await dialogRef.afterClosed().toPromise();
		return !!result.override;
	}

	private openOverrideDialog(overrideData: Partial<PassLimitOverride>, location: Location, isOriginRoom: boolean): Observable<DynamicDialogAction> {
		// calculate button colors
		// calculate button text
		const { isKiosk, isStaff, roomPassLimit, studentCount, isWaitInLine, currentCount } = overrideData;
		const isStudent = !isStaff || isKiosk;
		const primaryButtonGradientBackground = !isStudent ? '#E32C66' : '#7083A0';
		const primaryButtonLabel = isWaitInLine ? 'Skip the Line' : !isStudent ? 'Override' : 'Back';

		// calculate header text
		let headerText: string;
		if (isWaitInLine) {
			headerText = 'Skip the Line';
		} else if (!isStudent) {
			headerText =
				studentCount === 1
					? `Limit reached: ${currentCount}/${roomPassLimit} students have passes to this room`
					: `Creating these ${studentCount} passes will exceed the room's ${roomPassLimit}-pass limit`;
		} else {
			headerText = 'Room Limit Reached';
		}

		// fetch templateRef for body
		const overrideFac = this.compFactRes.resolveComponentFactory(OverrideBodyComponent).create(this.inj);
		overrideFac.instance.overrideData = overrideData;
		overrideFac.instance.location = location;
		overrideFac.instance.isOriginRoom = isOriginRoom;
		overrideFac.instance.ngOnInit();
		overrideFac.changeDetectorRef.detectChanges();

		const data: DynamicDialogData = {
			headerText: headerText,
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel,
			secondaryButtonLabel: 'Cancel',
			modalBody: overrideFac.instance.mainBody,
			secondaryButtonGradientBackground: '#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonGradientBackground,
			primaryButtonTextColor: '#FFFFFF',
			classes: 'tw-min-h-0',
			icon: {
				name: 'hand',
				background: '#FFFFFF',
				size: '34',
			},
		};

		const dialogService = this.dialogFactoryService.open(data, {
			panelClass: 'dynamic-dialog-modal-min',
			disableClose: false,
		});

		if (overrideData?.wilp) {
			this.liveUpdateService
				.listen(WaitingInLineEvents.Delete)
				.pipe(
					filter((e) => e.action === WaitingInLineEvents.Delete && (e.data as WaitingInLinePass).id === overrideData.wilp?.id),
					takeUntil(dialogService.closed$)
				)
				.subscribe(() => {
					dialogService.dialogRef.close();
				});
		}

		// for a waiting in line pass that has triggered the override modal, we're making the assumption that
		// the waiting in line pass card dialog is present.
		// The following code destroys the override modal if the waiting in line pass card modal is destroyed.
		const wilDialogRef: MatDialogRef<WaitInLineCardComponent> | undefined = this.dialog.openDialogs.find(
			(d) => d.componentInstance instanceof WaitInLineCardComponent
		);
		if (wilDialogRef) {
			// no need to worry about the subscription leaking, the underlying _beforeClosed
			// subject explicitly completes after returning a value.
			wilDialogRef.beforeClosed().subscribe({
				next: () => {
					dialogService.dialogRef.close();
				},
			});
		}

		return dialogService.closed$;
	}

	private isRoomPassLimitReached(isOriginLimit: boolean, passLimit: PassLimit, numStudentsToCreatePassesFor = 0): boolean {
		if (isOriginLimit) {
			return passLimit.max_passes_from_active && passLimit.from_count + numStudentsToCreatePassesFor > passLimit.max_passes_from;
		}

		if (!passLimit.max_passes_to_active) {
			return false;
		}

		const totalCount = passLimit.to_count + numStudentsToCreatePassesFor;
		return totalCount > passLimit.max_passes_to;
	}

	reachedRoomPassLimit(currentPage: 'from' | 'to', passLimit: PassLimit, isStaff?: boolean): boolean {
		if (!passLimit || isStaff) {
			return false;
		}

		const { isEnabled, max, count } =
			currentPage === 'to'
				? {
						isEnabled: passLimit.max_passes_to_active,
						max: passLimit.max_passes_to,
						count: passLimit.to_count,
				  }
				: {
						isEnabled: passLimit.max_passes_from_active,
						max: passLimit.max_passes_from,
						count: passLimit.from_count,
				  };

		return isEnabled && count >= max;
	}

	tooltipDescription(currentPage: 'from' | 'to' | 'none', passLimit: PassLimit, fromOrToRoom: 'fromRoom' | 'toRoom'): string {
		if (!passLimit) {
			return '';
		}

		if ([currentPage === 'from', !this.http.getSchool().show_active_passes_number, passLimit.to_count <= passLimit.max_passes_to].every(Boolean)) {
			return '';
		}

		let tooltipMessage =
			fromOrToRoom === 'fromRoom'
				? `${passLimit.from_count}/${passLimit.max_passes_from} students have passes from this room.`
				: `${passLimit.to_count}/${passLimit.max_passes_to} students have passes to this room.`;

		if (this.featureFlags.isFeatureEnabled(FLAGS.WaitInLine) && this.featureFlags.isFeatureEnabledV2(FLAGS.WaitInLineUIRefresh)) {
			tooltipMessage += ' Students will wait in line until a spot opens.';
		}

		return tooltipMessage;
	}

	private getLocationWithPinnableData(location: Location, pinnable: Pinnable): LocationWithPinnableData {
		const locationWithPinnableData: LocationWithPinnableData = {
			...location,
			pinnable_title: pinnable.title,
			gradient_color: pinnable.gradient_color,
			icon: pinnable.icon,
			type: pinnable.type,
			category: pinnable.category,
			color_profile: pinnable.color_profile,
			ignore_students_pass_limit: pinnable.ignore_students_pass_limit,
			show_as_origin_room: pinnable.show_as_origin_room,
			pinnable_id: pinnable.id,
			nameRoom: location.title + ' (' + location.room + ')',
			isSameObject(that: BaseModel) {
				return this?.id === that?.id;
			},
			isAssignedToSchool(schoolId: number): boolean {
				const mySchool: number = (this as any).school_id || (this as any).school_id_fk;
				if (mySchool === undefined) {
					return true;
				}
				return mySchool === schoolId;
			},
		};
		return locationWithPinnableData;
	}
}
