import * as React from 'react';

interface IHoverTextProps {
	children: React.ReactNode;
	message?: string;
	className?: string;
	onClick?: () => void;
}

export function HoverText({ children, message, className = '', onClick = () => null }: IHoverTextProps): React.ReactElement {
	const [isHovered, setIsHovered] = React.useState(false);

	return (
		<div
			className={`tw-relative ${className}`}
			onMouseEnter={() => {
				if (message) {
					setIsHovered(true);
				}
			}}
			onMouseLeave={() => setIsHovered(false)}
			onClick={onClick}>
			{isHovered && (
				<div
					className={`
						tw-absolute
						tw-bottom-full
						tw-left-1/2
						tw--translate-x-1/2
						tw-w-44
						tw-mb-2
						tw-p-3
						tw-bg-black
						tw-text-white
						tw-rounded-xl
						tw-text-sm
						tw-font-medium
						tw-text-center
						tw-shadow-base
						tw-animate-fadein-300
					`}>
					{message}
				</div>
			)}
			{children}
		</div>
	);
}
