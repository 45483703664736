import { ChangeDetectorRef, Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogContentComponent } from './dialog-content.component';

/**
 * This service helps to open and manage modal dialogs that are consistent with
 * our design system. It abstracts the complexity of the Angular Material Dialog
 * API into a simpler interface with common configuration options. This service
 * also allows us to swap Material Dialog out for Angular CDK or another library
 * in the future if needed.
 */
@Injectable()
export class DialogService {
	constructor(private dialog: MatDialog) {}

	/**
	 * Opens a modal dialog with the given content template. This dialog is
	 * consistent with the Dynamic Dialog Modal in our Backpack design system.
	 *
	 * This service wraps the template in a DialogContentComponent, which provides
	 * basic layout and styling for the dialog container. It expects the template
	 * passed in to include a <bp-dialog-header>, <bp-dialog-body>, and
	 * <bp-dialog-footer> (and only those!). Check out the DialogContent in
	 * Storybook for usage examples.
	 *
	 * @template Result - The type of data returned when the dialog is closed.
	 *   This is provided for convenience, but it is not used internally: all
	 *   built-in ways to close the dialog (click overlay, close button) result in
	 *   a void result.
	 * @param dialogContent - The content of the dialog, which should include a
	 *   <bp-dialog-header>, <bp-dialog-body>, and <bp-dialog-footer>.
	 * @returns A reference to the opened dialog instance. This is needed to
	 *   programmatically close the dialog or to subscribe to the afterClosed()
	 */
	openDialog<Result>(dialogContent: TemplateRef<unknown>, changeDetectorRef: ChangeDetectorRef): MatDialogRef<DialogContentComponent, Result> {
		const dialogConfig: MatDialogConfig = {
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-dark-backdrop',
			panelClass: 'bp-dialog-container',
			position: { top: '', bottom: '', left: '', right: '' },
			maxWidth: '100vw',
			maxHeight: '85vh',
			autoFocus: false,
			data: {
				dismiss: () => dialogRef.close(),
				parentChangeDetector: changeDetectorRef,
			},
		};

		const dialogRef = this.dialog.open<DialogContentComponent, void, Result>(DialogContentComponent, dialogConfig);
		dialogRef.componentInstance.content = dialogContent;

		return dialogRef;
	}
}
