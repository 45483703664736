import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ScrollPositionService {
	private scrollPositionHash: any = {};
	scrollToTopSource = new Subject<void>();
	scrollToTop$ = this.scrollToTopSource.asObservable();

	saveComponentScroll(componentName: string, scrollPosition: number) {
		this.scrollPositionHash[componentName] = scrollPosition;
	}

	getComponentScroll(componentName: string) {
		return this.scrollPositionHash[componentName];
	}

	triggerScrollToTop(): void {
		console.log('scroll top triggered');
		this.scrollToTopSource.next(undefined);
	}
}
