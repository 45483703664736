import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { isNaN } from 'lodash';
import { fromEvent, of, Subject } from 'rxjs';
import { catchError, concatMap, filter, mapTo, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HallPass, Request } from '../models';
import { HallPassesService } from '../services/hall-passes.service';
import { RequestsService } from '../services/requests.service';
import { StorageService } from '../services/storage.service';

/*
 * TODO: Restructure component
 *  This component should only tell if the teacher's pin was entered correctly or not
 *  It shouldn't have any request or pass limit logic in here.
 */

@Component({
	selector: 'app-teacher-pin-student',
	templateUrl: './teacher-pin-student.component.html',
	styleUrls: ['./teacher-pin-student.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeacherPinStudentComponent implements OnInit, OnDestroy {
	@Input() request: Request;
	@Input() requestId: number;
	@Input() isStaff: boolean;

	@Output() pinResult = new EventEmitter<HallPass | string>();
	@Output() blurEvent: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('inp', { static: true }) inp: ElementRef;
	@ViewChild('confirmDialogBody') confirmDialogBody: TemplateRef<HTMLElement>;

	incorrect: boolean;
	passLimit: number;
	pin = '';
	attempts = 5;
	destroy$ = new Subject<any>();
	circles = [false, false, false, false];

	constructor(
		private requestService: RequestsService,
		private cdr: ChangeDetectorRef,
		private storage: StorageService,
		private hallpassService: HallPassesService
	) {}

	ngOnInit() {
		if (this.storage.getItem('pinAttempts') && JSON.parse(this.storage.getItem('pinAttempts'))[this.requestId]) {
			this.attempts = JSON.parse(this.storage.getItem('pinAttempts'))[this.requestId];
		}
		this.inp.nativeElement.focus();
		fromEvent<KeyboardEvent>(this.inp.nativeElement, 'keyup')
			.pipe(
				takeUntil(this.destroy$),
				filter((event) => this.isValidKeyPress(event)),
				switchMap((event) => {
					this.cdr.detectChanges();
					const idMatch = +(event.target as any).id === +this.requestId;

					if (event.code === 'Backspace') {
						this.pin = this.pin.slice(0, this.pin.length - 1);
						this.circles[this.pin.length] = false;
						this.cdr.detectChanges();
						return of(null);
					}

					if (this.isNumberKeyPressed(event) && idMatch) {
						this.pin += event.key;

						if (this.pin.length <= 4) {
							this.circles[this.pin.length - 1] = true;
							this.cdr.detectChanges();
						}

						if (this.pin.length === 4) {
							// if WIL isn't enabled, then we should show the override dialog asking the user to confirm overriding the room limit
							// if WIL is enabled, then we should accept the request and let the backend handle the rest

							return this.requestService.checkLimits({ teacher_pin: this.pin }, this.request, this.confirmDialogBody).pipe(
								concatMap((httpBody) => this.requestService.acceptRequest(this.request, httpBody)),
								catchError((err) => {
									if ((err as HttpErrorResponse).error?.conflict_student_ids) {
										this.hallpassService.showEncounterPreventionToast({
											exclusionPass: this.request,
											isStaff: this.isStaff,
										});
										return of('encounter prevention');
									} else if (err.message === 'override cancelled') {
										this.clearPin();
										this.pinResult.emit(null);
									} else {
										return this.handleIncorrectPin();
									}

									return of(null);
								})
							);
						}
					}

					return of(null);
				}),
				tap((data) => {
					if (!data) {
						this.cdr.detectChanges();
					}
				}),
				filter(Boolean)
			)
			.subscribe((data: HallPass | string) => {
				if (data === 'encounter prevention') {
					this.pinResult.emit(data);
					return;
				}

				const storageData = JSON.parse(this.storage.getItem('pinAttempts'));
				if (storageData && storageData[this.requestId] === 0) {
					delete storageData[this.requestId];
					this.storage.setItem('pinAttempts', JSON.stringify({ ...storageData }));
				}
				this.pinResult.emit(data);
			});
	}

	private handleIncorrectPin() {
		this.incorrect = true;
		this.attempts -= 1;
		if (this.storage.getItem('pinAttempts')) {
			this.storage.setItem(
				'pinAttempts',
				JSON.stringify({
					...JSON.parse(this.storage.getItem('pinAttempts')),
					[this.requestId]: this.attempts,
				})
			);
		} else {
			this.storage.setItem('pinAttempts', JSON.stringify({ [this.requestId]: this.attempts }));
		}
		if (this.attempts > 0) {
			this.clearPin();
			this.blur();
		} else {
			return this.requestService.cancelRequest(this.requestId).pipe(mapTo(true));
		}
		this.cdr.detectChanges();
		return of(null);
	}

	private clearPin() {
		setTimeout(() => {
			this.pin = '';
			this.circles = [false, false, false, false];
			this.cdr.detectChanges();
			this.incorrect = false;
		}, 300);
	}

	private isValidKeyPress(event: KeyboardEvent): boolean {
		return (this.isNumberKeyPressed(event) || event.code === 'Backspace') && this.pin.length < 4;
	}

	private isNumberKeyPressed(event: KeyboardEvent): boolean {
		return !isNaN(parseFloat(event.key));
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	blur() {
		this.inp.nativeElement.focus();
	}
}
