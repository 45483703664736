import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconName } from '../../backpack-icons/backpack-icon.component';

export type buttonType = 'white' | 'light' | 'lightest' | 'color' | 'danger';
@Component({
	selector: 'bp-button',
	templateUrl: './button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
	@Input() type: buttonType = 'white';
	@Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
	@Input() disabled = false;
	@Input() title = '';
	/**
	 * backpack-icon name - see backpack-icons module, appears to left of title
	 */
	@Input() leftIcon?: IconName;
	/**
	 * backpack-icon name - see backpack-icons module, appears to right of title
	 */
	@Input() rightIcon?: IconName;
	@Input() width: 'auto' | 'full' = 'auto';
	@Input() shrinkOnPress = false;

	// Icon sizes are currently a first guess, as they're not specified in
	// relation to buttons for our design system. If a design uses a different
	// size than what's here, check with a designer! Then either update Figma, or
	// update this component.
	get iconSize(): string {
		switch (this.size) {
			case 'sm':
				return '12';
			case 'md':
				return '14';
			case 'lg':
				return '16';
			case 'xl':
				return '20';
		}
	}

	get classes(): string {
		let sizeClasses = '';
		switch (this.size) {
			case 'sm':
				sizeClasses = 'tw-text-xs tw-p-2 tw-gap-1';
				break;
			case 'md':
				sizeClasses = 'tw-text-sm tw-p-3 tw-gap-1.5';
				break;
			case 'lg':
				sizeClasses = 'tw-text-base tw-p-4 tw-gap-2';
				break;
			case 'xl':
				sizeClasses = 'tw-text-lg tw-p-5 tw-gap-3';
				break;
		}

		let typeClasses = '';
		switch (this.type) {
			case 'white':
				typeClasses = `tw-text-gray-500 tw-bg-white tw-border tw-border-solid tw-border-gray-300 tw-shadow-sm ${
					this.disabled ? '' : 'hover:tw-bg-gray-100 active:tw-bg-gray-150'
				}`;
				break;
			case 'light':
				typeClasses = `tw-text-gray-500 tw-bg-gray-150 tw-border-none ${this.disabled ? '' : 'hover:tw-bg-gray-200 active:tw-bg-gray-300'}`;
				break;
			case 'lightest':
				typeClasses = `tw-text-gray-500 tw-bg-gray-100  tw-border-none ${this.disabled ? '' : 'hover:tw-bg-gray-150 active:tw-bg-gray-200'}`;
				break;
			case 'color':
				typeClasses = `tw-text-white tw-bg-green-500 tw-border-none tw-shadow-sm ${
					this.disabled ? '' : 'hover:tw-bg-green-600 active:tw-bg-green-700'
				}`;
				break;
			case 'danger':
				typeClasses = `tw-text-white tw-bg-red-500 tw-border-none tw-shadow-sm ${this.disabled ? '' : 'hover:tw-bg-red-600 active:tw-bg-red-700'}`;
				break;
		}

		let sharedClasses = 'tw-appearance-none tw-flex tw-flex-row tw-items-center tw-rounded-lg tw-font-sans tw-font-medium';
		if (this.width === 'full') {
			sharedClasses += ' tw-w-full tw-justify-center';
		}
		if (this.disabled) {
			sharedClasses += ' tw-cursor-default tw-opacity-30';
		} else {
			sharedClasses += ' tw-cursor-pointer';
		}

		if (this.shrinkOnPress) {
			sharedClasses += ' active:tw-scale-backpack-pressed';
		}
		return `bp-button ${sharedClasses} ${sizeClasses} ${typeClasses}`;
	}
}
