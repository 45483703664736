import { z } from 'zod';
import { BaseModel } from './base';

export class ColorProfile extends BaseModel {
	constructor(
		public id: number,
		public title: string,
		public gradient_color: string,
		public solid_color: string,
		public overlay_color: string,
		public pressed_color: string,
		public time_color: string
	) {
		super();
	}

	static fromJSON(JSON: Record<string, any> | null): ColorProfile {
		if (!JSON) {
			return null;
		}

		const id: number = JSON.id,
			title: string = JSON.title,
			gradient_color: string = JSON.gradient_color,
			solid_color: string = JSON.solid_color,
			overlay_color: string = JSON.overlay_color,
			pressed_color: string = JSON.pressed_color,
			time_color: string = JSON.time_color;

		return new ColorProfile(id, title, gradient_color, solid_color, overlay_color, pressed_color, time_color);
	}

	static schema = z
		.object(
			{
				id: z.number(),
				title: z.string(),
				gradient_color: z.string(),
				solid_color: z.string(),
				overlay_color: z.string(),
				pressed_color: z.string(),
				time_color: z.string(),
			},
			{
				message: 'Expected an object while parsing ColorProfile',
			}
		)
		.transform(
			(parsed) =>
				new ColorProfile(
					parsed.id,
					parsed.title,
					parsed.gradient_color,
					parsed.solid_color,
					parsed.overlay_color,
					parsed.pressed_color,
					parsed.time_color
				)
		);

	static fromUnknown(data: unknown) {
		return ColorProfile.schema.safeParse(data);
	}
}
