import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';

@Pipe({
	name: 'asFormArray',
})
export class AsFormArrayPipe implements PipeTransform {
	transform(value: AbstractControl): UntypedFormArray {
		return value as UntypedFormArray;
	}
}
