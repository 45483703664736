<div
	[ngClass]="{
		'to-wrapper-to-date': formState.forLater && formState.formMode.role === 1,
		'to-wrapper': !formState.forLater || formState.formMode.role !== 1
	}">
	<div [ngClass]="headerTransition" #header>
		<div
			class="header-background"
			[attr.data-motion-opacity]="(frameMotion$ | async).direction"
			[style.background-image]="'radial-gradient(circle at 98% 97%, #FFFFFF, #FFFFFF)'"></div>

		<div style="margin-left: 16px" class="back-button back-button-grey" (click)="backToDestinationSelect()">
			<img [src]="'./assets/Back-Button.svg' | resolveAsset" alt="Back to Student Select" />
		</div>

		<div
			*ngIf="!isKioskMode && originPinnable$ | async as originPin"
			class="from-origin"
			[attr.data-motion-translate]="(frameMotion$ | async).direction"
			(click)="backToOriginSelect()">
			<div [style.background]="originPin.getCssGradient()" class="room-icon-container">
				<img class="room-icon" [src]="originPin.icon | resolveAsset" alt="ICON" />
			</div>

			<span class="room-title">{{ formState.data?.direction?.from?.title || 'Pass Origin' }}</span>
			<img [src]="'./assets/Edit (Gray).svg'" alt="Change Origin Icon" />
		</div>
	</div>

	<div class="content-background" [attr.data-motion-opacity]="(frameMotion$ | async).direction"></div>
	<div class="to-content ds-mt-20" [attr.data-motion-translate]="(frameMotion$ | async).direction">
		<div class="location-tables" style="width: 95%">
			<ng-container *ngIf="suggestedPinnables | async as sp">
				<app-location-table-v2
					class="locations"
					[forStaff]="isStaff"
					[forKioskMode]="isKioskMode"
					[selectedStudents]="formState.data.roomStudents ? formState.data.roomStudents : formState.data.selectedStudents"
					[rightHeaderText]="true"
					[inputWidth]="'240px'"
					[hasLocks]="true"
					[forLater]="formState.forLater"
					[showStars]="false"
					[noRightStar]="true"
					[showPicture]="true"
					[showFavorites]="false"
					[invalidLocation]="originLocation?.id"
					[originLocation]="originLocation"
					[searchByTeacherName]="true"
					[placeholder]="'To Where'"
					[dummyString]="'Check your spelling and try again.'"
					[currentPage]="'to'"
					[updatedLocation$]="updatedLocation$"
					(choiceSelected)="locationSelected($event)"
					[passLimitInfo]="formState.passLimitInfo"
					[override]="categoryConfig"
					[suggestedPinnables]="sp"
					[isFolderSearch]="true">
				</app-location-table-v2>
			</ng-container>
		</div>
	</div>
</div>

<ng-template let-alerts="alerts" #confirmDialogBodyVisibility>
	<ng-container *ngFor="let a of alerts">
		<div class="ds-dialog-title">
			<span>{{ a.title }}</span>
		</div>

		<div class="ds-dialog-normal-text ds-mb-10">
			<p style="margin: 10px 0px 0px">{{ a.text }}</p>
		</div>
	</ng-container>
</ng-template>
