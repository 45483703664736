import { Injectable, OnDestroy } from '@angular/core';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { pickTruthy } from 'Util';
import { Location } from '../models/Location';
import { LocationsService } from './locations.service';
import { ScheduleService } from './schedule.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class RoomSwitchService implements OnDestroy {
	// we can emit the room evertime it should be switched
	// one idea is to handle this emission in the period countdown component
	teacherRooms: Location[] = [];
	private manuallySelectedRoom = new BehaviorSubject<Location | undefined>(undefined);
	private destroy$: Subject<void> = new Subject<void>();
	private userSwitchedRoom = false;

	private filteredCurrentPeriod$ = this.scheduleService.currentPeriod$.pipe(
		distinctUntilChanged((prev, curr) => isEqual(prev, curr)), // Emits every time currentPeriod actually changes
		shareReplay({ bufferSize: 1, refCount: true }) // Prevents unnecessary re-executions & memory leaks
	);

	private scheduledRoom: Observable<Location | undefined> = this.filteredCurrentPeriod$.pipe(
		withLatestFrom(this.scheduleService.nextPeriod$), // Get the latest nextPeriod whenever currentPeriod changes
		map(([currentPeriod, nextPeriod]) => {
			//During class time set the current period room
			if (currentPeriod) {
				return this.scheduleSwitchedRooms(currentPeriod.room_id);
			}

			//During passing time set the scheduled room to the next period room
			if (currentPeriod == null && nextPeriod != null) {
				this.userSwitchedRoom = false;
				return this.scheduleSwitchedRooms(nextPeriod.room_id);
			}
			return undefined;
		}),
		startWith(undefined),
		takeUntil(this.destroy$),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	selectedRoom$ = combineLatest([this.scheduledRoom, this.manuallySelectedRoom]).pipe(
		map(([scheduled, manual]) => {
			// if scheduled room is set and user has not manually switched rooms then return scheduled room else return manual room
			return scheduled && !this.userSwitchedRoom ? scheduled : manual;
		}),
		distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
		takeUntil(this.destroy$),
		shareReplay({ bufferSize: 1, refCount: true }) // Ensures multiple subscribers don't trigger new emissions
	);

	//Sets the selected room
	setSelectedRoom(room: Location) {
		if (room) {
			this.manuallySelectedRoom.next(room);
		}
	}

	//Sets the selected room by room id if it matches an assigned room.
	setSelectedRoomById(roomId: number | null) {
		const foundRoom = this.teacherRooms.find((room) => room.id === roomId);
		if (foundRoom) {
			this.manuallySelectedRoom.next(foundRoom);
		}
	}

	//user has manually switched rooms using the room picker
	userSwitchedRooms(room: Location) {
		this.userSwitchedRoom = true;
		this.setSelectedRoom(room);
	}

	// Automatic room change that comes from a period switch
	scheduleSwitchedRooms(roomId: number | null): Location | undefined {
		if (!this.userSwitchedRoom) {
			// this.setSelectedRoomById(roomId);
			const foundRoom = this.teacherRooms.find((room) => room.id === roomId);
			return foundRoom;
		}
		return undefined;
	}

	// Update teacherRooms when there is an change to a location in the teacherRooms array
	updateTeacherRooms(updatedLoc: Location | undefined) {
		if (updatedLoc) {
			const index = this.teacherRooms.findIndex((loc) => loc.id === updatedLoc.id);
			if (index !== -1) {
				this.teacherRooms[index] = updatedLoc;
			}
		}
	}

	constructor(private userService: UserService, private locationService: LocationsService, private scheduleService: ScheduleService) {
		// Potentially retrieve locaion from local storage and then wipe the local storage of the location
		// CODE HERE

		this.userService.userData
			.pipe(
				pickTruthy(),
				takeUntil(this.destroy$),
				switchMap((user) => this.locationService.getLocationsWithTeacherRequest(user).pipe(filter((res: Location[]) => !!res.length))),
				map((locations) => {
					this.teacherRooms = locations;
					if (!this.manuallySelectedRoom.getValue()) {
						this.manuallySelectedRoom.next(locations[0] || undefined);
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		//Just a thought to potentially set local storage on destroy to save the last selected room???
		//locationMap[this.currentSchool.id] = { selected: loc, period_room: loc };
		//this.storage.setMap(StorageKeys.selectedLocationV2, locationMap);
		this.destroy$.next();
		this.destroy$.complete();
	}
}
