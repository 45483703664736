import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { RoundInputComponent } from '../../../../admin/round-input/round-input.component';
import { LocationOverrideConfig, LocationTableV2Component } from '../../../../location-table-v2/location-table-v2.component';
import { Location, PassLimit, Pinnable, User, PassLimitInfo } from '../../../../models';
import { BootstrapService } from '../../../../services/bootstrap.service';
import { FeatureFlagService, FLAGS } from '../../../../services/feature-flag.service';
import { HallPassesService } from '../../../../services/hall-passes.service';
import { LocationsService, SuggestedLocations } from '../../../../services/locations.service';
import { PassLimitService } from '../../../../services/pass-limit.service';
import { CurrentClassAndActivity, ScheduleService } from '../../../../services/schedule.service';
import { ScreenService } from '../../../../services/screen.service';
import { CreateFormService, FrameMotionTransition } from '../../../create-form.service';
import { CreatePassDialogData } from '../../../create-hallpass-forms.component';
import { Navigation } from '../../main-hall-pass-form.component';
import { States } from '../locations-group-container.component';
import { PassLimitDialogComponent, PassLimitOverride, PassLimitOverrideResponse } from '../pass-limit-dialog/pass-limit-dialog.component';

// classOrActivityList create pass flow has this.data.FORM_STATE AND this.formState
// studentSnapshot create pass flow uses this.data.FORM_STATE AND this.formState
// studentPassesFooter create pass flow uses this.formstate
// teacherHomeEmptyState create pass flow uses this.formState
// navbar create pass flow uses this.formstate
// kioskMode create pass flow uses this.data.FORM_STATE
export type CreationFlowType =
	| 'student'
	| 'classOrActivityList'
	| 'studentSnapshot'
	| 'studentPassesFooter'
	| 'teacherHomeEmptyState'
	| 'navbar'
	| 'kioskMode';

@Component({
	selector: 'app-from-to-where',
	templateUrl: './from-to-where.component.html',
	styleUrls: ['./from-to-where.component.scss'],
})
export class FromToWhereComponent implements OnInit, OnDestroy {
	@ViewChild('header', { static: true }) header!: ElementRef<HTMLDivElement>;
	@ViewChild('locationTable') locationTable!: LocationTableV2Component;
	@ViewChild('folderTable') folderTable!: LocationTableV2Component;
	@ViewChild('fromContent') fromContent!: ElementRef;
	@ViewChild('destinationInput') destinationInput!: RoundInputComponent;
	@ViewChild('originInput') originInput!: RoundInputComponent;

	@Input() isStaff = false;
	@Input() userId: number | null = null;

	@Input() formState: Navigation | null = null;

	@Input() studentText: string | boolean;
	@Input() currentPage = 'from';
	@Input() lockOriginLocation = false;
	@Input() lockDestinationLocation = false;

	@Output() selectedOriginLocation = new EventEmitter<Pinnable>();
	@Output() selectedDestinationLocation: EventEmitter<Pinnable> = new EventEmitter<Pinnable>();
	@Output() backButton: EventEmitter<Navigation> = new EventEmitter<Navigation>();

	frameMotion$: BehaviorSubject<FrameMotionTransition> = this.formService.getFrameMotionDirection();
	placeholder = '';

	headerTransition = {
		'from-header': true,
		'from-header_animation-back': false,
	};
	studentSelected: User | undefined;

	numSelectedStudents: number | undefined;
	numSelectedStudentsText = '';
	originFocused = true;
	hasSelectedPinnableValue = false;
	destinationFocused = false;
	originSearchValue = '';
	destinationSearchValue = '';
	originLocation: Location | undefined;
	destinationLocation: Location | undefined;
	private originPinnable: Pinnable | null = null;
	originPinnableBackground: SafeStyle | null = null;
	originPinnableIcon = '';
	destinationPinnableBackground: SafeStyle | null = null;
	destinationPinnableIcon = '';
	passLimits: { [id: number]: PassLimit } | null = null;
	suggestedPinnables: Observable<Pinnable[]> = of([]);
	private destinationPinnable: Pinnable | null = null; // only defined when a destination is selected
	pinnableDimensions = this.screenService.getPinnableDimensions();
	private pinnables: Pinnable[] = [];
	categoryConfig: LocationOverrideConfig | null = null;
	groupName: string | undefined;
	liveOrigin: Location | null = null;
	updatedLocation$: Observable<Location> | null = null;

	/**
	 * This subject gets triggered when the user manually selects an origin. It serves to
	 * overwrite the auto-selected origin from the student's schedule and prevents the
	 * manually-selected origin from being overwritten by processAgendas function
	 */
	private originManuallySelected$: Subject<void> = new Subject<void>();
	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<FromToWhereComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data:
			| {
					adminSelectedStudent?: User;
					FORM_STATE?: Navigation;
			  }
			| Partial<CreatePassDialogData>,
		private formService: CreateFormService,
		public screenService: ScreenService,
		private featureFlagService: FeatureFlagService,
		private locationsService: LocationsService,
		private pinnableService: HallPassesService,
		private studentPassLimits: PassLimitService,
		private sanitizer: DomSanitizer,
		private locationService: LocationsService,
		private scheduleService: ScheduleService,
		public bootstrapService: BootstrapService
	) {}

	ngOnInit(): void {
		this.pinnableService.pinnables$.pipe(takeUntil(this.destroy$)).subscribe((pinnables) => {
			this.pinnables = pinnables;
		});
		// Sometimes this component has the formstate in the this.formState input,
		// and sometimes it has it in the MAT_DIALOG_DATA data object (and sometimes both).
		// To make an iterative change, I am going to use the this.formState input and populate it from the data object
		// if it's not already defined.
		if (this.data?.FORM_STATE && !this.formState) {
			this.formState = this.data.FORM_STATE;
		}
		this.numSelectedStudents = this.formState?.data?.selectedStudents?.length;
		this.groupName = this.numSelectedStudents === this.formState?.data?.selectedGroup?.users.length ? this.formState?.data?.selectedGroup?.title : '';
		if (this.formState?.data?.selectedStudents?.length) {
			this.studentSelected = this.formState.data.selectedStudents[0];
		}
		this.placeholder = 'Select where your student(s) are';
		this.setOrigin();
		if (this.formState?.data?.direction?.to) {
			this.destinationLocation = this.formState.data.direction.to;
		}
		this.setPinnableData(this.pinnables, this.originLocation, this.destinationLocation);

		this.frameMotion$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
			switch (v.direction) {
				case 'back':
					this.headerTransition['from-header'] = false;
					this.headerTransition['from-header_animation-back'] = true;
					break;
				case 'forward':
					this.headerTransition['from-header'] = true;
					this.headerTransition['from-header_animation-back'] = false;
					break;
				default:
					this.headerTransition['from-header'] = true;
					this.headerTransition['from-header_animation-back'] = false;
			}
		});

		this.locationsService.getPassLimitRequest();
		this.locationsService.pass_limits_entities$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
			this.passLimits = res;
		});

		this.updatedLocation$ = this.formService.getUpdatedChoice();
		this.numSelectedStudentsText = this.getStudentNames();
	}

	get isOriginWaitInLineEnabled(): boolean {
		return this.featureFlagService.isFeatureEnabled(FLAGS.WaitInLine) && this.featureFlagService.isFeatureEnabledV2(FLAGS.OriginWaitInLine);
	}

	private getPassCreationFlowType(): CreationFlowType {
		if (!this.isStaff && !this.formState?.kioskMode) {
			return 'student';
		}
		if (this.formState?.kioskMode) {
			return 'kioskMode';
		}
		if (this.data?.adminSelectedStudent && this.formState?.data?.selectedStudents?.length === 1 && this.formState?.openedFrom === 'navbar') {
			return 'studentSnapshot';
		}
		if (this.data?.adminSelectedStudent && !this.data.FORM_STATE) {
			return 'studentPassesFooter';
		}
		if (!!this.data?.FORM_STATE && this.formState?.data?.selectedStudents?.length === 1 && this.formState?.data?.direction?.from) {
			return 'classOrActivityList';
		}
		if (!this.data.FORM_STATE && this.formState?.data?.selectedStudents?.length === 1 && !this.formState.openedFrom) {
			return 'teacherHomeEmptyState';
		}
		return 'navbar';
	}

	private setOrigin(): void {
		const flowType: CreationFlowType = this.getPassCreationFlowType();
		let obs: Observable<CurrentClassAndActivity>;
		switch (flowType) {
			case 'kioskMode':
				if ((this.data as Partial<CreatePassDialogData>)?.kioskModeRoom) {
					this.setLocationData((this.data as Partial<CreatePassDialogData>).kioskModeRoom!);
				}
				break;
			case 'student':
				obs = combineLatest([
					this.scheduleService.currentAgendaItems$.pipe(startWith([])),
					this.scheduleService.activeActivityAgendaItemsForStudents$,
				]).pipe(
					map(([classAgendaItems, activityAgendaItems]) => {
						return { currentClass: classAgendaItems[0], currentActivity: activityAgendaItems[0] };
					})
				);
				this.processAgendas(obs);
				break;
			case 'classOrActivityList':
				if (this.formState?.data?.selectedStudents) {
					this.bootstrapService
						.loadDataForOther(this.formState?.data?.selectedStudents[0].id)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) => {
							this.processAgendas(this.scheduleService.otherCurrentAgendaItems$);
						});
				}
				break;
			case 'studentSnapshot':
			case 'studentPassesFooter':
				if (this.data.adminSelectedStudent) {
					this.bootstrapService
						.loadDataForOther(this.data.adminSelectedStudent.id)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) => {
							this.processAgendas(this.scheduleService.otherCurrentAgendaItems$);
						});
				}
				break;
			case 'teacherHomeEmptyState':
			case 'navbar': {
				let directionFrom: Location | undefined;
				if (
					this.featureFlagService.isFeatureEnabledV2(FLAGS.ActivitiesWithoutPasses) &&
					this.formState?.data?.selectedStudents?.length === 1 &&
					this.formState.data.selectedStudents[0].current_activities &&
					this.formState.data.selectedStudents[0].current_activities.length > 0
				) {
					this.locationsService
						.location$(this.formState.data.selectedStudents[0].current_activities[0].location_id)
						.pipe(
							filter((l) => !!l),
							take(1),
							takeUntil(this.destroy$)
						)
						.subscribe((directionFrom) => {
							this.setLocationData(directionFrom!);
							this.initOriginDestinationFocusState();
							this.setSuggestedRooms();
						});
					return;
				} else if (
					this.formState?.data?.selectedStudents?.length === 1 &&
					this.formState.data.selectedStudents[0].current_classes &&
					this.formState.data.selectedStudents[0].current_classes.length > 0
				) {
					directionFrom = this.formState.data.selectedStudents[0].current_classes[0].room;
				}
				if (directionFrom) {
					this.setLocationData(directionFrom);
				}
				break;
			}
		}

		this.initOriginDestinationFocusState();
		this.setSuggestedRooms();
	}

	private setLocationData(origin: Location): void {
		this.originLocation = origin;
		this.liveOrigin = origin;
		if (this.formState) {
			this.formState.data.direction.from = origin;
		}
		this.destinationLocation = this.formState?.data?.direction?.to;
		this.setPinnableData(this.pinnables, this.originLocation, this.destinationLocation);
	}

	private overwritePinWithNewLocation(pin: Pinnable, loc: Location): Pinnable {
		const clonedPin = cloneDeep(pin);
		clonedPin.location = loc;
		clonedPin.title = loc.title;
		clonedPin.type = 'location';
		clonedPin.id = null;
		return clonedPin;
	}

	private setSuggestedRooms(): void {
		if (this.originLocation) {
			const studentPassLimit$ =
				this.isStaff && !this.formState?.kioskMode ? of<PassLimitInfo>({ showPasses: false }) : this.studentPassLimits.passLimitChanges$();

			this.suggestedPinnables = combineLatest(
				this.locationsService.getSuggestedRooms(this.formState!.data!.direction!.from!.id),
				this.pinnableService.pinnables$.pipe(take(1)),
				studentPassLimit$
			).pipe(
				filter(([sp, ap, pli]) => {
					return !!sp;
				}),
				takeUntil(this.destroy$),
				map(([response, allPins, passLimitInfo]: [SuggestedLocations, Pinnable[], PassLimitInfo]) => {
					const pins = response.suggested_locations.map((loc) => {
						return allPins.find((p) => p?.location?.id === loc.location.id) || Pinnable.fromAugmentedLocation(loc);
					});
					const { showPasses, current } = passLimitInfo;
					return pins.map((p) => {
						const newPinnable = cloneDeep(p);
						newPinnable.location.restricted = p.location.restricted || (!p.ignore_students_pass_limit && showPasses && current === 0);
						return newPinnable;
					});
				})
			);
		} else {
			this.suggestedPinnables = of([]);
		}
	}

	onSearch(value: string): void {
		if (this.categoryConfig) {
			this.folderTable?.onSearch(value);
		} else {
			this.locationTable?.onSearch(value);
		}
	}

	onOriginFocus(type: string): void {
		if (type === 'origin') {
			this.originFocused = true;
			this.originInput.input.nativeElement.value = '';
			this.categoryConfig = null;
			this.destinationFocused = false;
			this.originSearchValue = '';
			this.destinationSearchValue = '';
			this.onSearch('');
		}
	}

	onDestinationFocus(type: string): void {
		if (type === 'destination') {
			this.destinationFocused = true;
			if (this.destinationInput) {
				this.destinationInput.input.nativeElement.value = '';
			}
			this.hasSelectedPinnableValue = false;
			this.originFocused = false;
			this.originSearchValue = '';
			this.destinationSearchValue = '';
			this.onSearch('');
		}
	}

	ngOnDestroy(): void {
		this.originManuallySelected$.complete();
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	originLocationSelected(location: Location): void {
		this.originManuallySelected$.next(undefined);
		this.originLocation = location;
		this.formState!.data!.direction!.from = this.originLocation;
		this.setSuggestedRooms();
		this.setPinnableData(this.pinnables, this.originLocation, this.destinationLocation);
		if (this.originPinnable) {
			this.selectedOriginLocation.emit(this.originPinnable);
		}

		if (this.destinationLocation) {
			// advance form to next componet
			// emit chosen location
			const emitter = () => {
				this.selectedDestinationLocation.emit(this.destinationPinnable!);
			};
			// students go forward
			if (!this.isStaff) {
				this.formService.nextStep(this.formState!, true, emitter);
				return;
			}
			this.formService.checkRoomVisibility(this.formState!, true, location, emitter).pipe(takeUntil(this.destroy$)).subscribe();
		} else {
			this.originFocused = false;
			this.destinationFocused = true;
			this.hasSelectedPinnableValue = false;
		}
	}

	back(): void {
		if (!this.isStaff && !this.formState?.forLater) {
			this.formService.setFrameMotionDirection('disable');
			this.formService.compressableBoxController.next(true);
		} else {
			this.formService.compressableBoxController.next(false);
			this.formService.setFrameMotionDirection('back');
		}
		//empty out previous selections
		if (this.formState?.data?.direction?.from) {
			this.formState.data.direction.from = undefined;
		}
		if (this.formState?.data?.direction?.to) {
			this.formState.data.direction.to = undefined;
		}
		if (this.formState?.data?.direction?.pinnable) {
			this.formState.data.direction.pinnable = undefined;
		}
		if (this.formState?.data?.selectedGroup) {
			this.formState.data.selectedGroup = undefined;
		}
		setTimeout(() => {
			if (this.formState) {
				if (this.formState.forLater || this.formState.missedRequest) {
					this.formState.previousState = !this.formState.forLater ? States.FromToWhere : States.from;
					this.formState.previousStep = 3;
					this.formState.step = 1;
					this.formState.state = !this.formState.forLater ? States.FromToWhere : States.from;
					this.formState.previousStep = 3;
				} else if (!!this.studentText && this.formState.state === States.from) {
					this.formState.previousState = States.from;
					this.formState.step = 2;
					this.formState.state = States.from;
					this.formState.previousStep = 3;
				} else if (!!this.studentText && this.formState.state === States.FromToWhere) {
					this.formState.previousState = States.FromToWhere;
					this.formState.step = 2;
					this.formState.state = States.FromToWhere;
					this.formState.previousStep = 3;
				} else if (this.formState.previousState === States.restrictedTarget) {
					this.formState.previousState = undefined;
					this.formState.state = States.restrictedTarget;
					this.formState.step = 0;
				} else {
					this.formState.step = 0;
				}
				this.formState.previousState = !this.formState.forLater ? States.FromToWhere : States.from;

				this.backButton.emit(this.formState);
			}
		}, 20);
	}

	private getStudentNames(): string {
		const studentNames: string[] = this.formState?.data?.selectedStudents?.map((student) => student.display_name) || [];
		const combinedNames: string = studentNames.join(', ');

		// Get the width of the text element containing the student names
		const textElement: HTMLElement = document.createElement('span');
		textElement.style.fontSize = '14px';
		textElement.style.fontWeight = '500';
		textElement.style.visibility = 'hidden';
		textElement.style.position = 'absolute';
		textElement.style.whiteSpace = 'nowrap';
		textElement.innerText = combinedNames;
		document.body.appendChild(textElement);
		const width: number = textElement.offsetWidth;
		document.body.removeChild(textElement);

		// Check if the width exceeds 280px
		if (width > 280) {
			// Return a different string indicating the number of students
			return `${studentNames.length} students`;
		} else {
			// Return the combined student names
			return combinedNames;
		}
	}

	suggestedRoomSelected(pin: Pinnable): void {
		if (pin.type === 'location') {
			this.destLocationSelected(pin.location);
		} else {
			console.warn('Unexpected pinnable selected: Suggested Pinnable had type category', pin);
		}
	}

	async destLocationSelected(destinationLocation: Location): Promise<void> {
		this.setDestinationVariables(destinationLocation);
		if (this.originLocation) {
			// only students
			const proceedWithPassCreation = await this.canOverrideRoomDestinationLimit(destinationLocation);
			if (!proceedWithPassCreation) {
				return;
			}
			const emitter = () => {
				this.selectedDestinationLocation.emit(this.destinationPinnable!);
			};
			if (this.formState) {
				this.formService
					.checkRoomVisibility(this.formState, false, destinationLocation, emitter)
					.pipe(takeUntil(this.destroy$))
					.subscribe((visible) => {
						if (!visible) {
							this.unsetDestinationVariables();
						}
					});
			}
		} else {
			this.destinationFocused = false;
			this.originFocused = true;
			this.categoryConfig = null;
		}
	}

	/**
	 * setDestinationVariables populates all state respective to a destination according to
	 * a provided Location. This function does not check or verify the Location, so the calling function
	 * must ensure the Location is not null.
	 */
	private setDestinationVariables(destinationLocation: Location): void {
		this.destinationLocation = destinationLocation;
		this.formState!.data!.direction!.to = this.destinationLocation;
		this.setPinnableData(this.pinnables, this.originLocation, this.destinationLocation);
		this.hasSelectedPinnableValue = true;
	}

	/**
	 * unsetDestinationVariables marks all state respective to a destination as null. It's
	 * intended to be called when unselecting a destination during pass creation.
	 * If you're running into null errors during pass creation regarding a destination,
	 * it's possible this function was called when it shouldn't have been.
	 */
	private unsetDestinationVariables(): void {
		this.destinationPinnable = null;
		this.destinationLocation = undefined;
		this.destinationPinnableBackground = null;
		this.destinationPinnableIcon = '';
		if (this.formState?.data?.direction?.to) {
			this.formState.data.direction.to = undefined;
		}
		this.hasSelectedPinnableValue = false;
	}

	private async canOverrideRoomDestinationLimit(selection: Location): Promise<boolean | undefined> {
		if (this.featureFlagService.isFeatureEnabled(FLAGS.WaitInLine)) {
			// move forward to wait in line card
			this.forwardAndEmit();
			return true;
		}

		const passLimit = this.passLimits && this.passLimits[+selection.id] ? this.passLimits[+selection.id] : null;
		if (!passLimit?.max_passes_to_active) {
			this.forwardAndEmit();
			return true;
		}

		const capacityAfterSelection =
			(!this.isStaff ? 1 : this.formState?.data?.selectedStudents?.length || 0) + (passLimit.max_passes_to_active ? passLimit?.to_count || 0 : 0);
		const overLimit = capacityAfterSelection > passLimit.max_passes_to;

		if (!overLimit) {
			this.forwardAndEmit();
			return true;
		}

		const studentRoomLimitReachedConfig: MatDialogConfig<Partial<PassLimitOverride>> = {
			panelClass: 'overlay-dialog',
			backdropClass: 'custom-backdrop',
			width: '450px',
			disableClose: true,
			data: {
				isStaff: this.isStaff,
				isKiosk: this.formState?.kioskMode,
				roomPassLimit: passLimit.max_passes_to,
				currentCount: passLimit.to_count,
				studentCount: this.formState?.data?.selectedStudents?.length || 0,
			},
		};

		// PassLimitDialogComponent is intended to always return Partial<PassLimitOverrideResponse> when
		// the dialog is closed. The return value is never intended to be null or undefined.
		const dialogResponse: Partial<PassLimitOverrideResponse> = await this.dialog
			.open(PassLimitDialogComponent, studentRoomLimitReachedConfig)
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.toPromise();

		if (dialogResponse.cancelPassCreationDialog) {
			this.dialogRef.close();
			this.unsetDestinationVariables();
			return false;
		}

		if (!dialogResponse.override) {
			this.unsetDestinationVariables();
		}

		return dialogResponse.override;
	}

	private forwardAndEmit(): void {
		this.formService.setFrameMotionDirection('disable');
		this.formService.scalableBoxController.next(true);
		setTimeout(() => {
			this.selectedDestinationLocation.emit(this.destinationPinnable!);
		}, 100);
	}

	checkPinnable(forTeacherRooms: boolean, pinnable: Pinnable): boolean {
		// hide kiosk mode room
		if (!forTeacherRooms) {
			if (this.formState?.kioskMode) {
				return pinnable.location && this.originLocation ? this.isValidPinnable(pinnable) : true;
			} else {
				return true;
			}
		} else {
			if (this.formState?.kioskMode) {
				return pinnable.location && this.originLocation ? pinnable.location.id != this.originLocation?.id : true;
			} else {
				return true;
			}
		}
	}

	isValidPinnable(pinnable: Pinnable): boolean {
		// === and ids are dangerous as ids are numeric strings or numbers
		// using == will pose its own dangers
		// if (pinnable.location.id == this.location.id)
		// as we know ids are numbers we cast them to pure numbers
		if (this.originLocation && +pinnable.location.id === +this.originLocation.id) return false;
		if (this.isStaff && !this.formState?.kioskMode) return true;

		const forNowCondition =
			!this.formState?.forLater &&
			pinnable.location.restricted &&
			pinnable.location.request_mode === 'all_teachers_in_room' &&
			pinnable.location.request_send_origin_teachers &&
			!this.originLocation?.teachers.length;

		const forLaterCondition =
			this.formState?.forLater &&
			pinnable.location.scheduling_restricted &&
			pinnable.location.scheduling_request_mode === 'all_teachers_in_room' &&
			pinnable.location.scheduling_request_send_origin_teachers &&
			!this.originLocation?.teachers.length;

		return !(forNowCondition || forLaterCondition);
	}

	async pinnableSelected(pin: Pinnable): Promise<void> {
		this.destinationPinnable = pin;
		if (pin.type === 'category') {
			const locationConfig = {
				category: pin.category,
			};
			this.categoryConfig = {
				location$: this.locationService.getLocationsWithConfigV2(locationConfig),
				searchConfig: locationConfig,
				headerText: pin.title,
			};
			this.frameMotion$.pipe(takeUntil(this.destroy$)).subscribe((v: FrameMotionTransition) => {
				switch (v.direction) {
					case 'back':
						this.headerTransition['from-header'] = false;
						this.headerTransition['from-header_animation-back'] = true;
						break;
					case 'forward':
						this.headerTransition['from-header'] = true;
						this.headerTransition['from-header_animation-back'] = false;
						break;
					default:
						this.headerTransition['from-header'] = true;
						this.headerTransition['from-header_animation-back'] = false;
				}
			});
			// When we click on a folder, we need to refocus the destination input.
			this.destinationInput.input.nativeElement.focus();
			return;
		} else {
			await this.destLocationSelected(pin.location);
		}
	}

	scrollToTop(): void {
		if (this.fromContent?.nativeElement) {
			this.fromContent.nativeElement.scrollTop = 0;
		}
	}

	private setPinnableData(pinnables: Pinnable[], originLocation?: Location, destinationLocation?: Location): void {
		let originPinnable: Pinnable | undefined;
		let destinationPinnable: Pinnable | undefined;
		if (pinnables) {
			originPinnable =
				pinnables.find((p) => p?.location?.id === originLocation?.id) || pinnables.find((p) => p?.category === originLocation?.category);
			destinationPinnable =
				pinnables.find((p) => p?.location?.id === destinationLocation?.id) || pinnables.find((p) => p?.category === destinationLocation?.category);
		}

		if (originPinnable?.color_profile) {
			const gradient: string[] = originPinnable?.color_profile?.gradient_color.split(',');
			this.originPinnableBackground = this.sanitizer.bypassSecurityTrustStyle(
				'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')'
			);
		}

		if (originPinnable?.icon) {
			this.originPinnableIcon = originPinnable.icon;
		}

		if (destinationPinnable && destinationLocation) {
			this.destinationPinnable = this.overwritePinWithNewLocation(destinationPinnable, destinationLocation);
		}

		if (originPinnable && originLocation) {
			this.originPinnable = this.overwritePinWithNewLocation(originPinnable, originLocation);
		}

		if (destinationPinnable?.color_profile) {
			const gradient: string[] = destinationPinnable?.color_profile?.gradient_color.split(',');
			this.destinationPinnableBackground = this.sanitizer.bypassSecurityTrustStyle(
				'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')'
			);
		}

		if (destinationPinnable?.icon) {
			this.destinationPinnableIcon = destinationPinnable.icon;
		}
	}

	folderBack(): void {
		this.categoryConfig = null;
		this.destinationSearchValue = '';
		this.destinationInput.input.nativeElement.value = '';
		this.destinationInput.input.nativeElement.focus();
	}

	// Method to process agendas after loading data for other
	private processAgendas(agendas$: Observable<CurrentClassAndActivity>) {
		agendas$
			.pipe(
				filter((agendas) => !!agendas && (!!agendas.currentClass || !!agendas.currentActivity)),
				take(1),
				takeUntil(this.destroy$),
				switchMap((agendas) => {
					if (this.featureFlagService.isFeatureEnabledV2(FLAGS.ActivitiesWithoutPasses) && agendas.currentActivity?.activity?.location_id) {
						return this.locationsService.location$(agendas.currentActivity.activity.location_id).pipe(
							filter((l) => !!l),
							take(1)
						);
					}
					if (agendas.currentClass?.class.room) {
						return of(agendas.currentClass?.class.room);
					}
					return EMPTY;
				})
			)
			.subscribe((location: Location | undefined) => {
				if (location) {
					this.setLocationData(location);
				}
				this.setSuggestedRooms();
				this.initOriginDestinationFocusState();
			});
		this.initOriginDestinationFocusState();
	}

	private initOriginDestinationFocusState(): void {
		if (this.originLocation && !this.lockDestinationLocation) {
			this.originFocused = false;
			this.destinationFocused = true;
		} else if (this.destinationLocation && !this.lockOriginLocation) {
			this.originFocused = true;
			this.destinationFocused = false;
		}
	}
}
