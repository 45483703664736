<div
	[class]="'tw-flex tw-flex-col tw-min-h-66 ' + (displayData.classes ?? '')"
	[ngClass]="{ 'tw-pb-7': !displayData.displayModalFooter }"
	#modalContainer>
	<sp-dynamic-dialog-header
		#modalHeader
		[icon]="displayData.icon"
		[headerText]="displayData.headerText"
		[showCloseIcon]="displayData.showCloseIcon ?? false"
		[subHeaderTemplate]="displayData.subHeaderTemplate"
		[scrolled]="scrolled"
		(dialogClose)="dialogRef.close('secondary')">
	</sp-dynamic-dialog-header>
	<!-- BODY -->
	<div
		class="tw-flex tw-grow tw-flex-col tw-pr-7"
		[ngClass]="{ 'tw-overflow-auto': hasScroll }"
		#modalBodyContainer
		[style.height]="modalBodyContainerHeightStr"
		[style.padding-left]="displayData?.icon ? '85px' : '28px'"
		(scroll)="onScroll($event)">
		<ng-template
			[ngTemplateOutlet]="displayData.modalBody"
			[ngTemplateOutletContext]="displayData?.templateData"
			*ngIf="displayData?.modalBody"></ng-template>
	</div>
	<!-- FOOTER -->
	<div
		*ngIf="displayData.displayModalFooter"
		class="tw-flex tw-items-center tw-p-6 tw-pt-5.75"
		[ngClass]="{
			'tw-justify-between': isWizard || displayData?.leftFooterTemplate,
			'tw-justify-end': !isWizard && !displayData?.leftFooterTemplate
		}"
		[ngStyle]="{ 'border-top': hasScroll ? '1px solid #D4D9E2' : 'none' }"
		#modalFooter>
		<p *ngIf="isWizard" class="tw-m-0 tw-text-gray-500 tw-font-normal tw-text-sm">Step {{ stepIndex + 1 }} of {{ data.steps.length }}</p>
		<div class="tw-m-0 tw-text-gray-500 tw-font-normal tw-text-sm">
			<ng-template [ngTemplateOutlet]="displayData.leftFooterTemplate" *ngIf="displayData?.leftFooterTemplate"></ng-template>
		</div>
		<div class="tw-flex tw-justify-end tw-gap-2 tw-items-center">
			<app-gradient-button
				[gradient]="displayData.secondaryButtonGradientBackground"
				[textColor]="displayData.secondaryButtonTextColor"
				[disabled]="displayData.disableSecondaryButton"
				*ngIf="displayData?.secondaryButtonLabel"
				(buttonClick)="onSecondaryButtonClick()">
				<div>
					{{ displayData.secondaryButtonLabel }}
				</div>
			</app-gradient-button>
			<app-gradient-button
				*ngIf="displayData?.primaryButtonLabel"
				customToolTip
				[contentTemplate]="displayData?.primaryButtonTooltip"
				[positionStrategy]="{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'top', offsetY: 35 }"
				(buttonClick)="onPrimaryButtonClick()"
				[disabled]="data?.disablePrimaryButton"
				[customShadow]="'0px 2px 10px 0px #10141833'"
				[gradient]="displayData.primaryButtonGradientBackground ? displayData.primaryButtonGradientBackground : null"
				[textColor]="displayData.primaryButtonTextColor ? displayData.primaryButtonTextColor : null">
				<div>
					{{ displayData.primaryButtonLabel }}
				</div>
			</app-gradient-button>
		</div>
	</div>
</div>
