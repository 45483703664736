import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-textarea',
	templateUrl: './app-textarea.component.html',
	styleUrls: ['./app-textarea.component.scss'],
})
export class AppTextareaComponent implements AfterViewInit {
	@Input() width = '200px';
	@Input() height = '70px';
	@Input() placeholder = '';
	@Input() focused = false;
	@Input() control: UntypedFormControl | undefined;
	@Input() label = '';
	@Input() maxLength = 10000;
	@Input() resize: 'resize' | 'none' | 'vertical' | 'horizontal' = 'resize';

	@ViewChild('textArea') textArea: ElementRef | undefined;

	hovered = false;

	ngAfterViewInit(): void {
		if (this.focused) {
			this.textArea?.nativeElement.focus();
		}
	}
}
