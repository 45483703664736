import { createAction, props } from '@ngrx/store';
import { SchoolActivityInstance, SchoolActivityState } from '../../../models';
import {
	CreateSchoolActivityInstanceReq,
	SchoolActivityInstancesForPeriodReq,
	SchoolActivityInstancesReq,
} from '../../../services/school-activity.service';

export enum SchoolActivityInstancesActionTypes {
	GetSchoolActivityInstances = '[SchoolActivities] Get GetSchoolActivityInstances',
	GetSchoolActivityInstancesSuccess = '[SchoolActivities] Get GetSchoolActivityInstances Success',
	GetSchoolActivityInstancesFailure = '[SchoolActivities] Get GetSchoolActivityInstances Failure',

	GetSchoolActivityInstancesForFlexPeriod = '[SchoolActivities] Get GetSchoolActivityInstancesForFlexPeriod',
	GetSchoolActivityInstancesForFlexPeriodSuccess = '[SchoolActivities] Get GetSchoolActivityInstancesForFlexPeriod Success',
	GetSchoolActivityInstancesForFlexPeriodFailure = '[SchoolActivities] Get GetSchoolActivityInstancesForFlexPeriod Failure',

	SelectActivityInstance = '[SchoolActivities] Select SchoolActivityInstance',
	ClearActivityInstance = '[SchoolActivities] Clear SchoolActivityInstance',

	CreateSchoolActivityInstance = '[SchoolActivities] Create SchoolActivityInstance',
	CreateSchoolActivityInstanceSuccess = '[SchoolActivities] Create SchoolActivityInstance Success',
	CreateSchoolActivityInstanceFailure = '[SchoolActivities] Create SchoolActivityInstance Failure',

	UpdateSchoolActivityInstance = '[SchoolActivities] Update SchoolActivityInstance',
	UpdateSchoolActivityInstanceSuccess = '[SchoolActivities] Update SchoolActivityInstance Success',
	UpdateSchoolActivityInstanceFailure = '[SchoolActivities] Update SchoolActivityInstance Failure',

	RemoveSchoolActivityInstance = '[SchoolActivities] Remove SchoolActivityInstance',
	RemoveSchoolActivityInstanceSuccess = '[SchoolActivities] Remove SchoolActivityInstance Success',
	RemoveSchoolActivityInstanceFailure = '[SchoolActivities] Remove SchoolActivityInstance Failure',

	RemoveAllSchoolActivityInstances = '[SchoolActivities] Remove All SchoolActivityInstance',
	RemoveAllSchoolActivityInstancesSuccess = '[SchoolActivities] Remove All SchoolActivityInstance Success',
	RemoveAllSchoolActivityInstancesFailure = '[SchoolActivities] Remove All SchoolActivityInstance Failure',

	AddSchoolActivityInstance = '[SchoolActivities] Add SchoolActivityInstance',
	AddSchoolActivityInstanceSuccess = '[SchoolActivities] Add SchoolActivityInstance Success',
	AddSchoolActivityInstanceFailure = '[SchoolActivities] Add SchoolActivityInstance Failure',

	CreateSchoolActivityInstancesBulk = '[SchoolActivities] Create SchoolActivityInstancesBulk',
	CreateSchoolActivityInstancesBulkSuccess = '[SchoolActivities] Create SchoolActivityInstancesBulk Success',
	CreateSchoolActivityInstancesBulkSuccessV2 = '[SchoolActivities] Create SchoolActivityInstancesBulk Success V2',
	CreateSchoolActivityInstancesBulkFailure = '[SchoolActivities] Create SchoolActivityInstancesBulk Failure',
}

export const GetSchoolActivityInstancesAction = createAction(
	SchoolActivityInstancesActionTypes.GetSchoolActivityInstances,
	props<{ req: SchoolActivityInstancesReq }>()
);

export const GetSchoolActivityInstancesSuccessAction = createAction(
	SchoolActivityInstancesActionTypes.GetSchoolActivityInstancesSuccess,
	props<{ instances: SchoolActivityInstance[] }>()
);

export const GetSchoolActivityInstancesFailureAction = createAction(
	SchoolActivityInstancesActionTypes.GetSchoolActivityInstancesFailure,
	props<{ error: string }>()
);

export const GetSchoolActivityInstancesForFlexPeriodAction = createAction(
	SchoolActivityInstancesActionTypes.GetSchoolActivityInstancesForFlexPeriod,
	props<{ req: SchoolActivityInstancesForPeriodReq }>()
);

export const GetSchoolActivityInstancesForFlexPeriodSuccessAction = createAction(
	SchoolActivityInstancesActionTypes.GetSchoolActivityInstancesForFlexPeriodSuccess,
	props<{ instances: SchoolActivityInstance[] }>()
);

export const GetSchoolActivityInstancesForFlexPeriodFailureAction = createAction(
	SchoolActivityInstancesActionTypes.GetSchoolActivityInstancesForFlexPeriodFailure,
	props<{ error: string }>()
);

export const SelectActivityInstanceAction = createAction(SchoolActivityInstancesActionTypes.SelectActivityInstance, props<{ instanceId: number }>());

export const ClearSelectedActivityInstanceAction = createAction(SchoolActivityInstancesActionTypes.ClearActivityInstance);

export const CreateSchoolActivityInstanceAction = createAction(
	SchoolActivityInstancesActionTypes.CreateSchoolActivityInstance,
	props<{ start: Date; end: Date; activityId: number; state: SchoolActivityState }>()
);

export const CreateSchoolActivityInstanceSuccessAction = createAction(
	SchoolActivityInstancesActionTypes.CreateSchoolActivityInstanceSuccess,
	props<{ instance: SchoolActivityInstance }>()
);

export const CreateSchoolActivityInstanceFailureAction = createAction(
	SchoolActivityInstancesActionTypes.CreateSchoolActivityInstanceFailure,
	props<{ error: string }>()
);

export const UpdateSchoolActivityInstanceAction = createAction(
	SchoolActivityInstancesActionTypes.UpdateSchoolActivityInstance,
	props<{ instance: SchoolActivityInstance }>()
);

export const UpdateSchoolActivityInstanceSuccessAction = createAction(
	SchoolActivityInstancesActionTypes.UpdateSchoolActivityInstanceSuccess,
	props<{ instance: SchoolActivityInstance }>()
);

export const UpdateSchoolActivityInstanceFailureAction = createAction(
	SchoolActivityInstancesActionTypes.UpdateSchoolActivityInstanceFailure,
	props<{ error: string }>()
);

export const RemoveSchoolActivityInstanceAction = createAction(
	SchoolActivityInstancesActionTypes.RemoveSchoolActivityInstance,
	props<{ instanceId: number }>()
);

export const RemoveSchoolActivityInstanceSuccessAction = createAction(
	SchoolActivityInstancesActionTypes.RemoveSchoolActivityInstanceSuccess,
	props<{ instanceId: number }>()
);

export const RemoveSchoolActivityInstanceFailureAction = createAction(
	SchoolActivityInstancesActionTypes.RemoveSchoolActivityInstanceFailure,
	props<{ error: string }>()
);

export const RemoveAllSchoolActivityInstancesAction = createAction(SchoolActivityInstancesActionTypes.RemoveAllSchoolActivityInstances);

export const RemoveAllSchoolActivityInstancesSuccessAction = createAction(SchoolActivityInstancesActionTypes.RemoveAllSchoolActivityInstancesSuccess);

export const RemoveAllSchoolActivityInstancesFailureAction = createAction(SchoolActivityInstancesActionTypes.RemoveAllSchoolActivityInstancesFailure);

export const AddSchoolActivityInstanceAction = createAction(
	SchoolActivityInstancesActionTypes.AddSchoolActivityInstance,
	props<{ instance: SchoolActivityInstance }>()
);

export const AddSchoolActivityInstanceSuccessAction = createAction(
	SchoolActivityInstancesActionTypes.AddSchoolActivityInstanceSuccess,
	props<{ instance: SchoolActivityInstance }>()
);

export const AddSchoolActivityInstanceFailureAction = createAction(
	SchoolActivityInstancesActionTypes.AddSchoolActivityInstanceFailure,
	props<{ error: string }>()
);

// bulk create instances
export const CreateSchoolActivityInstancesBulkAction = createAction(
	SchoolActivityInstancesActionTypes.CreateSchoolActivityInstancesBulk,
	props<{ instanceReqs: CreateSchoolActivityInstanceReq[] }>()
);

export const CreateSchoolActivityInstancesBulkSuccessAction = createAction(
	SchoolActivityInstancesActionTypes.CreateSchoolActivityInstancesBulkSuccess,
	props<{ instance: SchoolActivityInstance; selectInstance: boolean; removeFakeInstances: boolean }>()
);

export const CreateSchoolActivityInstancesBulkSuccessActionV2 = createAction(
	SchoolActivityInstancesActionTypes.CreateSchoolActivityInstancesBulkSuccessV2,
	props<{ instances: SchoolActivityInstance[] }>()
);

export const CreateSchoolActivityInstancesBulkFailureAction = createAction(
	SchoolActivityInstancesActionTypes.CreateSchoolActivityInstancesBulkFailure,
	props<{ error: string }>()
);
