import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackpackIconComponent } from './backpack-icon.component';
import { FeatureIconEncounterPreventionComponent } from './feature-icons/feature-icon-encounter-prevention.component';
import { FeatureIconPassLimitsComponent } from './feature-icons/feature-icon-pass-limits.component';
import { IconAdd01Component } from './icons/icon-add-01.component';
import { IconAdminComponent } from './icons/icon-admin.component';
import { IconAppearanceComponent } from './icons/icon-appearance.component';
import { IconArrowLeft02Component } from './icons/icon-arrow-left-02.component';
import { IconBalloonsComponent } from './icons/icon-balloons.component';
import { IconCalendarComponent } from './icons/icon-calendar.component';
import { IconChangeUserComponent } from './icons/icon-change-user.component';
import { IconCheckInComponent } from './icons/icon-check-in.component';
import { IconCheckmark01Component } from './icons/icon-checkmark-01.component';
import { IconCheckmark02Component } from './icons/icon-checkmark-02.component';
import { IconChevronExpandComponent } from './icons/icon-chevron-expand.component';
import { IconChevronLeftComponent } from './icons/icon-chevron-left.component';
import { IconChevronRightComponent } from './icons/icon-chevron-right.component';
import { IconClassComponent } from './icons/icon-class.component';
import { IconClock03Component } from './icons/icon-clock-03.component';
import { IconClose01Component } from './icons/icon-close-01.component';
import { IconClose02Component } from './icons/icon-close-02.component';
import { IconContactTraceComponent } from './icons/icon-contact-trace.component';
import { IconDashboardComponent } from './icons/icon-dashboard.component';
import { IconDataComponent } from './icons/icon-data.component';
import { IconDetectedEncountersComponent } from './icons/icon-detected-encounters.component';
import { IconDownloadCircleComponent } from './icons/icon-download-circle.component';
import { IconDropInComponent } from './icons/icon-drop-in.component';
import { IconEditComponent } from './icons/icon-edit.component';
import { IconEmergencyComponent } from './icons/icon-emergency.component';
import { IconEmptyBoxComponent } from './icons/icon-empty-box.component';
import { IconError01Component } from './icons/icon-error-01.component';
import { IconError02Component } from './icons/icon-error-02.component';
import { IconExploreComponent } from './icons/icon-explore.component';
import { IconFlexComponent } from './icons/icon-flex.component';
import { IconGiftComponent } from './icons/icon-gift.component';
import { IconGlobeComponent } from './icons/icon-globe.component';
import { IconGroupComponent } from './icons/icon-group.component';
import { IconHallMonitorComponent } from './icons/icon-hall-monitor.component';
import { IconHandComponent } from './icons/icon-hand.component';
import { IconHelpComponent } from './icons/icon-help.component';
import { IconIdCard02Component } from './icons/icon-id-card-02.component';
import { IconLaunchComponent } from './icons/icon-launch.component';
import { IconMore01Component } from './icons/icon-more-01.component';
import { IconMore02Component } from './icons/icon-more-02.component';
import { IconNoTeacherComponent } from './icons/icon-no-teacher.component';
import { IconPassLimitsComponent } from './icons/icon-pass-limits.component';
import { IconPassesComponent } from './icons/icon-passes.component';
import { IconPasswordComponent } from './icons/icon-password.component';
import { IconRoomComponent } from './icons/icon-room.component';
import { IconScheduleComponent } from './icons/icon-schedule.component';
import { IconSchoolComponent } from './icons/icon-school.component';
import { IconSettingsTeacherComponent } from './icons/icon-settings-teacher.component';
import { IconShirtComponent } from './icons/icon-shirt.component';
import { IconStarComponent } from './icons/icon-star.component';
import { IconSunComponent } from './icons/icon-sun.component';
import { IconUserComponent } from './icons/icon-user.component';
import { IconVolumeComponent } from './icons/icon-volume.component';

/**
 * Backpack Design System Icons Module
 * Figma: https://www.figma.com/design/je00n7UoOqKVZ2vwpvXvgN/Icons?m=auto&t=HWdlxgrAoSe3vIPN-6
 *
 * Only add icons here if they exist in Figma!
 *
 * Monocolor icons should use `currentcolor` for their `fill` and `stroke` attributes. This allows them
 * to inherit the color of their parent element, styled via CSS. Remove width/height attributes from the SVG.
 *
 */
@NgModule({
	declarations: [
		BackpackIconComponent,

		FeatureIconEncounterPreventionComponent,
		FeatureIconPassLimitsComponent,

		IconAdd01Component,
		IconAdminComponent,
		IconAppearanceComponent,
		IconArrowLeft02Component,
		IconBalloonsComponent,
		IconCalendarComponent,
		IconChangeUserComponent,
		IconCheckInComponent,
		IconCheckmark01Component,
		IconCheckmark02Component,
		IconChevronExpandComponent,
		IconChevronLeftComponent,
		IconChevronRightComponent,
		IconClassComponent,
		IconClock03Component,
		IconClose01Component,
		IconClose02Component,
		IconContactTraceComponent,
		IconDashboardComponent,
		IconDataComponent,
		IconDetectedEncountersComponent,
		IconDownloadCircleComponent,
		IconDropInComponent,
		IconEditComponent,
		IconEmergencyComponent,
		IconEmptyBoxComponent,
		IconError01Component,
		IconError02Component,
		IconExploreComponent,
		IconFlexComponent,
		IconGiftComponent,
		IconGlobeComponent,
		IconGroupComponent,
		IconHallMonitorComponent,
		IconHandComponent,
		IconHelpComponent,
		IconIdCard02Component,
		IconLaunchComponent,
		IconMore01Component,
		IconMore02Component,
		IconNoTeacherComponent,
		IconPassesComponent,
		IconPassLimitsComponent,
		IconPasswordComponent,
		IconRoomComponent,
		IconScheduleComponent,
		IconSchoolComponent,
		IconSettingsTeacherComponent,
		IconShirtComponent,
		IconStarComponent,
		IconSunComponent,
		IconUserComponent,
		IconVolumeComponent,
		// Please keep icons in alphabetical order
	],
	imports: [CommonModule],
	exports: [
		BackpackIconComponent,
		// We do not export icon components directly.
		// Instead, use the BackpackIconComponent to render them.
	],
})
export class BackpackIconsModule {}
