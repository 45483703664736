import { ConnectedPosition } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, HostListener, Injectable, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { captureException } from '@sentry/angular';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, from, iif, interval, merge, Observable, of, publishReplay, Subject, timer } from 'rxjs';
import {
	catchError,
	concatMap,
	distinctUntilChanged,
	filter,
	finalize,
	map,
	mergeMap,
	refCount,
	skip,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { CreatePassDialogData } from '../create-hallpass-forms/create-hallpass-forms.component';
import { States } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/locations-group-container.component';
import { MainHallPassFormComponent } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { DarkThemeSwitch } from '../dark-theme-switch';
import { DeviceDetection } from '../device-detection.helper';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { KioskModeDialogComponent } from '../kiosk-mode/kiosk-mode-dialog/kiosk-mode-dialog.component';
import { LiveDataService } from '../live-data/live-data.service';
import { NavbarDataService } from '../main/navbar-data.service';
import {
	HallPass,
	Invitation,
	Location,
	NoFlyTime,
	PassActionEnum,
	PassCollectionEnum,
	PassFilterModel,
	PassFilters,
	PassLike,
	Pinnable,
	Request,
	User,
	WaitingInLinePass,
} from '../models';
import { PassCardComponent } from '../pass-card/pass-card.component';
import { WaitInLineCardComponent } from '../pass-cards/wait-in-line-card/wait-in-line-card.component';
import { StartPassNotificationComponent } from '../passes/start-pass-notification/start-pass-notification.component';
import { ReportFormComponent } from '../report-form/report-form.component';
import { ScrollPositionService } from '../scroll-position.service';
import { RoomCheckinCodeDialogComponent } from '../shared/shared-components/room-checkin-code-dialog/room-checkin-code-dialog.component';
import { CalendarService } from './calendar.service';
import { SPClassWithUsers } from './classes.service';
import { CollapsibleStatesService } from './collapsible-states.service';
import { DataService } from './data-service';
import { EmergencyService } from './emergency.service';
import { EncounterPreventionService } from './encounter-prevention.service';
import { FeatureFlagService, FLAGS } from './feature-flag.service';
import { HallPassesService, MODAL_MAX_HEIGHT } from './hall-passes.service';
import { HttpService } from './http-service';
import { KioskLoginResponse, KioskModeService } from './kiosk-mode.service';
import { EncounterPreventionResponse, EventData, LiveUpdateEvent, LiveUpdateService } from './live-update.service';
import { LocationsService } from './locations.service';
import { NoFlyTimeService } from './no-fly-time.service';
import { NotificationButtonService } from './notification-button.service';
import { RoomSwitchService } from './room-switch.service';
import { ScreenService } from './screen.service';
import { SideNavService } from './side-nav.service';
import { StorageKeys, StorageService } from './storage.service';
import { TimeService } from './time.service';
import { ToastService, ToastState } from './toast.service';
import { UserService } from './user.service';
import { sortWil, WaitInLineService } from './wait-in-line.service';

export type NoFlyTimesDisplay = {
	noFlyTimes: NoFlyTime[];
	enabled: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class HomepageService {
	emptyPassRequestMessage: { image: string; text: string } | undefined;
	passesLoaded = false;

	// pass observables
	futurePasses: Observable<HallPass[]> | undefined; // this is used in teacher home page for non-desktop sized screens
	activePasses: Observable<HallPass[]> | undefined;
	pastPasses: Observable<HallPass[]> | undefined;
	waitInLinePasses: Observable<WaitingInLinePass[]>;

	// passes
	studentPassesMap = new Map<number, HallPass[]>(); //student id to number of upcoming passes
	futurePassesThisPeriod: HallPass[] | undefined;
	futurePassesComing: HallPass[] | undefined;
	futurePasses2$ = new BehaviorSubject<HallPass[]>([]); // this is used in teacher home page for desktop sized screens
	futurePasses2ForStudents: HallPass[] | undefined;
	activePasses2: HallPass[] | undefined;
	pastPasses2: HallPass[] | undefined;
	sentRequests2: Request[] | Invitation[] | undefined;
	receivedRequests2: Request[] | Invitation[] | undefined;

	nowRequests: Request[] | Invitation[];
	scheduledRequests: Request[] | Invitation[];
	activeTabIndex = 0;
	hasActiveTabIndex = false;
	private isSortedNewtoOld = true;
	requestLoading = false;

	// request observables
	sentRequests: Observable<Request[] | Invitation[]>;
	receivedRequests: Observable<Request[] | Invitation[]>;

	// currently active pass, request or wait-in-line, for student side, and their active states
	isActivePass$: Observable<boolean>;
	isActiveWaitInLine$: Observable<boolean>;
	hasActivePassRequest: boolean;
	currentPass$ = new BehaviorSubject<HallPass | null>(null);
	currentWaitInLine$ = new BehaviorSubject<WaitingInLinePass>(null);
	currentRequestForStudent$ = new BehaviorSubject<Request>(null);
	private fullScreenWaitInLineRef: MatDialogRef<WaitInLineCardComponent>;

	inboxHasItems: Observable<boolean> = of(null);
	private inboxLoaded: Observable<boolean> = of(false);

	private filterActivePass$: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(null);
	private filterFuturePass$: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(null);
	private filterReceivedPass$: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(null);
	private filterSendPass$: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(null);
	private filterExpiredPass$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	expiredPassesSelectedSort$: Observable<PassActionEnum>;
	private destroy$ = new Subject<void>();

	effectiveUser: User;
	isStaff = false;
	isAssistant = false;
	isStudent = false;
	isKioskMode = false;
	// used for schedules
	greeting$ = new BehaviorSubject<string>('Welcome');
	// used for old home page without schedules
	greetingOrPeriod = 'Welcome';
	currentScrollPosition: number;
	isSmartphone = DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile();
	isMobile = DeviceDetection.isMobile();
	createButtonTooltipText = '';

	cursor = 'pointer';

	waitInLineTitle: Observable<string> = timer(0, 750).pipe(
		takeUntil(this.destroy$),
		map((count) => `Waiting in Line${'.'.repeat(count % 4)}`)
	);
	isWaitInLine = false;
	showProfilePictures = false;
	schoolsLength$: Observable<number>;
	private createHallPassDialogRef: MatDialogRef<MainHallPassFormComponent>;

	clock = timer(0, 1000).pipe(map(() => new Date()));

	selectedLocationId: number;

	// used to prevent multiple clicks while dialog hasn't been opened
	setRoomToKioskModeProcessing: boolean;
	private pinnables$: Observable<Pinnable[]> = this.passesService.getPinnablesRequest().pipe(filter((r: Pinnable[]) => !!r.length));
	private pinnables: Pinnable[];
	showAsOriginRoom = true;
	showEndedPasses: BehaviorSubject<boolean>;
	navBarBreakPoint = this.screenService.extraLargeDeviceBreakPoint;

	_teacherHasPasses: Observable<boolean>;
	_studentHasPasses: Observable<boolean>;
	_showRequestTitles: boolean;
	heartBeat = new Subject<number>();

	noFlyTimes: NoFlyTime[] = [];
	currentNoFlyTimeIndex: number;
	currentNoFlyEndTimeString: string;
	noFlyTimeActive = false;
	noFlyTimeTooltipHeight = 160;
	noFlyTimeTooltipPosition: ConnectedPosition[] = [
		{
			originX: 'center',
			originY: 'bottom',
			overlayX: 'center',
			overlayY: 'top',
		},
	];
	isOpenNoFlyTimeTooltip: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isOpenNoFlyTimeNuxTooltip: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isOpenStudentNoFlyTimeTooltip: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isSecondOpenStudentNoFlyTimeTooltip: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isThirdOpenStudentNoFlyTimeTooltip: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	noFlyTimeNuxAndTooltip$: Observable<NoFlyTimesDisplay> = this.userService.effectiveUser$.pipe(
		switchMap((u) =>
			iif(
				() => u.isStaff(),
				combineLatest([this.noFlyTimeService.noFlyTimes$, this.noFlyTimeService.noFlyTimeEnabled]).pipe(
					map(([noFlyTimes, enabled]) => ({ noFlyTimes, enabled }))
				),
				of(null)
			)
		)
	);

	scheduleTooltipOverlayOffsetY = 0;

	sortIconData = {
		src: './assets/Sorted New to Old.svg',
	};

	private greetingIntervalId: any;

	// controls whether a waiting in line pass transitioning to an active pass should display in a modal
	// or a non-waiting in line pass that is active needs to open
	private activePassNeedsToOpen$ = new BehaviorSubject<boolean>(false);

	private sortOptionMapping: Partial<Record<PassActionEnum, string>> = {
		[PassActionEnum.PAST_HOUR]: 'Past hour',
		[PassActionEnum.TODAY]: 'Today',
		[PassActionEnum.PAST_THREE_DAYS]: 'Past 3 days',
		[PassActionEnum.PAST_SEVEN_DAYS]: 'Past 7 days',
		[PassActionEnum.SCHOOL_YEAR]: 'This school year',
	};
	private sortOptions: { display: string; color: string; action: PassActionEnum }[] = [];
	private defaultSortOptions: { display: string; color: string; action: PassActionEnum }[] = [
		{ display: 'This school year', color: this.darkTheme.getColor(), action: PassActionEnum.SCHOOL_YEAR },
		{ display: 'All Time', color: this.darkTheme.getColor(), action: PassActionEnum.ALL_TIME },
	];

	private selectedSort$ = new BehaviorSubject<PassActionEnum>(null);

	// TODO (DHRUV + ZUBAIR): We are figuring out why a null user is being passed to getExpiredPassesRequest.

	currentClasses: SPClassWithUsers[];
	dayTypeTooltipOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	periodCountDownTooltipOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	classStudentSearch: User[][];
	classesCollapsedStates: boolean[];
	// todo get this working again
	noFocusOnSearchBar = true; //when navigating back to page with collapsed states saved this prevents auto focusing

	passesInitialized = false;
	noFlyTimeInitialized = false;
	selectedLocationInitialized = false;
	renderTeacherPageV2 = false;

	@Output() sortMode = new EventEmitter<string>();

	@HostListener('window:resize')
	checkDeviceWidth() {
		if (this.screenService.isDeviceLargeExtra) {
			this.cursor = 'default';
		}
	}

	@HostListener('window:scroll', ['$event'])
	scroll(event) {
		this.currentScrollPosition = event.currentTarget.scrollTop;
		if (this.passesService.expiredPassesNextUrl$.getValue()) {
			if (event.currentTarget.offsetHeight + event.target.scrollTop >= event.currentTarget.scrollHeight - 600) {
				combineLatest([this.expiredPassesSelectedSort$.pipe(take(1)), this.liveDataService.expiredPassesLoading$.pipe(take(1))])
					.pipe(
						filter(([_, loading]) => !loading),
						takeUntil(this.destroy$)
					)
					.subscribe(([sort, _]) => {
						this.liveDataService.getExpiredPassesRequest(this.effectiveUser, sort, this.passesService.expiredPassesNextUrl$.getValue());
					});
			}
		}
	}

	showInboxAnimated() {
		return this.dataService.inboxState;
	}

	constructor(
		public dataService: DataService,
		public dialog: MatDialog,
		private liveDataService: LiveDataService,
		private liveUpdateService: LiveUpdateService,
		private timeService: TimeService,
		private navbarService: NavbarDataService,
		public screenService: ScreenService,
		public darkTheme: DarkThemeSwitch,
		private userService: UserService,
		private notificationButtonService: NotificationButtonService,
		private httpService: HttpService,
		public router: Router,
		private passesService: HallPassesService,
		private sideNavService: SideNavService,
		private locationsService: LocationsService,
		private titleService: Title,
		public kioskMode: KioskModeService,
		private locationService: LocationsService,
		private featureService: FeatureFlagService,
		private storage: StorageService,
		private toast: ToastService,
		public emergencyService: EmergencyService,
		private noFlyTimeService: NoFlyTimeService,
		public calendarService: CalendarService,
		private wilService: WaitInLineService,
		private collapsibleStateService: CollapsibleStatesService,
		private scrollService: ScrollPositionService,
		private hallPassService: HallPassesService,
		private encounterService: EncounterPreventionService,
		private roomSwitchService: RoomSwitchService
	) {
		this.isActivePass$ = combineLatest(this.currentPass$, this.timeService.now$, (pass, now) => {
			if (!pass) {
				return false;
			}
			return new Date(pass.start_time).getTime() <= now.getTime() && now.getTime() < new Date(pass.end_time).getTime();
		}).pipe(publishReplay(1), refCount());

		this.isActiveWaitInLine$ = this.currentWaitInLine$.pipe(map(Boolean));

		combineLatest([this.isActivePass$, this.isActiveWaitInLine$]).pipe(
			takeUntil(this.destroy$),
			map(([hasActivePass, hasActiveWILPass]) => {
				this.setHasActivePassRequest();
				this.createButtonTooltipText = this.passesService.getCreatePassButtonTooltipText(
					this.emergencyService.isEmergencyActivated.value,
					hasActivePass,
					this.hasActivePassRequest,
					hasActiveWILPass
				);
			})
		);

		this.listenForEncounterPreventionDeleteWil();

		merge(this.passesService.watchMessageAlert(), this.passesService.watchAllEndingPasses())
			.pipe(
				filter(() => !this.isStaff),
				switchMap(({ action, data }) => {
					if (action === 'message.alert' && !this.dialog.getDialogById('startNotification')) {
						const isFirstPass: boolean = (data as EventData).type.includes('first_pass');
						this.screenService.customBackdropEvent$.next(true);
						const SPNC = this.dialog.open(StartPassNotificationComponent, {
							id: 'startNotification',
							panelClass: 'main-form-dialog-container',
							backdropClass: 'notification-backdrop',
							disableClose: true,
							hasBackdrop: false,
							data: {
								title: isFirstPass ? 'Quick Reminder' : 'You didn’t end your pass last time…',
								subtitle: 'When you come back to the room, remember to end your pass!',
							},
						});
						SPNC.afterClosed().subscribe(() => this.screenService.customBackdropEvent$.next(false));
					} else if (action === 'hall_pass.end') {
						if (this.dialog.getDialogById('startNotification')) {
							this.dialog.getDialogById('startNotification').close();
							return of(true);
						}
					}
					return of(false);
				})
			)
			.subscribe((res) => {
				if (res) {
					this.screenService.customBackdropEvent$.next(false);
				}
			});

		if (this.noFlyTimeService.noFlyTimeEnabled) {
			this.initializeNoFlyTime();
		} else {
			// want to set the render conditions to true so page still renders just in case feature isnt on
			this.noFlyTimeInitialized = true;
			this.checkRenderTeacherPageV2();
		}

		this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
			this.isKioskMode = this.kioskMode.isKioskMode();
		});

		this.isKioskMode = this.kioskMode.isKioskMode();
		this.showEndedPasses = this.userService.showExpiredPasses;

		const currentSchool = this.httpService.getSchool();
		let selectedLocationMap = this.storage.getMap(StorageKeys.selectedLocationV2);
		if (!selectedLocationMap) {
			selectedLocationMap = new Map<string, any>();
		}

		// get the user and populate NgRx with user data
		this.userService.effectiveUser$
			.pipe(
				tap((effectiveUser) => {
					this.effectiveUser = effectiveUser;
					this.isStaff = this.effectiveUser.isStaff();
					this.isAssistant = this.effectiveUser.isAssistant();
					this.isStudent = this.effectiveUser.isStudent();
					this.showProfilePictures = this.isStudent && this.effectiveUser.show_profile_pictures === 'everywhere';
					interval(60000)
						.pipe(
							takeUntil(this.destroy$),
							startWith(0),
							tap(() => {
								this.greeting$.next(this.getGreeting());
							})
						)
						.subscribe();
					if (this.isStudent) {
						this.popUpReadyToStartWil();
					}
					if (this.isStaff) {
						this.navBarBreakPoint = this.screenService.navBarStaffBreakpoint;
					} else {
						this.titleService.setTitle(`${this.effectiveUser.display_name} | SmartPass`);
					}
					this.titleService.setTitle('SmartPass');
					this.dataService.updateInbox(true);
				}),
				switchMap(() => {
					return this.userService.getSortPreference(this.effectiveUser.id.toString()).pipe(
						catchError((error) => {
							captureException(error);
							return of(null);
						})
					);
				}),
				tap((sortPreference) => {
					if (sortPreference) {
						this.isSortedNewtoOld = sortPreference.sort_pass_requests_by_newest;
						this.updateSortIcon();
					}
				}),
				switchMap(() => {
					if (!this.effectiveUser.isStudent()) {
						this.receivedRequests = this.liveDataService.requests$;
						// teachers do not see sent requests on their home screen,
						// only from the calendar
						this.sentRequests = of([]);
					} else {
						this.receivedRequests = this.liveDataService.invitations$;
						this.sentRequests = merge(
							this.liveDataService.watchActiveRequests(this.effectiveUser).pipe(
								// remove requests without a request time, they should display in the active pass area
								map((reqs) => {
									return reqs.filter((r) => !!r.request_time);
								})
							),
							this.liveDataService
								.watchActiveScheduledRequests(this.effectiveUser)
								// remove requests without a request time, they should display in the active pass area
								.pipe(
									map((reqs) => {
										return reqs.filter((r) => !!r.request_time);
									})
								)
						);
					}
					return combineLatest([this.receivedRequests, this.sentRequests]);
				}),
				map(([receivedRequests, sentRequests]) => {
					const nowRequests = (receivedRequests as Request[]).filter((passRequest) => passRequest.request_time === null);
					const declinedSentRequestsForStudent = (sentRequests as Request[]).filter(
						(r) => r.status === 'declined' && r.student.id === this.effectiveUser.id
					);
					const scheduled = (receivedRequests as Request[]).filter((passRequest) => passRequest.request_time !== null);
					return {
						nowRequests,
						declinedSentRequestsForStudent,
						scheduled,
						receivedRequests,
						sentRequests,
					};
				}),
				tap(({ nowRequests, declinedSentRequestsForStudent, scheduled, receivedRequests, sentRequests }) => {
					this.nowRequests = this.sortNowRequests(nowRequests);
					this.scheduledRequests = scheduled.concat(declinedSentRequestsForStudent);
					if (!this.hasActiveTabIndex && (this.nowRequests.length || this.scheduledRequests.length)) {
						this.activeTabIndex = this.nowRequests?.length ? 0 : this.scheduledRequests?.length ? 1 : 0;
						this.hasActiveTabIndex = true;
					}
					this.receivedRequests2 = receivedRequests;
					this.sentRequests2 = sentRequests;
					this.setHasActivePassRequest();
					this._showRequestTitles = receivedRequests.length > 0 && sentRequests.length > 0;
				}),
				skip(1),
				tap(() => {
					this.passesLoaded = true;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.passesInitialized = true;
					this.checkRenderTeacherPageV2();
				},
				error: (err) => {
					captureException(err, {
						extra: {
							message: 'Sort Preference and Active request pipe error',
						},
					});
				},
			});

		this.userService.effectiveUser$
			.pipe(
				distinctUntilChanged((user1, user2) => {
					return user1.id === user2.id;
				}),
				switchMap((user) => iif(() => user.isStudent(), this.currentPass$, of(null))),
				filter((pass) => !!pass),
				switchMap((pass) => {
					return this.passesService.watchEndPass(pass.id);
				}),
				tap((pass) => {
					this.currentPass$.next(undefined);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		// replay user data from service and create wait in line listener
		this.waitInLinePasses = this.userService.effectiveUser$.pipe(
			distinctUntilChanged((user1, user2) => {
				return user1.id === user2.id;
			}),
			takeUntil(this.destroy$),
			switchMap(() => this.roomSwitchService.selectedRoom$),
			filter((loc) => !!loc),
			switchMap((location) => {
				return this.liveDataService.watchWaitingInLinePasses({ type: 'origin', value: location });
			}),
			map((wilPasses) => {
				return wilPasses.sort(sortWil);
			})
		);

		this.userService.user$
			.pipe(
				filter((u) => !!u),
				switchMap((u) =>
					this.showEndedPasses.asObservable().pipe(
						filter((s) => !!s),
						map((show) => ({ show, u }))
					)
				),
				tap(({ show, u }: { show: boolean; u: User }) => {
					if (u.show_expired_passes !== show) {
						this.userService.updateUserRequest(u, { show_expired_passes: show });
					}
				}),
				takeUntil(this.destroy$),
				switchMap(() =>
					this.passesService.passFilters$.pipe(
						filter<Record<string, PassFilters>>(Boolean),
						map((filter) => filter[PassFilterModel.PastPasses])
					)
				)
			)
			.subscribe((filter) => {
				const color = this.darkTheme.getColor();
				this.sortOptions = filter.filters
					.map((action) => ({
						display: this.sortOptionMapping[action],
						color,
						action: action,
					}))
					.concat(...this.defaultSortOptions);
				this.selectedSort$.next(filter.default || PassActionEnum.SCHOOL_YEAR);
			});

		combineLatest([this.userService.effectiveUser$, this.passesService.passFilters$.pipe(startWith(null))])
			.pipe(
				filter(([user, filters]) => user.isTeacher()),
				switchMap(([user, filters]) => {
					return combineLatest([
						this.roomSwitchService.selectedRoom$.pipe(filter((loc) => !!loc)),
						this.selectedSort$.asObservable().pipe(filter<PassActionEnum>(Boolean)),
					]).pipe(
						distinctUntilChanged(([prevLoc, prevSort], [currentLoc, currentSort]) => prevLoc.id === currentLoc.id && prevSort === currentSort),
						switchMap(([selectedLoc, sort]) => {
							return this.liveDataService.watchPastHallPasses({ type: 'location', value: selectedLoc }, 50, sort);
						})
					);
				})
			)
			.subscribe((endedPasses) => {
				this.pastPasses2 = endedPasses;
			});

		this.userService.effectiveUser$.pipe(
			take(1),
			takeUntil(this.destroy$),
			switchMap((user) => {
				return this.watchLocationEventsForTeacher(user);
			})
		);

		const endOfToday = moment(this.timeService.nowDate()).endOf('day');

		this.getEmptyPassRequestMessage();

		this._teacherHasPasses = this.roomSwitchService.selectedRoom$.pipe(
			filter((loc) => !!loc),
			takeUntil(this.destroy$),
			switchMap((loc) => {
				this.selectedLocationId = loc.id;
				return combineLatest([
					this.liveDataService.watchActiveHallPasses(of({ sort: '-created', search_query: '' }), {
						type: 'location',
						value: loc,
					}),
					this.liveDataService.watchFutureHallPasses({ type: 'location', value: loc }).pipe(
						map((p) => {
							return p.filter((hp) => {
								const passStartTime = moment(hp.start_time);
								return passStartTime < endOfToday;
							});
						})
					),
					of(loc),
				]);
			}),
			map(([activePasses, futurePasses, loc]) => {
				this.futurePasses2$.next(this.sortPasses(futurePasses));
				this.futurePassesComing = this.sortPasses(futurePasses).filter((p) => p.destination.id === this.selectedLocationId);
				this.activePasses2 = this.sortPasses(activePasses);
				this.futurePassesThisPeriod = [];
				return this.activePasses2.length > 0;
			})
		);

		// If user has waiting in line pass, and they click "start pass" to start the active pass,
		// we want the active pass to open in modal.
		// This listens for the activePassNeedsToOpen$ behavior subject to be true,
		// and then gets the current active pass once and opens it in the pass dialog.
		combineLatest([this.currentPass$, this.activePassNeedsToOpen$])
			.pipe(
				filter(([passLike, needsToOpen]) => passLike instanceof HallPass && !!needsToOpen),
				distinctUntilChanged(([prevPass, prevFlag], [currentPass, currentFlag]) => prevPass.id === currentPass.id && prevFlag === currentFlag),
				tap(([passLike, _]) => {
					if (passLike instanceof HallPass) {
						this.screenService.customBackdropStyle$.next({
							background: this.passesService.setPassOverlayColor(this.isStudent, passLike),
						});
						this.screenService.customBackdropEvent$.next(true);
						this.openPassDialog(passLike, false, false);
					} else {
						this.openPassDialog(passLike, false, false);
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this._studentHasPasses = combineLatest([
			this.userService.effectiveUser$.pipe(
				take(1),
				switchMap((user: User) => this.liveDataService.watchActivePassLike(user)),
				tap((passLike) => {
					this.currentPass$.next(passLike instanceof HallPass ? passLike : null);
					this.currentRequestForStudent$.next(passLike instanceof Request ? passLike : null);
					if (passLike instanceof WaitingInLinePass) {
						const currentWil = this.currentWaitInLine$.value;
						if (currentWil?.line_position != passLike.line_position || currentWil?.missed_start_attempts != passLike.missed_start_attempts) {
							this.currentWaitInLine$.next(passLike);
						}
					} else {
						this.currentWaitInLine$.next(undefined);
					}
				})
			),
			this.liveDataService.futurePasses$.pipe(
				map((passes) => {
					const today = moment();
					let endOfWeek = today.endOf('week').subtract(1, 'day');
					if (today.isoWeekday() === 6) {
						endOfWeek = endOfWeek.add(1, 'week');
					}
					return passes.filter((hp) => {
						const passStartTime = moment(hp.start_time);
						return passStartTime < endOfWeek;
					});
				})
			),
		]).pipe(
			map(([currentActive, futurePasses]) => {
				const active = currentActive instanceof HallPass || currentActive instanceof WaitingInLinePass || currentActive instanceof Request;
				this.futurePasses2ForStudents = futurePasses;
				return active || futurePasses.length > 0;
			})
		);

		// set up requests and invitation listeners
		this.userService.effectiveUser$
			.pipe(
				switchMap((user) => {
					if (!user.isStudent()) {
						this.receivedRequests = this.liveDataService.requests$;
						// teachers do not see sent requests on their home screen,
						// only from the calendar
						this.sentRequests = of([]);
					} else {
						this.receivedRequests = this.liveDataService.invitations$;
						this.sentRequests = merge(
							this.liveDataService.watchActiveRequests(this.effectiveUser).pipe(
								// remove requests without a request time, they should display in the active pass area
								map((reqs) => {
									return reqs.filter((r) => !!r.request_time);
								})
							),
							this.liveDataService
								.watchActiveScheduledRequests(this.effectiveUser)
								// remove requests without a request time, they should display in the active pass area
								.pipe(
									map((reqs) => {
										return reqs.filter((r) => !!r.request_time);
									})
								)
						);
					}

					return combineLatest([this.receivedRequests, this.sentRequests]);
				})
			)
			.subscribe({
				next: ([receivedRequests, sentRequests]) => {
					this.receivedRequests2 = receivedRequests;
					this.sentRequests2 = sentRequests;
					this.nowRequests = (this.receivedRequests2 as Request[]).filter((passRequest) => passRequest.request_time === null);
					const declinedSentRequestsForStudent = (sentRequests as Request[]).filter(
						(r) => r.status === 'declined' && r.student.id === this.effectiveUser.id
					);
					const scheduled = (this.receivedRequests2 as Request[]).filter((passRequest) => passRequest.request_time !== null);
					this.scheduledRequests = scheduled.concat(declinedSentRequestsForStudent);
					//this.cdr.detectChanges();
					this.setHasActivePassRequest();
					this._showRequestTitles = receivedRequests.length > 0 && sentRequests.length > 0;
				},
			});
		this.schoolsLength$ = this.httpService.schoolsLength$;
		const notifBtnDismissExpires = moment(JSON.parse(localStorage.getItem('notif_btn_dismiss_expiration')));
		if (this.notificationButtonService.dismissExpirtationDate === notifBtnDismissExpires) {
			this.notificationButtonService.dismissButton$.next(false);
		}

		this.inboxHasItems = combineLatest(
			this.liveDataService.requestsTotalNumber$,
			this.liveDataService.requestsLoaded$,
			this.liveDataService.invitationsTotalNumber$,
			this.liveDataService.invitationsLoaded$,
			(length1, loaded1, length2, loaded2) => {
				if (loaded1 && loaded2) {
					return length1 + length2 > 0;
				}
			}
		);

		this.inboxLoaded = combineLatest(this.liveDataService.requestsLoaded$, this.liveDataService.invitationsLoaded$, (l1, l2) => l1 && l2);

		if (this.screenService.isDeviceLargeExtra) {
			this.cursor = 'default';
		}

		this.httpService.globalReload$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.locationsService.getLocationsWithConfigRequest('v1/locations?limit=1000&visibility_ids_only=true');
			this.locationsService.getFavoriteLocationsRequest();
		});

		this.pinnables$
			.pipe(
				tap((res: Pinnable[]) => {
					this.pinnables = res;
				}),
				// listen for the pinnable's show as origin room setting being changed
				// to show a toast if the user tries to create a pass.
				switchMap(() => {
					return this.locationService.listenPinnableSocket();
				}),
				tap((res) => {
					this.locationService.updatePinnableSuccessState(res.data as Pinnable);
					this.showAsOriginRoom = (res.data as Pinnable).show_as_origin_room;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	private listenForEncounterPreventionDeleteWil(): void {
		this.userService.effectiveUser$
			.pipe(
				takeUntil(this.destroy$),
				switchMap((user: User) => {
					return iif(
						() => user.isStudent(),
						this.liveUpdateService.listen('ep.delete_wilp').pipe(
							filter((data: LiveUpdateEvent) => !!data?.data && (data.data as EncounterPreventionResponse).exclusion_pass?.student.id === user.id),
							map((data: LiveUpdateEvent) => data.data)
						),
						this.liveUpdateService.listen('ep.delete_wilp').pipe(
							filter((data: LiveUpdateEvent) => !!data?.data),
							mergeMap((data: any) => {
								return this.encounterService.getExclusionGroups({ student: data.data.conflict_student_ids }).pipe(
									map((exclusionGroups) => ({
										...data.data,
										exclusionGroups,
									}))
								);
							})
						)
					);
				})
			)

			.subscribe({
				next: (response: EncounterPreventionResponse) => {
					this.passesService.showEncounterPreventionToast({
						exclusionPass: WaitingInLinePass.fromJSON(response.exclusion_pass),
						isStaff: this.isStaff && !this.isKioskMode,
						exclusionGroups: response.exclusionGroups,
						conflictStudentIds: response.conflict_student_ids,
						conflictPasses: response.conflict_passes,
						isKioskMode: this.isKioskMode,
					});
				},
			});
	}

	private popUpReadyToStartWil(): void {
		this.currentWaitInLine$
			.pipe(
				takeUntil(this.destroy$),
				filter((wilPass) => wilPass?.isReadyToStart() && this.fullScreenWaitInLineRef?.getState() !== MatDialogState.OPEN),
				catchError((error, originalObs) => {
					captureException(error, {
						extra: {
							message: 'Error in Home Page Service: Waiting in Line Passes Chain',
						},
					});
					return originalObs;
				})
			)
			.subscribe({
				next: (wilPass) => {
					const modalHeight = this.passesService.getModalHeight(HallPass.fromJSON(wilPass), this.isStaff, this.kioskMode.isKioskMode());

					const modalWidth = this.passesService.getModalWidth(this.isSmartphone);
					const modalMinWidth = this.passesService.getModalMinWidth(this.isSmartphone);
					const config: MatDialogConfig = {
						panelClass: (this.isStaff ? 'teacher-' : 'student-') + 'pass-card-dialog-container',
						backdropClass: 'custom-backdrop',
						disableClose: true,
						closeOnNavigation: true,
						width: modalWidth,
						height: modalHeight,
						minWidth: modalMinWidth,
						maxWidth: '686px',
						maxHeight: `${MODAL_MAX_HEIGHT}px`,
						data: {
							pass: WaitingInLinePass.fromJSON(wilPass),
							nextInLine: true,
							forStaff: this.isStaff,
						},
					};
					this.fullScreenWaitInLineRef = this.dialog.open(WaitInLineCardComponent, config);

					this.fullScreenWaitInLineRef.afterClosed().subscribe((shouldShowActivePassModal) => {
						// when waiting in line pass modal closes, determine if active pass needs to open in a modal.
						if (shouldShowActivePassModal === 'showActivePassModal') {
							this.activePassNeedsToOpen$.next(true);
						}
					});
				},
			});
	}

	private watchLocationEventsForTeacher(user: User): Observable<any> {
		const locationPipe = this.createLocationPipeForTeacher(user.id);
		const pinnablePipe = this.createPinnablePipeForTeacher(user.id);
		return this.liveUpdateService.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return merge(
					// Initial loading of locations
					this.locationsService.getLocationsWithTeacherRequest(user),
					// When location.patched emits, reload locations
					this.liveUpdateService.listen('location.patched').pipe(
						locationPipe,
						switchMap(() => this.locationsService.getLocationsWithTeacherRequest(user))
					),
					// When pinnable.patched emits, reload locations
					this.liveUpdateService.listen('pinnable.patched').pipe(
						pinnablePipe,
						switchMap(() => this.locationsService.getLocationsWithTeacherRequest(user))
					)
				);
			})
		);
	}

	async startWILPass(wil: WaitingInLinePass, forStaff: boolean): Promise<any> {
		this.requestLoading = true;
		const passRequest$ = this.wilService.startWilPassNow(wil.id).pipe(
			concatMap((response) => {
				if (response?.conflict_student_ids) {
					const user = this.userService.userData.value;
					return this.hallPassService.handleEncounterPrevention({
						conflictPasses: response?.conflict_passes,
						preventedEncounters: response?.prevented_encounters,
						conflictStudentIds: response?.conflict_student_ids.map((id) => id.toString()),
						passes: [],
						body: {
							duration: wil.duration,
							origin: wil.origin.id,
							destination: wil.destination.id,
							travel_type: wil.travel_type,
						},
						selectedTravelType: wil.travel_type,
						selectedStudents: [wil.student],
						selectedLocation: wil.destination,
						forStaff,
						attemptedPass: wil,
						currentUser: user,
						waitInLinePassId: wil.id,
					});
				}

				return of(response);
			}),
			takeUntil(this.destroy$)
		);
		let overallPassRequest$: Observable<any>;

		if (!this.isStaff && !this.isKioskMode) {
			overallPassRequest$ = passRequest$;
		} else {
			// We do not count the WaitingInLinePass if the pass is ready to start. This is because the backend already
			// considers a ready to start pass as part of the number of passes in the room.
			// If the WaitingInLinePass isn't ready to start, then this would be considered an override. It's set to 1
			// since we're only overriding a single WaitingInLine pass into the room.

			let isOriginLine: boolean;
			let blocking = false;
			let doublyLinkedPassStillInLine = false;

			if (this.featureService.isFeatureEnabledV2(FLAGS.OriginWaitInLine) && this.featureService.isFeatureEnabledV2(FLAGS.WaitInLineUIRefresh)) {
				isOriginLine = wil.is_origin_line;
				if (isOriginLine && !!wil.linked_pass) {
					doublyLinkedPassStillInLine = wil.line_position > 0 && wil.linked_pass.line_position > 0;
					const blockingStatus = await this.wilService.getLineBlockedStatus(wil.origin.id).toPromise();
					blocking = blockingStatus.blocked;
				}
			} else {
				isOriginLine = false;
			}

			if (!blocking && !doublyLinkedPassStillInLine) {
				return passRequest$.toPromise();
			}

			// either blocking, or ready at neither line, then show the modal
			const studentCount = wil.isReadyToStart() ? 0 : 1;
			overallPassRequest$ = from(this.locationsService.checkIfFullRoom(wil.destination, this.isKioskMode, studentCount, true, wil)).pipe(
				concatMap((overrideRoomLimit) => {
					if (!overrideRoomLimit) {
						return of(null);
					}
					return of(true);
				}),
				filter(Boolean),
				concatMap(() => passRequest$),
				takeUntil(this.destroy$)
			);
		}

		return overallPassRequest$
			.pipe(
				finalize(() => {
					// closeDialog(true);
				})
			)
			.toPromise();
	}

	private createLocationPipeForTeacher(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((l) => Location.fromJSON(l));
					} else {
						return [Location.fromJSON(data)];
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchLocationEventsForTeacher', e);
					return originalObs;
				})
			);
	}

	// const pin = Pinnable.fromJSON(pinnableUpdated.data);
	// 						if (pin.location) {
	// 							const pinLoc = Location.fromJSON(pin.location);
	// 							const locationHasTeacher = pinLoc.teachers.some((t) => t === this.user.id || t.id === this.user.id);
	// 							if (locationHasTeacher) {
	// 								this.locations.push(pinLoc);
	// 							} else {
	// 								this.locations = this.locations.filter((l) => l.id !== pinLoc.id);
	// 							}
	// 						}
	private createPinnablePipeForTeacher(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((p) => Pinnable.fromJSON(p));
					} else {
						return [Pinnable.fromJSON(data)];
					}
				}),
				// map((pins) => {
				// 	return pins.map(pin => {
				// 		if (pin.location) {
				// 			const pinLoc = Location.fromJSON(pin.location);
				// 			const locationHasTeacher = pinLoc.teachers.some((t) => t === userId || t.id === userId);
				// 			if (locationHasTeacher) {
				// 				return pinLoc;
				// 			}
				// 		}
				// 	});
				// }),
				catchError((e, originalObs) => {
					console.log('Error in watchLocationEventsForTeacher', e);
					return originalObs;
				})
			);
	}

	private updateRoomOptions(currentData: Location[], newData: Location[], user: User): Location[] {
		const itemsToAdd = [];
		newData.map((newItem) => {
			const currentItem = currentData.find((cd) => cd.id === newItem.id);
			const currentItemIndex = currentData.findIndex((cd) => cd.id === newItem.id);
			if (currentItem) {
				currentData[currentItemIndex] = newItem;
			} else {
				itemsToAdd.push(newItem);
			}
			return currentItem;
		});
		return currentData.concat(itemsToAdd).filter((loc) => {
			return loc.teachers?.some((t) => t === user.id || t.id === user.id);
		});
	}

	private getSelectedLocation(locations: Location[], selected): Location {
		let selectedLocation: Location;
		const roomIds = locations.map((loc) => loc.id);
		const selectedExists = selected ? roomIds.includes(selected.id) : false;

		const currentSchool = this.httpService.getSchool();
		let selectedLocationMap = this.storage.getMap(StorageKeys.selectedLocationV2);
		if (!selectedLocationMap) {
			selectedLocationMap = new Map<string, any>();
		}

		const periodRoom = selectedLocationMap[currentSchool.id]?.period_room;

		if (!selected || !selectedExists) {
			selectedLocation = periodRoom?.id ? periodRoom : locations[0];
			selectedLocationMap[currentSchool.id] = {
				selected: selectedLocation,
				period_room: periodRoom || selectedLocation,
			};
			this.storage.setMap(StorageKeys.selectedLocationV2, selectedLocationMap);
		} else {
			selectedLocation = selected;
		}
		return selectedLocation;
	}

	setActiveUserAndGetPasses(setUser: User): void {
		this.liveDataService
			.watchActivePassLike(setUser)
			.pipe(takeUntil(this.destroy$))
			.subscribe((passLike) => {
				this.currentPass$.next(passLike instanceof HallPass ? passLike : null);
				this.currentRequestForStudent$.next(passLike instanceof Request ? passLike : null);
				if (passLike instanceof WaitingInLinePass) {
					const currentWil = this.currentWaitInLine$.value;
					if (currentWil?.line_position != passLike.line_position || currentWil?.missed_start_attempts != passLike.missed_start_attempts) {
						this.currentWaitInLine$.next(passLike);
					}
				} else {
					this.currentWaitInLine$.next(undefined);
				}
			});
	}

	private updateSortIcon(): void {
		this.sortIconData.src = this.isSortedNewtoOld ? './assets/Sorted New to Old.svg' : './assets/Sorted Old to New.svg';
	}

	private sortNowRequests(requests: Request[]): Request[] {
		if (requests?.length) {
			requests.sort((a, b) => {
				const dateA = new Date(a.created);
				const dateB = new Date(b.created);
				return this.isSortedNewtoOld ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
			});
		}
		return requests;
	}

	toggleAndSaveSortPreference(): void {
		this.isSortedNewtoOld = !this.isSortedNewtoOld;
		this.userService
			.setSortPreference(this.effectiveUser.id.toString(), this.isSortedNewtoOld)
			.pipe(
				takeUntil(this.destroy$),
				catchError((error) => {
					console.error('Failed to save sort preference', error);
					return of(error);
				})
			)
			.subscribe();
		this.nowRequests = this.sortNowRequests(this.nowRequests as Request[]);
		this.updateSortIcon();
	}

	private sortPasses(passes: HallPass[]): HallPass[] {
		return passes.sort((p1, p2) => p1.start_time.getTime() - p2.start_time.getTime());
	}

	private getGreeting(): string {
		const now = moment();
		const today = moment();

		// Set the time to 4 AM
		const today4am = today.set({ hour: 4, minute: 0, second: 0, millisecond: 0 });

		if (now.isBefore(today4am)) {
			return 'Good evening';
		}

		const todayNoon = today.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
		if (now.isBefore(todayNoon)) {
			return 'Good morning';
		}

		const before6pm = today.set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
		if (now.isBefore(before6pm)) {
			return 'Good afternoon';
		}

		// 6pm and after
		return 'Good evening';
	}

	showNoFlyTimeTooltip(): void {
		this.isOpenNoFlyTimeTooltip.next(true);
	}

	closeNoFlyTimeTooltip(): void {
		this.isOpenNoFlyTimeTooltip.next(false);
	}

	showStudentNoFlyTimeTooltip(): void {
		this.isOpenStudentNoFlyTimeTooltip.next(true);
	}

	closeStudentNoFlyTimeTooltip(): void {
		this.isOpenStudentNoFlyTimeTooltip.next(false);
	}

	showSecondStudentNoFlyTimeTooltip(): void {
		this.isSecondOpenStudentNoFlyTimeTooltip.next(true);
	}

	closeSecondStudentNoFlyTimeTooltip(): void {
		this.isSecondOpenStudentNoFlyTimeTooltip.next(false);
	}

	showThirdStudentNoFlyTimeTooltip(): void {
		this.isThirdOpenStudentNoFlyTimeTooltip.next(true);
	}

	closeThirdStudentNoFlyTimeTooltip(): void {
		this.isThirdOpenStudentNoFlyTimeTooltip.next(false);
	}

	showMainFormForStudent(forLater: boolean, student: User, selectedLocation: Location): void {
		const openDialog = this.dialog.openDialogs.find((d) => d.componentInstance instanceof MainHallPassFormComponent);
		if (
			openDialog?.componentInstance?.FORM_STATE?.data?.selectedStudents &&
			student.id === openDialog?.componentInstance?.FORM_STATE?.data?.selectedStudents[0].id
		) {
			return;
		}
		const FORM_STATE = {
			step: 3,
			previousStep: 2,
			state: States.FromToWhere,
			fromState: null,
			formMode: {
				role: null,
				formFactor: null,
			},
			data: {
				selectedGroup: null,
				selectedStudents: [student],
				direction: {
					from: selectedLocation,
				},
				roomStudents: null,
			},
			forInput: true,
			forLater: forLater,
			kioskMode: false,
			noFlyTimes: this.noFlyTimes,
			currentNoFlyTimeIndex: this.currentNoFlyTimeIndex,
			noFlyTimeActive: this.noFlyTimeActive,
			currentNoFlyEndTimeString: this.currentNoFlyEndTimeString,
			extras: new BehaviorSubject<string>(null),
			openedFrom: null,
			passLimitInfo: null,
		};

		// send to navbar to open dialog
		const { extraLargeDeviceBreakPoint, navBarStaffBreakpoint } = this.screenService;
		const breakpoint = this.isStaff ? navBarStaffBreakpoint : extraLargeDeviceBreakPoint;
		// show the pass creation dialog on the navbar when the "create pass" buttons are shown
		if (this.screenService.windowWidth >= breakpoint) {
			this.navbarService.createPassReceiver.next(FORM_STATE);
			return;
		}

		this.createHallPassDialogRef?.close();
		this.createHallPassDialogRef = this.dialog.open(MainHallPassFormComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				FORM_STATE: FORM_STATE,
				forStaff: this.isStaff,
			} as Partial<CreatePassDialogData>,
		});

		this.createHallPassDialogRef
			.afterClosed()
			.pipe(
				takeUntil(this.destroy$),
				filter<PassLike[]>((createdPasses) => createdPasses?.length > 0)
			)
			.subscribe({
				next: (createdPasses) => {
					const newPass = createdPasses[0];

					//scroll to top of the page after creating pass if staff
					if ((this.isStaff || this.isAssistant) && newPass instanceof HallPass && this.router.url === '/main/passes') {
						this.scrollService.triggerScrollToTop();
					}
				},
			});
	}

	showMainForm(forLater: boolean): void {
		// send to navbar to open dialog
		const { extraLargeDeviceBreakPoint, navBarStaffBreakpoint } = this.screenService;
		const breakpoint = this.isStaff ? navBarStaffBreakpoint : extraLargeDeviceBreakPoint;
		// show the pass creation dialog on the navbar when the "create pass" buttons are shown
		if (this.screenService.windowWidth >= breakpoint) {
			this.navbarService.createPassReceiver.next(undefined);
			return;
		}
		this.createHallPassDialogRef = this.dialog.open(MainHallPassFormComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				forLater: forLater,
				forStaff: this.isStaff,
				forInput: true,
				noFlyTimes: this.noFlyTimes,
				currentNoFlyTimeIndex: this.currentNoFlyTimeIndex,
				noFlyTimeActive: this.noFlyTimeActive,
				currentNoFlyEndTimeString: this.currentNoFlyEndTimeString,
			} as Partial<CreatePassDialogData>,
		});

		this.createHallPassDialogRef
			.afterClosed()
			.pipe(
				takeUntil(this.destroy$),
				filter<PassLike[]>((createdPasses) => createdPasses?.length > 0)
			)
			.subscribe({
				next: (createdPasses) => {
					const newPass = createdPasses[0];

					if (this.isStudent && !forLater && !(newPass instanceof HallPass)) {
						this.openStudentPassRequestModal(newPass);
					}

					//scroll to top of the page after creating pass if staff
					if ((this.isStaff || this.isAssistant) && newPass instanceof HallPass && this.router.url === '/main/passes') {
						this.scrollService.triggerScrollToTop();
					}
				},
			});
	}

	openStudentPassRequestModal(newPass: PassLike): void {
		const dialogData = {
			pass: newPass,
			fromPast: false,
			forFuture: false,
			isActive: true,
			forStaff: false,
			kioskMode: false,
			activePassTime$: this.heartBeat,
			showStudentInfoBlock: false,
			hideReport: true,
			openRequestPin: false,
		};
		const passCardComp = this.passesService.getPassCardComponent(newPass);

		const modalHeight = this.passesService.getModalHeight(newPass, this.isStaff, this.isKioskMode);

		const modalWidth = this.passesService.getModalWidth(this.isSmartphone);
		const modalMinWidth = this.passesService.getModalMinWidth(this.isSmartphone);
		this.dialog
			.open(passCardComp, {
				panelClass: 'student-pass-card-dialog-container',
				backdropClass: 'custom-backdrop',
				width: modalWidth,
				height: modalHeight,
				minWidth: modalMinWidth,
				maxWidth: '686px',
				maxHeight: `${MODAL_MAX_HEIGHT}px`,
				data: dialogData,
			})
			.afterClosed()
			.pipe(filter((passes) => passes?.length > 0))
			.subscribe((pass) => {
				if (!(pass[0] instanceof HallPass)) {
					const config: MatDialogConfig = {
						panelClass: 'student-pass-card-dialog-container',
						backdropClass: 'custom-backdrop',
						width: '40%',
						height: modalHeight,
						minWidth: '335px',
						maxWidth: '686px',
						maxHeight: '905px',
						data: {
							pass: pass[0],
							fromPast: false,
							forFuture: false,
							isActive: true,
							forStaff: false,
							kioskMode: false,
							activePassTime$: this.heartBeat,
							showStudentInfoBlock: false,
						},
					};
					this.dialog.open(PassCardComponent, config);
				}
			});
	}

	openSettings(value): void {
		if (value && !this.dialog.openDialogs.length) {
			this.sideNavService.openSettingsEvent$.next(true);
		}
	}

	async openRoomCodeDialog(selected: Location): Promise<void> {
		this.dialog.open<RoomCheckinCodeDialogComponent, { roomData: Location }>(RoomCheckinCodeDialogComponent, {
			panelClass: 'checkin-room-code-dialog-container',
			backdropClass: 'custom-bd',
			maxWidth: '100vw',
			maxHeight: '100vh',
			height: '100vh',
			width: '100vw',
			data: { roomData: selected },
		});
	}

	async setRoomToKioskMode(selected: Location): Promise<void> {
		const loginServer = this.httpService.getServerFromStorage();
		if (!loginServer) {
			throw new Error('No login server!');
		}

		if (this.setRoomToKioskModeProcessing) {
			return;
		}

		let pin: Pinnable;
		if (selected.category) {
			pin = this.pinnables.find((p: Pinnable) => p.category === selected.category);
		} else {
			pin = this.pinnables.find((p: Pinnable) => p.location?.id === selected.id);
		}
		if (pin) {
			this.showAsOriginRoom = pin.show_as_origin_room;
		}

		this.setRoomToKioskModeProcessing = true;
		this.kioskMode
			.getKioskModeLogin(selected.id)
			.pipe(
				// Get Dedicated Login details
				takeUntil<KioskLoginResponse>(this.destroy$),
				map(({ results }) => results), // de-nest server response
				finalize(() => (this.setRoomToKioskModeProcessing = false)),
				concatMap((kioskLoginInfo) => {
					const dialogRef = this.dialog.open(KioskModeDialogComponent, {
						panelClass: 'accounts-profiles-dialog',
						backdropClass: 'custom-bd',
						width: '425px',
						height: '480px',
						data: { selectedRoom: selected, loginData: kioskLoginInfo, showAsOriginRoom: this.showAsOriginRoom },
					});

					return dialogRef.afterClosed().pipe(
						filter(Boolean),
						tap(() => {
							this.kioskMode.setTempRoom(selected);
							this.router.navigate(['main/kioskMode/settings']);
						})
					);
				})
			)
			.subscribe({
				error: (err: Error) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 403 && err.error.detail === 'invalid permissions') {
							this.toast.openToast({
								title: 'Not assigned to room!',
								subtitle: 'You need to be assigned to this room to enter kiosk mode. Contact your admin for more info.',
								type: ToastState.Error,
							});
						}
					}
				},
			});
	}

	toggleShowEndedPasses(show: boolean): void {
		this.showEndedPasses.next(show);
	}

	filterPasses(collection: PassCollectionEnum, action: PassActionEnum): void {
		const filterMap: Record<string, BehaviorSubject<string | moment.Moment>> = {
			active: this.filterActivePass$,
			future: this.filterFuturePass$,
			'expired-passes': this.filterExpiredPass$,
			'received-pass-requests': this.filterReceivedPass$,
			'sent-pass-requests': this.filterSendPass$,
		};
		if (collection in filterMap) {
			filterMap[collection].next(action);
		}
	}

	prepareFilter(action: PassActionEnum, collection: PassCollectionEnum): void {
		switch (action) {
			case PassActionEnum.PAST_HOUR:
			case PassActionEnum.TODAY:
			case PassActionEnum.PAST_THREE_DAYS:
			case PassActionEnum.PAST_SEVEN_DAYS:
			case PassActionEnum.LOCATION:
				this.filterPasses(collection, action);
				break;
			default:
				this.filterPasses(collection, null);
				break;
		}
	}

	private getActivePassTime(pass: PassLike): Observable<string> {
		let end: Date;
		if (pass instanceof HallPass) {
			end = pass.expiration_time;
		} else {
			throw new Error('Invalid pass type');
		}
		const now: Date = this.timeService.nowDate();
		const diff: number = (end.getTime() - now.getTime()) / 1000;
		const mins: number = Math.floor(Math.abs(Math.floor(diff) / 60));
		const secs: number = Math.abs(Math.floor(diff) % 60);
		return of(mins + ':' + (secs < 10 ? '0' + secs : secs));
	}

	openPassDialogFromSidebar(event: { pass: PassLike; fromClick: boolean; fromEndButton: boolean; pageNumber: number }): void {
		this.openPassDialog(event.pass, event.fromClick, event.fromEndButton, event.pageNumber);
	}

	onPassInfoClick(pass: PassLike): void {
		if (pass instanceof WaitingInLinePass && pass.isReadyToStart()) {
			this.startWILPass(pass, true);
			return;
		}
		this.openPassDialog(pass, true, false);
	}

	openPassDialog(pass: PassLike, fromClick: boolean, fromEndButton: boolean, pageNumber = 1): void {
		if (!(pass instanceof WaitingInLinePass)) {
			this.dataService.markRead(pass).subscribe();
		}

		// parsing the proper dialog data for the dialog
		let data: any;
		let isForNowPass = true;

		if (pass instanceof HallPass) {
			if (this.kioskMode.getCurrentRoom().value) {
				pass.cancellable_by_student = false;
			}
			isForNowPass = this.calendarService.isForNowPass(pass);

			const { fromPast, isActive, forFuture } = this.passesService.calculatePassStatus(pass);
			const passStatus = this.passesService.getPassStatus(pass.start_time, pass.end_time, pass.expiration_time, false, false, false);

			// getting the active pass time
			const activePassTime$ = this.getActivePassTime(pass);

			data = {
				pass: pass,
				fromPast,
				forFuture,
				isActive: passStatus === 'active' || passStatus === 'overtime',
				forStaff: this.isStaff && !this.kioskMode.getCurrentRoom().value,
				kioskMode: !!this.kioskMode.getCurrentRoom().value,
				activePassTime$: activePassTime$,
				showStudentInfoBlock: this.isStaff || this.kioskMode.getCurrentRoom().value,
				showTeacherPinEntry: fromEndButton && pass.needs_check_in,
			};
			data.isActive = passStatus === 'active' || passStatus === 'overtime';

			if (isActive) {
				data.hideReport = true;
				if (this.isKioskMode && !forFuture) {
					data.hasDeleteButton = false;
				}
			}
		} else {
			data = {
				pass: pass,
				pageNumber: pageNumber,
				fromPast: false,
				forFuture: true,
				waitInLine: this.isWaitInLine,
				isActive: false,
				forStaff: this.isStaff && !this.kioskMode.getCurrentRoom().value,
				kioskMode: !!this.kioskMode.getCurrentRoom().value,
			};
		}
		if (!data.isActive || fromClick || !isForNowPass) {
			const modalHeight = this.passesService.getModalHeight(pass, this.isStaff, this.kioskMode.isKioskMode());
			const modalWidth = this.passesService.getModalWidth(this.isSmartphone);
			const config: MatDialogConfig = {
				panelClass: (this.isStaff ? 'teacher-' : 'student-') + 'pass-card-dialog-container',
				width: modalWidth,
				height: modalHeight,
				maxHeight: `${MODAL_MAX_HEIGHT}px`,
				data: data,
			};
			const dialogComp = this.passesService.getPassCardComponent(pass);
			const dialogRef = this.dialog.open(dialogComp, config);
			this.screenService.customBackdropStyle$.next({
				background: this.passesService.setPassOverlayColor(this.isStudent, pass),
			});
			this.screenService.customBackdropEvent$.next(true);
			dialogRef.afterClosed().subscribe((dialogData) => {
				this.screenService.customBackdropEvent$.next(false);
				this.screenService.customBackdropStyle$.next(undefined);
				if (dialogData?.report) {
					this.dialog.open(ReportFormComponent, {
						width: '425px',
						height: '500px',
						panelClass: 'form-dialog-container',
						backdropClass: 'cdk-overlay-transparent-backdrop',
						data: { report: dialogData.report },
					});
				}
			});
		}
	}

	openFilter(target, model: PassFilterModel): void {
		const filterDialog = this.dialog.open(DropdownComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				trigger: target.currentTarget,
				sortData: this.sortOptions,
				selectedSort: this.selectedSort$.value || PassActionEnum.SCHOOL_YEAR,
				maxHeight: '332px',
			},
		});

		filterDialog
			.afterClosed()
			.pipe(filter<PassActionEnum>(Boolean))
			.subscribe((action) => {
				const updatedFilter = action === this.selectedSort$.value ? PassActionEnum.SCHOOL_YEAR : action;
				this.selectedSort$.next(updatedFilter);
				this.passesService.updateFilterRequest(model, updatedFilter);
			});
	}

	endPass(pass: HallPass): void {
		this.passesService.endPass(pass.id).subscribe();
	}

	setHasActivePassRequest(): void {
		if (this.sentRequests2) {
			for (const request of this.sentRequests2) {
				if (request instanceof Request && request.status === 'pending' && request?.request_time === null) {
					this.hasActivePassRequest = true;
					return;
				}
			}
			this.hasActivePassRequest = false;
		}
	}

	getStudentListHeight(numberOfStudents: number): string {
		if (!numberOfStudents) {
			return '';
		}
		const pixelHeight = numberOfStudents * 56;
		return pixelHeight + 'px';
	}

	goToStudentPage(studentId: number): void {
		this.router.navigateByUrl(`/main/student/${studentId}`);
	}

	checkRenderTeacherPageV2(): void {
		this.renderTeacherPageV2 = this.passesInitialized && this.noFlyTimeInitialized && this.selectedLocationInitialized;
	}

	initializeNoFlyTime(): void {
		combineLatest([this.noFlyTimeService.noFlyTimes$, this.noFlyTimeService.noFlyTimeEnabled])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([noFlyTimes, noFlyTimeEnabled]) => {
				this.noFlyTimes = noFlyTimes;
				this.noFlyTimeTooltipHeight = 160 + 19 * (this.noFlyTimes.length - 1);
				this.currentNoFlyTimeIndex = this.noFlyTimeService.checkNoFlyTimes(this.noFlyTimes);
				if (this.currentNoFlyTimeIndex >= 0 && noFlyTimeEnabled) {
					this.noFlyTimeActive = true;
					const currentEndTime = this.noFlyTimeService.momentize(this.noFlyTimes[this.currentNoFlyTimeIndex].end_time);
					this.currentNoFlyEndTimeString = this.noFlyTimeService.formatTimeString(currentEndTime);
				} else {
					this.noFlyTimeActive = false;
				}
				this.noFlyTimeInitialized = true;
				this.checkRenderTeacherPageV2();
			});
	}

	getEmptyPassRequestMessage(): void {
		const emojiImages = {
			beach: './assets/emojis/C145-0.png',
			rocket: './assets/emojis/C145-1.png',
			detective: './assets/emojis/C145-2.png',
			ballon: './assets/emojis/C145-3.png',
			apple: './assets/emojis/C145-4.png',
			wave: './assets/emojis/C145-5.png',
			ghost: './assets/emojis/C145-6.png',
			rainbow: './assets/emojis/C145-7.png',
			flower: './assets/emojis/C145-8.png',
			glasses: './assets/emojis/C145-9.png',
		};

		const messages = [
			{ img: emojiImages.beach, text: "Looks like you're all clear! Time for a well-deserved break!" },
			{ img: emojiImages.rocket, text: "Zero requests! Looks like you're free to launch into some me-time!" },
			{ img: emojiImages.detective, text: "It's quiet... too quiet. But that's a good thing, right?" },
			{ img: emojiImages.ballon, text: "Empty here! Guess you've got some free time up your sleeve!" },
			{ img: emojiImages.apple, text: "You're all set! Perfect moment to polish that lesson plan!" },
			{ img: emojiImages.wave, text: 'The coast is clear! Time to surf the waves of your free time.' },
			{ img: emojiImages.ghost, text: "It's a ghost town in here! Enjoy the sound of silence!" },
			{ img: emojiImages.rainbow, text: 'No requests here! Time to kick back and enjoy your rainbow!' },
			{ img: emojiImages.flower, text: 'All caught up! Take a moment to smell the flowers!' },
			{ img: emojiImages.glasses, text: 'Not a request in sight! Looks like clear views ahead!' },
		];

		const randomIndex = Math.floor(Math.random() * messages.length);
		const message = messages[randomIndex];

		this.emptyPassRequestMessage = {
			image: message.img,
			text: message.text,
		};
	}

	onDestroy(): void {
		//reset student searches in class info sections
		this.classStudentSearch = this.currentClasses?.map((c) => c.class_users.students.map((s) => s.user));
		//save collapsed states for later
		this.classesCollapsedStates?.forEach((state, index) => {
			this.collapsibleStateService.setCollapsedState(index, state);
		});
		if (this.greetingIntervalId) {
			clearInterval(this.greetingIntervalId); // Clear the interval when the component is destroyed
		}
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
