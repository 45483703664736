import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { interval, of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { bumpIn } from '../animations';
import { PassLimit, Pinnable, School } from '../models';
import { FeatureFlagService, FLAGS } from '../services/feature-flag.service';
import { HttpService } from '../services/http-service';
import { LocationsService } from '../services/locations.service';

@Component({
	selector: 'app-pinnable',
	templateUrl: './pinnable.component.html',
	styleUrls: ['./pinnable.component.scss'],
	animations: [bumpIn],
})
export class PinnableComponent implements OnInit, OnChanges {
	// Need this so this only shows on pinnables we want it to. ex. teacher pass creation
	@Input() showActivePassesCounter = false;

	@Input() mock = null;

	@Input()
	pinnable: Pinnable;

	@Input()
	width = '120px';

	@Input()
	height = '70px';

	@Input()
	iconWidth = '30px';

	@Input()
	forLater = false;

	@Input()
	forStaff = false;

	@Input()
	forCollection = false;

	@Input()
	forBulk = false;

	@Input()
	valid = true;

	@Input()
	selected = false;

	@Input() disabled = false;

	@Input() currentPage: string;

	@Input() passLimit: PassLimit;

	@Input() isSameRoom: boolean;

	@Input() disabledRoom: boolean;

	@Output()
	onSelectEvent: EventEmitter<Pinnable> = new EventEmitter();

	restricted = false;
	buttonDown = false;
	hovered: boolean;
	intervalId;
	currentSchool: School;

	showTooltipWithDelay: boolean;

	hoverDestroyer$: Subject<any>;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private locationsService: LocationsService,
		private http: HttpService,
		private featureFlagService: FeatureFlagService
	) {
		this.currentSchool = this.http.getSchool();
	}

	get shadow() {
		let i = 0;
		const hexColors = [];
		const rawHex = this.mock ? this.mock.solid.slice(1) : this.pinnable.color_profile.solid_color.slice(1);
		do {
			hexColors.push(rawHex.slice(i, i + 2));
			i += 2;
		} while (i < rawHex.length);
		const rgbString = hexColors.map((color) => parseInt(color, 16)).join(', ');

		if (this.hovered && this.valid && !this.disabled) {
			return `0px 3px 10px rgba(${rgbString}, 0.2)`;
		}

		return ' 0px 3px 5px rgba(0, 0, 0, 0.1)';
	}

	get showActivePassCount() {
		return (
			this.passLimit?.max_passes_to_active &&
			this.passLimit?.to_count > 0 &&
			this.featureFlagService.isFeatureEnabled(FLAGS.WaitInLine) &&
			this.featureFlagService.isFeatureEnabledV2(FLAGS.WaitInLineUIRefresh) &&
			this.showActivePassesCounter
		);
	}

	get showTooltip() {
		if (!this.currentPage) {
			// not on pass creation page, do not show tooltip
			return false;
		}

		if (this.isSameRoom) {
			// show the 'same room' tooltip for all users
			return true;
		}

		if (this.pinnable.type == 'location' && !this.pinnable?.location?.enable) {
			// disabled room, show the 'closed room' tooltip for all users
			return true;
		}

		if (!this.passLimit) {
			return false;
		}

		return this.showActivePassCount && this.forStaff;
	}

	get tooltipDescription(): string {
		if (this.pinnable.location && !this.pinnable.location.enable && this.currentPage === 'to') {
			return 'This room has been closed by an admin.';
		}

		if (this.isSameRoom) {
			return `You selected ${this.pinnable.title} as the origin (where you are now)!`;
		}

		if (this.passLimit?.max_passes_to_active) {
			return this.passLimit && this.locationsService.tooltipDescription('to', this.passLimit, 'toRoom');
		}
	}

	get buttonState() {
		return this.valid && !this.disabled ? (this.buttonDown ? 'down' : 'up') : 'up';
	}

	ngOnInit() {
		if (!this.mock) {
			if (this.pinnable.location) {
				this.restricted = (this.pinnable.location.restricted && !this.forLater) || (this.pinnable.location.scheduling_restricted && this.forLater);
			}
		}
		if (this.disabledRoom && this.currentPage === 'to') {
			this.disabled = true;
			// this.valid = false;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.changeDetector.detectChanges();
	}

	tooltipDelay(hover, delayValue?) {
		if (hover) {
			of('')
				.pipe(delay(delayValue))
				.subscribe((res) => {
					this.showTooltipWithDelay = true;
				});
		} else {
			this.showTooltipWithDelay = false;
		}
	}

	onHover(evt: Event, container: HTMLElement) {
		this.hoverDestroyer$ = new Subject<any>();
		const target = evt.target as HTMLElement;
		target.style.width = `auto`;
		target.style.transition = `none`;

		const targetWidth = target.getBoundingClientRect().width;
		const containerWidth = container.getBoundingClientRect().width;

		let margin = 0;
		interval(35)
			.pipe(takeUntil(this.hoverDestroyer$))
			.subscribe(() => {
				if (targetWidth - margin > containerWidth) {
					target.style.marginLeft = `-${margin}px`;
					margin++;
				}
			});
	}

	onLeave({ target: target }) {
		target.style.marginLeft = '0px';
		target.style.transition = `margin-left .4s ease`;
		target.style.width = `100%`;

		this.hoverDestroyer$.next(undefined);
		this.hoverDestroyer$.complete();
	}

	onSelect() {
		if (this.valid && !this.disabled) {
			this.onSelectEvent.emit(cloneDeep(this.pinnable));
		}
	}

	getGradient() {
		if (this.buttonDown) {
			if (this.pinnable.color_profile.pressed_color) {
				return this.pinnable.color_profile.pressed_color;
			} else {
				return this.pinnable.color_profile.gradient_color.split(',')[0];
			}
		} else {
			const gradient: string[] = this.pinnable.color_profile.gradient_color.split(',');
			return 'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')';
		}
	}

	onPress(press: boolean) {
		if (!this.disabled) {
			this.buttonDown = press;
		}
	}
}
