import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Location } from '../models/Location';
import { StorageKeys, StorageService } from './storage.service';
import { LocationsService } from './locations.service';
import { HttpService } from './http-service';
import { filter, switchMap, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { captureException, captureMessage } from '@sentry/angular';

export interface KioskSettings {
	findByName: boolean;
	findById: boolean;
	findByScan: boolean;
}

export interface KioskSettings {
	findByName: boolean;
	findById: boolean;
	findByScan: boolean;
}

export interface KioskLogin {
	username: string;
	password: string;
}

export interface KioskLoginResponse {
	results: KioskLogin;
}

@Injectable({
	providedIn: 'root',
})
export class KioskModeService {
	private currentKioskSettings$: BehaviorSubject<KioskSettings> = new BehaviorSubject<KioskSettings>(this.getKioskModeSettings());
	// This variable is used to unregister the keyboard listener of the create pass window while the check in process is happening.
	checkInShowing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	loadCurrentRoomById = new Subject<number>();
	private tempRoom: Location;
	currentRoom$: BehaviorSubject<Location> = new BehaviorSubject(null);

	constructor(private storageService: StorageService, private locationsService: LocationsService, private http: HttpService) {
		this.loadCurrentRoomById
			.asObservable()
			.pipe(
				filter((locId) => locId !== this.currentRoom$.value?.id),
				switchMap((locId) => this.locationsService.getLocation(locId)),
				map(Location.fromJSON)
			)
			.subscribe({
				next: (location) => {
					this.currentRoom$.next(location);
				},
			});
	}

	triggerFetchFromStorage() {
		// this is the new way of getting the kiosk location
		let kioskLocationId = this.storageService.getItem(StorageKeys.kioskModeLocationId);

		// for schools that haven't signed out of kiosk mode, they will have the kiosk room
		// stored in the old way
		if (!kioskLocationId) {
			const kioskRoom = this.storageService.getItem(StorageKeys.currentKioskRoom);
			if (kioskRoom) {
				kioskLocationId = JSON.parse(kioskRoom).id;
			} else {
				const school = this.http.getSchool();
				const effectiveUserId = this.http.getEffectiveUserId();
				captureException(new Error('No kiosk location!'), {
					level: 'warning',
					extra: {
						message: `No kiosk location in local storage for userId: ${JSON.stringify(effectiveUserId)} and school: ${JSON.stringify(school)}`,
					},
				});
			}
		}

		this.loadCurrentRoomById.next(parseInt(kioskLocationId, 10));
	}

	getCurrentRoom(): BehaviorSubject<Location> {
		return this.currentRoom$;
	}

	getTempRoom(): Location {
		return this.tempRoom;
	}

	setTempRoom(room: Location) {
		this.tempRoom = room;
	}

	setTempAsCurrentRoom() {
		const room = cloneDeep(this.tempRoom);
		this.tempRoom = undefined;
		this.currentRoom$.next(room);
	}

	areValidSettings(obj: any): obj is KioskSettings {
		return typeof obj === 'object' && 'findByName' in obj && 'findById' in obj && 'findByScan' in obj;
	}

	getKioskModeSettings(): KioskSettings {
		let settings = null;
		try {
			settings = JSON.parse(this.storageService.getItem('kioskSettingsData'));
		} catch (e) {
			console.log(e);
		}

		if (settings == null || !this.areValidSettings(settings)) {
			return {
				findByName: true,
				findById: false,
				findByScan: false,
			};
		} else {
			return settings as KioskSettings;
		}
	}

	setKioskModeSettings(settings: KioskSettings) {
		this.storageService.setItem('kioskSettingsData', JSON.stringify(settings));
		this.currentKioskSettings$.next(settings);
	}

	resetPassword(location: Location) {
		return this.http.patch(`v1//kiosk/${location.id}/password`);
	}

	getKioskModeLogin(locationId: number): Observable<KioskLoginResponse> {
		return this.http.get(`v1/kiosk/${locationId}/login`);
	}

	getAllKioskLogins(): Observable<string> {
		return this.http.get('v1/kiosk/all_logins', { responseType: 'text' as 'json' });
	}

	isKioskMode(): boolean {
		return !!this.storageService.getItem(StorageKeys.kioskModeLocationId);
	}

	kioskSettingsValidCheck() {
		const obj = this.getKioskModeSettings();
		let check = false;
		for (const key in obj) {
			if (obj[key] == true) check = true;
		}
		return check;
	}

	loginAsKiosk() {
		if (!this.tempRoom.id) {
			throw new Error('cannot enter kiosk mode, location not set');
		}
		return this.http.post<any>('sessions/enter-kiosk-mode', { location: this.tempRoom.id }).pipe(map(() => this.tempRoom.id));
	}
}
