import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KioskModeService } from '../../services/kiosk-mode.service';

@Component({
	selector: 'app-kiosk-mode-dialog',
	templateUrl: './kiosk-mode-dialog.component.html',
	styleUrls: ['./kiosk-mode-dialog.component.scss'],
})
export class KioskModeDialogComponent implements OnInit {
	loginInfoForm: UntypedFormGroup;
	kioskData: any;

	constructor(
		public dialogRef: MatDialogRef<KioskModeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private kioskMode: KioskModeService
	) {}

	ngOnInit(): void {
		this.kioskData = this.data;
		this.loginInfoForm = new UntypedFormGroup({
			username: new UntypedFormControl({ value: this.data.loginData.username, disabled: true }),
			password: new UntypedFormControl({ value: this.data.loginData.password, disabled: true }),
		});
	}

	fetchLoginData() {}

	resetPassword() {
		this.kioskMode.resetPassword(this.data.selectedRoom).subscribe({
			next: (result: any) => {
				this.loginInfoForm = new UntypedFormGroup({
					username: new UntypedFormControl({ value: result.results.username, disabled: true }),
					password: new UntypedFormControl({ value: result.results.password, disabled: true }),
				});
			},
		});
	}

	back() {
		this.dialogRef.close();
	}
}
