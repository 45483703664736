import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, merge, of, Subject, timer } from 'rxjs';
import { delay, filter, takeUntil, tap } from 'rxjs/operators';
import { toastSlideInOut } from '../animations';
import { Toast } from '../models/Toast';
import { User } from '../models/User';
import { KioskModeService } from '../services/kiosk-mode.service';
import { ToastService, ToastState } from '../services/toast.service';

const TOASTDELAY = 6 * 1000 - 200;

@Component({
	selector: 'app-custom-toast',
	templateUrl: './custom-toast.component.html',
	styleUrls: ['./custom-toast.component.scss'],
	animations: [toastSlideInOut],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomToastComponent implements OnInit, OnDestroy {
	@Input() toast: { data: Toast; id: string };
	@Input() indexPosition: number;
	@Input() user: User;

	toggleToast: boolean;
	data: Toast;
	isKioskMode: boolean;
	private timerValue: number;
	lineColor: string;

	private destroy$ = new Subject<void>();
	private destroyClose$ = new Subject<void>();

	constructor(private toastService: ToastService, private kioskMode: KioskModeService, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.data = this.toast.data;
		this.lineColor = this.getLineColor(this.data.type);
		this.isKioskMode = !!this.kioskMode.getCurrentRoom().value;
		setTimeout(() => {
			this.toggleToast = true;
			this.cdr.detectChanges();
		}, 250);

		merge(of(1), interval(1000))
			.pipe(takeUntil(this.destroyClose$))
			.subscribe((seconds) => (this.timerValue = seconds > 1 ? seconds + 1 : 1));

		timer(TOASTDELAY)
			.pipe(
				takeUntil(this.destroyClose$),
				filter(() => !this.data.showButton && !this.data.encounterPrevention && !this.data.closeManually),
				tap(() => {
					this.toggleToast = false;
					this.cdr.detectChanges();
				}),
				delay(200)
			)
			.subscribe(() => {
				this.toastService.closeToast([this.toast.id]);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	close(evt?: Event): void {
		if (evt) {
			this.toggleToast = false;
			evt.stopPropagation();
			setTimeout(() => {
				this.toastService.closeToast([this.toast.id]);
			}, 200);
			return;
		}
	}

	download(action): void {
		this.toastService.toastButtonClick$.next(action);
	}

	private getLineColor(type): string {
		if (type === 'success') {
			return '#00B476';
		} else if (type === ToastState.Error) {
			return '#E32C66';
		} else if (type === ToastState.Info) {
			return '#1F195E';
		}
	}

	over(): void {
		this.destroyClose$.next(undefined);
	}

	leave(): void {
		if (!this.data.showButton && !this.data.encounterPrevention && !this.data.closeManually) {
			of(null)
				.pipe(
					delay(TOASTDELAY - this.timerValue * 1000),
					takeUntil(this.destroyClose$),
					tap(() => {
						this.toggleToast = false;
						this.cdr.detectChanges();
					}),
					delay(200)
				)
				.subscribe(() => {
					this.toastService.closeToast([this.toast.id]);
				});
		}
	}
}
