import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-notification-room-form',
	templateUrl: './notification-room-form.component.html',
	styleUrls: ['./notification-room-form.component.scss'],
})
export class NotificationRoomFormComponent {
	id: string;
	roomName: string;
	gradient: string;
	icon: string;
	form: UntypedFormGroup;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NotificationRoomFormComponent>) {
		this.id = data.room.id;
		this.roomName = data.room.title;
		this.gradient = data.room.color_profile.gradient_color;
		this.icon = data.room.icon;
		this.form = data.roomData;
	}

	close() {
		this.dialogRef.close({
			id: this.id,
			form: this.form,
		});
	}

	get roomBackground(): string {
		const colors = this.gradient.split(',');
		return `radial-gradient(circle at 73% 71%, ${colors[0]} 0%, ${colors[1]} 144%)`;
	}
}
