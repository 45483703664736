import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { DropdownOptions } from 'app/core/components/dropdown-selection/dropdown-selection.component';
import { SchoolActivity, SchoolActivityInstance, SchoolActivityState } from 'app/models';
import { FlexPeriod, SignUpWindowSessions } from 'app/services/flex-period.service';
import { InstanceTimes } from 'app/services/school-activity.service';
import { TimeRangeInputType } from 'app/shared/time-range-input/time-range-input/time-range-input.component';
import * as moment from 'moment-timezone';
import { RRule, Weekday } from 'rrule';

export const SCHEDULE_TYPE_KEY = 'scheduleType';
export const SCHEDULE_REPEAT_END_DATE_KEY = 'repeatEndDate';
export const FLEX_PERIOD_ID_KEY = 'flexPeriodId';
export const FLEX_PERIOD_REPEATS_KEY = 'flexPeriodRepeats';
export const FLEX_INSTANCE_TIMES_KEY = 'flexInstanceTimes';
export const CUSTOM_SCHEDULE_DATE_KEY = 'customStartDate';
export const CUSTOM_SCHEDULE_TIME_RANGE_KEY = 'customTimeRange';
export const CUSTOM_SCHEDULE_REPEAT_KEY = 'customRepeat';

export const DEFAULT_REPEAT_END_DATE = moment().add(4, 'months');

export const activityScheduleFormFields = {
	[SCHEDULE_TYPE_KEY]: new FormControl<'flex_period' | 'custom'>('flex_period', {
		nonNullable: true,
		validators: Validators.required,
	}),
	[SCHEDULE_REPEAT_END_DATE_KEY]: new FormControl<moment.Moment | undefined>(undefined, null),

	// Flex period
	[FLEX_PERIOD_REPEATS_KEY]: new FormControl<SchoolActivityState>('flex_recurring', Validators.required),
	[FLEX_PERIOD_ID_KEY]: new FormControl<number | undefined>(undefined, Validators.required),
	[FLEX_INSTANCE_TIMES_KEY]: new FormControl<InstanceTimes[]>([], null),

	// Custom scheduling
	[CUSTOM_SCHEDULE_DATE_KEY]: new FormControl(moment(), null),
	[CUSTOM_SCHEDULE_TIME_RANGE_KEY]: new FormControl<TimeRangeInputType | undefined>(undefined, null),
	[CUSTOM_SCHEDULE_REPEAT_KEY]: new FormControl<string | undefined>(undefined, null),
};

export function addActivityScheduleFormFields(form: UntypedFormGroup) {
	Object.entries(activityScheduleFormFields).forEach(([key, control]) => {
		form.addControl(key, control);
	});
}

//#endregion

//#region Form validators

export function flexScheduleRepeatEndValidator(form: FormGroup, instances: SchoolActivityInstance[]): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (form.value[SCHEDULE_TYPE_KEY] != 'flex_period') {
			return null;
		}
		if (instances.length === 0) {
			return null;
		}

		const firstScheduled = instances.filter((i) => i.state != 'canceled').sort((a, b) => a.start_time.localeCompare(b.start_time))[0];
		const controlDate = moment(control.value).startOf('day');
		const firstScheduledDate = moment(new Date(firstScheduled.start_time)).startOf('day');
		if (controlDate < firstScheduledDate) {
			return { afterStart: 'Set a date after ' + firstScheduledDate.format('MMM D, YYYY') };
		}
		return null;
	};
}

export function customScheduleRepeatEndValidator(form: FormGroup): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (form.value[SCHEDULE_TYPE_KEY] != 'custom') {
			return null;
		}
		if (!control.value) {
			return { required: 'This field is required' };
		}

		if (control.value < form.value[CUSTOM_SCHEDULE_DATE_KEY]) {
			return { afterStart: 'Set a date after ' + form.value[CUSTOM_SCHEDULE_DATE_KEY].format('MMM D, YYYY') };
		}

		return null;
	};
}

//#endregion

//#region Data conversion

export function getActivityStartDate(activity: SchoolActivity, tz: string): moment.Moment {
	return activity.custom_start_time ? moment.tz(activity.custom_start_time, tz) : moment();
}

export function getActivityTimeRange(activity: SchoolActivity, tz: string): TimeRangeInputType | null {
	if (activity.custom_start_time && activity.custom_end_time) {
		const start = moment.tz(activity.custom_start_time, tz).format('HH:mm');
		const end = moment.tz(activity.custom_end_time, tz).format('HH:mm');
		return {
			start,
			end,
		};
	}

	return null;
}

export function getStartAndEndDates(
	date: moment.Moment,
	timeRange: TimeRangeInputType,
	tz: string
): { startTime: moment.Moment; endTime: moment.Moment } {
	const startTime = moment.tz(date.format('YYYY-MM-DD') + ' ' + timeRange.start, tz);
	const endTime = moment.tz(date.format('YYYY-MM-DD') + ' ' + timeRange.end, tz);
	return { startTime, endTime };
}

// Instance list helpers

export const getUpcomingCustomInstanceTimes = (
	startTimeIso: string,
	endTimeIso: string,
	repeatRule: string | null | undefined,
	repeatEndDateIso: string | null | undefined
): InstanceTimes[] => {
	const upcomingTimes: InstanceTimes[] = [];

	const startTime = moment(startTimeIso).set({ second: 0, millisecond: 0 });
	const endTime = moment(endTimeIso).set({ second: 0, millisecond: 0 });

	if (repeatRule && repeatEndDateIso) {
		const rule = RRule.fromString(repeatRule);
		rule.options.dtstart = moment(startTimeIso).startOf('day').toDate();
		rule.options.until = moment(repeatEndDateIso).endOf('day').toDate();

		// Build instance times
		const duration = moment.duration(endTime.diff(startTimeIso));
		const startOfToday = moment().startOf('day');
		for (const date of rule.all()) {
			// Don't create instances in the past
			if (date < startOfToday.toDate()) {
				continue;
			}
			const instanceStart = moment(date).set({
				date: date.getUTCDate(),
				month: date.getUTCMonth(),
				year: date.getUTCFullYear(),
				hour: startTime.hour(),
				minute: startTime.minute(),
			});
			const instanceEnd = instanceStart.clone().add(duration);
			upcomingTimes.push({ start: instanceStart.toDate(), end: instanceEnd.toDate() });
		}
	} else {
		if (startTime.isAfter(moment())) {
			upcomingTimes.push({ start: startTime.toDate(), end: endTime.toDate() });
		}
	}

	// Remove today's entry if it's in the past
	if (upcomingTimes.length > 0 && upcomingTimes[0].start < new Date()) {
		upcomingTimes.shift();
	}

	return upcomingTimes;
};

export const getUpcomingFlexInstanceTimes = (
	flexPeriod: FlexPeriod,
	startDay: Date | undefined,
	repeatEndDateIso: string | null | undefined = null
): InstanceTimes[] => {
	startDay = startDay ?? new Date();
	const upcomingTimes: InstanceTimes[] = [];
	const endDate = repeatEndDateIso ? moment(repeatEndDateIso).toDate() : new Date(startDay.getTime() + 4 * 7 * 24 * 60 * 60 * 1000); // Default to four weeks in the future

	// Iterate from tomorrow to next four weeks
	for (let date = startDay; date <= endDate; date = moment(date).add(1, 'day').toDate()) {
		const dayOfWeek = date.getDay();

		// Find matching schedules for the day of the week
		const matchingSchedules = (flexPeriod.schedules || []).filter((schedule) => {
			return schedule.days_of_week.includes(dayOfWeek);
		});

		// Generate start and end times for each matching schedule
		matchingSchedules.forEach((schedule) => {
			const startTime = new Date(date);
			startTime.setHours(schedule.start_hour, schedule.start_minute, 0, 0);
			const endTime = new Date(date);
			endTime.setHours(schedule.end_hour, schedule.end_minute, 0, 0);

			// Add the start and end times to the scheduleArray
			upcomingTimes.push({ start: startTime, end: endTime });
		});
	}

	// Remove today's entry if it's in the past
	if (upcomingTimes.length > 0 && upcomingTimes[0].start < new Date()) {
		upcomingTimes.shift();
	}

	return upcomingTimes;
};

//#endregion

//#region Repeat option helpers

export function getRepeatOptions(date: moment.Moment): DropdownOptions<string>[] {
	const options: DropdownOptions<string>[] = [
		{
			value: '',
			title: 'Does not repeat',
		},
	];

	const dayOfWeek = date.format('dddd');
	const nthOccurence = Math.ceil(date.date() / 7);

	const weeklyRule = new RRule({
		freq: RRule.WEEKLY,
		byweekday: DAY_OF_WEEK_TO_RRULE(dayOfWeek),
	});
	options.push({ value: weeklyRule.toString(), title: 'Every ' + dayOfWeek });

	const everyOtherWeekRule = new RRule({
		freq: RRule.WEEKLY,
		interval: 2,
		byweekday: DAY_OF_WEEK_TO_RRULE(dayOfWeek),
	});
	options.push({ value: everyOtherWeekRule.toString(), title: 'Every other ' + dayOfWeek });

	const monthOnDate = new RRule({
		freq: RRule.MONTHLY,
		bymonthday: date.date(),
	});
	options.push({ value: monthOnDate.toString(), title: 'Monthly on the ' + date.date() + NTH(date.date()) });

	const monthOnOccrence = new RRule({
		freq: RRule.MONTHLY,
		byweekday: DAY_OF_WEEK_TO_RRULE(dayOfWeek).nth(nthOccurence),
	});
	options.push({
		value: monthOnOccrence.toString(),
		title: 'Monthly on the ' + nthOccurence + NTH(nthOccurence) + ' ' + dayOfWeek,
	});

	return options;
}

const NTH = (d: number) => {
	if (d > 3 && d < 21) return 'th';
	switch (d % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};

const DAY_OF_WEEK_TO_RRULE = (dayOfWeek: string): Weekday => {
	switch (dayOfWeek) {
		case 'Monday':
			return RRule.MO;
		case 'Tuesday':
			return RRule.TU;
		case 'Wednesday':
			return RRule.WE;
		case 'Thursday':
			return RRule.TH;
		case 'Friday':
			return RRule.FR;
		case 'Saturday':
			return RRule.SA;
		case 'Sunday':
			return RRule.SU;
		default:
			return RRule.MO;
	}
};

//#endregion

//#region Student sign-up window helpers

export interface SignUpWindowForm {
	signup_window_start: string;
	signup_window_end: string;
}

export const NO_LIMIT_START = '-100 years';
export const NO_LIMIT_END_VALUES = ['-1 second', '-00:00:01'];

// Updated options with match arrays
export const signUpOptions = [
	{ title: '1 month before', value: '-1 mons', matches: ['-1 mons'], order: 1, disabled: false },
	{ title: '1 week before', value: '-7 days', matches: ['-7 days'], order: 2, disabled: false },
	{ title: '1 day before', value: '-1 days', matches: ['-1 days'], order: 3, disabled: false },
	{ title: '1 hour before', value: '-1 hour', matches: ['-1 hour', '-01:00:00'], order: 4, disabled: false },
	{ title: 'No limit', value: 'no_limit', matches: [NO_LIMIT_START, ...NO_LIMIT_END_VALUES], order: 5, disabled: false },
];

export function getSignUpWindowLabel(value: string, isStart: boolean): string {
	if (isStart && value === NO_LIMIT_START) return 'As early as students want';
	if (!isStart && NO_LIMIT_END_VALUES.includes(value)) return 'Up until sessions start';

	const match = signUpOptions.find((opt) => opt.matches.includes(value));
	return match ? `${match.title} sessions start` : 'Unknown';
}

export function getWindowSession(flexPeriod: FlexPeriod): SignUpWindowSessions {
	return {
		earliest: getSignUpWindowLabel(flexPeriod.signup_window_start, true),
		latest: getSignUpWindowLabel(flexPeriod.signup_window_end, false),
	};
}

export function getDisplayLabelForStart(value: string): string {
	if (value === NO_LIMIT_START) return 'as early as they want';
	const match = signUpOptions.find((opt) => opt.matches.includes(value));
	return match ? `starting ${match.title.toLowerCase()} sessions start` : '...';
}

export function getDisplayLabelForEnd(value: string): string {
	if (NO_LIMIT_END_VALUES.includes(value)) return 'the sessions start';
	const match = signUpOptions.find((opt) => opt.matches.includes(value));
	return match ? `${match.title.toLowerCase()} sessions start` : '...';
}

//#endregion
