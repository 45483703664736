import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, take } from 'rxjs';
import { CalendarPickerService } from '../services/calendar-picker.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'sp-calendar-input',
	template: ` <div class="tw-flex tw-flex-col tw-gap-3" *ngIf="control | formValue | async as formControl">
		<div *ngIf="label" class="tw-text-navy-500 tw-text-base tw-font-semibold">{{ label }}</div>
		<div
			#inputField
			class="tw-h-10 tw-px-3 tw-flex tw-items-center tw-border tw-border-solid tw-text-navy-500 tw-rounded-lg tw-bg-gray-050"
			[ngClass]="{
				'tw-border-gray-150': !control.errors && !(focused | async),
				'tw-bg-white tw-border-navy-500 tw-shadow-focus': (focused | async),
				'tw-border-red-500 tw-shadow-error': control.errors
			}"
			(click)="showCalendar()">
			{{ formControl.value | date : 'MMM d, YYYY' }}
		</div>
		<div *ngIf="control.errors" class="tw-text-red-500 tw-flex tw-text-sm">
			<bp-icon icon="error-02" size="16" class="tw-mr-1"></bp-icon>
			<span *ngFor="let error of control.errors | keyvalue">{{ error.value }}.&nbsp;</span>
		</div>
	</div>`,
})
export class SpCalendarInputComponent {
	@Input() label: string | undefined;
	@Input() control!: FormControl<moment.Moment>;
	@Input() setCallback: (($event: moment.Moment[]) => void) | undefined;
	@ViewChild('inputField') inputField!: ElementRef;

	focused = new BehaviorSubject<boolean>(false);

	constructor(private calendarPickerService: CalendarPickerService) {}

	showCalendar(): void {
		this.focused.next(true);
		const calendar = this.calendarPickerService.showCalendar(this.inputField, [this.control.value], this.closeCalendar.bind(this));

		calendar.dateSelected.pipe(take(1)).subscribe((selectedDates: moment.Moment[]) => {
			if (this.setCallback) {
				this.setCallback(selectedDates);
			} else {
				this.control.setValue(selectedDates[0].startOf('day'));
			}
		});
	}

	closeCalendar(): void {
		this.focused.next(false);
	}
}
