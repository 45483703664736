import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { SchoolActivityInstance } from '../../../models';
import {
	BulkSignUpForActivityReq,
	GetAttendeesForInstanceReq,
	SchoolActivityAttendee,
	SchoolActivityService,
	SignUpForActivityReq,
} from '../../../services/school-activity.service';
import { ToastService } from '../../../services/toast.service';
import {
	BulkRemoveAttendeeAndSignUpActionFailure,
	BulkSignOutForActivityFailureAction,
	BulkSignOutForActivitySuccessAction,
	BulkSignUpForActivityAction,
	BulkSignUpForActivityFailureAction,
	BulkSignUpForActivitySuccessAction,
	GetAttendeesForInstanceFailureAction,
	GetAttendeesForInstanceSuccessAction,
	RemoveAttendeeAndSignUpActionFailure,
	RemoveAttendeeAndSignUpActionSuccess,
	SchoolActivityAttendeesActionTypes,
	SignUpForActivityAction,
	SignUpForActivityFailureAction,
	SignUpForActivitySuccessAction,
} from '../actions/school-activity-attendees.actions';

@Injectable()
export class SchoolActivityAttendeesEffects {
	loadSchoolActivityAttendees$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityAttendeesActionTypes.GetAttendeesForInstance),
			exhaustMap(({ req }: { req: GetAttendeesForInstanceReq }) => {
				return this.schoolActivityService.GetAttendeesForInstanceHTTP(req).pipe(
					map((attendees: SchoolActivityAttendee[]) => GetAttendeesForInstanceSuccessAction({ attendees })),
					catchError((error) => of(GetAttendeesForInstanceFailureAction({ error: error })))
				);
			})
		);
	});

	SignUpForActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityAttendeesActionTypes.SignUpForActivity),
			exhaustMap(({ req, instance }: { req: SignUpForActivityReq; instance: SchoolActivityInstance }) => {
				return this.schoolActivityService.StudentSignUpForActivityHTTP(req).pipe(
					map((attendee) => {
						this.toastService.openToast({ title: 'Signed up for activity!', type: 'success' });
						return SignUpForActivitySuccessAction({ attendee: attendee });
					}),
					catchError((error) => of(SignUpForActivityFailureAction({ error: error.message })))
				);
			})
		);
	});

	BulkSignUpForActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityAttendeesActionTypes.BulkSignUpForActivity),
			exhaustMap(({ req, instance }: { req: BulkSignUpForActivityReq; instance: SchoolActivityInstance }) => {
				return this.schoolActivityService.TeacherBulkSignUpForActivityHTTP(req, instance).pipe(
					map((attendees) => {
						return BulkSignUpForActivitySuccessAction({ attendees: attendees });
					}),
					catchError((error) => of(BulkSignUpForActivityFailureAction({ error: error.message })))
				);
			})
		);
	});

	BulkSignOutForActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityAttendeesActionTypes.BulkSignOutForActivity),
			exhaustMap(({ attendeeIds }: { attendeeIds: number[] }) => {
				return this.schoolActivityService.TeacherBulkSignOutForActivityHTTP(attendeeIds).pipe(
					map((attendees) => BulkSignOutForActivitySuccessAction({ attendeeIds: attendeeIds })),
					catchError((error) => of(BulkSignOutForActivityFailureAction({ error: error.message })))
				);
			})
		);
	});

	RemoveAttendeeAndSignUpForActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityAttendeesActionTypes.RemoveAttendeeAndSignUpAction),
			exhaustMap(({ attendeeId, req, instance }: { attendeeId: number; req: SignUpForActivityReq; instance: SchoolActivityInstance }) => {
				return this.schoolActivityService.RemoveAttendeeFromActivityInstanceHTTP(attendeeId).pipe(
					map((attendee) => {
						if (req) {
							return SignUpForActivityAction({ req, instance });
						}
						this.toastService.openToast({ title: `Student removed`, type: 'success' });
						return RemoveAttendeeAndSignUpActionSuccess();
					}),
					catchError((error) => {
						this.toastService.openToast({ title: `Error while removing student, try again`, type: 'error' });
						return of(RemoveAttendeeAndSignUpActionFailure({ error: error.message }));
					})
				);
			})
		);
	});

	BulkRemoveAttendeeAndSignUpForActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityAttendeesActionTypes.BulkRemoveAttendeeAndSignUpAction),
			exhaustMap(({ attendeeIds, req, instance }: { attendeeIds: number[]; req: BulkSignUpForActivityReq; instance: SchoolActivityInstance }) => {
				return this.schoolActivityService.TeacherBulkSignOutForActivityHTTP(attendeeIds).pipe(
					map((attendees) => {
						return BulkSignUpForActivityAction({ req, instance });
					}),
					catchError((error) => {
						this.toastService.openToast({ title: `Error while removing students, try again`, type: 'error' });
						return of(BulkRemoveAttendeeAndSignUpActionFailure({ error: error.message }));
					})
				);
			})
		);
	});

	constructor(private actions$: Actions, private schoolActivityService: SchoolActivityService, private toastService: ToastService) {}
}
