import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { BehaviorSubject, iif, interval, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, mergeMap, pluck, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Util } from '../../Util';
import { NextStep, scalePassCards } from '../animations';
import { ConsentMenuComponent } from '../consent-menu/consent-menu.component';
import { CreateFormService, FrameMotionTransition } from '../create-hallpass-forms/create-form.service';
import { CreateHallpassFormsComponent } from '../create-hallpass-forms/create-hallpass-forms.component';
import { MainHallPassFormComponent, Navigation } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { Request, User, HallPass, Location } from '../models';
import { DataService } from '../services/data-service';
import { RequestsService } from '../services/requests.service';

import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { States } from 'app/create-hallpass-forms/main-hallpass--form/locations-group-container/locations-group-container.component';
import { isNull, uniq, uniqBy } from 'lodash';
import * as moment from 'moment';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { DeviceDetection } from '../device-detection.helper';
import { NavbarDataService } from '../main/navbar-data.service';
import { RequestDatePipe } from '../pipes/request-date.pipe';
import { DomCheckerService } from '../services/dom-checker.service';
import { EncounterPreventionService } from '../services/encounter-prevention.service';
import { HallPassesService, METRICS_FOOTER_HEIGHT } from '../services/hall-passes.service';
import { KeyboardShortcutsService } from '../services/keyboard-shortcuts.service';
import { KioskModeService } from '../services/kiosk-mode.service';
import { LocationsService } from '../services/locations.service';
import { ScreenService } from '../services/screen.service';
import { StorageService } from '../services/storage.service';
import { ToastService, ToastState } from '../services/toast.service';
import { UserService } from '../services/user.service';
import { ConfirmDeleteKioskModeComponent } from './confirm-delete-kiosk-mode/confirm-delete-kiosk-mode.component';

@Component({
	selector: 'app-request-card',
	templateUrl: './request-card.component.html',
	styleUrls: ['./request-card.component.scss'],
	animations: [NextStep, scalePassCards],
	providers: [RequestDatePipe, DatePipe],
})
export class RequestCardComponent implements OnInit, OnDestroy, AfterViewInit {
	@HostListener('window:resize', ['$event.target'])
	onResize(event) {
		// scale modal and position close icon if not in hall pass creation form container
		if (!this.inFormContainer) {
			let footerHeight = 0;
			if (
				(this.forStaff && this.isModal) ||
				(this.formState?.kioskMode && this.request.id && this.request.status !== 'declined' && !this.inFormContainer)
			) {
				footerHeight = METRICS_FOOTER_HEIGHT;
			}
			this.hallpassService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
			this.cdr.detectChanges();
		}
	}

	@Input() request: Request;
	@Input() forFuture = false;
	@Input() fromPast = false;
	@Input() forInput = false;
	@Input() forStaff = false;
	@Input() formState: Navigation;

	// This input is used when a pass modal is within the hall pass creation workflow.
	// It is used to apply different styling when the pass is in the smaller form container,
	// except for when the pass is within the kiosk mode form flow.
	@Input() inFormContainer = false;

	// This input is used when a pass modal is within the hall pass creation workflow in kiosk mode.
	@Input() forKioskModeFormContainer = false;

	@Output() cardEvent: EventEmitter<Navigation> = new EventEmitter<Navigation>();
	@Output() scaleCard: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('cardWrapper') cardWrapper: ElementRef;
	@ViewChild('PassLimitOverride') overriderBody: TemplateRef<any>;

	pageNumber = 1;
	selectedDuration: number;
	selectedTravelType = 'one_way';
	private selectedStudents;
	private fromHistory;
	private fromHistoryIndex;
	private messageEditOpen = false;
	private dateEditOpen = false;
	private cancelOpen = false;
	private pinnableOpen = false;
	private user: User;

	isModal: boolean;

	private nowTeachers;
	private futureTeachers;

	performingAction: boolean;
	frameMotion$: BehaviorSubject<FrameMotionTransition>;
	private options: any[];
	private header: string;

	private hoverDestroyer$: Subject<void>;

	displayTeacherPinEntry = false;
	solidColorRgba: string;
	solidColorRgba2: string;
	removeShadow: boolean;
	leftTextShadow: boolean;
	kioskModeRestricted: boolean;
	private showCloseIcon: boolean;
	passCreationId = 'request-card';

	borderRadius: string;

	backgroundGradient = 'linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #E48C15, #E48C15)';
	footerBackgroundGradient: string;
	studentName = '';
	requestDateTimeStr = '';
	invalidDate = false;
	isMissedRequest = false;
	reschedulingMissedRequest = false;
	isDecliningRequest = false;
	showCircleCountdownForStudentDeclined = true;
	originRoomIcon: string;
	approvedOrDeniedBy: User;
	private isSmartphone = DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile();

	private isEnableProfilePictures$: Observable<boolean>;

	scaleCardTrigger$: Observable<string>;

	inflightRequest$: Observable<boolean> = this.requestService.acceptRequestInflight$.asObservable();
	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<RequestCardComponent | MainHallPassFormComponent>,
		private viewContainerRef: ViewContainerRef,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private requestService: RequestsService,
		public dialog: MatDialog,
		public dataService: DataService,
		private createFormService: CreateFormService,
		public screenService: ScreenService,
		private shortcutsService: KeyboardShortcutsService,
		private navbarData: NavbarDataService,
		private storage: StorageService,
		private domCheckerService: DomCheckerService,
		private userService: UserService,
		private locationsService: LocationsService,
		private createPassFormRef: MatDialogRef<CreateHallpassFormsComponent>,
		private toast: ToastService,
		private hallpassService: HallPassesService,
		private kioskService: KioskModeService,
		private cdr: ChangeDetectorRef,
		private requestDatePipe: RequestDatePipe,
		private encounterService: EncounterPreventionService,
		private datePipe: DatePipe,
		private router: Router
	) {}

	get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}

	get teacherNames() {
		const destination = this.request.destination;
		const origin = this.request.origin;
		if (this.formState && this.formState.kioskMode) {
			return origin.teachers;
		}
		if (destination.scheduling_request_mode === 'all_teachers_in_room') {
			if (destination.scheduling_request_send_origin_teachers && destination.scheduling_request_send_destination_teachers) {
				return [...destination.teachers, ...origin.teachers];
			} else if (destination.scheduling_request_send_origin_teachers) {
				return origin.teachers;
			} else if (destination.scheduling_request_send_destination_teachers) {
				return destination.teachers;
			}
		}
		return this.request.teachers;
	}

	get filteredTeachers() {
		return uniqBy(this.teacherNames, 'id');
	}

	get isKioskMode() {
		return this.kioskService.isKioskMode();
	}

	ngOnInit(): void {
		this.getIsRequestMissed();
		this.reschedulingMissedRequest = this.data?.missedRequest;
		if (this.dialogRef.componentInstance instanceof MainHallPassFormComponent) {
			this.passCreationId = 'pass-creation';
		}
		this.displayTeacherPinEntry = !!this.data?.openRequestPin;
		this.borderRadius = this.formState?.openedFrom !== 'navbar' ? '20px' : '0 0 20px 20px';
		this.scaleCardTrigger$ = this.domCheckerService.scalePassCard;
		this.frameMotion$ = this.createFormService.getFrameMotionDirection();

		if (this.data?.pass) {
			this.isModal = true;
			this.request = this.data.pass;
			this.pageNumber = this.data.pageNumber;
			this.forInput = this.data.forInput;
			this.forFuture = this.data.forFuture;
			this.fromPast = this.data.fromPast;
			this.forStaff = this.data.forStaff;
			this.selectedStudents = this.data.selectedStudents;
			this.fromHistory = this.data.fromHistory;
			this.fromHistoryIndex = this.data.fromHistoryIndex;
			this.selectedTravelType = this.request.travel_type;
			this.getInvalidDate();
			this.addWSListeners();
		} else if (this.request) {
			this.selectedTravelType = this.request.travel_type;
			this.getInvalidDate();
			this.addWSListeners();
		}
		this.getApprovedOrDeniedBy();

		if (this.data?.request?.duration) {
			this.selectedDuration = this.data.request?.duration;
		}

		if (this.request?.request_time) {
			this.invalidDate = Util.invalidDate(this.request.request_time);
		} else if (!this.forInput && !this.request?.request_time && !this.forStaff) {
			this.invalidDate = true;
		}
		const gradientArray = this.request.color_profile.gradient_color.split(',');
		if (this.request?.status === 'pending' && this.isMissedRequest) {
			this.backgroundGradient = 'linear-gradient(0deg, #F0F2F5, #F0F2F5),linear-gradient(0deg, #E2E6EC, #E2E6EC)';
		}

		if (this.request?.status === 'declined') {
			this.backgroundGradient = 'linear-gradient(0deg, #F0F2F5, #F0F2F5),linear-gradient(0deg, #E48C15, #E48C15)';
		}

		this.footerBackgroundGradient = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ', ' + gradientArray[1] + ')';

		this.getOriginRoomIcon();
		this.studentName = this.request?.student?.abbreviatedName(!this.userService.getFeatureFlagNewAbbreviation());

		// TODO: If a pass request is denied and resent, there is no good way to know if we should update the pass request.
		// Also, there is no request ID if the request has not been created yet. - SM
		merge(this.requestService.watchRequestDeny(this.request.id), this.requestService.watchRequestUpdate(this.request.id))
			.pipe(takeUntil(this.destroy$))
			.subscribe((request) => {
				this.request = request;
				this.performingAction = false;
				this.displayTeacherPinEntry = false;
				this.getDateTimeStr();
			});

		// when in kiosk mode, listen for pass request being created, so we can know if it's been denied, or approved but WIL
		if (this.formState?.kioskMode) {
			this.formState?.extras
				.pipe(
					filter((extras) => extras === 'createdRequest'),
					mergeMap(() => {
						const denyObservable = this.requestService.watchRequestDeny(this.request.id);
						const deleteObservable = this.requestService.watchRequestDelete(this.request.id);

						return merge(denyObservable, deleteObservable);
					}),
					tap((request) => {
						if (request.status === 'declined' || request.status === 'pending') {
							this.dialogRef.close();
						}
					}),
					takeUntil(this.destroy$)
				)
				.subscribe();
		}

		this.shortcutsService.onPressKeyEvent$.pipe(pluck('key'), takeUntil(this.destroy$)).subscribe((key) => {
			if (key[0] === 'a') {
				this.approveRequest();
			} else if (key[0] === 'd') {
				this.denyRequest('');
			}
		});

		this.userService.userJSON$
			.pipe(
				map((user) => User.fromJSON(user)),
				takeUntil(this.destroy$)
			)
			.subscribe((user) => {
				this.user = user;
			});

		this.isEnableProfilePictures$ = this.userService.isEnableProfilePictures$;

		if (this.isModal) {
			if (this.request.gradient_color) {
				this.solidColorRgba = Util.convertHex(this.request.gradient_color.split(',')[0], 100);
				this.solidColorRgba2 = Util.convertHex(this.request.gradient_color.split(',')[1], 100);
			} else if (this.request.color_profile) {
				this.solidColorRgba = Util.convertHex(this.request.color_profile.gradient_color.split(',')[0], 100);
				this.solidColorRgba2 = Util.convertHex(this.request.color_profile.gradient_color.split(',')[1], 100);
			}
		}

		this.locationsService.getPassLimitRequest();
		this.createFormService
			.getUpdatedChoice()
			.pipe(takeUntil(this.destroy$))
			.subscribe((loc) => {
				console.log(loc);
			});

		this.locationsService
			.listenLocationSocket()
			.pipe(
				takeUntil(this.destroy$),
				filter((res) => !!res),
				tap((res) => {
					try {
						const loc: Location = Location.fromJSON(res.data);
						this.locationsService.updateLocationSuccessState(loc);
						this.request.destination.title = loc.title;
					} catch (e) {
						console.log(e);
					}
				})
			)
			.subscribe();

		this.dialogRef
			.afterOpened()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.showCloseIcon = this.isModal;
			});

		this.dialogRef
			.backdropClick()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.showCloseIcon = false;
			});
	}

	ngAfterViewInit(): void {
		if (this.forKioskModeFormContainer) {
			const modalHeight = this.hallpassService.getModalHeight(this.request, false, true);
			const modalWidth = this.hallpassService.getModalWidth(this.isSmartphone);
			this.dialogRef.updateSize(modalWidth, modalHeight);
		}
		// scale modal and position close icon if not in hall pass creation form container
		if (!this.inFormContainer) {
			let footerHeight = 0;
			if (
				(this.forStaff && this.isModal) ||
				(this.formState?.kioskMode && this.request.id && this.request.status !== 'declined' && !this.inFormContainer)
			) {
				footerHeight = METRICS_FOOTER_HEIGHT;
			}
			this.hallpassService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
		}
	}

	private getApprovedOrDeniedBy(): void {
		if (this.request.approved_or_denied_by) {
			this.approvedOrDeniedBy = this.request.teachers.find((t) => t.id === this.request.approved_or_denied_by) || this.request.teachers[0];
		} else {
			this.approvedOrDeniedBy = this.request.teachers[0];
		}
	}

	private getInvalidDate(): void {
		if (this.request?.request_time) {
			this.invalidDate = Util.invalidDate(this.request.request_time);
		} else if (!this.forInput && !this.request?.request_time && !this.forStaff) {
			this.invalidDate = true;
		}
	}

	private addWSListeners(): void {
		merge(this.requestService.watchRequestDeny(this.request.id), this.requestService.watchRequestUpdate(this.request.id))
			.pipe(takeUntil(this.destroy$))
			.subscribe((request) => {
				this.request = request;
				this.performingAction = false;
				this.displayTeacherPinEntry = false;
			});

		merge(
			this.requestService.watchRequestCancel(this.request.id),
			this.requestService.watchRequestAccept(this.request.id),
			this.requestService.watchRequestDelete(this.request.id)
		)
			.pipe<Request | HallPass>(takeUntil(this.destroy$))
			.subscribe({
				next: (pass) => {
					if (this.dialogRef.getState() === MatDialogState.OPEN) {
						this.dialogRef.close();
					}
				},
			});

		// when in kiosk mode, listen for pass request being created, so we can know if it's been denied, or approved but WIL
		if (this.formState?.kioskMode) {
			this.formState?.extras
				.pipe(
					filter((extras) => extras === 'createdRequest'),
					mergeMap(() => {
						const denyObservable = this.requestService.watchRequestDeny(this.request.id);
						const deleteObservable = this.requestService.watchRequestDelete(this.request.id);

						return merge(denyObservable, deleteObservable);
					}),
					tap((request) => {
						if (request.status === 'declined' || request.status === 'pending') {
							this.dialogRef.close();
						}
					}),
					takeUntil(this.destroy$)
				)
				.subscribe();
		}
	}

	private getOriginRoomIcon(): void {
		// make sure pinnables are loaded in the store before getting room icon
		this.hallpassService.loadedPinnables$
			.pipe(
				take(1),
				switchMap((loaded) => {
					return iif(() => loaded, of(null), this.hallpassService.getPinnablesRequest());
				}),
				switchMap(() => {
					return this.hallpassService.getPinnable(this.request.origin);
				}),
				filter((pin) => !!pin),
				take(1)
			)
			.subscribe((pin) => {
				this.originRoomIcon = pin.icon;
			});
	}

	private getDateTimeStr(): void {
		// only shows for future requests and denied requests
		if (this.request.status === 'declined' || this.request.status === 'pending') {
			this.requestDateTimeStr = this.formatDateTime(this.request.request_time);
		}
	}

	private getIsRequestMissed(): void {
		if (this.inFormContainer || this.forKioskModeFormContainer) {
			return;
		}
		if (this.formState?.missedRequest) {
			this.isMissedRequest = true;
			return;
		}
		// only future passes can be missed
		// for now passes always show
		if (this.data?.pass) {
			const requestDate = this.requestDatePipe.transform(this.data?.pass);
			if (requestDate !== this.data.pass.created && Util.invalidDate(new Date(requestDate))) {
				this.isMissedRequest = true;
				return;
			}
		}
		if (this.request) {
			const requestDate = this.requestDatePipe.transform(this.request);
			if (Util.invalidDate(new Date(requestDate))) {
				this.isMissedRequest = true;
				return;
			}
		}
	}

	ngOnDestroy(): void {
		this.formState = null;
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

	get isFutureOrNowTeachers() {
		const to = this.formState.data.direction.to;
		if (
			(!this.formState.forLater && to.request_mode !== 'any_teacher') ||
			(this.formState.forLater && to.scheduling_request_mode !== 'any_teacher')
		) {
			return (
				(to &&
					((!this.formState.forLater && to.request_mode === 'all_teachers_in_room') ||
						to.request_mode === 'specific_teachers' ||
						(to.request_mode === 'teacher_in_room' && to.teachers.length === 1))) ||
				(this.formState.forLater && to.scheduling_request_mode === 'all_teachers_in_room') ||
				to.scheduling_request_mode === 'specific_teachers' ||
				(to.scheduling_request_mode === 'teacher_in_room' && to.teachers.length === 1)
			);
		}
	}

	private generateTeachersToRequest(): void {
		const to = this.formState.data.direction.to;
		if (!this.forFuture) {
			if (to.request_mode === 'all_teachers_in_room') {
				if (to.request_send_destination_teachers && to.request_send_origin_teachers) {
					this.nowTeachers = [...this.formState.data.direction.to.teachers, ...this.formState.data.direction.from.teachers];
				} else if (to.request_send_destination_teachers) {
					this.nowTeachers = this.formState.data.direction.to.teachers;
				} else if (to.request_send_origin_teachers) {
					this.nowTeachers = this.formState.data.direction.from.teachers;
				}
			} else if (to.request_mode === 'specific_teachers' && this.request.destination.request_teachers.length === 1) {
				this.nowTeachers = to.request_teachers;
			} else if (to.request_mode === 'specific_teachers' || to.request_mode === 'teacher_in_room') {
				this.nowTeachers = this.request.teachers;
			}
			if (!this.nowTeachers || this.nowTeachers.length === 0) {
				this.nowTeachers = this.request.teachers;
			}
		} else {
			if (to.scheduling_request_mode === 'all_teachers_in_room') {
				if (to.scheduling_request_send_origin_teachers && to.scheduling_request_send_destination_teachers) {
					this.futureTeachers = [...this.formState.data.direction.to.teachers, ...this.formState.data.direction.from.teachers];
				} else if (to.scheduling_request_send_origin_teachers) {
					this.futureTeachers = this.formState.data.direction.from.teachers.length
						? this.formState.data.direction.from.teachers
						: this.formState.data.direction.to.teachers;
				} else if (to.scheduling_request_send_destination_teachers) {
					this.futureTeachers = this.formState.data.direction.to.teachers;
				}
			} else if (to.scheduling_request_mode === 'specific_teachers' && this.request.destination.scheduling_request_teachers.length === 1) {
				this.futureTeachers = this.request.destination.scheduling_request_teachers;
			} else if (to.scheduling_request_mode === 'specific_teachers' && this.request.destination.scheduling_request_teachers.length > 1) {
				this.futureTeachers = this.request.teachers;
			} else if ((to.scheduling_request_mode === 'teacher_in_room' && to.teachers.length === 1) || to.scheduling_request_mode === 'any_teacher') {
				this.futureTeachers = this.request.teachers;
			}
		}
	}

	private formatDateTime(date: Date, timeOnly?: boolean): string {
		if (date instanceof Date) {
			return Util.formatDateTime(date, timeOnly);
		}
		return '';
	}

	newRequest(): void {
		this.performingAction = true;
		this.generateTeachersToRequest();
		const body: any = this.forFuture
			? {
					origin: this.request.origin.id,
					destination: this.request.destination.id,
					attachment_message: this.request.attachment_message,
					travel_type: this.selectedTravelType,
					request_time: this.request.request_time.toISOString(),
					duration: this.selectedDuration * 60,
			  }
			: {
					origin: this.request.origin.id,
					destination: this.request.destination.id,
					attachment_message: this.request.attachment_message,
					travel_type: this.selectedTravelType,
					duration: this.selectedDuration * 60,
			  };

		if (this.isFutureOrNowTeachers && !this.formState.kioskMode) {
			if (this.forFuture) {
				body.teachers = uniq(this.futureTeachers.map((t) => t.id));
			} else {
				body.teachers = uniq(this.nowTeachers.map((t) => t.id));
			}
		} else {
			body.teachers = uniq(this.request.teachers.map((t) => t.id));
			if (this.formState.kioskMode) {
				const student = this.formState.data.kioskModeStudent || this.formState.data.selectedStudents[0];
				body.student_id = student.id;
			}
		}

		if (this.forStaff) {
			const invitation = {
				students: this.request.student.id,
				default_origin: this.request.origin.id,
				destination: +this.request.destination.id,
				date_choices: [new Date(this.formState.data.date.date).toISOString()],
				duration: this.request.duration,
				travel_type: this.request.travel_type,
			};

			this.requestService
				.createInvitation(invitation)
				.pipe(
					takeUntil(this.destroy$),
					switchMap(() => {
						return this.requestService.cancelRequest(this.request.id);
					}),
					catchError((error) => {
						this.openErrorToast(error);
						return of(error);
					})
				)
				.subscribe(() => {
					this.performingAction = true;
					this.dialogRef.close();
				});
		} else {
			this.requestService
				.createRequest(body)
				.pipe(
					takeUntil(this.destroy$),
					switchMap((res: Request) => {
						this.request = res;
						this.forInput = false;
						this.kioskModeRestricted = true;
						if (this.formState?.kioskMode) {
							this.createPassFormRef.disableClose = true;
							this.formState.data.kioskModeStudent = res.student;
						}
						return this.formState.previousStep === 1
							? this.requestService.cancelRequest(this.request.id)
							: this.formState.missedRequest
							? this.requestService.cancelInvitation(this.formState.data.request.id, { message: '' })
							: of(null);
					})
				)
				.subscribe({
					next: () => {
						this.performingAction = true;
						if (this.formState?.kioskMode) {
							this.isModal = true;
							this.formState.extras.next('createdRequest');
						}
						if (!this.formState?.kioskMode) {
							if ((DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile()) && this.forFuture) {
								this.dataService.openRequestPageMobile();
							}
							// navigate student to calendar if student created scheduled pass request
							if (!this.forStaff && this.forFuture && !DeviceDetection.isAndroid() && !DeviceDetection.isIOSMobile()) {
								const date = this.datePipe.transform(this.request.request_time, 'yyyy-MM-dd');
								const requestTime = new Date(this.request.request_time);

								const hours = requestTime.getHours();
								const minutes = requestTime.getMinutes();

								const startTime = `${hours}:${minutes}`;

								// |start_time| isn't implemented anymore on the passes-calendar tab
								// but let's keep it in the URL for now.
								const url = this.router
									.createUrlTree(['main', 'passes-calendar'], {
										queryParams: { start_date: date, start_time: startTime },
									})
									.toString();
								this.router.navigateByUrl(url);
							}
							this.dialogRef.close([Request.fromJSON(this.request)]);
						} else {
							this.dialogRef.close(Request.fromJSON(this.request));
						}
					},
					error: (err: Error) => {
						this.performingAction = false;
						if (err instanceof HttpErrorResponse) {
							if (err.status === 404 && err.error.detail === 'no assigned teacher to this room') {
								this.toast.openToast({
									title: "Can't request pass!",
									subtitle: "Your teacher isn't assigned to this room, so you can't request a pass. Reach out to your admin for support.",
									type: ToastState.Error,
								});
								return;
							}
						}
						this.openErrorToast(err);
					},
				});
		}
	}

	openErrorToast(error) {
		if (error?.error?.room_pass_limit?.message === 'room pass limit reached!') {
			const subtitle: SafeHtml = `${error?.error?.room_pass_limit?.room_name} has a max capacity of zero. Please contact your admin to update this. <a href="https://articles.smartpass.app/en/articles/6855997-room-max-capacity" target="_blank">Learn more</a>`;

			this.toast.openToast(
				{
					title: 'Pass cannot be created',
					subtitle,
					type: 'error',
				},
				`${error.status}`
			);
		}
		this.toast.openToast(
			{
				title: 'Oh no! Something went wrong',
				subtitle: `Please try refreshing the page. If the issue keeps occurring, contact us at support@smartpass.app. (${error.status})`,
				type: 'error',
			},
			`${error.status}`
		);
	}

	changeDate(resend_request?: boolean): void {
		if (!this.dateEditOpen) {
			const requestIdToCancel = this.request.id;
			this.dateEditOpen = true;
			this.dialogRef.close();
			const config = {
				panelClass: 'form-dialog-container',
				maxWidth: '100vw',
				backdropClass: 'custom-backdrop',
				data: {
					entryState: {
						step: 1,
						state: !this.formState?.forLater ? States.FromToWhere : States.from,
					},
					forInput: false,
					originalToLocation: this.request.destination,
					colorProfile: this.request.color_profile,
					originalFromLocation: this.request.origin,
					request_time: resend_request || this.invalidDate ? new Date() : this.request.request_time,
					request: this.request,
					resend_request: resend_request,
				},
			};
			const dateDialog = this.dialog.open(CreateHallpassFormsComponent, config);

			dateDialog
				.afterClosed()
				.pipe(
					tap(() => (this.dateEditOpen = false)),
					filter((state) => resend_request && state?.data?.date),
					switchMap((state) => {
						const body: any = {
							origin: this.request.origin.id,
							destination: this.request.destination.id,
							attachment_message: this.request.attachment_message,
							travel_type: this.request.travel_type,
							teachers: this.request.teachers.map((u) => u.id),
							duration: this.request.duration,
							request_time: moment(state.data.date.date).toISOString(),
						};
						if (this.forStaff) {
							body.student_id = this.request.student.id;
						}
						return this.requestService.createRequest(body);
					}),
					tap((request) => {
						this.request = Request.fromJSON(request);
					}),
					switchMap(() => this.requestService.cancelRequest(requestIdToCancel)),
					catchError((error) => {
						this.openErrorToast(error);
						return of(error);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe();
		}
	}

	private editMessage(): void {
		if (!this.messageEditOpen) {
			this.messageEditOpen = true;
			const infoDialog = this.dialog.open(CreateHallpassFormsComponent, {
				width: '750px',
				maxWidth: '100vw',
				panelClass: 'form-dialog-container',
				backdropClass: 'invis-backdrop',
				data: {
					entryState: 'restrictedMessage',
					originalMessage: this.request.attachment_message,
					originalToLocation: this.request.destination,
					colorProfile: this.request.color_profile,
					originalFromLocation: this.request.origin,
				},
			});

			infoDialog
				.afterClosed()
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					this.request.attachment_message = data.message === '' ? this.request.attachment_message : data.message;
					this.messageEditOpen = false;
				});
		}
	}

	cancelRequest(evt: MouseEvent): boolean {
		if (this.formState && this.formState.kioskMode && !this.forInput) {
			const CD = this.dialog.open(ConfirmDeleteKioskModeComponent, {
				panelClass: 'overlay-dialog',
			});

			CD.afterClosed()
				.pipe(takeUntil(this.destroy$))
				.subscribe((action) => {
					if (action === 'delete') {
						this.chooseAction(action);
					}
				});
		} else {
			if (!this.cancelOpen) {
				const target = new ElementRef(evt.currentTarget);
				this.options = [];
				this.header = '';
				if (!this.forInput) {
					if (this.forStaff && !this.isMissedRequest) {
						this.options.push(this.genOption('Attach Message & Deny', '#7f879d', 'deny_with_message', './assets/Message (Blue-Gray).svg'));
					} else {
						this.options.push(this.genOption('Delete Request', '#7083A0', 'delete'));
					}
				} else {
					if (!this.pinnableOpen) {
						this.formState.step = this.formState.previousStep === 1 ? 1 : 3;
						this.formState.previousStep = 4;
						this.createFormService.setFrameMotionDirection('disable');
						this.cardEvent.emit(this.formState);
					}
					return false;
				}

				UNANIMATED_CONTAINER.next(true);
				this.cancelOpen = true;
				const cancelDialog = this.dialog.open(ConsentMenuComponent, {
					panelClass: 'consent-dialog-container',
					backdropClass: 'invis-backdrop',
					data: { header: this.header, options: this.options, trigger: target, adjustForScroll: true },
				});

				cancelDialog
					.afterClosed()
					.pipe(
						tap(() => UNANIMATED_CONTAINER.next(false)),
						takeUntil(this.destroy$)
					)
					.subscribe((action) => {
						this.chooseAction(action);
					});
			}
		}
	}

	private chooseAction(action): void {
		this.cancelOpen = false;
		if (action === 'cancel' || action === 'stop') {
			this.dialogRef.close();
		} else if (action === 'editMessage') {
			this.editMessage();
		} else if (action === 'deny_with_message') {
			let denyMessage = '';
			if (action.indexOf('message') !== -1) {
				this.messageEditOpen = true;
				const config = {
					panelClass: 'form-dialog-container',
					backdropClass: 'invis-backdrop',
					data: {
						forInput: false,
						entryState: { step: 3, state: States.message },
						teachers: this.request.teachers.map((u) => u.id),
						originalMessage: '',
						originalToLocation: this.request.destination,
						colorProfile: this.request.color_profile,
						gradient: this.request.color_profile.gradient_color,
						originalFromLocation: this.request.origin,
						isDeny: true,
						studentMessage: this.request.attachment_message,
					},
				};
				const messageDialog = this.dialog.open(CreateHallpassFormsComponent, config);

				messageDialog
					.afterClosed()
					.pipe(
						filter((res) => !!res),
						takeUntil(this.destroy$)
					)
					.subscribe((matData) => {
						if (isNull(matData.data.message)) {
							this.messageEditOpen = false;
							return;
						}
						if (matData.data?.message) {
							denyMessage = matData.data.message;
							this.messageEditOpen = false;
							console.log('DENIED =====>', matData, action);
							this.denyRequest(denyMessage);
						} else {
							denyMessage = matData.message;
							this.messageEditOpen = false;
							this.denyRequest(denyMessage);
						}
					});
				return;
			}
		} else if (action === 'deny') {
			this.denyRequest('No message');
		} else if (action === 'delete') {
			this.requestService
				.cancelRequest(this.request.id)
				.pipe(
					catchError((error) => {
						this.openErrorToast(error);
						return of(error);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe(() => {
					const storageData = JSON.parse(this.storage.getItem('pinAttempts'));
					if (storageData?.[this.request.id]) {
						delete storageData[this.request.id];
						this.storage.setItem('pinAttempts', JSON.stringify({ ...storageData }));
					}
					this.dialogRef.close();
				});
		} else if (action === 'change_date') {
			this.changeDate(true);
		}
	}

	denyRequest(denyMessage: string): void {
		this.isDecliningRequest = true;
		const body = {
			message: denyMessage,
		};
		this.requestService
			.denyRequest(this.request.id, body)
			.pipe(
				takeUntil(this.destroy$),
				catchError((error) => {
					this.openErrorToast(error);
					return of(error);
				})
			)
			.subscribe((httpData) => {
				this.dialogRef.close();
			});
	}

	private genOption(
		display,
		color,
		action,
		icon?,
		hoverBackground?,
		clickBackground?
	): { display; color; action; icon; hoverBackground; clickBackground } {
		return { display, color, action, icon, hoverBackground, clickBackground };
	}

	approveRequest(): void {
		if (this.performingAction) {
			this.requestService.setAcceptRequestInflightStatus(true);
			return;
		}

		this.performingAction = true;
		this.requestService.setAcceptRequestInflightStatus(true);

		this.requestService
			.checkLimits({}, this.request, this.overriderBody)
			.pipe(
				concatMap((httpBody) => {
					return this.requestService.acceptRequest(this.request, httpBody);
				}),
				catchError((error) => {
					if (error instanceof HttpErrorResponse && error.error?.conflict_student_ids) {
						return this.encounterService.getExclusionGroups({ student: error.error?.conflict_student_ids }).pipe(
							map((exclusionGroups) => {
								this.hallpassService.showEncounterPreventionToast({
									exclusionPass: this.request,
									isStaff: this.forStaff,
									exclusionGroups,
								});
								return throwError(error);
							})
						);
					}

					return throwError(error);
				}),
				takeUntil(this.destroy$),
				finalize(() => {
					this.performingAction = false;
					this.requestService.setAcceptRequestInflightStatus(false);
				})
			)
			.subscribe({
				next: () => this.dialogRef.close(),
				error: (err: Error) => {
					this.openErrorToast(err);
					console.error(err);
				},
			});
	}

	onHover(evt: HTMLElement, container: HTMLElement): void {
		this.hoverDestroyer$ = new Subject<any>();
		const target = evt;
		target.style.width = `auto`;
		target.style.transition = `none`;

		const targetWidth = target.getBoundingClientRect().width;
		const containerWidth = container.getBoundingClientRect().width;

		let margin = 0;
		interval(35)
			.pipe(takeUntil(this.hoverDestroyer$), takeUntil(this.destroy$))
			.subscribe(() => {
				if (margin > 0) {
					this.leftTextShadow = true;
				}
				if (targetWidth - margin > containerWidth) {
					target.style.marginLeft = `-${margin}px`;
					margin++;
				} else {
					this.removeShadow = true;
				}
			});
	}

	onLeave(target: HTMLElement): void {
		target.style.marginLeft = this.filteredTeachers.length > 1 ? '0px' : '15px';
		target.style.transition = `margin-left .4s ease`;
		target.style.width = `auto`;
		this.removeShadow = false;
		this.leftTextShadow = false;
		this.hoverDestroyer$.next(undefined);
		this.hoverDestroyer$.complete();
	}

	goToPin(): void {
		this.displayTeacherPinEntry = true;
	}

	handlePinResult(teacherPinResponse: HallPass | string): void {
		if (teacherPinResponse === 'encounter prevention') {
			this.displayTeacherPinEntry = false;
			return;
		}
		this.dialogRef.close([teacherPinResponse]); // it should be a HallPass here
	}

	resendRequest(): void {
		// future requests will change date and create new pass request
		if (this.request.request_time) {
			this.changeDate(true);
			return;
		}
		// for now requests will display time picker
		if (this.showCircleCountdownForStudentDeclined) {
			this.showCircleCountdownForStudentDeclined = false;
			return;
		}

		this.requestService
			.cancelRequest(this.request.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				const body: any = {
					origin: this.request.origin.id,
					destination: this.request.destination.id,
					attachment_message: this.request.attachment_message,
					travel_type: this.request.travel_type,
					teachers: this.request.teachers.map((u) => u.id),
					duration: this.selectedDuration * 60,
					student_id: this.request?.student ? this.request.student.id : this.formState.data.kioskModeStudent.id,
				};
				if (this.request.request_time) {
					body.request_time = this.request.request_time.toISOString();
				}

				const preRequestStatus = this.request.status;
				this.request.status = 'pending';

				this.requestService
					.createRequest(body)
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: (request) => {
							this.request = Request.fromJSON(request);
							console.log('pass request resent', this.request);
						},
						error: (err) => {
							console.error('While resending a pass request: ', err);
							this.request.status = preRequestStatus;
						},
					});
			});
	}
}
