import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/**
 * This pipe is used to get the value of a form control as an observable. This
 * is useful when you want to use the value of a form control in a template but
 * you don't want to bind it to an input, perhaps because it is bound elsewhere.
 * Otherwise, with OnPush change detection, accessing `control.value` directly
 * is not reactive.
 *
 * Usage:
 *   <div *ngIf="inputControl | formValue | async as input">
 *     Value: {{ input.value }}
 *   </div>
 *   <div *ngIf="group | formValue | async as group">
 *     Value: {{ group.value.field_a }} {{ group.value.field_b}}
 *   </div>
 */
@Pipe({
	name: 'formValue',
	pure: true,
})
export class FormControlValuePipe implements PipeTransform {
	// In Angular 14, we can parameterize this by the control type instead of any.
	transform(control: AbstractControl): Observable<{ value: any }> {
		return control.valueChanges.pipe(
			startWith(control.value),
			// The value is wrapped in an object so that it is always truthy for *ngIf:
			map((value) => ({ value }))
		);
	}
}
