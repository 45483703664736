import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * You probably do not need to use this component directly!
 *
 * DialogContentComponent is a reusable component for displaying dialog content.
 * It is primarily used within the DialogService when opening dialogs. It
 * includes a close button which dismisses the modal when clicked; this dismiss
 * function is also passed to the content template as a context variable for,
 * e.g. a Cancel button.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'bp-dialog-content',
	template: `
		<button class="close-button tw-text-gray-500" (click)="data.dismiss()">
			<bp-icon icon="close-01" size="20"></bp-icon>
		</button>
		<ng-container *ngTemplateOutlet="content; context: { $implicit: data.dismiss }"></ng-container>
	`,
	styles: [
		`
			:host {
				display: flex;
				flex-direction: column;
				position: relative;
				width: 33.75rem;
				max-height: 85vh;
				height: auto;
				overflow: hidden;

				background: white;
				padding: 0;
				border-radius: 12px;
				box-shadow: 0px 5px 26px 0px rgba(0, 0, 0, 0.15);
			}

			.close-button {
				position: absolute;
				top: 28px;
				right: 28px;
				background: none;
				border: none;
				outline: none;
				cursor: pointer;
				z-index: 2; /* Ensure it's above other elements */
			}
		`,
	],
})
export class DialogContentComponent implements AfterContentChecked {
	@Input() content: TemplateRef<unknown> | null = null;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { dismiss: () => void; parentChangeDetector: ChangeDetectorRef }
	) {}

	ngAfterContentChecked(): void {
		/*
		 * The dialog should logically be treated as a child of the component that
		 * opened it. Material Dialog provides some functionality for this by
		 * passing a ViewContainerRef to the dialog config, but getting that ref is
		 * verbose (the one injected into the constructor will not work; it must be
		 * from inside the template). Instead we ask the opener to pass in its
		 * ChangeDetectorRef, and manually mark it for checking whenever the dialog
		 * content is checked.
		 *
		 * In the future, use of signals may make this unnecessary, as they provide
		 * a more granular way for callbacks inside the dialog to trigger updates on
		 * the opener.
		 */
		if (this.data.parentChangeDetector) {
			this.data.parentChangeDetector.markForCheck();
		}
	}
}
