import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

import { DialogService } from 'app/backpack/dialog-modal/dialog.service';
import { SchoolActivity } from 'app/models';
import { HttpService } from 'app/services/http-service';
import { SchoolActivityService } from 'app/services/school-activity.service';
import { TimeRangeInputType } from 'app/shared/time-range-input/time-range-input/time-range-input.component';
import * as moment from 'moment';
import { CUSTOM_SCHEDULE_DATE_KEY, CUSTOM_SCHEDULE_TIME_RANGE_KEY, getStartAndEndDates } from '../activity-form-util';

/**
 * AddCustomSessionFormComponent
 * Form component for managing date and time of a new custom activity session.
 *
 * Takes an activity, and creates a new session at the date and time specified
 * by the form.
 *
 * Intended for use with custom, non-repeating activities. Behavior for flex
 * activities or repeating custom activities is undefined.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'sp-add-custom-session-form',
	template: `
		<div>
			<bp-button
				size="lg"
				type="lightest"
				width="full"
				leftIcon="add-01"
				[shrinkOnPress]="true"
				customToolTip=""
				(click)="dialogService.openDialog(addCustomDialog, cdr)">
				New Session</bp-button
			>
		</div>
		<ng-template #addCustomDialog let-dismiss>
			<bp-dialog-header>Add Session</bp-dialog-header>
			<bp-dialog-body>
				<div class="tw-flex tw-flex-col tw-gap-8 tw-pb-3">
					<sp-calendar-input label="Date" [control]="startDateControl"></sp-calendar-input>

					<div>
						<div class="tw-text-navy-500 tw-text-base tw-font-semibold tw-mb-3">Time</div>
						<sp-time-range-input [control]="timeRangeControl" [additionalDuration]="30"></sp-time-range-input>
					</div>

					<div *ngIf="addCustomForm.errors?.error as error">
						{{ error }}
					</div>
				</div>
				<div class="tw-text-red-500 tw-py-2" *ngIf="formState === 'error'">Error saving session</div>
			</bp-dialog-body>
			<bp-dialog-footer>
				<bp-button (click)="dismiss()" type="white" title="Cancel"></bp-button>
				<bp-button
					(click)="onSaveCustom(dismiss)"
					[disabled]="formState == 'saving'"
					type="color"
					[title]="formState == 'saving' ? 'Saving...' : 'Save'"></bp-button>
			</bp-dialog-footer>
		</ng-template>
	`,
})
export class AddCustomSessionFormComponent {
	@Input() activity!: SchoolActivity;

	startDateControl = new FormControl<moment.Moment>(moment(), { nonNullable: true });
	timeRangeControl = new FormControl<TimeRangeInputType | undefined>(undefined);
	addCustomForm = new FormGroup({
		[CUSTOM_SCHEDULE_DATE_KEY]: this.startDateControl,
		[CUSTOM_SCHEDULE_TIME_RANGE_KEY]: this.timeRangeControl,
	});
	formState: '' | 'saving' | 'error' = ''; // Manage button status and error display

	constructor(
		public dialogService: DialogService,
		private httpService: HttpService,
		private schoolActivityService: SchoolActivityService,
		public cdr: ChangeDetectorRef
	) {}

	async onSaveCustom(dismiss: () => void) {
		if (!this.addCustomForm.value[CUSTOM_SCHEDULE_DATE_KEY] || !this.addCustomForm.value[CUSTOM_SCHEDULE_TIME_RANGE_KEY]) {
			return;
		}

		const tz = this.httpService.currentSchoolSubject.getValue().timezone;
		const { startTime, endTime } = getStartAndEndDates(
			this.addCustomForm.value[CUSTOM_SCHEDULE_DATE_KEY],
			this.addCustomForm.value[CUSTOM_SCHEDULE_TIME_RANGE_KEY],
			tz
		);

		// Create a new session directly with the API, showing a loading spinner in the form while waiting.
		// On error, the modal displays an error instead of closing.
		try {
			this.formState = 'saving';
			const resp = await firstValueFrom(
				this.schoolActivityService.CreateActivityInstanceHTTP(startTime.toDate(), endTime.toDate(), this.activity.id, 'scheduled')
			);
			this.schoolActivityService.AddActivityInsatnceToStore(resp);
			this.formState = '';

			dismiss();
		} catch {
			this.formState = 'error';
		}
	}
}
