import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FeatureIconEncounterPreventionComponent } from './feature-icons/feature-icon-encounter-prevention.component';
import { FeatureIconPassLimitsComponent } from './feature-icons/feature-icon-pass-limits.component';
import { IconAdd01Component } from './icons/icon-add-01.component';
import { IconAdminComponent } from './icons/icon-admin.component';
import { IconAppearanceComponent } from './icons/icon-appearance.component';
import { IconArrowLeft02Component } from './icons/icon-arrow-left-02.component';
import { IconBalloonsComponent } from './icons/icon-balloons.component';
import { IconCalendarComponent } from './icons/icon-calendar.component';
import { IconChangeUserComponent } from './icons/icon-change-user.component';
import { IconCheckInComponent } from './icons/icon-check-in.component';
import { IconCheckmark01Component } from './icons/icon-checkmark-01.component';
import { IconCheckmark02Component } from './icons/icon-checkmark-02.component';
import { IconChevronExpandComponent } from './icons/icon-chevron-expand.component';
import { IconChevronLeftComponent } from './icons/icon-chevron-left.component';
import { IconChevronRightComponent } from './icons/icon-chevron-right.component';
import { IconClassComponent } from './icons/icon-class.component';
import { IconClock01Component } from './icons/icon-clock-01.component';
import { IconClock03Component } from './icons/icon-clock-03.component';
import { IconClose01Component } from './icons/icon-close-01.component';
import { IconClose02Component } from './icons/icon-close-02.component';
import { IconContactTraceComponent } from './icons/icon-contact-trace.component';
import { IconDashboardComponent } from './icons/icon-dashboard.component';
import { IconDataComponent } from './icons/icon-data.component';
import { IconDetectedEncountersComponent } from './icons/icon-detected-encounters.component';
import { IconDownloadCircleComponent } from './icons/icon-download-circle.component';
import { IconDropInComponent } from './icons/icon-drop-in.component';
import { IconEditComponent } from './icons/icon-edit.component';
import { IconEmergencyComponent } from './icons/icon-emergency.component';
import { IconEmptyBoxComponent } from './icons/icon-empty-box.component';
import { IconError01Component } from './icons/icon-error-01.component';
import { IconError02Component } from './icons/icon-error-02.component';
import { IconExploreComponent } from './icons/icon-explore.component';
import { IconFlexComponent } from './icons/icon-flex.component';
import { IconGiftComponent } from './icons/icon-gift.component';
import { IconGlobeComponent } from './icons/icon-globe.component';
import { IconGroupComponent } from './icons/icon-group.component';
import { IconHallMonitorComponent } from './icons/icon-hall-monitor.component';
import { IconHandComponent } from './icons/icon-hand.component';
import { IconHelpComponent } from './icons/icon-help.component';
import { IconIdCard02Component } from './icons/icon-id-card-02.component';
import { IconLaunchComponent } from './icons/icon-launch.component';
import { IconMore01Component } from './icons/icon-more-01.component';
import { IconMore02Component } from './icons/icon-more-02.component';
import { IconNoTeacherComponent } from './icons/icon-no-teacher.component';
import { IconPassLimitsComponent } from './icons/icon-pass-limits.component';
import { IconPassesComponent } from './icons/icon-passes.component';
import { IconPasswordComponent } from './icons/icon-password.component';
import { IconRoomComponent } from './icons/icon-room.component';
import { IconScheduleComponent } from './icons/icon-schedule.component';
import { IconSchoolComponent } from './icons/icon-school.component';
import { IconSettingsTeacherComponent } from './icons/icon-settings-teacher.component';
import { IconShirtComponent } from './icons/icon-shirt.component';
import { IconShowComponent } from './icons/icon-show.component';
import { IconSparklesComponent } from './icons/icon-sparkles.component';
import { IconStarComponent } from './icons/icon-star.component';
import { IconSunComponent } from './icons/icon-sun.component';
import { IconUserComponent } from './icons/icon-user.component';
import { IconVolumeComponent } from './icons/icon-volume.component';

/**
 * This component provides a simple interface for rendering any Backpack icon.
 * Icons are automatically centered in a box of the specified size.
 *
 * Usage: `<bp-icon icon="dashboard" size="24"></bp-icon>`
 *
 * To add a new icon: (Only add icons that exist in Backpack in Figma!)
 *  1. Create a new component in the `icons` directory
 *  2. Edit the SVG template to remove the `width` and `height` attributes, and set `fill` and `stroke` to `currentcolor`
 *  2. Add the component to the module's `declarations` array, and add it to the `icons` record below.
 */
@Component({
	selector: 'bp-icon',
	template: ` <ng-container *ngComponentOutlet="iconComponent"></ng-container>`,
	styles: [
		`
			:host {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 0 0 auto;

				& > * {
					display: contents;

					svg {
						/* Some browsers (Safari) default to 0% instead of 100%: */
						height: 100%;
						width: 100%;
					}
				}
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackpackIconComponent {
	@HostBinding('style.height.px')
	@HostBinding('style.width.px')
	@Input()
	size = '20';

	@Input() icon: IconName = 'dashboard';

	get iconComponent() {
		return icons[this.icon];
	}
}

export const icons = {
	admin: IconAdminComponent,
	'add-01': IconAdd01Component,
	appearance: IconAppearanceComponent,
	'arrow-left-02': IconArrowLeft02Component,
	balloons: IconBalloonsComponent,
	calendar: IconCalendarComponent,
	'change-user': IconChangeUserComponent,
	'check-in': IconCheckInComponent,
	'checkmark-01': IconCheckmark01Component,
	'checkmark-02': IconCheckmark02Component,
	'chevron-expand': IconChevronExpandComponent,
	'chevron-left': IconChevronLeftComponent,
	'chevron-right': IconChevronRightComponent,
	class: IconClassComponent,
	'clock-01': IconClock01Component,
	'clock-03': IconClock03Component,
	'close-01': IconClose01Component,
	'close-02': IconClose02Component,
	'contact-trace': IconContactTraceComponent,
	dashboard: IconDashboardComponent,
	data: IconDataComponent,
	'drop-in': IconDropInComponent,
	'detected-encounters': IconDetectedEncountersComponent,
	'download-circle': IconDownloadCircleComponent,
	edit: IconEditComponent,
	emergency: IconEmergencyComponent,
	'empty-box': IconEmptyBoxComponent,
	'error-01': IconError01Component,
	'error-02': IconError02Component,
	explore: IconExploreComponent,
	flex: IconFlexComponent,
	gift: IconGiftComponent,
	globe: IconGlobeComponent,
	group: IconGroupComponent,
	'hall-monitor': IconHallMonitorComponent,
	hand: IconHandComponent,
	help: IconHelpComponent,
	'id-card-02': IconIdCard02Component,
	launch: IconLaunchComponent,
	'no-teacher': IconNoTeacherComponent,
	'more-01': IconMore01Component,
	'more-02': IconMore02Component,
	'pass-limits': IconPassLimitsComponent,
	passes: IconPassesComponent,
	password: IconPasswordComponent,
	room: IconRoomComponent,
	school: IconSchoolComponent,
	'settings-teacher': IconSettingsTeacherComponent,
	shirt: IconShirtComponent,
	show: IconShowComponent,
	sparkles: IconSparklesComponent,
	star: IconStarComponent,
	sun: IconSunComponent,
	schedule: IconScheduleComponent,
	user: IconUserComponent,
	volume: IconVolumeComponent,

	// Feature Icons
	'feature-encounter-prevention': FeatureIconEncounterPreventionComponent,
	'feature-pass-limits': FeatureIconPassLimitsComponent,
} as const;

export type IconName = keyof typeof icons;
