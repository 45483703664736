import { EntityState } from '@ngrx/entity';
import { School, SchoolActivity, SchoolActivityInstance } from '../../models';
import { SchoolActivityAttendee } from '../../services/school-activity.service';
import { accountsReducer } from '../accounts/reducers';
import { IAccountsState } from '../accounts/states';
import { colorsReducer } from '../color-profiles/reducers';
import { ColorsState } from '../color-profiles/states';
import { contactTraceReducer } from '../contact-trace/reducers';
import { IContactTraceStates } from '../contact-trace/states';
import { EncounterDetectionState } from '../encounter-detection';
import { encounterDetectionReducer } from '../encounter-detection/reducers';
import { exclusionGroupsReducer } from '../encounters-prevention/excusion-groups/reducers/exclusion-groups.reducer';
import { IExclusionGroupsState } from '../encounters-prevention/excusion-groups/states';
import { favoriteLocationsReducer } from '../favorite-locations/reducers';
import { FavoriteLocationsState } from '../favorite-locations/states';
import { flexPeriodsReducer, FlexPeriodState } from '../flex-periods';
import { IIntrosState, IntrosReducer } from '../intros';
import { locationsReducer } from '../locations/reducers';
import { LocationsState } from '../locations/states';
import { onboardProcessReducer } from '../onboard-process/reducers';
import { ProcessState } from '../onboard-process/states';
import { PassFiltersReducer } from '../pass-filters/reducers';
import { IFiltersState } from '../pass-filters/states';
import { passLikeCollectionReducer } from '../pass-like-collection/reducers';
import { IPassLikeCollectionState } from '../pass-like-collection/states/pass-like-collection.state';
import { passLimitsReducer } from '../pass-limits/reducers';
import { IPassLimitState } from '../pass-limits/states';
import { IPassesState, passesReducer } from '../passes';
import { pinnablesReducer } from '../pinnables/reducers';
import { IPinnablesState } from '../pinnables/states';
import { profilePicturesReducer } from '../profile-pictures/reducers';
import { IProfilePicturesState } from '../profile-pictures/states';
import { quickPreviewPassesReducer } from '../quick-preview-passes/reducers';
import { IQuickPreviewPassesState } from '../quick-preview-passes/states';
import { ReportsState } from '../reports';
import { reportsReducer } from '../reports/reducers';
import { representedUsersReducer } from '../represented-users/reducers';
import { IRepresentedUsersState } from '../represented-users/states';
import { schoolActivitiesReducer } from '../school-activities';
import { schoolActivitiesInstancesReducer } from '../school-activities-instances';
import { schoolActivityAttendeesReducer } from '../school-activity-attendees';
import { schoolsReducer } from '../schools/reducers';
import { SchoolsState } from '../schools/states';
import { studentGroupsReducer } from '../student-groups/reducers';
import { GroupsStates } from '../student-groups/states';
import { teacherLocationsReducer } from '../teacherLocations/reducers';
import { toastReducer } from '../toast/reducers';
import { IToastState } from '../toast/states';
import { userReducer } from '../user/reducers/user.reducer';
import { UserState } from '../user/states';

export interface AppState {
	readonly accounts: IAccountsState;
	readonly colorProfiles: ColorsState;
	readonly contactTrace: IContactTraceStates;
	readonly encounterDetection: EncounterDetectionState;
	readonly exclusionGroups: IExclusionGroupsState;
	readonly favoriteLocations: FavoriteLocationsState;
	readonly flexPeriods: FlexPeriodState;
	readonly intros: IIntrosState;
	readonly locations: LocationsState;
	readonly onboardProcess: ProcessState;
	readonly pass_filters: IFiltersState;
	readonly pass_limits: IPassLimitState;
	readonly passes: IPassesState;
	readonly passLikeCollection: IPassLikeCollectionState;
	readonly pinnables: IPinnablesState;
	readonly profilePictures: IProfilePicturesState;
	readonly quickPreviewPasses: IQuickPreviewPassesState;
	readonly reports: ReportsState;
	readonly representedUsers: IRepresentedUsersState;
	readonly school: School[];
	readonly schoolActivities: EntityState<SchoolActivity>;
	readonly schoolActivityAttendees: EntityState<SchoolActivityAttendee>;
	readonly schoolActivityInstances: EntityState<SchoolActivityInstance>;
	readonly schools: SchoolsState;
	readonly studentGroups: GroupsStates;
	readonly teacherLocations: LocationsState;
	readonly toast: IToastState;
	readonly user: UserState;
}

export const reducers = {
	accounts: accountsReducer,
	colorProfiles: colorsReducer,
	contactTrace: contactTraceReducer,
	encounterDetection: encounterDetectionReducer,
	exclusionGroups: exclusionGroupsReducer,
	favoriteLocations: favoriteLocationsReducer,
	flexPeriods: flexPeriodsReducer,
	intros: IntrosReducer,
	locations: locationsReducer,
	onboardProcess: onboardProcessReducer,
	pass_filters: PassFiltersReducer,
	pass_limits: passLimitsReducer,
	passes: passesReducer,
	passLikeCollection: passLikeCollectionReducer,
	pinnables: pinnablesReducer,
	profilePictures: profilePicturesReducer,
	quickPreviewPasses: quickPreviewPassesReducer,
	reports: reportsReducer,
	representedUsers: representedUsersReducer,
	schoolActivities: schoolActivitiesReducer,
	schoolActivityAttendees: schoolActivityAttendeesReducer,
	schoolActivityInstances: schoolActivitiesInstancesReducer,
	schools: schoolsReducer,
	studentGroups: studentGroupsReducer,
	teacherLocations: teacherLocationsReducer,
	toast: toastReducer,
	user: userReducer,
};
