import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { getUpcomingCustomInstanceTimes, getUpcomingFlexInstanceTimes } from 'app/activities/activity-form-util';
import { SchoolActivity, SchoolActivityInstance, SchoolActivityState } from '../../../models';
import { FlexPeriod } from '../../../services/flex-period.service';
import { InstanceTimes } from '../../../services/school-activity.service';

interface SchedulePeriod {
	start: Date;
	end: Date;
	selected: boolean;
	hidden: boolean;
}
@Component({
	selector: 'sp-select-days',
	templateUrl: './select-days.component.html',
	styleUrls: ['./select-days.component.scss'],
})
export class SelectDaysComponent implements OnInit {
	closeDialog: (state: SchoolActivityState, starts: InstanceTimes[]) => void = () => {
		/* */
	};
	options: SchedulePeriod[] = [];
	private maxSelect = 6;
	private repeatsEveryFlexPeriodSelected = false; // this will be true if the user selects the "repeats every flex period" toggle
	selectDaysForm = new UntypedFormGroup({ toggle: new UntypedFormControl('') });
	private state: SchoolActivityState = 'flex_recurring';

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			flexPeriod?: FlexPeriod;
			state: SchoolActivityState;
			activity?: SchoolActivity;
			instances?: SchoolActivityInstance[];
			preSelectedDates?: Date[];
		}
	) {}

	ngOnInit(): void {
		this.buildOptions();
	}

	buildOptions(): void {
		let instanceTimes: InstanceTimes[] = [];

		// Generate upcoming schedules depending on the input activity or flex period
		if (this.data.activity) {
			switch (this.data.activity.schedule_type) {
				case 'custom':
					if (!this.data.activity.custom_start_time || !this.data.activity.custom_end_time) {
						return;
					}

					instanceTimes = getUpcomingCustomInstanceTimes(
						this.data.activity.custom_start_time,
						this.data.activity.custom_end_time,
						this.data.activity.custom_repeat_rule,
						this.data.activity.repeat_end_date
					);

					break;
				case 'flex_period':
					if (!this.data.flexPeriod) {
						return;
					}
					instanceTimes = getUpcomingFlexInstanceTimes(this.data.flexPeriod, new Date(), this.data.activity.repeat_end_date);
					break;
			}
		} else if (this.data.flexPeriod) {
			instanceTimes = getUpcomingFlexInstanceTimes(this.data.flexPeriod, new Date());
		} else {
			return;
		}

		// Create SchedulePeriod entries that hide or preselect the dates specified
		// by the component input
		const scheduleArray: SchedulePeriod[] = [];
		for (const instanceTime of instanceTimes) {
			const startTime = instanceTime.start;
			const endTime = instanceTime.end;

			// Add the start and end times to the scheduleArray
			const hidden =
				this.data.instances?.some((instance) => {
					return moment(instance.start_time).isSame(moment(startTime), 'minute');
				}) ?? false;
			const preselected =
				this.data.preSelectedDates?.some((preSelectedDate) => {
					return moment(preSelectedDate).isSame(moment(startTime), 'minute');
				}) ?? false;
			scheduleArray.push({ start: startTime, end: endTime, selected: preselected, hidden: hidden });
		}

		this.state = this.data.state;
		this.options = scheduleArray;
	}

	tomorrow(): Date {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(tomorrow.getDate() + 1);
		return tomorrow;
	}

	incrementDate(date: Date): Date {
		const nextDate = new Date(date);
		nextDate.setDate(nextDate.getDate() + 1);
		return nextDate;
	}

	toggle(start: Date): void {
		if (this.repeatsEveryFlexPeriodSelected) {
			this.onEnabledToggle(false);
			return;
		}

		const option = this.options.find((schedule) => schedule.start === start);
		const numSelected = this.options.reduce((accumulator, obj) => {
			if (obj.selected === true) {
				return accumulator + 1;
			}
			return accumulator;
		}, 0);

		if (option && (numSelected < this.maxSelect || option.selected)) {
			option.selected = !option.selected;
		}
	}

	handleCancel(): void {
		const times: InstanceTimes[] = this.options
			.filter((option) => this.data.preSelectedDates?.some((preSelectedDate) => preSelectedDate.getTime() === option.start.getTime()))
			.map((option) => {
				return { start: option.start, end: option.end };
			});
		this.closeDialog('scheduled', times);
	}

	handleSubmit(): void {
		const times: InstanceTimes[] = this.options
			.filter((option) => option.selected)
			.map((option) => {
				return { start: option.start, end: option.end };
			});
		if (times.length === 0) {
			this.handleCancel();
		} else {
			this.closeDialog(this.state, times);
		}
	}

	onEnabledToggle(recurring: boolean): void {
		this.repeatsEveryFlexPeriodSelected = recurring;
		if (recurring) {
			this.state = 'flex_recurring';
		}
		this.options.forEach((option) => {
			option.selected = recurring;
		});
	}
}
