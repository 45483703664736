import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'app/services/http-service';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { ROLES, User } from '../models/User';
import { ProfileStatus, UserService } from '../services/user.service';

@Component({
	selector: 'app-smartpass-search',
	templateUrl: './smartpass-search.component.html',
	styleUrls: ['./smartpass-search.component.scss'],
	animations: [
		trigger('inputAnimate', [
			state(
				'open',
				style({
					width: '260px',
				})
			),
			state(
				'close',
				style({
					width: '242px',
				})
			),
			transition('open <=> close', animate('.3s ease')),
		]),
		trigger('logoAnimate', [
			state(
				'open',
				style({
					'margin-right': '12px',
					display: 'block',
				})
			),
			state(
				'close',
				style({
					'margin-right': '0',
					display: 'none',
				})
			),
			transition('open <=> close', animate('.3s ease')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartpassSearchComponent {
	@Input() focused = false;
	@Input() height = '30px';
	@Input() width = '';

	isFocus = false;
	results$ = new BehaviorSubject<User[]>([]);
	resetInputValue$: Subject<string> = new Subject<string>();
	loading = false;
	loaded = false;

	constructor(private router: Router, private userService: UserService, private cdr: ChangeDetectorRef, private http: HttpService) {}

	search(value: string | undefined) {
		if (!value) {
			this.loading = false;
			this.loaded = false;
			this.results$.next([]);
			return;
		}

		void this.searchRequestHttp(value, []);
	}

	goToUserPage(user: User) {
		void this.router.navigateByUrl(`/main/student/${user.id}`);
		this.isFocus = false;
		this.resetInputValue$.next('');
		this.afterSearchHttp(user.id);
	}

	goToHomePage() {
		void this.router.navigateByUrl(`/main/passes`);
		this.isFocus = false;
		this.resetInputValue$.next('');
	}

	focusEvent(value: boolean) {
		this.isFocus = value;
	}

	async searchRequestHttp(searchValue: string, hideStatuses?: ProfileStatus[]) {
		this.loading = true;
		this.loaded = false;

		const resp = await firstValueFrom(this.userService.searchProfile(ROLES.Student, 50, searchValue, hideStatuses));
		this.results$.next(resp.results);

		this.loading = false;
		this.loaded = true;
	}

	afterSearchHttp(userId: number): Promise<unknown> {
		// Always executes call whether the caller awaits it or not
		return firstValueFrom(this.http.post('v1/recent_search', { user: userId }));
	}
}
