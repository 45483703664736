import { createAction, props } from '@ngrx/store';
import { SchoolActivityInstance } from '../../../models';
import {
	BulkSignUpForActivityReq,
	GetAttendeesForInstanceReq,
	SchoolActivityAttendee,
	SignUpForActivityReq,
} from '../../../services/school-activity.service';

export enum SchoolActivityAttendeesActionTypes {
	GetAttendeesForInstance = '[SchoolActivityAttendees] GetAttendeesForInstance',
	GetAttendeesForInstanceSuccess = '[SchoolActivityAttendees] GetAttendeesForInstance Success',
	GetAttendeesForInstanceFailure = '[SchoolActivityAttendees] GetAttendeesForInstance Failure',

	RemoveAttendeesAction = '[SchoolActivityAttendees] RemoveAttendeesAction',
	RemoveAttendeesActionSuccess = '[SchoolActivityAttendees] RemoveAttendeesAction Success',
	RemoveAttendeesActionFailure = '[SchoolActivityAttendees] RemoveAttendeesAction Failure',

	RemoveAttendeeAndSignUpAction = '[SchoolActivityAttendees] RemoveAttendeeAndSignUpAction',
	RemoveAttendeeAndSignUpActionSuccess = '[SchoolActivityAttendees] RemoveAttendeeAndSignUpAction Success',
	RemoveAttendeeAndSignUpActionFailure = '[SchoolActivityAttendees] RemoveAttendeeAndSignUpAction Failure',

	BulkRemoveAttendeeAndSignUpAction = '[SchoolActivityAttendees] BulkRemoveAttendeeAndSignUpAction',
	BulkRemoveAttendeeAndSignUpActionSuccess = '[SchoolActivityAttendees] BulkRemoveAttendeeAndSignUpAction Success',
	BulkRemoveAttendeeAndSignUpActionFailure = '[SchoolActivityAttendees] BulkRemoveAttendeeAndSignUpAction Failure',

	SignUpForActivity = '[SchoolActivityAttendees] SignUpForActivity',
	SignUpForActivitySuccess = '[SchoolActivityAttendees] SignUpForActivity Success',
	SignUpForActivityFailure = '[SchoolActivityAttendees] SignUpForActivity Failure',

	BulkSignUpForActivity = '[SchoolActivityAttendees] BulkSignUpForActivity',
	BulkSignUpForActivitySuccess = '[SchoolActivityAttendees] BulkSignUpForActivity Success',
	BulkSignUpForActivityFailure = '[SchoolActivityAttendees] BulkSignUpForActivity Failure',

	BulkSignOutForActivity = '[SchoolActivityAttendees] BulkSignOutForActivity',
	BulkSignOutForActivitySuccess = '[SchoolActivityAttendees] BulkSignOutForActivity Success',
	BulkSignOutForActivityFailure = '[SchoolActivityAttendees] BulkSignOutForActivity Failure',
}

export const GetAttendeesForInstanceAction = createAction(
	SchoolActivityAttendeesActionTypes.GetAttendeesForInstance,
	props<{ req: GetAttendeesForInstanceReq }>()
);

export const GetAttendeesForInstanceSuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.GetAttendeesForInstanceSuccess,
	props<{ attendees: SchoolActivityAttendee[] }>()
);

export const GetAttendeesForInstanceFailureAction = createAction(
	SchoolActivityAttendeesActionTypes.GetAttendeesForInstanceFailure,
	props<{ error: string }>()
);

export const RemoveAttendeesAction = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeesAction);

export const RemoveAttendeesSuccessAction = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeesActionSuccess);

export const RemoveAttendeesFailureAction = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeesActionFailure);

export const RemoveAttendeeAndSignUpAction = createAction(
	SchoolActivityAttendeesActionTypes.RemoveAttendeeAndSignUpAction,
	props<{ attendeeId: number; req?: SignUpForActivityReq; instance?: SchoolActivityInstance }>()
);

export const RemoveAttendeeAndSignUpActionSuccess = createAction(SchoolActivityAttendeesActionTypes.RemoveAttendeeAndSignUpActionSuccess);

export const RemoveAttendeeAndSignUpActionFailure = createAction(
	SchoolActivityAttendeesActionTypes.RemoveAttendeeAndSignUpActionFailure,
	props<{ error: string }>()
);

export const BulkRemoveAttendeeAndSignUpAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkRemoveAttendeeAndSignUpAction,
	props<{ attendeeIds: number[]; req: BulkSignUpForActivityReq; instance: SchoolActivityInstance }>()
);
export const BulkRemoveAttendeeAndSignUpActionSuccess = createAction(SchoolActivityAttendeesActionTypes.BulkRemoveAttendeeAndSignUpActionSuccess);

export const BulkRemoveAttendeeAndSignUpActionFailure = createAction(
	SchoolActivityAttendeesActionTypes.BulkRemoveAttendeeAndSignUpActionFailure,
	props<{ error: string }>()
);

export const SignUpForActivityAction = createAction(
	SchoolActivityAttendeesActionTypes.SignUpForActivity,
	props<{ req: SignUpForActivityReq; instance: SchoolActivityInstance }>()
);

export const SignUpForActivitySuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.SignUpForActivitySuccess,
	props<{ attendee: SchoolActivityAttendee }>()
);

export const SignUpForActivityFailureAction = createAction(SchoolActivityAttendeesActionTypes.SignUpForActivityFailure, props<{ error: string }>());

export const BulkSignUpForActivityAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignUpForActivity,
	props<{ req: BulkSignUpForActivityReq; instance: SchoolActivityInstance }>()
);

export const BulkSignUpForActivitySuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignUpForActivitySuccess,
	props<{ attendees: SchoolActivityAttendee[] }>()
);

export const BulkSignUpForActivityFailureAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignUpForActivityFailure,
	props<{ error: string }>()
);

export const BulkSignOutForActivityAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignOutForActivity,
	props<{ attendeeIds: number[] }>()
);

export const BulkSignOutForActivitySuccessAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignOutForActivitySuccess,
	props<{ attendeeIds: number[] }>()
);

export const BulkSignOutForActivityFailureAction = createAction(
	SchoolActivityAttendeesActionTypes.BulkSignOutForActivityFailure,
	props<{ error: string }>()
);
