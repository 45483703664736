import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { distinctUntilChanged, map, repeat, scan, shareReplay, switchMap, take } from 'rxjs/operators';
import { EventData, LiveUpdateEvent, LiveUpdateService } from './live-update.service';

interface RTTimeReport {
	requestTime: number;
	responseTime: number;
	serverTime: number;
}

interface DriftEstimate {
	drift: number; // server - client
	error: number;
}

@Injectable({
	providedIn: 'root',
})
/**
 * This service has static methods for convenience. It must be dependency injected somewhere
 * to properly load it's drift measurements.
 */
export class TimeService {
	/**
	 * An estimate the client's time drift from server time. This number is calculated as server time - client time
	 * so that it can be added to the client's current time.
	 */
	static latestDriftEstimate$ = new BehaviorSubject(0);
	static readonly ActiveTolerance = 60000; // in milliseconds

	/**
	 * This observable emits the current (server-drift-adjusted) time each second.
	 * Note that this rate is not guaranteed: it can emit more frequently (if a new drift estimate is received)
	 * or less (if the tab is in the background or the device is in a low-power state).
	 */
	now$: Observable<Date> = TimeService.latestDriftEstimate$.pipe(
		switchMap((drift) => {
			// How many milliseconds are we past the last second?
			const clockOffset = (new Date().getTime() + drift) % 1000;

			return new Observable<Date>((observer) => {
				// Get the current timestamp, shifted back to the previous flat second.
				const base: DOMHighResTimeStamp = performance.now() - clockOffset;
				let lastTick = 0;
				let animationFrameId: number;
				observer.next(undefined);

				const emitHeartbeat = (ts: DOMHighResTimeStamp) => {
					// If this tick is in a different 1000ms window than the last one, the wall clock time is now in a new second.
					const tick = Math.floor((ts - base) / 1000);
					if (tick !== lastTick) {
						observer.next(undefined);
						lastTick = tick;
					}
					animationFrameId = requestAnimationFrame(emitHeartbeat);
				};

				this.ngZone.runOutsideAngular(() => {
					animationFrameId = requestAnimationFrame(emitHeartbeat);
				});

				return () => cancelAnimationFrame(animationFrameId);
			}).pipe(map(() => new Date(Date.now() + drift)));
		}),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	constructor(private liveUpdateService: LiveUpdateService, private ngZone: NgZone) {
		this.liveUpdateService
			.listen('heartbeat')
			.pipe(
				take(1),
				switchMap(() => this.requestServerTime()),
				repeat(10),
				map(computeEstimatedDrift),
				scan<DriftEstimate, DriftEstimate[]>((acc, value) => {
					const acc2 = [value, ...acc];
					// only keep the eight lowest error drift estimates.
					acc2.sort((a, b) => a.error - b.error);
					return acc2.slice(0, 7);
				}, []),
				map(combineDriftEstimates),
				distinctUntilChanged()
			)
			.subscribe((driftEstimate) => TimeService.latestDriftEstimate$.next(driftEstimate));
	}

	/**
	 * This method has some important caveats. First, it cannot handle simultaneous calls sharing the same websocket.
	 * Second, it's a cold observable ie. it only sends the request to the server when someone subscribes to it.
	 */
	private requestServerTime(): Observable<RTTimeReport> {
		return defer(() => {
			const requestTime = Date.now();
			this.liveUpdateService.sendMessage('time.time_request', null);

			return this.liveUpdateService.listen('time.time_response').pipe(
				map((event: LiveUpdateEvent) => {
					return {
						requestTime: requestTime,
						serverTime: Date.parse((event.data as EventData).utc_time),
						responseTime: Date.now(),
					};
				}),
				take(1)
			);
		});
	}

	now() {
		return Date.now() + TimeService.latestDriftEstimate$.value;
	}

	nowDate(): Date {
		return new Date(this.now());
	}

	static getNowDate(): Date {
		return new Date(Date.now() + TimeService.latestDriftEstimate$.value);
	}
}

function computeEstimatedDrift(report: RTTimeReport): DriftEstimate {
	// written defensively against integer overflow and cast to an integer

	let error = report.responseTime - report.requestTime;
	if (error === 0) {
		error = 1;
	}

	const rtt = report.responseTime - report.requestTime;
	const oneWayLatency = rtt / 2;
	const drift = report.serverTime - (report.responseTime + oneWayLatency);

	/**
	 * if serverTime is greater than responseTime, that means the serverTime is ahead and the client
	 * is behind server time. We'll represent this with a positive drift.
	 * Likewise, if responseTime is greater than serverTime, it means the client is ahead of the
	 * server, we'll represent this with a negative drift
	 */

	return {
		drift,
		error: error,
	};
}

function combineDriftEstimates(estimates: DriftEstimate[]) {
	let numerator = 0;
	let denominator = 0;

	for (const estimate of estimates) {
		numerator += estimate.drift / estimate.error;
		denominator += 1 / estimate.error;
	}

	return Math.round(numerator / denominator);
}
