import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { captureMessage } from '@sentry/angular';
import { RoomSwitchService } from 'app/services/room-switch.service';
import { remove } from 'lodash';
import { iif, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, retryWhen, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { pickTruthy } from 'Util';
import { scalePassCards } from '../animations';
import { Navigation } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { HallPass, HallPassStatus } from '../models/HallPass';
import { HallPassLimit } from '../models/HallPassLimits';
import { Location } from '../models/Location';
import { School } from '../models/School';
import { User } from '../models/User';
import { FeatureFlagService, FLAGS } from '../services/feature-flag.service';
import { HallPassesService, PassRequestBody } from '../services/hall-passes.service';
import { LocationsService } from '../services/locations.service';
import { PassLimitService } from '../services/pass-limit.service';
import { ToastService } from '../services/toast.service';
import { UserService } from '../services/user.service';
import { ConfirmationDialogComponent, ConfirmationTemplates } from '../shared/shared-components/confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'sp-duration-travel-form',
	templateUrl: './duration-travel-form.component.html',
	styleUrls: ['./duration-travel-form.component.scss'],
	animations: [scalePassCards],
	providers: [DatePipe],
})
export class DurationTravelFormComponent implements OnInit, OnChanges, OnDestroy {
	@Input() pass!: HallPass;
	@Input() forStaff = false;
	@Input() formState!: Navigation;
	@Input() forFuture = false;
	@Input() students: User[] = [];

	@Output() cardEvent = new EventEmitter<Navigation>();

	@ViewChild('confirmDialogBody') confirmDialog?: TemplateRef<HTMLElement>;
	@ViewChild('confirmDialogBodyVisibility') confirmDialogVisibility?: TemplateRef<HTMLElement>;

	// Form state
	selectedDuration = 0;
	selectedTravelType = 'one_way';
	selectedLocation!: Location;
	passLimit?: HallPassLimit;

	// Action meta data
	performingAction = false;

	private user?: User;
	private school?: School;

	// Cached template variables
	passStatus!: HallPassStatus;
	backgroundGradient = '';
	footerBackgroundGradient = '';
	endedBgColorClass = '';
	passCssClasses = '';
	borderRadius = '';
	borderClasses = '';
	buttonText = '';
	originRoomIcon?: string;

	private destroy$ = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<DurationTravelFormComponent>,
		private hallPassService: HallPassesService,
		public dialog: MatDialog,
		private userService: UserService,
		private toastService: ToastService,
		private locationsService: LocationsService,
		private features: FeatureFlagService,
		private cdr: ChangeDetectorRef,
		private hallPassesService: HallPassesService,
		private locationService: LocationsService,
		private datePipe: DatePipe,
		private router: Router,
		private passLimitService: PassLimitService,
		private roomSwitchService: RoomSwitchService
	) {}

	get waitInLineEnabled(): boolean {
		return this.features.isFeatureEnabled(FLAGS.WaitInLine);
	}

	get originWaitInLineEnabled(): boolean {
		return this.features.isFeatureEnabledV2(FLAGS.OriginWaitInLine);
	}

	getButtonText(): string {
		// If there are no selected students, this pass is being created for oneself (numStudents becomes 1)
		const numStudents = this.students?.length || 1;
		const PASS = `Pass${numStudents > 1 ? 'es' : ''}`;

		if (this.forFuture) {
			return `Schedule ${PASS}`;
		}

		const destLimitReached = this.formState?.data?.destLimitReached;
		const origLimitReached = this.originWaitInLineEnabled && this.formState?.data?.origLimitReached;
		if (this.waitInLineEnabled && (destLimitReached || origLimitReached) && numStudents === 1) {
			return this.forStaff ? 'Send to Line' : 'Wait in Line';
		}

		return this.forStaff ? `Send ${PASS}` : `Start ${PASS}`;
	}

	ngOnInit() {
		this.borderRadius = this.formState?.openedFrom !== 'navbar' ? '20px' : '0 0 20px 20px';
		this.school = this.userService.getUserSchool();
		this.passLimitService
			.getPassLimit()
			.pipe(takeUntil(this.destroy$))
			.subscribe((pl) => (this.passLimit = pl.pass_limit));

		try {
			if (!this.pass) {
				captureMessage('no pass in pass card from input or data - schoolId: ' + this.school.id);
			} else if (!this.pass.destination) {
				captureMessage('from pass input - no pass destination in pass card: ' + JSON.stringify(this.pass) + ' schoolId: ' + this.school.id);
			} else if (!this.pass.origin) {
				captureMessage('from pass input - no pass origin in pass card: ' + JSON.stringify(this.pass) + ' schoolId: ' + this.school.id);
			}
		} catch (e) {
			console.log('error trying to capture message in sentry', e);
		}

		this.buttonText = this.getButtonText();
		this.getOriginRoomIcon();
		this.setPassUI();

		this.roomSwitchService.selectedRoom$.pipe(takeUntil(this.destroy$)).subscribe((room) => {
			if (room) {
				this.selectedLocation = room;
			}
		});

		this.userService.userJSON$
			.pipe(
				map((user) => User.fromJSON(user)),
				takeUntil(this.destroy$)
			)
			.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			});

		this.locationsService
			.listenLocationSocket()
			.pipe(
				takeUntil(this.destroy$),
				pickTruthy(),
				tap((res) => {
					try {
						const loc = Location.fromJSON(res.data);
						if (loc) {
							this.locationsService.updateLocationSuccessState(loc);
							this.pass.destination.title = loc.title;
						}
					} catch (e) {
						console.log(e);
					}
				})
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.pass && !!changes.pass.currentValue) {
			this.pass = changes.pass.currentValue as HallPass;
			this.passStatus = this.hallPassesService.getPassStatus(this.pass.start_time, this.pass.end_time, this.pass.expiration_time, true, false);
			this.cdr.detectChanges();
			this.setPassUI();
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private prepareTemplateDataVisibility({ visibility_alerts, students }, origin, destination) {
		const out = [];
		for (const a of visibility_alerts) {
			const ss = a.room_students.map((sid) => {
				const found = students.find((s) => s.User.id === sid);
				if (!found) return '<unknown name>';
				return found.User.first_name + ' ' + found.User.last_name;
			});
			let joint = 'for';
			if (a.location_id == origin) {
				joint = 'to come from';
			} else if (a.location_id == destination) {
				joint = 'to go to';
			}
			//const phrase = `These students do not have permission ${joint} this room "${a['location_title']}"`;
			const phrase = `These students do not have permission ${joint} this room`;
			out.push({ phrase, students: ss.join(', ') });
		}
		return out;
	}

	get isRecurringFuture(): boolean {
		if (Number.isInteger(this.pass.schedule_config_id)) {
			// retrieving a pass from the server with a schedule_config_id
			return true;
		}

		return !!this.formState?.data?.date?.schedule_option;
	}

	newPass() {
		this.performingAction = true;

		const body: PassRequestBody = {
			duration: this.selectedDuration * 60,
			origin: this.pass.origin.id,
			destination: this.pass.destination.id,
			travel_type: this.selectedTravelType,
		};

		if (this.forStaff) {
			let ss: User[] = this.students;
			if (this.formState.data?.roomStudents?.length > 0) {
				ss = this.formState.data.roomStudents;
			}
			body.students = ss.map((user) => user.id);
		} else {
			body.student = this.pass.student.id;
		}
		body.override_visibility = this.formState.data.roomOverride;

		if (this.forFuture) {
			body.issuer_message = this.pass.issuer_message;
			body.start_time = this.pass.start_time.toISOString();
			if (this.isRecurringFuture) {
				// if the schedule config is present and is repeating, then set the `recurring_scheduled_config` key, otherwise omit the key
				// from the body.
				// the backend will ignore if the key is not present
				body.recurring_scheduled_config = this.formState.data.date.schedule_option;
			}
		}
		if (!this.forStaff) {
			delete body.override_visibility;
			body.isNotBulk = true;
		}

		of(body)
			.pipe(
				concatMap((b) => (this.forStaff ? this.hallPassService.bulkCreatePass(b) : this.hallPassService.createPass(b))),
				takeUntil(this.destroy$),
				switchMap(({ conflict_student_ids, passes, error, conflict_passes, prevented_encounters }) => {
					if (error) {
						const code = error.code;
						switch (code) {
							case 'max_pass_limit_reached':
								this.toastService.openToast({
									title: 'Maximum Hall Pass Limit Reached',
									subtitle: 'The maximum hall pass limit for the school has been reached. Please try again later.',
									type: 'error',
								});
								break;
							case 'pass_cooldown':
								this.toastService.openToast({
									title: error.message,
									type: 'error',
								});
								break;
							case 'no_fly_time_active':
								this.toastService.openToast({
									title: 'No Fly Time Active',
									subtitle: error.message,
									type: 'error',
								});
								break;
							default:
								this.toastService.openToast({
									title: 'Unknown error code',
									type: 'error',
								});
								break;
						}
						return of(null);
					}

					if (conflict_student_ids) {
						return this.hallPassService.handleEncounterPrevention({
							conflictPasses: conflict_passes,
							preventedEncounters: prevented_encounters,
							conflictStudentIds: conflict_student_ids,
							passes: passes,
							body: body,
							selectedTravelType: this.selectedTravelType,
							selectedStudents: this.students,
							selectedLocation: this.selectedLocation,
							forStaff: this.forStaff,
							attemptedPass: this.pass,
							currentUser: this.user,
							onClose: () => this.dialogRef.close(),
						});
					}

					return of(passes);
				}),
				retryWhen((errors: Observable<HttpErrorResponse>) => {
					const getOverrideFromDialog = (error) => {
						const afterDialogClosed$ = this.dialog
							.open(ConfirmationDialogComponent, {
								panelClass: 'overlay-dialog',
								backdropClass: 'custom-backdrop',
								closeOnNavigation: true,
								data: {
									headerText: '',
									body: this.confirmDialogVisibility,
									buttons: {
										confirmText: 'Override',
										denyText: 'Skip These Students',
									},
									templateData: { alerts: this.prepareTemplateDataVisibility(error, body.origin, body.destination) },
									icon: {
										name: 'Eye (Green-White).svg',
										background: '',
										spacing: '5px',
									},
								} as ConfirmationTemplates,
							})
							.afterClosed();

						return afterDialogClosed$.pipe(
							map((override) => ({
								override,
								students: error.students.map((s) => s.User.id),
							}))
						);
					};

					return errors.pipe(
						tap((errorResponse) => {
							const isVisibilityError = 'visibility_alerts' in errorResponse.error;
							// not our error case? dispatch it to the next retryWhen
							if (!isVisibilityError) {
								throw errorResponse;
							}
							// a student has been checked server side and had no room visibility
							const isRoomsClosed = 'rooms_closed' in errorResponse.error;
							if (!this.forStaff && !isRoomsClosed) {
								const roomNames = errorResponse.error.visibility_alerts.map((r) => r?.location_title ?? '');
								const title = `You don't have access to ${
									roomNames.length > 1 ? 'Rooms ' + roomNames.join(', ') : roomNames[0] === '' ? 'Room' : 'Room ' + roomNames[0]
								}.`;
								this.toastService.openToast({
									title,
									subtitle: 'Please ask your teacher to create a pass for you.',
									type: 'error',
								});

								this.performingAction = false;
								throw 'this student has been subject of room visibility rules';
							}
							// both teachers and students must see as a room is closed only by an admin
							if (isRoomsClosed) {
								const roomNames = errorResponse.error.visibility_alerts.filter((r) => !r.enable).map((r) => r?.location_title ?? '');
								this.toastService.openToast({
									title: 'Room status',
									subtitle: `Closed: ${roomNames.join(',')}`,
									type: 'error',
								});

								this.performingAction = false;
								throw 'room has been closed';
							}
						}),
						concatMap(({ error }) => getOverrideFromDialog(error)),
						concatMap(({ override, students }: { override: boolean; students: number[] }) => {
							if (override === undefined) {
								this.dialogRef.close();
								throw 'confirmation closed';
							}
							if (override === true) {
								body.override_visibility = true;
								return of(null);
							}

							if (override === false) {
								remove(body.students as number[], (elem) => students.includes(elem));
								// removal left us with no students
								// this is as canceling the process
								if (body?.students?.length === 0) {
									this.performingAction = false;
									//this.dialogRef.close();
									throw 'no students after skiping';
								}
								return of(null);
							}
						})
					);
				}),
				retryWhen((errors: Observable<HttpErrorResponse>) =>
					this.hallPassService
						.handlePassLimitError({
							errors,
							studentIds: body.students,
							studentName: this.students[0].display_name,
							frequency: this.passLimit?.frequency,
							confirmDialog: this.confirmDialog,
						})
						.pipe(
							filter(({ override }) => {
								this.performingAction = override !== undefined;
								return override !== undefined;
							}),
							concatMap(({ override, students }: { override: boolean; students: number[] }) => {
								if (override === true) {
									body.override = true;
									return of(null);
								}

								if (override === false) {
									remove(body.students as number[], (elem) => students.includes(elem));
									// server side "no students" case is seen as bad request
									if (body.students.length === 0) {
										this.dialogRef.close();
										throw new Error('No students to create passes for');
									}
									return of(null);
								}
							})
						)
				),
				catchError((error: HttpErrorResponse) => {
					if (error.error.detail === 'could not create pass' && this.pass.student.status === 'suspended') {
						this.toastService.openToast({
							title: 'Your account is suspended. Please contact your school admin',
							type: 'error',
						});
						return throwError(error);
					}

					if (error.status === 403 && !this.forStaff && this.pass.destination.max_passes_to_active && this.pass.destination.max_passes_to === 0) {
						this.toastService.openToast({
							title: "Can't Start Pass to This Room",
							subtitle: 'Your admin has the max capacity of this room set to zero. Please contact them if you think this is a mistake.',
							type: 'error',
						});
						return throwError(error);
					}

					this.toastService.openToast({
						title: 'Something went wrong!',
						subtitle: 'Could not create pass, contact support for more info',
						type: 'error',
					});
					return throwError(error);
				})
			)
			.subscribe({
				next: (createdPasses: HallPass[] | HallPass) => {
					this.performingAction = false;
					this.hallPassService.createPassEvent$.next(true);

					const parsedCreated = (Array.isArray(createdPasses) ? createdPasses : [createdPasses])
						.map((p) => {
							try {
								return HallPass.fromJSON(p);
							} catch {
								console.warn('could not parse created pass');
								return undefined;
							}
						})
						.filter(Boolean);

					if (parsedCreated.length > 0 && this.forStaff) {
						const origin = parsedCreated[0].origin;
						const destination = parsedCreated[0].destination;
						if (
							!this.router.url.includes('student') &&
							this.user.roles.includes('access_hall_monitor') &&
							origin &&
							destination &&
							(!this.selectedLocation || (origin?.id !== this.selectedLocation?.id && destination?.id !== this.selectedLocation?.id))
						) {
							this.router.navigate(['main/hallmonitor']);
						}
					}
					// navigate student to calendar if student created scheduled pass
					if (parsedCreated?.length > 0 && !this.forStaff && this.forFuture) {
						const date = this.datePipe.transform(parsedCreated[0].start_time, 'yyyy-MM-dd');
						const startTime = `${parsedCreated[0].start_time.getHours()}:${parsedCreated[0].start_time.getMinutes()}`;
						const url = this.router
							.createUrlTree(['main', 'passes-calendar'], {
								queryParams: { date: date, start_time: startTime },
							})
							.toString();
						this.router.navigateByUrl(url);
					}

					this.dialogRef.close(parsedCreated);
				},
				error: () => {
					this.performingAction = false;
					this.dialogRef.close();
				},
			});
	}

	cancelEdit() {
		this.formState.step = 3;
		this.formState.previousStep = 4;
		if (this.formState.data) {
			this.formState.data.roomStudents = null;
		}
		this.cardEvent.emit(this.formState);
		return false;
	}

	private getOriginRoomIcon(): void {
		// make sure pinnables are loaded in the store before getting room icon
		this.hallPassService.loadedPinnables$
			.pipe(
				take(1),
				switchMap((loaded) => {
					return iif(() => loaded, of(null), this.hallPassesService.getPinnablesRequest());
				}),
				switchMap(() => {
					return this.hallPassesService.getPinnable(this.pass.origin);
				}),
				pickTruthy(),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe((pin) => {
				this.originRoomIcon = pin.icon;
			});
	}

	private setPassUI() {
		this.getCssClasses();
		this.setBackgroundColor();
		this.borderClasses = 'upcoming';
	}

	private getCssClasses(): void {
		this.passCssClasses = '';

		if (this.forStaff) {
			this.passCssClasses = this.passCssClasses + ' for-staff';
		} else {
			this.passCssClasses = this.passCssClasses + ' for-student';
		}

		this.passCssClasses = this.passCssClasses + ' in-form-container ' + this.passStatus;
	}

	private setBackgroundColor(): void {
		let gradientArray = [];
		gradientArray = this.pass.color_profile.gradient_color.split(',');
		this.backgroundGradient = '#FFFFFF';
		this.footerBackgroundGradient = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ', ' + gradientArray[1] + ')';
	}
}
