<div [class]="'calendar ' + classes" [style.width]="width + 'px'">
	<div class="calendar-navs">
		<div class="year-nav" *ngIf="showYear">
			<div class="switch-year __left" (click)="prevYear()">
				<img class="year-icon" [src]="'./assets/Chevron Left (Navy).svg' | resolveAsset" alt="prev" />
			</div>
			<span class="ds-dialog-subtitle">{{ currentDate.format('YYYY') }}</span>
			<div class="switch-year __right" (click)="nextYear()">
				<img class="year-icon" [src]="'./assets/Chevron Right (Navy).svg' | resolveAsset" alt="next" />
			</div>
		</div>
		<mat-divider *ngIf="showYear"></mat-divider>

		<div class="month-nav">
			<button type="button" *ngIf="!isDisabledPreviousMonthButton" class="switch-month __left" (click)="prevMonth()">
				<img class="month-icon" [src]="'./assets/Chevron Left (Navy).svg' | resolveAsset" alt="prev" />
			</button>

			<div class="switch-month __left disabled" *ngIf="isDisabledPreviousMonthButton">
				<img style="width: 15px" [src]="'./assets/Chevron Left (Blue-Gray).svg' | resolveAsset" />
			</div>

			<span class="ds-dialog-subtitle"
				>{{ currentDate.format('MMMM') }} <var>{{ currentDate.format('YYYY') }}</var></span
			>
			<button type="button" *ngIf="!isDisabledNextMonthButton" class="switch-month __right" (click)="nextMonth()">
				<img class="month-icon" [src]="'./assets/Chevron Right (Navy).svg' | resolveAsset" alt="next" />
			</button>

			<div class="switch-month __right disabled" *ngIf="isDisabledNextMonthButton">
				<img class="month-icon" [src]="'./assets/Chevron Right (Blue-Gray).svg' | resolveAsset" alt="next" />
			</div>
		</div>
		<mat-divider></mat-divider>
	</div>
	<div class="month-grid" [ngClass]="{ 'date-range': range || rangeWeeks }">
		<div class="days">
			<div class="day-names">
				<div *ngFor="let name of dayNames" class="day-name" [style.width]="width / 7 + 'px'" [style.height]="width / 7 + 'px'">
					{{ name }}
				</div>
			</div>
			<div class="divider" [style.width]="width + 'px'">
				<mat-divider></mat-divider>
			</div>
		</div>

		<div class="weeks">
			<div *ngFor="let week of weeks" class="week">
				<div
					*ngFor="let day of week"
					customToolTip
					[toolTipShowDelay]="0"
					[toolTipHideDelay]="0"
					[position]="'top'"
					[additionalOffsetY]="-55"
					[contentTemplate]="disabledDayTooltip"
					[showToolTip]="!!day.tooltipContent">
					<!--WEEKDAYS-->
					<div
						class="{{ day.cssClasses }}"
						*ngIf="!day.isWeekendDay"
						[style.width]="width / 7 + 'px'"
						[style.height]="width / 7 + 'px'"
						(click)="onClickDay(day)"
						(mouseenter)="hoverDates(day.mDate)"
						(mouseleave)="disabledHovered()"
						[ngStyle]="{ 'background-color': !day.disabled && day.selected && day.today ? selectedColor : 'none' }"
						[ngClass]="{
							rightBorder:
								(day.selected && selectedDates.length > 1 && isLastSelectedDay(day.mDate)) ||
								(hovered && isLastHoveredDay(day.mDate) && selectedDates.length == 1),
							hovered: hovered && !isSelected(day.mDate) && !isRangeHovered(day.mDate),
							rangeHovered: isRangeHovered(day.mDate) && !isSelected(day.mDate)
						}">
						<div class="date-text">{{ day.mDate.date() }}</div>
						<div class="pass-dot" *ngIf="day.isDot"></div>
					</div>
					<!--END WEEKDAYS-->

					<!--WEEKEND DAYS - HOWEVER NO CURRENT USE OF WEEKEND DAYS ARE IN THE APP-->
					<div
						class="{{ day.cssClasses }}"
						*ngIf="day.isWeekendDay && showWeekend"
						[style.width]="width / 7 + 'px'"
						[style.height]="width / 7 + 'px'"
						(click)="onClickDay(day)"
						(mouseenter)="hoverDates(day.mDate)"
						(mouseleave)="disabledHovered()"
						[ngStyle]="{ 'background-color': !day.disabled && day.selected && day.today ? selectedColor : 'none' }"
						[ngClass]="{
							rightBorder:
								(day.selected && selectedDates.length > 1 && isLastSelectedDay(day.mDate)) ||
								(hovered && isLastHoveredDay(day.mDate) && selectedDates.length == 1),
							hovered: hovered && !isSelected(day.mDate) && !isRangeHovered(day.mDate),
							rangeHovered: isRangeHovered(day.mDate) && !isSelected(day.mDate)
						}">
						<div class="date-text">{{ day.mDate.date() }}b</div>
						<div class="pass-dot" *ngIf="day.isDot"></div>
					</div>
					<!--END WEEKEND DAYS-->

					<ng-template #disabledDayTooltip>
						<sp-tooltip [text]="day.tooltipContent.text" [link]="day.tooltipContent.link" [linkText]="day.tooltipContent.linkText">
							<div dataDetails [innerHTML]="day.tooltipContent.dataDetails"></div>
						</sp-tooltip>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
	<div class="time-picker" *ngIf="showTime">
		<mat-divider></mat-divider>
		<app-time-picker
			[currentDate]="selectedDates?.length ? selectedDates[0] : currentDate"
			[forseDate$]="forseUpdate$"
			[min]="min"
			(timeResult)="timePickerResult($event)"></app-time-picker>
	</div>
</div>
