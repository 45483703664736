<div class="view-activity" *ngIf="activityInstanceData$ | async as instanceData">
	<div class="left-section">
		<div class="va-details">
			<div class="va-title">
				{{ data.activity.name
				}}<app-icon-button
					[srcString]="'Dots'"
					[size]="17"
					[hasShadow]="true"
					(clickEvent)="openDeleteActivity($event, data.activity.id)"></app-icon-button>
			</div>
			<div class="va-tags">
				<div *ngIf="data.activity.managers.length == 0">
					<img
						[class]="teacherIcon && 'va-teacher-icon'"
						[src]="teacherIcon ? teacherIcon : ('./assets/Teacher (Blue-Gray).svg' | resolveAsset)"
						alt="Teacher" />
					{{ teacherName }}
				</div>
				<div *ngFor="let teacher of data.activity.managers">
					<img
						[class]="teacher.profile_picture && 'va-teacher-icon'"
						[src]="teacher.profile_picture ? teacher.profile_picture : ('./assets/Teacher (Blue-Gray).svg' | resolveAsset)"
						alt="Teacher" />
					{{ teacher.display_name }}
				</div>
				<div *ngIf="data.activity.max_attendees > 0">
					<img [src]="'./assets/Room Icon (Blue-Gray).svg' | resolveAsset" alt="Room" />
					{{ data.activity.max_attendees }} students max
				</div>
				<div>
					<ng-container *ngIf="data.activity.schedule_type == 'flex_period'">
						<ng-container *ngIf="data.activity?.state === 'flex_recurring'">
							<img [src]="'./assets/Repeat Outline (Blue-Gray).svg' | resolveAsset" alt="Repeat" />
							{{ 'Every ' + instanceData.flexPeriod.name }}
						</ng-container>

						<ng-container *ngIf="data.activity?.state === 'scheduled'">
							{{ 'Scheduled for ' + instanceData.flexPeriod.name }}
						</ng-container>
					</ng-container>
					<ng-container *ngIf="data.activity.schedule_type == 'custom'"> Custom Schedule </ng-container>

					<ng-container *ngIf="data.activity?.state === 'canceled' || data.activity.state === 'deleted'"> Activity cancelled </ng-container>
				</div>

				<div>
					<ng-container *ngIf="data.activity?.public_event"> Public activity </ng-container>

					<ng-container *ngIf="!data.activity?.public_event"> Private activity </ng-container>
				</div>
			</div>
			<div class="va-desc">
				{{ data.activity.description }}
			</div>
		</div>

		<div class="flex-feedback-button">
			<span> How do you like Flex? </span>
			<div>
				<img [src]="'./assets/Thumbs Up (Gray).svg' | resolveAsset" alt="Thumbs Up" (click)="openIntercom()" />
				<img [src]="'./assets/Thumbs Down (Gray).svg' | resolveAsset" alt="Thumbs Down" (click)="openIntercom()" />
			</div>
		</div>
	</div>

	<div class="va-right tw-px-7">
		<sp-attendees
			*ngIf="!displayUpcoming"
			[selectedInstance]="instanceData.selected"
			[maxNumAttending]="data.activity.max_attendees || 0"
			[showDeleteInstance]="showDeleteInstance"
			(openDeleteInstance)="openDeleteInstance($event)"></sp-attendees>

		<sp-upcoming
			*ngIf="displayUpcoming"
			[activity]="data.activity"
			[initialDay]="data.day"
			[emitInitialSelectedInstance]="emitInitialSelectedInstance"
			[flexPeriod]="instanceData.flexPeriod"
			[upcomingInstances]="instanceData.upcoming"
			[pastInstances]="instanceData.past"
			[allowInstanceSelection]="false"
			(showDeleteInstance)="updateShowDeleteInstance($event)"></sp-upcoming>
	</div>
</div>

<ng-template #deleteActivityDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove an activity, activity sessions that have students in them will still exist, but new sessions can't be created.
	</p>
</ng-template>
<ng-template #deleteInstanceDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove an activity session, student passes for the session will be cancelled.
	</p>
</ng-template>
