<div class="sp-search-wrapper" *ngIf="chipsMode && !inputField" #searchWrapper>
	<div class="chip">
		<app-sp-chips
			data-cy="teacher-search"
			[preventRemovingLast]="preventRemovingLast"
			[selectedProfiles]="selectedOptions"
			[suggestedTeacher]="suggestedTeacherOn ? suggestedTeacher : false"
			[selectedTarget]="searchTarget"
			[orgUnitList]="orgUnits"
			[orgUnitExistCheck]="orgUnitExistCheck"
			[isProposed]="isProposed"
			(add)="showInputFieldByChips()"
			[textAddButton]="textAddButton ? textAddButton : null"
			[buttonTextColor]="buttonTextColor"
			[buttonGradient]="buttonGradient"
			[buttonLeftIcon]="buttonLeftIcon"
			[addButtonShadow]="addButtonShadow ? addButtonShadow : ''"
			[addButtonDisable]="addButtonDisable"
			[addButtonBorder]="addButtonBorder ? addButtonBorder : null"
			(updateSelectedEvent)="update($event)"
			(addSuggestedTeacher)="addSuggested($event)">
		</app-sp-chips>
	</div>
</div>

<div class="sp-search-wrapper" [style.width]="width" *ngIf="inputField" #searchWrapper>
	<div class="input" [style.box-shadow]="isMobile ? 'none' : '0 0 6px 0 rgba(0, 0, 0, 0.1)'">
		<div class="round-input-container">
			<app-round-input
				data-cy="search-inp"
				[minWidth]="'auto'"
				[width]="'100%'"
				[backgroundColor]="backgroundColor ? backgroundColor : isMobile && !studentInput.valueLength ? '#F4F4F4' : '#FFFFFF'"
				[height]="height"
				[placeholder]="placeholder"
				[disabled]="disabled"
				[fieldIconPosition]="'left'"
				[closeIcon]="!cancelButton"
				[focused]="focused"
				[fontSize]="fontSize"
				[color]="color"
				[borderRadius]="borderRadius"
				[boxShadow]="boxShadow"
				[border]="border"
				[forceFocused$]="forceFocused$"
				[selectReset$]="inputValue$"
				[boxShadow]="false"
				[pending$]="pending$"
				(ontextupdate)="onSearch($event); studentInput.valueLength = $event.length"
				(blurEvent)="onBlur($event)"
				(focusEvent)="onFocus($event)"
				#studentInput="roundInputRef"
				#inputComponent>
			</app-round-input>
		</div>

		<div
			*ngIf="showOptions && searchTarget === 'users'"
			[ngClass]="{ 'options-wrapper': students }"
			[style.height]="students ? '235px' : '0px'"
			(scroll)="scroll()">
			<div *ngIf="students" class="appearing" style="border-top: 1px solid #d8d8d8; width: 92%; margin: 0 auto"></div>
			<div *ngIf="!showDummy; else noStudentsDummy" style="margin-top: -1px">
				<div *ngIf="(students | async)?.length; else noUserFound" class="option-list_scrollable" [style.max-height]="listMaxHeight">
					<div
						data-cy="search-result-user"
						[ngClass]="{ disabled: isDisabled(student) }"
						#cell
						*ngFor="let student of students | async"
						tabindex="0"
						class="option-list_item"
						(mouseenter)="cell.hovered = true; showBackgroundOverlay = true"
						(mouseleave)="cell.hovered = false; cell.pressed = false"
						appCrossPointerEventTarget
						(pointerDownEvent)="cell.pressed = true"
						(pointerClickEvent)="cell.pressed = false; addStudent(student); focusAfterAdd ? forceFocused$.next(true) : null"
						(focus)="cell.hovered = true"
						(blur)="cell.hovered = false"
						[style.color]="'#555558'"
						[style.background-color]="cell.hovered || cell.isOpenTooltip ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'"
						cdkOverlayOrigin
						#trigger="cdkOverlayOrigin">
						<span notranslate>
							{{ student.display_name }}
							{{
								'(' +
									(student.primary_email
										? student.primary_email.slice(0, student.primary_email.indexOf('@'))
										: student.email
										? student.email.slice(0, student.email.indexOf('@'))
										: '') +
									')'
							}}
						</span>

						<div class="exist" *ngIf="isDisabled(student)">Already account</div>

						<img
							*ngIf="!isKioskMode && hasStudentRole(student) && (cell.hovered || cell.isOpenTooltip) && type !== 'G Suite' && showStudentInfo"
							width="15"
							[src]="'./assets/Info (Blue-Gray).svg'"
							alt="Info"
							(mouseenter)="studentNameOver(cell)"
							(mouseleave)="destroyOpen$.next(undefined); showBackgroundOverlay = false" />

						<ng-template
							cdkConnectedOverlay
							[cdkConnectedOverlayOrigin]="trigger"
							[cdkConnectedOverlayOpen]="cell.isOpenTooltip"
							[cdkConnectedOverlayWidth]="335"
							[cdkConnectedOverlayHeight]="300"
							[cdkConnectedOverlayPositions]="[
								{ panelClass: 'student-panel1', originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetX: 100, offsetY: 0 }
							]"
							[cdkConnectedOverlayHasBackdrop]="false"
							(positionChange)="updateOverlayPosition($event)">
							<div
								class="student-passes"
								#studentPasses
								(mouseleave)="showBackgroundOverlay = false; setAnimationTrigger('fadeOut'); studentNameLeave(cell)"
								(mouseover)="destroyAnimation$.next(undefined); showBackgroundOverlay = true; disableClose$.next()">
								<app-student-passes [profile]="student" [height]="300" [isResize]="false" (close)="cell.isOpenTooltip = false"></app-student-passes>
							</div>
						</ng-template>
					</div>
				</div>
				<ng-template #noUserFound>
					<div
						*ngIf="students && studentInput.valueLength > 0 && !(pending$ | async)"
						class="option-list_scrollable"
						[style.max-height]="listMaxHeight">
						<p class="no-user-found">No {{ role == '_profile_student' ? 'students' : 'teachers' }} found</p>
					</div>
				</ng-template>
			</div>
			<ng-template #noStudentsDummy>
				<div class="no-students-dummy">
					<div>
						<div class="dummy-image">
							<img width="150" [src]="'./assets/emptyStates/undraw_graduation_9x4i.svg'" alt="undraw_graduation_9x4i.svg" />
						</div>

						<div class="dummy-text">
							<span>No {{ dummyRoleText }} found</span>
						</div>
					</div>
				</div>
			</ng-template>
		</div>

		<div
			*ngIf="showOptions && (searchTarget === 'rooms' || searchTarget === 'roomsinfolders')"
			[ngClass]="{ 'options-wrapper': foundLocations.length }"
			[style.height]="foundLocations.length ? '235px' : showDummy ? '135px' : '0px'">
			<div *ngIf="foundLocations.length" class="appearing" style="border-top: 1px solid #d8d8d8; width: 92%; margin: 0 auto"></div>
			<div *ngIf="!showDummy; else nolocs" style="margin-top: -1px">
				<div class="option-list_scrollable" [style.max-height]="listMaxHeight">
					<div
						#cell
						*ngFor="let location of foundLocations"
						tabindex="0"
						class="option-list_item"
						(mouseenter)="cell.hovered = true"
						(mouseleave)="cell.hovered = false; cell.pressed = false"
						appCrossPointerEventTarget
						(pointerDownEvent)="cell.pressed = true"
						(pointerClickEvent)="cell.pressed = false; addLocation(location)"
						[style.color]="'#555558'"
						(focus)="cell.hovered = true"
						(blur)="cell.hovered = false"
						[style.background-color]="cell.hovered ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'">
						<span>
							{{ location.title }} <span *ngIf="searchTarget === 'roomsinfolders'">({{ location.category.split(' ').slice(0, -1).join(' ') }})</span>
						</span>
					</div>
				</div>
			</div>
			<ng-template #nolocs>
				<div class="no-students-dummy" style="height: 100%">
					<div style="text-align: center">
						<div class="dummy-image" style="font-weight: bold; font-size: 17px">No results</div>
						<div class="dummy-text" style="width: 160px">Create a new room on the Rooms tab</div>
					</div>
				</div>
			</ng-template>
		</div>

		<div
			*ngIf="showOptions && searchTarget === 'studentlists'"
			[ngClass]="{ 'options-wrapper': foundGroups.length }"
			[style.height]="foundGroups.length ? '235px' : showDummy ? '135px' : '0px'">
			<div *ngIf="foundGroups.length" class="appearing" style="border-top: 1px solid #d8d8d8; width: 92%; margin: 0 auto"></div>
			<div *ngIf="!showDummy; else nolocs" style="margin-top: -1px">
				<div class="option-list_scrollable" [style.max-height]="listMaxHeight">
					<div
						#cell
						*ngFor="let group of foundGroups"
						tabindex="0"
						class="option-list_item"
						(mouseenter)="cell.hovered = true"
						(mouseleave)="cell.hovered = false; cell.pressed = false"
						appCrossPointerEventTarget
						(pointerDownEvent)="cell.pressed = true"
						(pointerClickEvent)="cell.pressed = false; addStudentGroup(group)"
						[style.color]="'#555558'"
						(focus)="cell.hovered = true"
						(blur)="cell.hovered = false"
						[style.background-color]="cell.hovered ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'">
						<span>
							{{ group.title }}
						</span>
					</div>
				</div>
			</div>
			<ng-template #nolocs>
				<div class="no-students-dummy" style="height: 100%">
					<div style="text-align: center">
						<div class="dummy-image" style="font-weight: bold; font-size: 17px">No results</div>
					</div>
				</div>
			</ng-template>
		</div>

		<div
			*ngIf="showOptions && searchTarget === 'orgunits'"
			[ngClass]="{ 'options-wrapper': (orgunits | async) }"
			[style.height]="(orgunits | async) ? '235px' : '0px'">
			<div *ngIf="orgunits | async" class="appearing" style="border-top: 1px solid #d8d8d8; width: 92%; margin: 0 auto"></div>
			<div *ngIf="!showDummy; else noStudentsDummy" style="margin-top: -1px">
				<div class="option-list_scrollable" [style.max-height]="listMaxHeight">
					<div
						#cell
						*ngFor="let unit of orgunits | async"
						class="option-list_item"
						(mouseenter)="cell.hovered = true"
						(mouseleave)="cell.hovered = false; cell.pressed = false"
						appCrossPointerEventTarget
						(pointerDownEvent)="cell.pressed = true"
						(pointerClickEvent)="cell.pressed = false; inputValue$.next(''); addUnit(unit)"
						[style.color]="'#555558'"
						(focus)="cell.hovered = true"
						(blur)="cell.hovered = false"
						[style.background-color]="cell.hovered ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'">
						<span>
							{{ unit.path }}
						</span>
					</div>
				</div>
			</div>
			<ng-template #noStudentsDummy>
				<div class="no-students-dummy">
					<div>
						<div class="dummy-image">
							<img width="150" [src]="'./assets/emptyStates/undraw_graduation_9x4i.svg'" alt="undraw_graduation_9x4i.svg" />
						</div>

						<div class="dummy-text">
							<span>No {{ dummyRoleText }} found</span>
						</div>
					</div>
				</div>
			</ng-template>
		</div>

		<div
			*ngIf="showOptions && searchTarget === 'roles'"
			[ngClass]="{ 'options-wrapper': searchingRoles?.length }"
			[style.height]="searchingRoles?.length ? '235px' : '0px'">
			<div *ngIf="searchingRoles?.length" class="appearing" style="border-top: 1px solid #d8d8d8; width: 92%; margin: 0 auto"></div>
			<div class="option-list_scrollable" [style.max-height]="listMaxHeight">
				<div
					#cell
					*ngFor="let role of searchingRoles"
					class="option-list_item"
					(mouseenter)="cell.hovered = true"
					(mouseleave)="cell.hovered = false; cell.pressed = false"
					appCrossPointerEventTarget
					(pointerDownEvent)="cell.pressed = true"
					(pointerClickEvent)="cell.pressed = false; inputValue$.next(''); addRole(role)"
					[style.color]="'#555558'"
					(focus)="cell.hovered = true"
					(blur)="cell.hovered = false"
					[style.background-color]="cell.hovered ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'">
					<span>
						{{ role.role }}
					</span>
				</div>
			</div>
		</div>

		<div
			*ngIf="showOptions && searchTarget === 'local'"
			[ngClass]="{ 'options-wrapper': (teacherCollection$ | async) }"
			[style.height]="(teacherCollection$ | async) ? '235px' : '0px'">
			<div *ngIf="teacherCollection$ | async" class="appearing" style="border-top: 1px solid #d8d8d8; width: 92%; margin: 0 auto"></div>
			<div *ngIf="!showDummy; else noStudentsDummy" style="margin-top: -1px">
				<div class="option-list_scrollable" [style.max-height]="listMaxHeight">
					<div
						#cell
						*ngFor="let teacher of teacherCollection$ | async"
						tabindex="0"
						class="option-list_item"
						(mouseenter)="cell.hovered = true"
						(mouseleave)="cell.hovered = false; cell.pressed = false"
						appCrossPointerEventTarget
						(pointerDownEvent)="cell.pressed = true"
						(pointerClickEvent)="cell.pressed = false; inputValue$.next(''); addLocalTeacher(teacher)"
						[style.color]="'#555558'"
						(focus)="cell.hovered = true"
						(blur)="cell.hovered = false"
						[style.background-color]="cell.hovered ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'">
						<span notranslate>
							{{ teacher.display_name }} ({{
								teacher.primary_email
									? teacher.primary_email.slice(0, teacher.primary_email.indexOf('@'))
									: teacher.email
									? teacher.email.slice(0, teacher.email.indexOf('@'))
									: ''
							}})
						</span>
					</div>
				</div>
			</div>
			<ng-template #noStudentsDummy>
				<div class="no-students-dummy">
					<div>
						<div class="dummy-image">
							<img width="150" [src]="'./assets/emptyStates/undraw_graduation_9x4i.svg'" alt="undraw_graduation_9x4i.svg" />
						</div>

						<div class="dummy-text">
							<span>No {{ dummyRoleText }} found</span>
						</div>
					</div>
				</div>
			</ng-template>
		</div>

		<div
			*ngIf="showOptions && searchTarget === 'schools'"
			[ngClass]="{ 'options-wrapper': (schools | async) !== null }"
			[style.height]="(schools | async) !== null ? '235px' : '0px'">
			<div *ngIf="(schools | async)?.length" class="appearing" style="border-top: 1px solid #d8d8d8; width: 92%; margin: 0 auto"></div>
			<div *ngIf="!showDummy; else noSchoolsDummy" style="margin-top: -1px">
				<div class="option-list_scrollable" [style.max-height]="listMaxHeight">
					<div
						#cell
						*ngFor="let school of schools | async"
						class="option-list_item"
						(mouseenter)="cell.hovered = true"
						(mouseleave)="cell.hovered = false; cell.pressed = false"
						appCrossPointerEventTarget
						(pointerDownEvent)="cell.pressed = true"
						(pointerClickEvent)="cell.pressed = false; inputValue$.next(''); selectSchool(school)"
						[style.color]="'#555558'"
						(focus)="cell.hovered = true"
						(blur)="cell.hovered = false"
						[style.background-color]="cell.hovered ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'">
						<div>
							<div style="text-transform: capitalize; color: #00b476">
								{{ school.structured_formatting.main_text }}
							</div>
							<div style="color: #1f194e">
								{{ school.structured_formatting.secondary_text }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ng-template #noSchoolsDummy>
				<div class="no-schools-dummy">
					<div>
						<div class="dummy-text dummy-title"><span>No schools found</span></div>
						<div class="dummy-text">
							Need help? Contact
							<a href="mailto:help@smartpass.app" class="ds-link-text" style="font-size: 17px !important">help@smartpass.app</a>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>

	<div class="cancel-button" *ngIf="cancelButton">
		<app-gradient-button
			class="ds-py-5"
			[size]="'editable'"
			[width]="'85px'"
			[fontWeight]="'normal'"
			[minHeight]="'30px'"
			[textColor]="'#7F879D'"
			[gradient]="cancelButtonGradient"
			[buttonDownColor]="'#F7F7F7'"
			(buttonClick)="cancel(studentInput)"
			[leftIcon]="'./assets/Cancel (Blue-Gray).svg'"
			[leftImageWidth]="'12px'"
			[leftImageHeight]="'12px'"
			[fontSize]="'14px'"
			>Cancel</app-gradient-button
		>
	</div>

	<div class="option-list" *ngIf="!chipsMode && list && selectedOptions.length">
		<div class="ds-dialog-heading option-list_header" *ngIf="displaySelectedTitle">
			<span>Selected</span>
		</div>
		<div class="option-list_scrollable" [style.max-height]="listMaxHeight">
			<div
				#cell
				*ngFor="let selectedOption of selectedOptions; let i = index"
				tabindex="0"
				class="option-list_item"
				(mouseenter)="cell.hovered = true"
				(mouseleave)="cell.hovered = false; cell.pressed = false"
				[style.color]="'#555558'"
				(focus)="cell.hovered = true"
				(blur)="cell.hovered = false"
				[style.background-color]="cell.hovered ? (cell.pressed ? '#ECEDF1' : '#F1F2F4') : '#FFFFFF'"
				[attr.notranslate]="this.searchTarget === 'users' ? 'no' : null">
				<span *ngIf="this.searchTarget === 'users'">
					{{ selectedOption.display_name }} ({{
						selectedOption.primary_email
							? selectedOption.primary_email.slice(0, selectedOption.primary_email.indexOf('@'))
							: selectedOption.email
							? selectedOption.email.slice(0, selectedOption.email.indexOf('@'))
							: ''
					}})
				</span>
				<span *ngIf="this.searchTarget === 'orgunits'">
					{{ selectedOption.path }}
				</span>
				<span *ngIf="searchTarget === 'rooms'">
					{{ selectedOption.title }}
				</span>

				<img
					(mousedown)="cell.pressed = true"
					(mouseup)="cell.pressed = false"
					(click)="selectedOptions.splice(i, 1); this.onUpdate.emit(this.selectedOptions)"
					[src]="cell.hovered ? './assets/Cancel (Navy).svg' : './assets/Mark (Navy).svg'"
					width="15"
					alt="Check.svg" />
			</div>
		</div>
	</div>
</div>
