<div class="select-days">
	<div class="sd-title">
		<div (click)="handleCancel()">Cancel</div>
		<span>{{ data.flexPeriod?.name ?? 'Add Sessions' }}</span>
		<div (click)="handleSubmit()">Save</div>
	</div>
	<div class="sd-list">
		<ng-container *ngFor="let schedule of options">
			<div *ngIf="!schedule.hidden" class="sd-option" [ngClass]="schedule.selected ? 'selected' : ''" (click)="toggle(schedule.start)">
				<div>
					<img *ngIf="schedule.selected" [src]="'./assets/Check (Green).svg' | resolveAsset" alt="Checked" />
				</div>
				<span>{{ schedule.start | date : 'EEEE, MMMM d' }}</span>
				<span>{{ schedule.start | date : 'h:mm a' }} - {{ schedule.end | date : 'h:mm a' }}</span>
			</div>
		</ng-container>
	</div>
	<div class="sd-footer" *ngIf="data.flexPeriod">
		<span>Repeats every {{ data.flexPeriod.name }}</span>
		<form [formGroup]="selectDaysForm">
			<app-toggle-input
				[form]="selectDaysForm"
				[controlName]="'toggle'"
				[controlSize]="'small'"
				(pushOutValue)="onEnabledToggle($event)"
				[delimiter]="false"></app-toggle-input>
		</form>
	</div>
</div>
