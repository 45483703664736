<div
	class="groups-students-list"
	[style.width]="width"
	[style.height]="height"
	[style.min-height]="minHeight"
	[style.max-height]="maxHeight"
	[style.border]="border"
	[style.border-bottom-right-radius]="borderBottomRightRadius"
	[style.border-bottom-left-radius]="borderBottomLeftRadius"
	[style.box-shadow]="boxShadow"
	[style.background]="background"
	[ngClass]="{ 'bottom-border': selectedNumber }">
	<mat-list [style.padding-top]="'0px'">
		<div *ngFor="let group of groups">
			<mat-list-item
				class="group"
				appCrossPointerEventTarget
				(mouseenter)="_item.hovered = true"
				(mouseleave)="_item.hovered = false; _item.pressed = false"
				(mousedown)="mouseDown.emit(true); _item.pressed = true"
				(mouseup)="_item.pressed = false"
				(pointerClickEvent)="onSelect(group)"
				[style.background-color]="getBackground(_item)"
				#_item>
				<div class="selection">
					<img class="group-icon" src="../../../../../assets/Groups Icon.svg" />
					<span class="name">{{ group.title }}</span>
					<span class="number-of-students"> {{ group.users.length }} students</span>
					<img
						*ngIf="canEditGroup && _item.hovered"
						class="edit-icon"
						src="../../../../../assets/Edit (Blue-Gray).svg"
						(click)="editGroup($event, group)" />
				</div>
			</mat-list-item>
		</div>
		<div *ngFor="let student of students" spHover="#F5F6F8,#FFFFFF" (mousedown)="mouseDown.emit(true)">
			<mat-list-item class="group" [ngClass]="{ group_selected: selectedGroup && selectedGroup.id === group.id }" (click)="onSelect(student)">
				<div class="selection">
					<div
						*ngIf="student.profile_picture; else d"
						[ngStyle]="{ background: 'url(' + student.profile_picture + ') no-repeat left center/cover' }"
						class="profile-picture"></div>
					<ng-template #d>
						<img class="profile-picture" src="../../../../../assets/Avatar Default.svg" alt="Avatar" />
					</ng-template>
					<div class="selection-info">
						<span class="name">{{ student.display_name }}</span>

						<!-- ACTIVITY OR CLASS ORIGIN -->
						<div *ngIf="originInfo[student.id]?.originStatus === 'ok'" class="student-origin">
							<div class="pinnable" [style.background]="originInfo[student.id]!.backgroundColor">
								<img
									draggable="false"
									[style.width]="'13px'"
									[style.height]="'13px'"
									[src]="originInfo[student.id]!.icon | resolveAsset"
									alt="ICON" />
							</div>
							<div class="text">
								{{ originInfo[student.id]!.roomName }}
							</div>
						</div>

						<!-- HAS SCHEDULES BUT ORIGIN MISSING -->
						<div
							*ngIf="hasSchedulesFF && formState && !isKiosk && !originInfo[student.id] && (scheduleService.currentPeriod$ | async)"
							class="student-origin"
							#_originMissing>
							<img
								cdkOverlayOrigin
								#originLocationOverlayTrigger="cdkOverlayOrigin"
								(mouseenter)="setStudentHoverState(student.id, true)"
								(mouseleave)="delayTooltipForStudentSelect(student, false)"
								draggable="false"
								class="missing-icon"
								src="../../../../../assets/important-circle (Yellow).svg"
								alt="ICON" />
							<div class="missing-text">Scheduled Room Missing</div>
							<ng-template
								cdkConnectedOverlay
								[cdkConnectedOverlayOrigin]="originLocationOverlayTrigger"
								[cdkConnectedOverlayOpen]="studentHoverStates[student.id] || studentTooltipHoverStates[student.id]">
								<div
									(mouseenter)="setStudentTooltipHoverState(student.id, true)"
									(mouseleave)="delayTooltipForTooltipHover(student, false)"
									#_originMissingTooltip>
									<app-origin-location-tooltip
										[@TooltipFadeInOut]="'visible'"
										[classes]="originInfo[student.id]"
										[period]="(scheduleService.currentPeriod$ | async)?.long_name || (scheduleService.nextPeriod$ | async)?.long_name"
										[padding]="'12px'"
										[student]="student"
										[width]="'198px'"
										[tooltipType]="'missing'"
										[dayType]="(scheduleService.scheduleGroup$ | async)?.day_name">
									</app-origin-location-tooltip>
								</div>
							</ng-template>
						</div>

						<!-- HAS SCHEDULES BUT MULTIPLE CURRENT CLASSES -->
						<div *ngIf="!isKiosk && hasSchedulesFF && originInfo[student.id]?.originStatus === 'error'" class="student-origin" #_originError>
							<img
								cdkOverlayOrigin
								#originErrorOverlayTrigger="cdkOverlayOrigin"
								(mouseover)="setStudentHoverState(student.id, true)"
								(mouseleave)="delayTooltipForStudentSelect(student, false)"
								draggable="false"
								class="error-icon"
								src="../../../../../assets/important-circle (Red).svg"
								alt="ICON" />
							<div class="error-text">Scheduled Room Error</div>
							<ng-template
								cdkConnectedOverlay
								[cdkConnectedOverlayOrigin]="originErrorOverlayTrigger"
								[cdkConnectedOverlayOpen]="studentHoverStates[student.id] || studentTooltipHoverStates[student.id]">
								<div
									(mouseenter)="setStudentTooltipHoverState(student.id, true)"
									(mouseleave)="delayTooltipForTooltipHover(student, false)"
									#_originErrorTooltip>
									<app-origin-location-tooltip
										*ngIf="true"
										[@TooltipFadeInOut]="'visible'"
										[classes]="originInfo[student.id]?.classes || []"
										[period]="(scheduleService.currentPeriod$ | async)?.long_name || (scheduleService.nextPeriod$ | async)?.long_name"
										[padding]="'12px'"
										[student]="student"
										[width]="'198px'"
										[tooltipType]="'error'"
										[dayType]="(scheduleService.scheduleGroup$ | async)?.day_name">
									</app-origin-location-tooltip>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</mat-list-item>
		</div>
		<div *ngIf="students?.length === 0 && groups?.length === 0" class="no-results-found-container">
			No results found
			<p *ngIf="emptyStateString">{{ emptyStateString }}</p>
		</div>
	</mat-list>
</div>
