<div class="wrapper">
	<div class="backdrop" *ngIf="isFocus" appCrossPointerEventTarget (pointerClickEvent)="focusEvent(false)"></div>
	<div class="container" [ngClass]="{ focused: isFocus }">
		<div class="header" [style.background-color]="isFocus ? 'white' : 'transparent'">
			<div class="logo-icon" *ngIf="!isFocus" appCrossPointerEventTarget (pointerClickEvent)="goToHomePage()">
				<img [src]="'./assets/Smartpass Logo (Green).svg'" alt="logo" />
			</div>
			<div class="close" *ngIf="isFocus">
				<app-icon-only-button [size]="'regular'" [icon]="'Arrow Left'" (buttonClick)="focusEvent(false)"></app-icon-only-button>
			</div>

			<div class="input-container" [@inputAnimate]="isFocus ? 'open' : 'close'">
				<div class="input">
					<app-round-input
						[selectReset$]="resetInputValue$"
						[focused]="focused"
						[boxShadow]="false"
						[width]="'100%'"
						[height]="'40px'"
						[minWidth]="width"
						[fieldIconPosition]="'left'"
						[placeholder]="'Search Students'"
						[backgroundColor]="'#F0F2F5'"
						(focusEvent)="focusEvent($event)"
						(ontextupdate)="search($event)"></app-round-input>
				</div>
			</div>
		</div>

		<ng-container *ngIf="results$ | async as results">
			<div class="content" *ngIf="isFocus" [style.height]="loaded && results.length == 0 ? '30px' : '215px'">
				<div class="loading-theme_blue-gray spinner ds-w-100 ds-h-100 ds-flex-center-center" *ngIf="false">
					<mat-spinner [diameter]="55" [color]="'accent'"></mat-spinner>
				</div>
				<div class="empty-state-no-search" *ngIf="!loading && !loaded && results?.length == 0">
					<img [src]="'./assets/emptyStates/Students Empty State.svg'" alt="empty-state" />
					<div class="title">Search for students</div>
					<div class="subtitle">See previous passes, get quick stats, check permissions, and more...</div>
				</div>
				<div class="empty-state-searched" *ngIf="loaded && results.length == 0">No students found</div>
				<div class="search-result" *ngIf="results.length">
					<div
						*ngFor="let user of results"
						class="result-container hover:tw-bg-gray-100 active:hover:tw-bg-gray-150"
						(pointerClickEvent)="goToUserPage(user)"
						appCrossPointerEventTarget>
						<div class="value">
							<div class="avatar">
								<div
									*ngIf="user.profile_picture; else d"
									[ngStyle]="{ background: 'url(' + user.profile_picture + ') no-repeat left center/cover', width: '40px', height: '40px' }"></div>
								<ng-template #d>
									<img width="40" height="40" [src]="'./assets/Avatar Default.svg' | resolveAsset" alt="Avatar" />
								</ng-template>
							</div>
							<div class="title" notranslate>{{ user.display_name }}</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
