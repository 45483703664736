import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { WrappedIcon } from './wrapped-icon.component';

@Component({
	selector: 'sp-dynamic-dialog-header',
	template: `
		<div class="tw-p-7 tw-pb-6" [ngStyle]="{ 'box-shadow': scrolled ? '0px 9.6px 9.6px 0px rgba(16, 20, 24, 0.05)' : 'none' }">
			<div class="tw-flex tw-justify-between">
				<sp-wrapped-icon *ngIf="icon" [icon]="icon"></sp-wrapped-icon>
				<div class="tw-flex-grow">
					<div class="tw-font-bold tw-flex-col tw-text-xl tw-text-navy-500" *ngIf="headerText">
						{{ headerText }}
					</div>
				</div>
				<div class="tw-flex-col tw-cursor-pointer" *ngIf="showCloseIcon" (click)="dialogClose.emit()">
					<img src="assets/Close (Gray-500).svg" />
				</div>
			</div>
			<div class="tw-m-0">
				<ng-template [ngTemplateOutlet]="subHeaderTemplate" *ngIf="subHeaderTemplate"></ng-template>
			</div>
		</div>
	`,
})
export class DynamicDialogHeaderComponent {
	@Input() icon?: WrappedIcon;
	@Input() headerText?: string;
	@Input() showCloseIcon = false;
	@Input() subHeaderTemplate?: TemplateRef<unknown>;
	@Input() scrolled = false;
	@Output() dialogClose = new EventEmitter<void>();
}
