<div
	[ngClass]="{
		'from-wrapper-to-date': formState.forLater,
		'from-wrapper': !formState.forLater,
		'no-fly-time-style': formState.noFlyTimeActive && !formState.forLater
	}">
	<div
		*ngIf="isStaff || (!isStaff && formState.forLater)"
		[ngClass]="headerTransition"
		#header
		[attr.data-motion-opacity]="(frameMotion$ | async).direction">
		<div class="header-background"></div>
		<div style="margin-left: 16px" class="back-button back-button-grey" (click)="back()" *ngIf="!data?.fromAdmin">
			<img [src]="'./assets/Back-Button.svg' | resolveAsset" alt="Back to Student Select" />
			<span>Back</span>
		</div>
		<div style="margin-left: 16px" class="back-button back-button-grey" *ngIf="data?.fromAdmin"></div>
		<div *ngIf="isStaff && numSelectedStudents" style="margin-right: 16px" class="selected-students">
			<img class="group-icon" [src]="'./assets/Group (Blue-Gray).svg' | resolveAsset" />
			<div class="text">{{ numSelectedStudents }}</div>
		</div>
	</div>

	<div class="header" [attr.data-motion-translate]="(frameMotion$ | async).direction" #header>
		<div class="home-icon">
			<img class="home" [src]="'./assets/Home (Navy).svg' | resolveAsset" />
		</div>
		<p>Select your current room</p>
	</div>

	<div class="from-content" [attr.data-motion-translate]="(frameMotion$ | async).direction" (scroll)="tableScroll($event)" #rc>
		<div class="location-tables">
			<app-location-table-v2
				class="locations"
				[forStaff]="isStaff"
				[selectedStudents]="formState.data.roomStudents ? formState.data.roomStudents : formState.data.selectedStudents"
				[rightHeaderText]="true"
				[inputWidth]="'240px'"
				[forLater]="true"
				[showStars]="false"
				[noRightStar]="true"
				[showPicture]="true"
				[showFavorites]="true"
				[searchByTeacherName]="true"
				[placeholder]="placeholder"
				[dummyString]="'Check your spelling and try again.'"
				[currentPage]="'from'"
				[updatedLocation$]="updatedLocation$"
				(choiceSelected)="locationChosen($event)">
			</app-location-table-v2>
		</div>
	</div>

	<app-pass-limit-info *ngIf="!isStaff" [passLimitInfo]="formState?.passLimitInfo"></app-pass-limit-info>
</div>
