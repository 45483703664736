import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CreateFormService } from '../../../create-hallpass-forms/create-form.service';
import { Navigation } from '../../../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { GroupsSettingsComponent } from '../../../create-hallpass-forms/main-hallpass--form/student-groups/groups-settings/groups-settings.component';
import { Location } from '../../../models/Location';
import { Pinnable } from '../../../models/Pinnable';
import { StudentList } from '../../../models/StudentList';
import { User } from '../../../models/User';
import { PassesAnimations } from '../../../passes/passes.animations';
import { SPClassWithUsers } from '../../../services/classes.service';
import { FeatureFlagService, FLAGS } from '../../../services/feature-flag.service';
import { HallPassesService } from '../../../services/hall-passes.service';
import { HomepageService } from '../../../services/homepage.service';
import { KioskModeService } from '../../../services/kiosk-mode.service';
import { LocationsService } from '../../../services/locations.service';
import { ScheduleService } from '../../../services/schedule.service';
import { ScreenService } from '../../../services/screen.service';
import { UserService } from '../../../services/user.service';

type OriginInfo = {
	roomName: string;
	icon: string;
	backgroundColor: SafeStyle;
	originStatus: 'ok' | 'error';
	classes?: SPClassWithUsers[];
};
@Component({
	selector: 'app-groups-and-student-select',
	templateUrl: './groups-and-student-select.component.html',
	styleUrls: ['./groups-and-student-select.component.scss'],
	animations: [PassesAnimations.TooltipFadeInOut],
})
export class GroupsAndStudentSelectComponent implements OnInit, OnDestroy, OnChanges {
	@Input() groups: StudentList[] = [];
	@Input() students: User[] = [];
	@Input() selectedNumber: number;
	@Input() canEditGroup = true;
	@Input() emptyStateString = '';
	@Output() handleSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() mouseDown: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() formState: Navigation;

	//styling
	@Input() width: string;
	@Input() height: string;
	@Input() maxHeight: string;
	@Input() border: string;
	@Input() borderBottomRightRadius: string;
	@Input() borderBottomLeftRadius: string;
	@Input() boxShadow: string;
	@Input() background: string;
	@Input() backgroundHovered: string;
	@Input() minHeight: string;

	@Output() createGroupEmit: EventEmitter<Navigation> = new EventEmitter<Navigation>();

	@ViewChild('_item') currentGroupElement: ElementRef;

	isEmptyGroups = false;
	originInfo: Record<number, OriginInfo | null> = {};
	studentHoverStates: { [key: string]: boolean } = {};
	studentTooltipHoverStates: { [key: string]: boolean } = {};

	frameMotion$: BehaviorSubject<any> = this.formService.getFrameMotionDirection();
	destroy$: Subject<void> = new Subject<void>();

	hasSchedulesFF = false;
	pinnables: Pinnable[] = [];

	isKiosk = this.kioskService.isKioskMode();

	importCreateGroupDialogRef: MatDialogRef<GroupsSettingsComponent> | null = null;

	constructor(
		public userService: UserService,
		public sanitizer: DomSanitizer,
		public formService: CreateFormService,
		public screenService: ScreenService,
		public dialog: MatDialog,
		public featureFlagService: FeatureFlagService,
		public scheduleService: ScheduleService,
		public pinnableService: HallPassesService,
		public homePageService: HomepageService,
		public locationsService: LocationsService,
		public kioskService: KioskModeService
	) {}

	ngOnInit(): void {
		this.hasSchedulesFF = this.featureFlagService.isFeatureEnabledV2(FLAGS.Schedules);

		this.pinnableService.pinnables$.pipe(takeUntil(this.destroy$)).subscribe((pinnables) => {
			this.pinnables = pinnables;
		});

		of(!this.groups || (this.groups && !this.groups.length))
			.pipe(takeUntil(this.destroy$))
			.subscribe((v) => {
				this.isEmptyGroups = v;
			});

		if (this.hasSchedulesFF) {
			this.groups?.forEach((group) => {
				group.users?.forEach((student) => {
					this.getStudentOriginAndUpdateMap(student);
				});
			});

			this.students?.forEach((student) => {
				this.getStudentOriginAndUpdateMap(student);
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.students && this.hasSchedulesFF) {
			this.groups?.forEach((group) => {
				group.users?.forEach((student) => {
					this.getStudentOriginAndUpdateMap(student);
				});
			});

			this.students?.forEach((student) => {
				this.getStudentOriginAndUpdateMap(student);
			});
		}
	}

	private getPinnableBackground(pin: Pinnable): SafeStyle {
		const gradient: string[] = pin?.color_profile?.gradient_color.split(',');
		return this.sanitizer.bypassSecurityTrustStyle('radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')');
	}

	private getPinnableFromLocation(location: Location): Pinnable | undefined {
		return this.pinnables.find((p) => p?.location?.id === location?.id) || this.pinnables.find((p) => p?.category === location?.category);
	}

	private getStudentOriginAndUpdateMap(student: User): void {
		if (
			this.featureFlagService.isFeatureEnabledV2(FLAGS.ActivitiesWithoutPasses) &&
			student.current_activities &&
			student.current_activities.length
		) {
			this.locationsService
				.location$(student.current_activities[0].location_id)
				.pipe(
					filter((l) => !!l),
					take(1)
				)
				.subscribe((location) => {
					const title = location!.title;
					const pin = this.getPinnableFromLocation(location!);
					const backgroundColor = this.getPinnableBackground(pin!);
					this.originInfo[student.id] = {
						roomName: title,
						icon: pin!.icon,
						backgroundColor: backgroundColor,
						originStatus: 'ok',
					};
				});
			return;
		}
		if (this.hasSchedulesFF && student.current_classes?.length) {
			const title = student.current_classes[0].room.title;
			const pin = this.getPinnableFromLocation(student.current_classes[0].room);
			const backgroundColor = this.getPinnableBackground(pin!);
			this.originInfo[student.id] = {
				roomName: title,
				icon: pin!.icon,
				backgroundColor: backgroundColor,
				originStatus: student.current_classes.length > 1 ? 'error' : 'ok',
				classes: student.current_classes,
			};
			return;
		}
		this.originInfo[student.id] = null;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getBackground(item): string {
		if (item.hovered) {
			return this.backgroundHovered ? this.backgroundHovered : '#F5F6F8';
		} else {
			return this.background ? this.background : '#FFFFFF';
		}
	}

	delayTooltipForStudentSelect(student: User, value: boolean): void {
		setTimeout(() => {
			this.setStudentHoverState(student.id, value);
		}, 350);
	}

	delayTooltipForTooltipHover(student: User, value: boolean): void {
		setTimeout(() => {
			this.setStudentTooltipHoverState(student.id, value);
		}, 350);
	}

	setStudentHoverState(studentId: number, state: boolean): void {
		if (state) {
			setTimeout(() => {
				this.studentHoverStates[studentId] = state;
			}, 300);
		} else {
			this.studentHoverStates[studentId] = state;
		}
	}

	setStudentTooltipHoverState(studentId: number, state: boolean): void {
		if (state) {
			setTimeout(() => {
				this.studentTooltipHoverStates[studentId] = state;
			}, 300);
		} else {
			this.studentTooltipHoverStates[studentId] = state;
		}
	}

	onSelect(item): void {
		this.handleSelect.emit(item);
	}

	editGroup(event: Event, group: StudentList): void {
		event.stopPropagation();
		this.importCreateGroupDialogRef = this.dialog.open(GroupsSettingsComponent, {
			closeOnNavigation: true,
			panelClass: 'app-group-settings-container',
			data: {
				formState: this.formState,
				groupToEdit: group,
			},
		});
	}
}
