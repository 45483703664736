import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SchoolActivity, SchoolActivityInstance } from 'app/models';

/**
 * AddSessionsComponent
 * Given an activity and a list of upcoming instances, this component decides
 * which behavior should be used for the "New Session" button.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'sp-activity-add-sessions',
	template: ` <sp-add-custom-session-form *ngIf="shouldAddCustom()" [activity]="activity"></sp-add-custom-session-form>
		<sp-add-repeating-session-dates
			*ngIf="!shouldAddCustom()"
			[activity]="activity"
			[upcomingInstances]="upcomingInstances"></sp-add-repeating-session-dates>`,
})
export class AddSessionsComponent {
	@Input() activity!: SchoolActivity;
	@Input() upcomingInstances: SchoolActivityInstance[] = [];

	shouldAddCustom(): boolean {
		return this.activity.schedule_type === 'custom' && this.activity.custom_repeat_rule == '';
	}
}
