import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { isEqual } from 'lodash';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { DeviceDetection } from '../device-detection.helper';
import { ColorProfile, User } from '../models';
import { HallPassesService } from '../services/hall-passes.service';
import { KioskModeService } from '../services/kiosk-mode.service';
import { LocationsService } from '../services/locations.service';
import { NotificationService, UserNotificationSettings } from '../services/notification-service';
import { StorageKeys, StorageService } from '../services/storage.service';
import { UserService } from '../services/user.service';
import { NotificationRoomFormComponent } from './notification-room-form/notification-room-form.component';

type RoomId = number | string;

interface RoomNotifIcon {
	id: RoomId;
	title: string;
	color_profile: ColorProfile;
	icon: string;
}

@Component({
	selector: 'app-notification-form',
	templateUrl: './notification-form.component.html',
	styleUrls: ['./notification-form.component.scss'],
})
export class NotificationFormComponent implements OnInit, OnDestroy {
	user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	rooms$: Observable<RoomNotifIcon[]> = of([]);
	form: UntypedFormGroup = null;
	notificationSoundsFlag = false;
	isKioskMode = false;

	isDisabledNotif!: boolean;
	isSafari!: boolean;
	destroy$ = new Subject<void>();

	constructor(
		private dialogRef: MatDialogRef<NotificationFormComponent>,
		private userService: UserService,
		private locationsService: LocationsService,
		private notificationService: NotificationService,
		private hallPassService: HallPassesService,
		private kioskMode: KioskModeService,
		private dialog: MatDialog,
		private fb: UntypedFormBuilder,
		private storageService: StorageService
	) {}

	private originalFormValue: object;

	ngOnInit(): void {
		this.isDisabledNotif = !NotificationService.hasPermission;
		this.isSafari = DeviceDetection.isSafari();
		this.userService.userJSON$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.user$.next(User.fromJSON(user));
		});

		this.notificationSoundsFlag = this.userService.getFeatureFlagAlertSounds();
		this.isKioskMode = this.kioskMode.isKioskMode();

		const settings$ = new Subject<UserNotificationSettings>();
		this.user$
			.pipe(
				takeUntil(this.destroy$),
				filter((user) => !!user),
				switchMap((user) => this.notificationService.getUserNotification(user))
			)
			.subscribe((settings: UserNotificationSettings) => {
				this.form = this.generateForm(settings);
				settings$.next(settings);
				settings$.complete();
			});

		this.rooms$ = this.generateRooms();
		forkJoin([settings$, this.rooms$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([settings, rooms]) => {
				const roomIds: RoomId[] = [];
				rooms.forEach((room) => {
					let roomSettings;
					roomIds.push(room.id);
					if (room.id in settings.myRooms) {
						roomSettings = this.fb.group(settings.myRooms[room.id]);
					} else {
						roomSettings = this.fb.group({
							to: true,
							from: true,
							expired: true,
						});
					}

					this.getRooms().addControl(room.id as string, roomSettings);
				});

				const roomsToRemove: RoomId[] = [];
				Object.entries(settings?.myRooms || []).forEach((roomId) => {
					if (!roomIds.includes(roomId[0])) {
						roomsToRemove.push(roomId[0]);
					}
				});
				roomsToRemove.forEach((roomId) => {
					delete settings.myRooms[roomId];
				});

				// only here the form is fully initialized
				this.originalFormValue = this.form.value;
			});
	}

	async ngOnDestroy(): Promise<void> {
		// accurate way to tell the form has changed
		if (isEqual(this.form?.value, this.originalFormValue)) {
			return;
		}

		const user = this.user$.getValue();
		if (!user) {
			console.warn('User is null, cannot save notification form');
			return;
		}

		try {
			await lastValueFrom(this.notificationService.updateUserNotification(user, this.form.getRawValue()));
			this.destroy$.next();
			this.destroy$.complete();
		} catch {
			console.warn('failed to save notification changes');
		}
	}

	get hasEmail() {
		if (this.user$.value == null) {
			return false;
		}

		return !this.user$.value.primary_email.endsWith('spnx.local');
	}

	get isUserLoaded() {
		return this.user$.value != null;
	}

	get isStudent() {
		if (this.user$.value == null) {
			return true;
		}

		return this.user$.value.isStudent();
	}

	get isAdmin() {
		return this.user$.value.isAdmin();
	}

	get isUsingEmail() {
		const controls = [
			'passRequestsEmail',
			'scheduledPassesEmail',
			'studentPassesEmail',
			'reportsEmail',
			'encounterPreventionEmail',
			'weeklySummaryEmail',
		];

		return controls.some((control) => this.form.get(control)?.value) && this.hasEmail;
	}

	get isUsingPush() {
		const controls = ['passRequestsPush', 'scheduledPassesPush', 'myRoomsPush', 'studentPassesPush'];

		return controls.some((control) => this.form.get(control).value);
	}

	get studentBottomText() {
		if (!this.hasEmail) {
			return 'Notifications will be sent to your email.';
		} else if (!this.isSafari) {
			return 'Notifications will be sent to this browser.';
		} else {
			return false;
		}
	}

	get emailByline() {
		if (this.hasEmail) {
			return this.user$.value.primary_email;
		} else {
			return 'Email notifications are not supported for usernames';
		}
	}

	get students() {
		return this.form.get('studentIds') as UntypedFormArray;
	}

	private getRooms(): UntypedFormGroup {
		return this.form.get('myRooms') as UntypedFormGroup;
	}

	private getRoom(id: string): UntypedFormGroup {
		if (this.getRooms().contains(id)) {
			return this.getRooms().controls[id] as UntypedFormGroup;
		}
		return null;
	}

	roomBackground(gradient): string {
		const colors = gradient.split(',');
		return `radial-gradient(circle at 73% 71%, ${colors[0]} 0%, ${colors[1]} 144%)`;
	}

	activeNotifications(type: string): string {
		const $push = this.form.controls[type + 'Push'];
		const $email = this.form.controls[type + 'Email'];
		// TODO uncomment bellow and the log will be shown continuosly in the console
		//console.log($email?.value)
		const push = $push?.value;
		const email = $email?.value;
		if (push && email) {
			return 'Push, Email';
		} else if (push) {
			return 'Push';
		} else if (email) {
			return 'Email';
		} else {
			return 'Off';
		}
	}

	activeRoomsNotifications(roomId: string): string {
		const room = this.getRoom(roomId);
		if (room == null) {
			return '';
		}

		let result = [];
		if (room.get('to').value) {
			result.push('To');
		}
		if (room.get('from').value) {
			result.push('From');
		}
		if (room.get('expired').value) {
			result.push('Expired');
		}
		if (result.length === 0) {
			result = ['Off'];
		}

		return result.join(', ');
	}

	send(): void {
		if (this.user$.value == null) {
			return;
		}

		this.userService.sendTestNotification(this.user$.value.id).pipe(takeUntil(this.destroy$)).subscribe();
	}

	close(): void {
		this.dialogRef.close();
	}

	openRoomInfo(room: RoomNotifIcon): void {
		const roomControl = this.getRoom(room.id as string);
		if (roomControl == null) {
			return;
		}

		this.dialog
			.open(NotificationRoomFormComponent, {
				panelClass: 'form-dialog-container',
				backdropClass: 'custom-backdrop',
				data: {
					room: room,
					roomData: roomControl,
				},
				height: '285px',
				width: '450px',
			})
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				console.log(result);
			});
	}

	private generateRooms(): Observable<RoomNotifIcon[]> {
		return forkJoin([
			this.user$
				.pipe(filter<User>(Boolean))
				.pipe(switchMap((user) => this.locationsService.getLocationsWithTeacherHTTP(user)))
				.pipe(take(1)),
			this.hallPassService.getPinnables().pipe(take(1)),
		]).pipe(
			map((data) => {
				const locations = data[0];
				const pinnables = data[1];
				if (pinnables == null) {
					return [];
				}

				const roomIcons: RoomNotifIcon[] = [];
				for (const location of locations) {
					for (const pin of pinnables) {
						if (location.id === pin.id || location.category === pin.category) {
							roomIcons.push({
								id: location.id,
								title: location.title,
								color_profile: pin.color_profile,
								icon: pin.icon,
							});
						}
					}
				}

				return roomIcons;
			})
		);
	}

	private generateForm(settings: UserNotificationSettings): UntypedFormGroup {
		if (this.isStudent) {
			const studentForm = this.fb.group({});
			if (this.notificationSoundsFlag) {
				const allNotificationSounds = this.storageService.getItem(StorageKeys.sounds) === 'true';
				studentForm.addControl('allNotificationSounds', this.fb.control(allNotificationSounds));
				studentForm.controls.allNotificationSounds.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
					this.storageService.setItem(StorageKeys.sounds, value);
				});
			}
			return studentForm;
		}

		const result = this.fb.group({
			passRequestsPush: [settings.passRequestsPush],
			passRequestsEmail: [settings.passRequestsEmail],
			scheduledPassesPush: [settings.scheduledPassesPush],
			scheduledPassesEmail: [settings.scheduledPassesEmail],
			myRoomsPush: [settings.myRoomsPush],
			myRooms: this.fb.group({}),
			studentPassesPush: [settings.studentPassesPush],
			studentPassesEmail: [settings.studentPassesEmail],
			studentIds: this.fb.array(settings.studentIds),
			settingsVersion: [settings.settingsVersion],
		});

		if (this.isAdmin) {
			result.addControl('reportsEmail', this.fb.control(settings.reportsEmail));
			result.addControl('encounterPreventionEmail', this.fb.control(settings.encounterPreventionEmail));
			result.addControl('weeklySummaryEmail', this.fb.control(settings.weeklySummaryEmail));
		}

		if (this.notificationSoundsFlag) {
			const allNotificationSounds = this.storageService.getItem(StorageKeys.sounds) === 'true';
			result.addControl('allNotificationSounds', this.fb.control(allNotificationSounds));
			result.controls.allNotificationSounds.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
				this.storageService.setItem(StorageKeys.sounds, value);
			});
		}

		return result;
	}
}
