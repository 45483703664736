import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * The header content of a Dynamic Dialog Modal. This includes the title as well
 * as several optional slots: an icon, a subheading, and a note.
 *
 * Note that while the Dynamic Dialog has a 'X' close button that appears in the
 * header, that is actually managed by the DialogContentComponent. (So you don't
 * have to!) The title has padding to account for the button and avoid overlap.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'bp-dialog-header',
	template: `
		<div class="heading-row">
			<ng-content select="[icon]"></ng-content>
			<div class="heading-content">
				<ng-content></ng-content>
			</div>
		</div>
		<div class="subheading">
			<ng-content select="[subheading]"></ng-content>
		</div>
		<div class="note">
			<ng-content select="[note]"></ng-content>
		</div>
	`,
	styles: [
		`
			:host {
				display: flex;
				flex-direction: column;
				gap: 6px;
				position: sticky;
				top: 0;
				z-index: 1;
				background-color: var(--gray-white, #fff);
				padding: 28px 28px 24px 28px;
			}
			.heading-row {
				display: flex;
				/* Avoid overlapping with the close button: */
				padding-right: 36px;
				gap: 16px;
			}
			.heading-content {
				flex: 1;
				color: var(--navy-500, #1f195e);
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subheading {
				color: var(--navy-500, #1f195e);
				font-size: 1.25rem;
				font-weight: 700;
			}
			.subheading:empty {
				display: none;
			}
			.note {
				color: var(--gray-500, #7083a0);
				font-size: 1rem;
			}
			.note:empty {
				display: none;
			}
			/* Shift title down to center on icon when present */
			:host:has([icon]) .heading-content {
				margin-top: 3.6px;
			}
			/* Apply smaller font size when note has content */
			:host:has(.note:not(:empty)) .subheading {
				font-size: 1rem;
			}
		`,
	],
})
export class DialogHeaderComponent {}
